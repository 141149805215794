import { withTheme } from '@material-ui/core/styles';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useStore } from '../hooks';
import totBackgroundLayer2 from '../resources/images/tot-background-layer-2.png';
import totBackgroundLayer3 from '../resources/images/tot-background-layer-3.png';

type CustomBackgroundProps = {
    backgroundImage: string;
};

const Background = withTheme(
    styled.div.attrs(() => ({
        className: 'tot-background',
    }))`
        display: flex;
        flex-grow: 1;
        height: 100%;
        background: ${(props) => {
            const secondary = props.theme.palette.secondary;
            return `url(${totBackgroundLayer3}) center,
                    url(${totBackgroundLayer2}) center,
                    linear-gradient(-55deg, ${secondary.main} 0%, ${secondary.dark} 100%)`;
        }};
        background-blend-mode: soft-light, overlay, normal;
    `,
);

const CustomBackground = withTheme(
    styled.div<CustomBackgroundProps>`
        display: flex;
        flex-grow: 1;
        height: 100%;
        background: ${(props) => {
            const {
                backgroundImage,
                theme: { variables },
            } = props;

            return variables.palette.background.overlay
                ? `url(${backgroundImage}) center, ${variables.palette.background.overlay}`
                : `url(${backgroundImage}) center`;
        }};
        background-blend-mode: soft-light, overlay, normal;
    `,
);

export type TotBackgroundProps = PropsWithChildren<{}>;

export const TotBackground = observer((props: TotBackgroundProps): JSX.Element => {
    const { children } = props;
    const { background } = useStore();
    const [defaultBackground] = useFeature('defaultBackground');

    if (defaultBackground) {
        return <Background>{children}</Background>;
    }

    return <CustomBackground backgroundImage={background}>{children}</CustomBackground>;
});
