import React from 'react';

export const CheckboxCheckedIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
        <path
            fill="currentColor"
            d="M15 0H3C1.343 0 0 1.343 0 3v12c0 1.657 1.343 3 3 3h12c1.657 0 3-1.343 3-3V3c0-1.657-1.343-3-3-3zm-2.29 7.669l-4.08 4.08c-.188.19-.444.296-.71.296-.267 0-.523-.106-.71-.296l-1.92-1.92c-.393-.392-.393-1.027 0-1.419.392-.393 1.027-.393 1.42 0l1.21 1.219 3.37-3.38c.392-.392 1.028-.392 1.42 0 .392.392.392 1.028 0 1.42z"
        />
    </svg>
);
