import { ModuleDTO } from '@platform/modular-navigation-widget';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { Api } from './Api';
import { RootStore } from './RootStore';

export class ModulesStore {
    @observable private rootStore: RootStore;
    @observable private api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    loadModulesList(): Promise<ModuleDTO[]> {
        return this.api.client(apiConfigs.loadModulesList()).then((res) => res.data);
    }
}
