import React from 'react';

export const PhoneIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="2 0 20 20" fill="currentColor">
        <defs>
            <mask id="o3lvb" width="2" height="2" x="-1" y="-1">
                <path fill="#fff" d="M2 0h20v20H2z" />
                <path d="M19.438 11a3 3 0 0 1 2.47 2.9v3a3.005 3.005 0 0 1-1 2.249 3 3 0 0 1-2 .76h-.38a19 19 0 0 1-16.52-16.46 3.002 3.002 0 0 1 3-3.41h3a3 3 0 0 1 3 2.49c.03.23.07.46.12.68.1.445.231.882.39 1.31a2 2 0 0 1-1 2.48l-.42.28a12.15 12.15 0 0 0 2 2.66 12.16 12.16 0 0 0 2.66 2l.22-.45a1.999 1.999 0 0 1 2.48-1c.426.161.865.292 1.31.39.22.05.45.12.67.12zm.48 2.879c.01-.48-1.2-1.06-1.6-1.15a13.752 13.752 0 0 1-1.58-.46l-.62 1.4c-.114.242-.318.43-.571.52-.242.1-.515.1-.759 0a14.489 14.489 0 0 1-7-7 1 1 0 0 1 .49-1.33l1.4-.65c-.192-.505-.55-1.95-.61-2.36-.1-.47-.52-.8-1-.79h-3a.995.995 0 0 0-.75.34 1.091 1.091 0 0 0-.25.82 17 17 0 0 0 14.71 14.78c.297.036.594-.055.821-.25.215-.19.339-.463.339-.75l-.02-3.12z" />
            </mask>
            <filter id="o3lva" width="112" height="130" x="-44" y="-46" filterUnits="userSpaceOnUse">
                <feOffset dy="18" in="SourceGraphic" result="FeOffset1157Out" />
                <feGaussianBlur in="FeOffset1157Out" result="FeGaussianBlur1158Out" stdDeviation="14.4 14.4" />
            </filter>
        </defs>
        <g>
            <g>
                <g filter="url(#o3lva)">
                    <path
                        fill="none"
                        d="M19.438 11a3 3 0 0 1 2.47 2.9v3a3.005 3.005 0 0 1-1 2.249 3 3 0 0 1-2 .76h-.38a19 19 0 0 1-16.52-16.46 3.002 3.002 0 0 1 3-3.41h3a3 3 0 0 1 3 2.49c.03.23.07.46.12.68.1.445.231.882.39 1.31a2 2 0 0 1-1 2.48l-.42.28a12.15 12.15 0 0 0 2 2.66 12.16 12.16 0 0 0 2.66 2l.22-.45a1.999 1.999 0 0 1 2.48-1c.426.161.865.292 1.31.39.22.05.45.12.67.12zm.48 2.879c.01-.48-1.2-1.06-1.6-1.15a13.752 13.752 0 0 1-1.58-.46l-.62 1.4c-.114.242-.318.43-.571.52-.242.1-.515.1-.759 0a14.489 14.489 0 0 1-7-7 1 1 0 0 1 .49-1.33l1.4-.65c-.192-.505-.55-1.95-.61-2.36-.1-.47-.52-.8-1-.79h-3a.995.995 0 0 0-.75.34 1.091 1.091 0 0 0-.25.82 17 17 0 0 0 14.71 14.78c.297.036.594-.055.821-.25.215-.19.339-.463.339-.75l-.02-3.12z"
                        mask='url("#o3lvb")'
                    />
                    <path
                        fill-opacity=".03"
                        d="M19.438 11a3 3 0 0 1 2.47 2.9v3a3.005 3.005 0 0 1-1 2.249 3 3 0 0 1-2 .76h-.38a19 19 0 0 1-16.52-16.46 3.002 3.002 0 0 1 3-3.41h3a3 3 0 0 1 3 2.49c.03.23.07.46.12.68.1.445.231.882.39 1.31a2 2 0 0 1-1 2.48l-.42.28a12.15 12.15 0 0 0 2 2.66 12.16 12.16 0 0 0 2.66 2l.22-.45a1.999 1.999 0 0 1 2.48-1c.426.161.865.292 1.31.39.22.05.45.12.67.12zm.48 2.879c.01-.48-1.2-1.06-1.6-1.15a13.752 13.752 0 0 1-1.58-.46l-.62 1.4c-.114.242-.318.43-.571.52-.242.1-.515.1-.759 0a14.489 14.489 0 0 1-7-7 1 1 0 0 1 .49-1.33l1.4-.65c-.192-.505-.55-1.95-.61-2.36-.1-.47-.52-.8-1-.79h-3a.995.995 0 0 0-.75.34 1.091 1.091 0 0 0-.25.82 17 17 0 0 0 14.71 14.78c.297.036.594-.055.821-.25.215-.19.339-.463.339-.75l-.02-3.12z"
                    />
                </g>
                <path d="M19.438 11a3 3 0 0 1 2.47 2.9v3a3.005 3.005 0 0 1-1 2.249 3 3 0 0 1-2 .76h-.38a19 19 0 0 1-16.52-16.46 3.002 3.002 0 0 1 3-3.41h3a3 3 0 0 1 3 2.49c.03.23.07.46.12.68.1.445.231.882.39 1.31a2 2 0 0 1-1 2.48l-.42.28a12.15 12.15 0 0 0 2 2.66 12.16 12.16 0 0 0 2.66 2l.22-.45a1.999 1.999 0 0 1 2.48-1c.426.161.865.292 1.31.39.22.05.45.12.67.12zm.48 2.879c.01-.48-1.2-1.06-1.6-1.15a13.752 13.752 0 0 1-1.58-.46l-.62 1.4c-.114.242-.318.43-.571.52-.242.1-.515.1-.759 0a14.489 14.489 0 0 1-7-7 1 1 0 0 1 .49-1.33l1.4-.65c-.192-.505-.55-1.95-.61-2.36-.1-.47-.52-.8-1-.79h-3a.995.995 0 0 0-.75.34 1.091 1.091 0 0 0-.25.82 17 17 0 0 0 14.71 14.78c.297.036.594-.055.821-.25.215-.19.339-.463.339-.75l-.02-3.12z" />
            </g>
        </g>
    </svg>
);
