import { Box, Grid } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';
import { StartPageHeaderLink } from './StartPageHeaderLink';

export const StartAuthHeaderLink = observer((): JSX.Element => {
    const { api, personStore } = useStore();

    switch (api.authStatus) {
        case 'ok':
            return (
                <Grid item>
                    <Box pt={2.25}>
                        <StartPageHeaderLink
                            component={RouterLink}
                            to={personStore.redirectPath}
                            color="secondary"
                            underline="none"
                        >
                            {personStore.person.lastNameWithInitials}
                        </StartPageHeaderLink>
                    </Box>
                </Grid>
            );
        default:
            return (
                <Grid item>
                    <Box pt={0.4}>
                        <StartPageHeaderLink
                            component={RouterLink}
                            to={clientRoute.login}
                            color="secondary"
                            underline="none"
                        >
                            <Grid container item alignItems="center">
                                <FormattedMessage id="startPage.headerLinks.login" />
                                <Box ml={1} style={{ lineHeight: 1 }}>
                                    <ExitToAppIcon />
                                </Box>
                            </Grid>
                        </StartPageHeaderLink>
                    </Box>
                </Grid>
            );
    }
});
