import { SidebarVars } from '../../../../types';

const width = 312;
const widthXl = 321;
const offsetVertical = 14;
const errorSize = 24;

export default {
    width,
    widthXl,
    offsetVertical,
    errorSize,
} as SidebarVars;
