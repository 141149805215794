import {
    Button,
    Grid,
    IconButton,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import { AuthorizationCheck, DeleteActionMenuItem, MenuButton } from '../../components';
import { useFlag, useStore } from '../../hooks';
import { CommissionMemberModel } from '../../models';
import { DotMenuIcon } from '../../resources';
import { RouteParamsDefault } from '../../types';
import { AddCommissionMemberDialog } from './AddCommissionMemberDialog';

export const CommissionMembers = observer((): JSX.Element => {
    const { commissionStore } = useStore();
    const { commissionMembers } = commissionStore;
    const { id } = useParams<RouteParamsDefault>();
    const intl = useIntl();
    const [isModalOpen, openModal, closeModal] = useFlag();
    const [isHeadModal, enableHeadModal, disableHeadModal] = useFlag();
    const [members, setMembers] = useState<CommissionMemberModel[]>([]);

    useEffect(() => {
        commissionMembers(id).then(setMembers);
    }, [id]);

    const loadMembers = async (): Promise<void> => {
        const newMembers = await commissionStore.loadCommissionMembers(id);
        setMembers(newMembers);
    };

    const deleteMember = (memberId: string) => async (): Promise<void> => {
        await commissionStore.deleteMember(memberId);
        await loadMembers();
    };

    const isCanAssignHead: boolean = !members.find((member) => member.isHead);

    const renderActionItems = (row: CommissionMemberModel): (() => ReactNode[]) => {
        return (): ReactNode[] => [
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                message={intl.formatMessage(
                    { id: 'commission.members.confirmDeletionInfoText' },
                    { title: row.person.name },
                )}
                onConfirm={deleteMember(row.id)}
            />,
        ];
    };

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton onClick={onClick}>
                <SvgIcon>
                    <DotMenuIcon />
                </SvgIcon>
            </IconButton>
        );
    };

    const renderActions = (row: CommissionMemberModel): JSX.Element => {
        return (
            <MenuButton
                disablePortal={true}
                renderButton={renderActionsButton}
                renderMenuItems={renderActionItems(row)}
            />
        );
    };

    const openAddHeadMemberModal = (): void => {
        enableHeadModal();
        openModal();
    };

    const openAddMemberModal = (): void => {
        disableHeadModal();
        openModal();
    };

    return (
        <React.Fragment>
            <AddCommissionMemberDialog
                commissionId={id}
                isHeadModal={isHeadModal}
                isOpen={isModalOpen}
                setIsClosed={closeModal}
                onSuccessSubmit={loadMembers}
            />
            <Grid container direction="column" spacing={5}>
                <Grid item container direction="row" justify="space-between">
                    <Grid item>
                        <Typography variant="h3">
                            <FormattedMessage id="commission.members.title" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <AuthorizationCheck
                            entityId={id}
                            entityCode={entities.Commission}
                            permCode={permissions.Commission.Edit}
                        >
                            <Grid container spacing={2}>
                                {isCanAssignHead && (
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="t-addHeadMember-btn"
                                            onClick={openAddHeadMemberModal}
                                        >
                                            <FormattedMessage id="commission.members.addHeadMember" />
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className="t-addMember-btn"
                                        onClick={openAddMemberModal}
                                    >
                                        <FormattedMessage id="commission.members.addMember" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography>№</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="common.user" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="commission.members.memberRole" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {members.map((entry, index) => {
                                    const entryIndex = index + 1;
                                    return (
                                        <TableRow key={entry.id} hover>
                                            <TableCell>{entryIndex}</TableCell>
                                            <TableCell>
                                                <Link
                                                    component={NavLink}
                                                    underline="none"
                                                    to={generatePath(clientRoute.user, { id: entry.person.userId })}
                                                >
                                                    {entry.person.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {entry.isHead ? (
                                                    <FormattedMessage id="commission.members.head" />
                                                ) : (
                                                    <FormattedMessage id="commission.members.member" />
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                <AuthorizationCheck
                                                    key="delete"
                                                    entityId={id}
                                                    entityCode={entities.Commission}
                                                    permCode={permissions.Commission.Delete}
                                                >
                                                    <Tooltip title={intl.formatMessage({ id: 'common.actions' })}>
                                                        {renderActions(entry)}
                                                    </Tooltip>
                                                </AuthorizationCheck>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    );
});
