const commission: Record<string, string | Record<string, string>> = {
    listTitle: 'Экспертные группы',
    create: 'Создать экспертную группу',
    fields: {
        manager: 'Председатель',
        members: 'Эксперты',
    },
    actions: {
        delete: 'Удалить экспертную группу',
        edit: 'Редактировать экспертную группу',
    },
    pagingInfo:
        'Всего {count, number} экспертн{count, plural, one {ая} few {ых} other {ых}} групп{count, plural, one {а} few {} other {}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmDeletionInfoText: 'Вы действительно хотите удалить экспертную группу "{title}"?',
    members: {
        member: 'Эксперт',
        head: 'Председатель',
    },
};

export default commission;
