import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { SubjectListPage } from './subject-list';
import { SubjectPage } from './subject-page';

export const SubjectPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route path={clientRoute.subject}>
                    <SubjectPage />
                </Route>
                <Route>
                    <SubjectListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
