import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { TransitionDialogProps } from './TransitionDialog';

export type LifeCycleTransitionConfirmationDialogProps = TransitionDialogProps & {
    isNotAllVoters: boolean;
};

export const LifeCycleTransitionConfirmationDialog = observer(
    (props: LifeCycleTransitionConfirmationDialogProps): JSX.Element => {
        const {
            transition,
            isModalOpen,
            lifeCycleTransition,
            handleErrorTransition,
            handleSuccessTransition,
            setModalIsClosed,
            isNotAllVoters,
        } = props;
        const { formatMessage } = useIntl();

        const handleConfirm = (): Promise<void> => {
            return lifeCycleTransition()
                .then(handleSuccessTransition)
                .catch(handleErrorTransition)
                .finally(setModalIsClosed);
        };

        const message = isNotAllVoters
            ? formatMessage({
                  id: 'commissionSession.SessionProcedureTransitionVotingToCommonDecisionMaking.notAllVoters',
              })
            : formatMessage({ id: 'common.lifeCycleConfirmText' }, { toState: transition.toStateTitle });

        const title = isNotAllVoters
            ? formatMessage({ id: 'common.SessionProcedureTransitionVotingToCommonDecisionMaking' })
            : formatMessage({ id: 'common.lifeCycleConfirmTitle' });

        const confirmText = isNotAllVoters
            ? formatMessage({
                  id: 'common.yes',
              })
            : transition.params.title;

        const cancelText = isNotAllVoters
            ? formatMessage({
                  id: 'common.no',
              })
            : undefined;

        return (
            <ConfirmationDialog
                id="confirm-transition"
                setIsClosed={setModalIsClosed}
                onConfirm={handleConfirm}
                message={message}
                isOpen={isModalOpen}
                title={title}
                keepMounted={true}
                confirmText={confirmText}
                className="t-transition-dialog"
                cancelText={cancelText}
            />
        );
    },
);
