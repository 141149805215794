import React from 'react';

export const PenIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
            <g>
                <path
                    fill="#35a7ff"
                    d="M19.928 5.289a.666.666 0 0 1 0 .14c.01.08.01.16 0 .24-.055.122-.13.233-.22.33l-2.84 2.78-10.87 10.93c-.201.2-.477.305-.76.29H.998a1 1 0 0 1-1-1v-4.24a.999.999 0 0 1 .29-.71l10.93-10.93 2.83-2.83a.998.998 0 0 1 .71-.29.998.998 0 0 1 .71.29l4.24 4.24c.187.189.291.444.29.71l-.07.05zm-5.17 2.78l-2.83-2.83-9.93 9.93v2.83h2.83zm2.82-2.83l-2.82-2.82-1.42 1.41 2.83 2.83z"
                />
            </g>
        </g>
    </svg>
);
