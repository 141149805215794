import { LikeFilter } from '../models';
import UserModel from '../models/person/UserModel';
import { CodeTitle, IdTitle } from './withTitle';

export type PersonDTO = {
    uuid: string;
    lastName: string;
    firstName: string;
    middleName?: string;
};

export type UserPersonDTO = {
    userId: string;
    person: PersonDTO;
};

export type UserStatusCode = 'blocked' | 'active' | 'inactive';

export type UserRowDTO = {
    login: IdTitle;
    status: UserStatusCode;
    name: string;
    roles: string;
};

export type UserFilter = {
    name: LikeFilter;
    login: LikeFilter;
};

export type PersonInfo = {
    user: UserModel;
    person: PersonDTO;
    roles: string[];
};

export type RolesSettings = {
    roles: CodeTitle[];
};

export type RolesSettingsDTO = {
    roles: string[];
};

export enum RolesFields {
    roles = 'roles',
}
