import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { DefaultContentContainer, GeneralizationTableCell, NoDataText } from './GeneralizationTableStyled';

export type GeneralizationTableProps = {
    tableIsEmpty: boolean;
    categoriesElements: ReactNode[];
    attributesElements: ReactNode[];
};

export const GeneralizationTable = observer((props: GeneralizationTableProps): JSX.Element => {
    const { tableIsEmpty, categoriesElements, attributesElements } = props;

    return (
        <Box width="100%" maxWidth="1256px">
            {tableIsEmpty ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <GeneralizationTableCell key="name">
                                    <Typography>
                                        <FormattedMessage id="commissionSession.generalizationTable.attributeTitle" />
                                    </Typography>
                                </GeneralizationTableCell>
                                {categoriesElements}

                                <TableCell style={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>{attributesElements}</TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <DefaultContentContainer container justify="center" alignItems="center">
                    <Grid item>
                        <NoDataText>
                            <FormattedMessage id="common.noData" />
                        </NoDataText>
                    </Grid>
                </DefaultContentContainer>
            )}
        </Box>
    );
});
