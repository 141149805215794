import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions, permissionsConfig } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    ActionsMenu,
    AuthorizationCheck,
    AuthorizationCheckAll,
    DeleteActionMenuItem,
    EditActionMenuItem,
    ExportButton,
    TableHeadTypography,
    TotTablePagination,
} from '../../../components';
import { useFlag, useStore } from '../../../hooks';
import { SubjectListModel } from '../../../models';
import { ClearFilterIcon } from '../../../resources';
import { SubjectRow } from '../../../types';
import { CreateSubjectDialog } from './CreateSubjectDialog';
import { SubjectListFilterPanel } from './SubjectListFilterPanel';

export const SubjectListPage = observer((): JSX.Element => {
    const [isModalOpen, openModal, closeModal] = useFlag();
    const { api, subjectStore, catalogStore } = useStore();
    const intl = useIntl();

    const model = useMemo(() => new SubjectListModel(api, catalogStore, subjectStore), []);
    const { sorting, categoryFilterData } = model;

    const deleteSubject = (subjectRow: SubjectRow): (() => Promise<void>) => {
        return () => {
            return subjectStore.deleteSubject(subjectRow.id).then(model.reloadData);
        };
    };

    const renderActionItems = (subjectRow: SubjectRow): (() => ReactNode[]) => {
        const { id } = subjectRow;
        return () => [
            <AuthorizationCheck key="delete-subject" {...permissionsConfig.deleteSubject(id)}>
                <DeleteActionMenuItem
                    id="delete"
                    message={intl.formatMessage(
                        { id: 'subject.confirmDeletionInfoText' },
                        { title: subjectRow.identifier },
                    )}
                    onConfirm={deleteSubject(subjectRow)}
                />
            </AuthorizationCheck>,
            <AuthorizationCheck key="edit-subject" {...permissionsConfig.editSubject(id)}>
                <EditActionMenuItem key="edit" path={generatePath(clientRoute.subjectEdit, { id })} />
            </AuthorizationCheck>,
        ];
    };

    useEffect(() => {
        return model.dispose;
    }, [model]);

    return (
        <React.Fragment>
            <CreateSubjectDialog setIsClosed={closeModal} isOpen={isModalOpen} categoryOptions={categoryFilterData} />

            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="subject.subjectListTitle" />
                                </Typography>
                            </Grid>

                            <Grid item xs={6} container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <SvgIcon>
                                                <ClearFilterIcon />
                                            </SvgIcon>
                                        }
                                        onClick={model.clearFilters}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.Subject.XlsSubjectList}
                                >
                                    <Grid item>
                                        <ExportButton
                                            queryData={model.queryData}
                                            loadRegisterList={subjectStore.exportListXls}
                                        />
                                    </Grid>
                                </AuthorizationCheck>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.AddSubject}
                                >
                                    <Grid item>
                                        <Button
                                            className="t-create-subject"
                                            color="primary"
                                            variant="contained"
                                            onClick={openModal}
                                        >
                                            <FormattedMessage id="subject.createSubject" />
                                        </Button>
                                    </Grid>
                                </AuthorizationCheck>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <SubjectListFilterPanel model={model} />
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {model.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.identifier.isActive}
                                                    direction={sorting.identifier.direction}
                                                    onClick={model.changeSorting(sorting.identifier)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <TableHeadTypography>
                                                        <FormattedMessage id="subject.fields.identifier" />
                                                    </TableHeadTypography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.title.isActive}
                                                    direction={sorting.title.direction}
                                                    onClick={model.changeSorting(sorting.title)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <TableHeadTypography>
                                                        <FormattedMessage id="subject.fields.title" />
                                                    </TableHeadTypography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableHeadTypography>
                                                    <FormattedMessage id="subject.fields.process" />
                                                </TableHeadTypography>
                                            </TableCell>
                                            <TableCell>
                                                <TableHeadTypography>
                                                    <FormattedMessage id="subject.fields.form" />
                                                </TableHeadTypography>
                                            </TableCell>
                                            <TableCell>
                                                <TableHeadTypography>
                                                    <FormattedMessage id="subject.fields.state" />
                                                </TableHeadTypography>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.created.isActive}
                                                    direction={sorting.created.direction}
                                                    onClick={model.changeSorting(sorting.created)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <TableHeadTypography>
                                                        <FormattedMessage id="subject.fields.created" />
                                                    </TableHeadTypography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableHeadTypography>
                                                    <FormattedMessage id="subject.fields.commissionSessions" />
                                                </TableHeadTypography>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.category.isActive}
                                                    direction={sorting.category.direction}
                                                    onClick={model.changeSorting(sorting.category)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <TableHeadTypography>
                                                        <FormattedMessage id="subject.fields.category" />
                                                    </TableHeadTypography>
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {model.rows.map((subject) => {
                                            const { id } = subject;
                                            return (
                                                <TableRow key={id} hover>
                                                    <TableCell>
                                                        <Link
                                                            component={NavLink}
                                                            underline="always"
                                                            to={generatePath(clientRoute.subject, { id })}
                                                        >
                                                            {subject.identifier || (
                                                                <FormattedMessage id="common.withoutNumber" />
                                                            )}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>{subject.title}</TableCell>
                                                    <TableCell>{subject.processTitle}</TableCell>
                                                    <TableCell>{subject.formTitle}</TableCell>
                                                    <TableCell>{subject.state}</TableCell>
                                                    <TableCell>
                                                        {subject.created && <FormattedDate value={subject.created} />}
                                                    </TableCell>
                                                    <TableCell>
                                                        {subject.session ? (
                                                            <Link
                                                                component={NavLink}
                                                                underline="always"
                                                                to={generatePath(clientRoute.commissionSessionProcess, {
                                                                    id: subject.session.id,
                                                                })}
                                                            >
                                                                {subject.session.title}
                                                            </Link>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {subject.category && (
                                                            <Link
                                                                component={NavLink}
                                                                underline="always"
                                                                to={generatePath(clientRoute.category, {
                                                                    id: subject.category.id,
                                                                })}
                                                            >
                                                                {subject.category.title}
                                                            </Link>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <AuthorizationCheckAll
                                                            or={true}
                                                            queries={[
                                                                permissionsConfig.deleteSubject(id),
                                                                permissionsConfig.editSubject(id),
                                                            ]}
                                                        >
                                                            <ActionsMenu
                                                                renderActionItems={renderActionItems}
                                                                row={subject}
                                                            />
                                                        </AuthorizationCheckAll>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={100}>
                                                <TotTablePagination
                                                    count={model.rowsCount}
                                                    page={model.pageNumber}
                                                    onChangePage={model.onChangePage}
                                                    onChangeRowsPerPage={model.onChangePageSize}
                                                    rowsPerPage={model.pageSize}
                                                    rowsPerPageOptions={model.pageSizeOptions}
                                                    pagingInfoMessageId="subject.registryPagingInfo"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
});
