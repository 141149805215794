import { Toolbar } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const TotObjectActionsBarStyled = withTheme(styled(Toolbar)`
    background-color: ${({ theme }): string => theme.palette.primary.contrastText};
    color: ${({ theme }): string => theme.palette.secondary.contrastText};
    border-top: ${({ theme }): string => `1px solid ${theme.variables.palette.hoverInLists}`};
`);

export type TotObjectActionsBarProps = PropsWithChildren<{
    style?: object;
}>;

export const TotObjectActionsBar = (props: TotObjectActionsBarProps): JSX.Element => {
    const { style, children } = props;
    return <TotObjectActionsBarStyled style={style}>{children}</TotObjectActionsBarStyled>;
};
