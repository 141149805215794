import { Box, Button, Dialog, DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RequiredLabel } from '../../../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../../../hooks';
import { ModalProps, ResultOfVotingDTO, ResultOfVotingField, SessionProcedureReloadTable } from '../../../../types';

export type SessionProcedureVotingResultsDialogProps = ModalProps & {
    activeCommonDecisionId: string;
    activeResultOfVoting: string;
    updateTable?: SessionProcedureReloadTable;
};

export const SessionProcedureVotingResultsDialog = observer(
    (props: SessionProcedureVotingResultsDialogProps): JSX.Element => {
        const { activeCommonDecisionId, isOpen, activeResultOfVoting, setIsClosed, updateTable } = props;
        const { commissionSessionStore } = useStore();
        const intl = useIntl();

        const { Yup } = useYup();
        const schema = Yup.object({
            resultOfVoting: Yup.string().required(),
        });

        const initialValues: ResultOfVotingDTO = {
            resultOfVoting: activeResultOfVoting,
        };

        const saveResultOfVoting = (values: ResultOfVotingDTO): Promise<void> => {
            return commissionSessionStore.saveResultOfVoting(activeCommonDecisionId, values).then(() => {
                updateTable && updateTable();
                setIsClosed();
            });
        };

        const resultLabel = (
            <RequiredLabel
                text={intl.formatMessage({ id: 'commissionSession.sessionProcedureSubjectList.resultOfVoting' })}
            />
        );

        const [isSending, endIcon, submitHandler] = useAntiDoubleClick(saveResultOfVoting);

        return (
            <Dialog maxWidth="sm" fullWidth open={isOpen}>
                <DialogTitle className="t-result-of-voting-modal-title">
                    <FormattedMessage id="commissionSession.sessionProcedureSubjectList.addResultOfVoting" />
                </DialogTitle>

                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={submitHandler}
                    enableReinitialize={true}
                >
                    <Form>
                        <DialogContent>
                            <Box pb={3}>
                                <Field
                                    component={TextField}
                                    variant="outlined"
                                    className="t-result-of-voting-modal-textfield"
                                    label={resultLabel}
                                    name={ResultOfVotingField.resultOfVoting}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    disabled={false}
                                />
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button color="primary" onClick={setIsClosed} className="t-result-of-voting-modal-cancel">
                                <FormattedMessage id="common.cancel" />
                            </Button>
                            <Button
                                className="t-result-of-voting-modal-submit"
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={isSending}
                                endIcon={endIcon}
                            >
                                <FormattedMessage id="common.save" />
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </Dialog>
        );
    },
);
