import { Tooltip } from '@material-ui/core';
import React, { PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuButton, renderActionsButton } from '../buttons';

export type ActionsMenuProps<T> = PropsWithChildren<{
    renderActionItems: (row: T) => () => ReactNode[];
    row: T;
    title?: string;
}>;

export const ActionsMenu = <T extends Object | null>(props: PropsWithChildren<ActionsMenuProps<T>>): JSX.Element => {
    const { row, renderActionItems, title } = props;

    return (
        <Tooltip title={title || <FormattedMessage id="common.actions" />}>
            <MenuButton
                disablePortal={false}
                renderButton={renderActionsButton}
                renderMenuItems={renderActionItems(row)}
            />
        </Tooltip>
    );
};
