import { TTable, TTableVisibilitySettings } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../components';
import { useFlag, useStore } from '../../../../../hooks';
import {
    RegistryType,
    RouteParamsDefault,
    SessionProcedureParams,
    SessionProcedureReloadTable,
    SessionProcedureRow,
} from '../../../../../types';
import { SessionProcedureVotingResultsDialog } from '../SessionProcedureVotingResultsDialog';
import { SessionProcedureSubjectCellFormatters } from './SessionProcedureSubjectCellFormaters';

type RouteParams = RouteParamsDefault & SessionProcedureParams;

export type SessionProcedureSubjectTableProps = {
    reloadData?: SessionProcedureReloadTable;
    setReloadData?: (reloadData: SessionProcedureReloadTable) => void;
};

const registryCode = RegistryType.sessionProcedure;

const registryVisibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithPresets: false,
        isWithAddCustomColumns: false,
        isWithCollapseRowMode: false,
    },
};

export const SessionProcedureSubjectTable = observer((props: SessionProcedureSubjectTableProps): JSX.Element => {
    const { reloadData, setReloadData } = props;

    const [isModalOpen, openModal, closeModal] = useFlag();
    const intl = useIntl();

    const { presetStore, catalogStore, userStore, registryStore } = useStore();
    const { saveListPresets } = presetStore;
    const { procedureId, id } = useParams<RouteParams>();

    const { fetchCatalogForTTable, fetchSelectDataByUrl } = catalogStore;
    const { userRoleList } = userStore;
    const { locale } = intl;
    const { fetchDataWithParams, fetchColumnsWithParams, downloadCreator } = registryStore;

    const [activeCommonDecisionId, setActiveCommonDecisionId] = useState<string>('');
    const [resultOfVoting, setResultOfVoting] = useState<string>('');

    const params = {
        sessionId: id,
        procedureId: procedureId,
    };

    const handleOpenModal = (commonDecisionId: string, resultOfVoting: string): void => {
        openModal();
        setActiveCommonDecisionId(commonDecisionId);
        setResultOfVoting(resultOfVoting);
    };

    const fetchData = fetchDataWithParams(params);
    const fetchColumns = fetchColumnsWithParams(params);

    const cellFormattersItems = SessionProcedureSubjectCellFormatters(procedureId, handleOpenModal);

    const registryTitle = intl.formatMessage({ id: 'commissionSession.listTitle' });
    const download = downloadCreator(registryTitle, registryCode, params);

    return (
        <React.Fragment>
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                {(allowed: boolean) => {
                    return (
                        <TTable<SessionProcedureRow>
                            registryCode={registryCode}
                            fetchData={fetchData}
                            fetchColumns={fetchColumns}
                            fetchCatalog={fetchCatalogForTTable}
                            fetchSelectDataByUrl={fetchSelectDataByUrl}
                            upload={download}
                            lang={locale}
                            fetchUserRoleList={userRoleList}
                            isAdmin={allowed}
                            cellFormatters={cellFormattersItems}
                            setReloadData={setReloadData}
                            saveListPresets={saveListPresets}
                            presetsSetting={presetStore}
                            visibleSettings={registryVisibleSettings}
                        />
                    );
                }}
            </AuthorizationCheck>

            <SessionProcedureVotingResultsDialog
                activeCommonDecisionId={activeCommonDecisionId}
                activeResultOfVoting={resultOfVoting}
                isOpen={isModalOpen}
                setIsClosed={closeModal}
                updateTable={reloadData}
            />
        </React.Fragment>
    );
});
