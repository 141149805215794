import { Grid } from '@material-ui/core';
import { Form, FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import { AuthorizationCheck, ServiceInfoPanel, TotObjectMain } from '../../components';
import { useFormApi, useStore } from '../../hooks';
import { RouteParamsDefault } from '../../types';
import { CommissionHeader } from './commission-header';
import { CommissionMembers } from './CommissionMembers';

export const CommissionPage = observer((): JSX.Element => {
    const { commissionStore, notificationStore } = useStore();
    const { getCommissionModel, deleteCommission, loadCommissionDTO } = commissionStore;
    const { onError } = notificationStore;
    const [formApi, setFormApi] = useFormApi();
    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();
    const model = useMemo(() => getCommissionModel(id), [id]);
    const { formModel, metaInfo, load } = model;
    const intl = useIntl();
    const { locale } = intl;

    const reloadModel = async () => {
        const commissionDTO = await loadCommissionDTO(id);
        load(commissionDTO);
        history.push(generatePath(clientRoute.commission, { id }));
    };

    const onSubmit = async (): Promise<void> => {
        if (formApi && formApi.validate()) {
            await commissionStore.saveCommission(id, formApi.getSubmission()).then(reloadModel);
        }
    };

    const handleDeleteCommission = (): Promise<void> => {
        return deleteCommission(id)
            .then(() => {
                history.push(generatePath(clientRoute.commissions));
            })
            .catch((error) => onError(error.response.data));
    };

    return (
        <Grid container direction="column">
            <Grid item>
                <CommissionHeader
                    commissionModel={model}
                    saveForm={onSubmit}
                    deleteCommission={handleDeleteCommission}
                />
            </Grid>
            <TotObjectMain>
                <Grid container spacing={10}>
                    <Grid item container direction="column" spacing={10}>
                        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                            <Grid item>
                                <ServiceInfoPanel metaInfoModel={metaInfo} />
                            </Grid>
                        </AuthorizationCheck>
                        <Grid item>
                            <Switch>
                                <Route exact path={[clientRoute.commissionEdit, clientRoute.commissionCreate]}>
                                    <Form className="t-commission-form" form={formModel} onFormReady={setFormApi} />
                                </Route>
                                <Route exact path={[clientRoute.commission, clientRoute.commissionAddHeadMember]}>
                                    <FormView locale={locale} form={formModel} onFormReady={setFormApi} />
                                </Route>
                            </Switch>
                        </Grid>
                        <Route exact path={[clientRoute.commission, clientRoute.commissionAddHeadMember]}>
                            <Grid item>
                                <CommissionMembers />
                            </Grid>
                        </Route>
                    </Grid>
                </Grid>
            </TotObjectMain>
        </Grid>
    );
});
