import { Grid, IconButton, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Form, FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { CSSProperties, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../components';
import { useReload, useStore } from '../../../../../hooks';
import { SessionProcedureCellFormModal } from '../../../../../models';
import { DecisionType, SessionDecision } from '../../../../../types';
import { StyledForm } from '../SessionProcedureSubjectListStyled';
import { SessionProcedureSubjectPrivateDecisions } from './private-decisions';

export type SessionProcedureCommonDecisionCellProps = SessionDecision & {
    procedureId: string;
    subjectId: string;
};

const formGap: CSSProperties = {
    margin: '2px',
};

export const SessionProcedureCommonDecisionCell = observer(
    (props: SessionProcedureCommonDecisionCellProps): JSX.Element => {
        const { form, decisionId, procedureId, subjectId } = props;
        const { commissionSessionStore } = useStore();
        const [reloadKey, reloadIncrement] = useReload();
        const intl = useIntl();

        const { locale } = intl;
        const { changeCommonDecisionState } = commissionSessionStore;

        const model = useMemo(() => {
            return new SessionProcedureCellFormModal(commissionSessionStore, form, decisionId, DecisionType.common);
        }, [decisionId, form]);

        const { id, formModel, onFormReady, onFormChange } = model;

        const onSubmit = async (): Promise<void> => {
            if (!id) {
                return;
            }

            const transition = await commissionSessionStore.commonDecisionTransition(id);
            changeCommonDecisionState(id, transition[0].id).then(() => {
                reloadIncrement();
            });
        };

        const decisionForm = (allowedEditDecision: boolean): JSX.Element => {
            const styledFormClass = allowedEditDecision ? 't-common-decision-form-edit' : 't-common-decision-form-view';
            return (
                <StyledForm className={styledFormClass}>
                    {allowedEditDecision ? (
                        <Form onFormReady={onFormReady} form={formModel} setFormIsChanged={onFormChange} />
                    ) : (
                        <FormView locale={locale} form={formModel} />
                    )}
                </StyledForm>
            );
        };

        return (
            <AuthorizationCheck
                key={reloadKey}
                entityCode={entities.SubjectDecision}
                permCode={permissions.SubjectDecision.EditCommonDecision}
                entityId={decisionId}
            >
                {(allowed: boolean) => {
                    return (
                        <Grid container justify="flex-start" alignItems="center" spacing={5} wrap="nowrap">
                            <Grid item style={formGap}>
                                {decisionForm(allowed)}
                            </Grid>
                            {allowed && (
                                <AuthorizationCheck
                                    permCode={permissions.SubjectDecision.NeedCastingVoteToDone}
                                    entityCode={entities.SubjectDecision}
                                    entityId={id}
                                >
                                    <Grid item>
                                        <Tooltip
                                            title={intl.formatMessage({
                                                id: 'commissionSession.sessionProcedureTable.confirmDecision',
                                            })}
                                        >
                                            <IconButton onClick={onSubmit}>
                                                <CheckIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </AuthorizationCheck>
                            )}
                            <Grid item>
                                <SessionProcedureSubjectPrivateDecisions
                                    procedureId={procedureId}
                                    subjectId={subjectId}
                                />
                            </Grid>
                        </Grid>
                    );
                }}
            </AuthorizationCheck>
        );
    },
);
