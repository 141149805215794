import { IconButton, MenuItem } from '@material-ui/core';
import { Language } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../hooks';
import { MenuButton } from '../buttons';

export const LanguageButton = observer((): JSX.Element => {
    const { intlStore } = useStore();

    const handleClose = (locale: string) => (): void => {
        intlStore.changeLocale(locale);
    };

    const renderMenuItems = (hideMenu: () => void): JSX.Element[] => {
        return intlStore.locales.map((locale) => {
            const handleSelect = (): void => {
                handleClose(locale)();
                hideMenu();
            };
            return (
                <MenuItem
                    dense
                    key={locale}
                    role="menuitem"
                    button={true}
                    selected={intlStore.locale === locale}
                    onClick={handleSelect}
                    tabIndex={0}
                >
                    {locale}
                </MenuItem>
            );
        });
    };

    const renderButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton color="secondary" onClick={onClick}>
                <Language />
            </IconButton>
        );
    };

    return (
        <MenuButton
            renderButton={renderButton}
            handleClose={handleClose(intlStore.locale)}
            renderMenuItems={renderMenuItems}
        />
    );
});
