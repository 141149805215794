import React from 'react';

export const FileSearchAlt = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
            <g>
                <path
                    fill="#f5c722"
                    d="M10 18a1 1 0 1 1 0 2H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h6.05a.887.887 0 0 1 .33.11.322.322 0 0 1 .09 0c.102.048.196.112.28.19l6 6c.077.082.141.177.19.28v.09c.029.087.049.178.06.27V9a1 1 0 0 1-2 0V8h-3a3 3 0 0 1-3-3V2H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1zm0-13a1 1 0 0 0 1 1h1.59L10 3.41V5zM6 6a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2h1zm13.71 12.29a.998.998 0 0 1 0 1.42 1 1 0 0 1-1.42 0l-1.16-1.17A3.46 3.46 0 0 1 12 15.49 3.49 3.49 0 0 1 18 13a3.44 3.44 0 0 1 .54 4.13zm-3.17-1.75c.286-.276.451-.653.46-1.05A1.47 1.47 0 0 0 15.44 14a1.48 1.48 0 0 0-1 .43 1.464 1.464 0 0 0-.44 1.06c0 .392.155.77.43 1.05a1.54 1.54 0 0 0 2.11 0zM11 10a1 1 0 0 1 0 2H5a1 1 0 1 1 0-2zm-6 6a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2H5z"
                />
            </g>
        </g>
    </svg>
);
