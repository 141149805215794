import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { UserListPage } from './user-list';
import { UserPage } from './UserPage';

export const UserPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route path={clientRoute.user}>
                    <UserPage />
                </Route>
                <Route path={clientRoute.users}>
                    <UserListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
