import { action, observable } from 'mobx';
import { NotificationListModel } from '../models';

export type SeverityType = 'error' | 'warning' | 'info' | 'success';

export const severityVariants: Record<string, SeverityType> = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error',
};

export class NotificationStore {
    @observable model: NotificationListModel = new NotificationListModel();

    @action.bound
    onSuccess(text: string): void {
        this.model.pushNotification(text, severityVariants.success);
    }

    @action.bound
    onError(text: string): void {
        this.model.pushNotification(text, severityVariants.error);
    }

    @action.bound
    onInfo(text: string): void {
        this.model.pushNotification(text, severityVariants.info);
    }

    @action.bound
    onWarning(text: string): void {
        this.model.pushNotification(text, severityVariants.warning);
    }

    @action.bound
    handleError(error: any): void {
        const errorMessage = error.response?.data;
        errorMessage ? this.onError(errorMessage) : console.error(error);
    }
}
