import React from 'react';

export const CheckboxDefaultIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
        <path
            fill="currentColor"
            d="M15.009 1.005c1.103 0 2 .897 2 1.999v12.001c0 1.102-.897 2-2 2h-12c-1.103 0-2-.898-2-2V3.004c0-1.102.897-1.999 2-1.999h12m0-1h-12c-1.657 0-3 1.343-3 2.999v12.001c0 1.656 1.343 3 3 3h12c1.657 0 3-1.344 3-3V3.004c0-1.656-1.343-2.999-3-2.999z"
        />
    </svg>
);
