import { FormModel } from '@platform/formiojs-react';
import { action, observable } from 'mobx';
import { UserDTO, UserStore } from '../../stores';
import { CodeTitle, RolesSettings } from '../../types';

export class UserPersonModel {
    @observable private userStore: UserStore;

    @observable id: string;
    @observable login = '';
    @observable lang = '';
    @observable roles: CodeTitle[] = [];
    @observable personForm: FormModel;

    constructor(userStore: UserStore, id: string) {
        this.userStore = userStore;
        this.id = id;
        this.personForm = new FormModel(this.id);
        this.load(true);
    }

    @action.bound
    load(isWithFormUpdate: boolean = false): void {
        this.userStore.loadUser(this.id).then((dto) => {
            this.setMainFields(dto, isWithFormUpdate);
        });
    }

    @action.bound
    setMainFields(dto: UserDTO, isWithFormUpdate: boolean): void {
        this.login = dto.login;
        this.lang = dto.lang;
        this.roles = dto.roles;
        isWithFormUpdate && this.personForm.load(dto.formInfo);
    }

    @action.bound
    setRoles(formValues: RolesSettings): Promise<void> {
        return this.userStore.saveUserRoles(this.id, formValues.roles).then(() => {
            this.load();
        });
    }
}
