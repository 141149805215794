import React from 'react';

export const EllipsisVIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18" fill="currentColor">
        <g>
            <g>
                <path d="M2 4a2 2 0 1 1-.001-4 2 2 0 0 1 0 4zm0 10a2 2 0 1 1-.002 4A2 2 0 0 1 2 14zm0-7a2 2 0 1 1-.001 4 2 2 0 0 1 0-4z" />
            </g>
        </g>
    </svg>
);
