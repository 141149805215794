import { Grid } from '@material-ui/core';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useError, useFlag, useStore } from '../../hooks';
import { Transition, TransitionsDTO } from '../../types';
import { ErrorDialog } from '../ErrorDialog';
import { ErrorMessage } from '../ErrorMessage';
import { TransitionDialog } from '../transition-dialogs';
import { renderTransitions } from './renderTransitions';

export type TotObjectTransitionsProps = {
    objectId: string;
    updateObjectPage: () => void;
    getTransitions: (objectId: string) => Promise<TransitionsDTO>;
    lifeCycleTransition: (transitionId: string, objectId: string, validate?: boolean) => Promise<void>;
};

export const TotObjectTransitions = observer((props: TotObjectTransitionsProps): JSX.Element => {
    const { lifeCycleTransition, objectId, updateObjectPage, getTransitions } = props;

    const [transition, setTransition] = useState<Transition>();
    const [data, setData] = useState<TransitionsDTO>([]);

    const { intlStore } = useStore();

    const [isModalOpen, openModal, closeModal] = useFlag();
    const [isError, errorText, enableError, resetError] = useError();

    const updateTransitions = (): void => {
        setData([]);
        getTransitions(objectId).then((data) => {
            setData(data);
        });
    };

    useEffect(() => {
        updateTransitions();
    }, [intlStore.locale, objectId]);

    const lifeCycleTransitionCallback = (): Promise<void> => {
        return lifeCycleTransition(transition?.id || '', objectId, transition?.params.validate || false);
    };

    const handleSuccessTransition = (): void => {
        updateObjectPage();
        updateTransitions();
    };

    const handleErrorTransition = (error: AxiosError): void => {
        const errorText = ErrorMessage(error);
        enableError(errorText);
    };

    const onClickModalOpen = (transition: Transition): (() => void) => {
        return (): void => {
            setTransition(transition);
            openModal();
        };
    };

    const onCloseModal = (): void => {
        closeModal();
        setTransition(undefined);
    };

    return (
        <React.Fragment>
            {transition && (
                <TransitionDialog
                    objectId={objectId}
                    transition={transition}
                    isModalOpen={isModalOpen}
                    setModalIsClosed={onCloseModal}
                    lifeCycleTransition={lifeCycleTransitionCallback}
                    handleSuccessTransition={handleSuccessTransition}
                    handleErrorTransition={handleErrorTransition}
                />
            )}
            <ErrorDialog message={errorText} isOpen={isError} setIsClosed={resetError} />
            <Grid container direction="row" spacing={4}>
                {renderTransitions(data, onClickModalOpen)}
            </Grid>
        </React.Fragment>
    );
});
