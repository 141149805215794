const pfTemplate: Record<string, string | Record<string, string>> = {
    listTitle: 'Шаблоны печатных форм',
    create: 'Создать шаблон',
    edit: 'Редактировать шаблон',
    maxFileSize: '3 МБ',
    fields: {
        title: 'Наименование',
        created: 'Дата создания',
        file: 'Файл',
    },
    actions: {
        download: 'Скачать шаблон',
    },
};

export default pfTemplate;
