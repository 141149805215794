import React from 'react';

export const fileTimesIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="currentColor">
        <g>
            <g>
                <path
                    fill="#963484"
                    d="M10.709 10.29a.999.999 0 0 1 0 1.42L9.409 13l1.3 1.29a.998.998 0 0 1 0 1.42.998.998 0 0 1-1.42 0L8 14.41l-1.29 1.3a.998.998 0 0 1-1.42 0 1 1 0 0 1 0-1.42l1.3-1.29-1.3-1.29a1.006 1.006 0 0 1 1.42-1.42l1.29 1.3 1.29-1.3a1 1 0 0 1 1.42 0zm5.29-3.35V17a3 3 0 0 1-3 3h-10a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h6.06c.108.02.213.057.31.11h.1c.103.047.197.112.28.19l6 6c.078.082.141.177.19.279v.09c.029.088.05.179.06.27zm-6-1.94a1 1 0 0 0 1 1h1.59L10 3.41V5zm4 3h-3a3 3 0 0 1-3-3V2h-5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10c.553 0 1-.449 1-1z"
                />
            </g>
        </g>
    </svg>
);
