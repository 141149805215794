import React from 'react';

export const RadioIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
        <path
            fill="currentColor"
            d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-18c-4.419 0-8 3.582-8 8s3.581 8 8 8c4.418 0 8-3.582 8-8s-3.582-8-8-8zm-.87 11.21c-.187.185-.438.289-.7.29-.266.001-.522-.103-.71-.29l-2.351-2.36c-.285-.245-.409-.628-.321-.994.088-.365.373-.65.738-.738.366-.088.749.036.994.322l1.65 1.65 4.29-4.3c.39-.388 1.02-.388 1.41 0 .189.188.296.443.296.71 0 .267-.107.522-.296.71l-5 5z"
        />
    </svg>
);
