import { action, observable } from 'mobx';
import { UserStore } from '../../stores';
import { RowsData, TableQueryData, UserFilter, UserRowDTO } from '../../types';
import { LikeFilter, TableModel } from '../list';

export class UserListModel extends TableModel<UserRowDTO, UserFilter, {}> {
    @observable userStore: UserStore;

    constructor(userStore: UserStore) {
        super(
            {
                name: new LikeFilter(),
                login: new LikeFilter(),
            },
            {},
        );
        this.userStore = userStore;
        this.pageSize = 10;
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<UserRowDTO>> {
        return this.userStore.loadList(queryData);
    }

    @action.bound
    blockUser(id: string): () => Promise<void> {
        return () => this.userStore.blockUser(id).then(this.reloadData);
    }

    @action.bound
    unblockUser(id: string): () => Promise<void> {
        return () => this.userStore.unblockUser(id).then(this.reloadData);
    }

    @action.bound
    deleteUser(id: string): () => Promise<void> {
        return () => this.userStore.deleteUser(id).then(this.reloadData);
    }
}
