import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React from 'react';

export type ClearFilterButtonProps = {
    clear: () => void;
};

export const ClearFilterButton = (props: ClearFilterButtonProps): JSX.Element => {
    const { clear } = props;
    const onClear = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        clear();
    };

    return (
        <IconButton onClick={onClear}>
            <Clear />
        </IconButton>
    );
};
