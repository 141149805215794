import React from 'react';

export const ExpandHeaderIcon = (): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 7H20V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V7Z"
            stroke="#63666a"
            stroke-width="2"
        />
        <path d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44771 20 5V7H4V5Z" stroke="#63666a" stroke-width="2" />
        <path d="M9 12L12 15L15 12" stroke="#63666a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
