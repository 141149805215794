import { css } from 'styled-components';
import { AppTheme } from '../../../../../types';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        display: block;
        color: ${sassVars.textAccentColor};
        font-family: ${sassVars.formElementLabelFontFamily};
        font-size: ${sassVars.formElementLabelFontSize};
        font-weight: ${sassVars.formElementLabelFontWeight};
        line-height: ${sassVars.formElementLabelLineHeight};
        letter-spacing: ${sassVars.formElementLabelLetterSpacing};
    `;
}
