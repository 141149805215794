import { action, computed, observable } from 'mobx';
import { IObservableValue } from 'mobx/lib/internal';
import { AttributesTableType, FormAttributeDTO, GeneralizationTableDTO, IdTitle } from '../../../types';
import { SessionProcedureAttributeTableModel } from '../session-procedure';

export class GeneralizationTableModel {
    @observable categories: IdTitle[] = [];
    @observable attributes: AttributesTableType[] = [];
    @observable procedureAttributeTableModel: SessionProcedureAttributeTableModel;
    @observable activeProcedureId: IObservableValue<string>;

    constructor(
        procedureAttributeTableModel: SessionProcedureAttributeTableModel,
        activeProcedureId: IObservableValue<string>,
    ) {
        this.procedureAttributeTableModel = procedureAttributeTableModel;
        this.activeProcedureId = activeProcedureId;
    }

    @computed
    get tableIsEmpty(): boolean {
        return !!this.categories.length && !!this.attributes.length;
    }

    @computed
    get tableIsFull(): boolean {
        let filledCells = 0;
        const cellsCount = this.categories.length * this.attributes.length;

        this.attributes.forEach((attribute) => {
            const filtered = attribute.categoryAttributes.filter((item) => item && item !== '');
            filledCells += filtered.length;
        });

        return this.tableIsEmpty && cellsCount === filledCells;
    }

    @action.bound
    load(dto: GeneralizationTableDTO): void {
        this.categories = dto.categories;
        this.attributes = dto.generalizationAttributes.map((r) => {
            const components: Array<FormAttributeDTO | string> = [];
            dto.categories.forEach((c) => {
                const component = r.categoryAttributes.find((comp) => {
                    return comp.ownerId === c.id;
                });
                if (component) {
                    components.push(component);
                } else {
                    components.push('');
                }
            });

            return {
                attributeId: r.attributeId,
                attributeTitle: r.attributeTitle,
                categoryAttributes: components,
            };
        });

        this.procedureAttributeTableModel.setIsGeneralizationTableFull(this.tableIsFull);
    }
}
