import axios, { AxiosInstance } from 'axios';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { AuthStatus } from '../types';
import { RootStore } from './RootStore';

export class Api {
    @observable private rootStore: RootStore;
    @observable authStatus: AuthStatus = AuthStatus.pending;

    client: AxiosInstance;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.client = axios.create({
            baseURL: this.rootStore.env.apiUrl,
            withCredentials: true,
        });
        this.client.interceptors.response.use((response) => response, this.authInterceptor);
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authInterceptor(error: any): void {
        const response = error.response || {};
        if (response.status === 401) {
            this.authStatus = AuthStatus.unauthorized;
        }
        throw error;
    }

    @action.bound
    async authVerify(): Promise<void> {
        this.authStatus = AuthStatus.pending;
        await this.client(apiConfigs.authorizationCheck([])).then((r) => r);
        this.authStatus = AuthStatus.ok;
    }
}
