const category: Record<string, string | Record<string, string>> = {
    categoryListTitle: 'Категории объектов',
    createCategory: 'Создать категорию',
    fields: {
        title: 'Наименование',
        created: 'Дата создания',
        author: 'Автор',
        lastModified: 'Последнее изменение',
        form: 'Форма объектов',
        process: 'ЖЦ объектов',
    },
    actions: {
        deleteCategory: 'Удалить категорию',
        editCategory: 'Редактировать категорию',
    },
    blocks: {
        serviceInfo: 'Служебная информация',
    },
    pagingInfo:
        'Всего {count, number} категор{count, plural, one {ия} few {ии} other {ий}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmDeletionInfoText: 'Вы действительно хотите удалить категорию "{title}"?',
};

export default category;
