const authentication: Record<string, string> = {
    loginTitle: 'Вход в кабинет',
    email: 'Email',
    password: 'Пароль',
    rememberMe: 'Запомнить меня',
    login: 'Войти',
    forgotPassword: 'Забыли пароль?',
    register: 'Зарегистрироваться',
    loginFailed: 'Неверный email или пароль',
    registrationTitle: 'Регистрация',
    firstName: 'Имя',
    middleName: 'Отчество',
    lastName: 'Фамилия',
    termsIsAccepted: 'Согласен с условиями пользовательского соглашения',
    emailAlreadyExists: 'Такой email уже зарегистрирован',
    emailNotExist: 'Пользователь с таким email не найден',
    emailNotConfirmed: 'Email не подтвержден',
    userBlocked: 'Ваша учетная запись недоступна',
    confirmingEmailReminder:
        'На указанный email уже отправлена ссылка для подтверждения. Пожалуйста, перейдите по ней (время действия ссылки – 15 минут), или попробуйте зарегистрироваться позже',
    confirmingEmailSent: 'На указанный email отправлено письмо для подтверждения.',
    confirmingEmailExpired:
        'К сожалению, срок действия вашей ссылки истёк. Мы уже отправили вам новую, пожалуйста, перейдите по ней (время действия новой ссылки – 15 минут).',
    confirmingEmailNotFound: 'Токен не найден.',
    recoveryTitle: 'Восстановление пароля',
    sendToken: 'Отправить',
    recoveryInfo:
        'Укажите логин, для которого хотите восстановить доступ. На email, указанный при регистрации, придет письмо со ссылкой для восстановления пароля.',
    recoveryLinkSent: 'На email, указанный при регистрации, отправлено письмо со ссылкой для восстановления пароля',
    recoveryPasswordFailed: 'Такой email не зарегистрирован или попытки восстановления были слишком частыми',
    newPasswordTitle: 'Смена пароля',
    newPassword: 'Новый пароль',
    repeatPassword: 'Повторите пароль',
    confirm: 'Подтвердить',
    passwordsMustMatch: 'Пароли должны совпадать',
    linkInvalidOrExpired: 'Ссылка неверная или срок её действия истёк',
    logout: 'Выход',
    loginEsia: 'Войти через Госуслуги',
    error: 'Произошла ошибка',
    dataUsePolicy: 'Нажимая кнопку "{button}", вы принимаете <a>Политику использования данных</a>.',
};

export default authentication;
