import React from 'react';

export const FileHiddenIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20">
        <path
            fill="#63666A"
            fill-rule="evenodd"
            d="M13.999 19.998H12V18h2v-1h1.999v.998c0 1.104-.896 2-2 2zm-14-2L0 16h2v2h1v1.998H1.989c-1.104 0-1.99-.896-1.99-2zM2 4v3H.006l.002-3H2zm0 11H.001l.002-3H2v3zm0-7v3H.004l.002-3H2zm2 10h3v1.998H4V18zM7 5h1.999v1.998H10V9H7V5zm1 13h3v1.998H8V18zm7.999-2H14v-3h1.999v3zM14 9h1.999v3H14V9zm-3-2.002h3.499l-2.116-2.116 1.25-1.25 2.366 2.366V8H14v1h-3V6.998zM2 2v1H.009V1.998c0-1.104.885-2 1.99-2h8l2.361 2.361-1.25 1.25L9 1.499V0H8v2h.999v2H7V2H5V0H4v2H2z"
        />
    </svg>
);
