import { CircularProgress } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const StyledCircularProgress = withTheme(
    styled(CircularProgress)`
        width: ${({ theme }) => theme.spacing(5)}px !important;
        height: ${({ theme }) => theme.spacing(5)}px !important;
    `,
);
