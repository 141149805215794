import { Box, Fade, Paper, Popper } from '@material-ui/core';
import React, { PropsWithChildren, useState } from 'react';

export type ResultOfCommonDecisionVotingProps = PropsWithChildren<{
    resultOfVoting: string;
}>;

export const ResultOfCommonDecisionVoting = (props: ResultOfCommonDecisionVotingProps): JSX.Element => {
    const { resultOfVoting, children } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const enableAnchorEl = (e: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(e.currentTarget);
    };

    const deleteAnchorEl = (): void => {
        setAnchorEl(null);
    };

    return (
        <Box onPointerEnter={enableAnchorEl} onPointerLeave={deleteAnchorEl}>
            {children}
            <Popper open={!!anchorEl} anchorEl={anchorEl} transition style={{ zIndex: 1102 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={200}>
                        <Paper>
                            <Box padding={3} maxWidth={300}>
                                {resultOfVoting}
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Box>
    );
};
