import { action, observable } from 'mobx';
import { apiConfigs } from '../../apiConfigs';
import { Api, CommissionStore, RootStore } from '../../stores';
import { CommissionRowModel, GroupedIdTitle, IdTitle, RowsData, TableQueryData } from '../../types';
import { InSetFilter, LikeFilter, Sort, TableModel } from '../list';
import { getLastNameWithInitials } from '../../utils';

type CommissionFilter = {
    title: LikeFilter;
    commissionMembers: InSetFilter;
    commissionHeads: InSetFilter;
    state: InSetFilter;
};

type CommissionSort = {
    title: Sort;
    commissionHeads: Sort;
};

type CommissionUser = {
    userId: string;
    person: {
        uuid: string;
        lastName: string;
        firstName: string;
        middleName: string;
    };
};

export class CommissionListModel extends TableModel<CommissionRowModel, CommissionFilter, CommissionSort> {
    @observable api: Api;
    @observable commissionStore: CommissionStore;
    @observable commissionHeadsFilterData: IdTitle[];
    @observable commissionMembersFilterData: IdTitle[];
    @observable stateFilterData: GroupedIdTitle;

    constructor(rootStore: RootStore) {
        const { api, commissionStore } = rootStore;
        const filter = {
            title: new LikeFilter(),
            commissionMembers: new InSetFilter(),
            commissionHeads: new InSetFilter(),
            state: new InSetFilter(),
        };

        const sort = {
            title: new Sort(),
            commissionHeads: new Sort(),
        };

        super(filter, sort);

        this.api = api;
        this.commissionStore = commissionStore;
        this.commissionHeadsFilterData = [];
        this.commissionMembersFilterData = [];
        this.stateFilterData = {};

        this.getCommissionsListFilterData();
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<CommissionRowModel>> {
        return this.commissionStore.loadList(queryData);
    }

    mapUserToIdTitle(users: CommissionUser[]): IdTitle[] {
        return users.map((user: CommissionUser) => {
            const { userId, person } = user;
            const { firstName, lastName, middleName } = person;
            return {
                id: userId,
                title: getLastNameWithInitials(firstName, lastName, middleName),
            };
        });
    }

    @action.bound
    getCommissionsListFilterData(): void {
        this.api
            .client(apiConfigs.commissionsListFilterData)
            .then((res) => res.data)
            .then((filterData) => {
                this.commissionHeadsFilterData = this.mapUserToIdTitle(filterData.commissionHeads);
                this.commissionMembersFilterData = this.mapUserToIdTitle(filterData.commissionMembers);
                this.stateFilterData = filterData.statesByProcess;
            });
    }
}
