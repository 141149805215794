import { FormApi, FormModel } from '@platform/formiojs-react';
import debounce from 'debounce';
import { action, observable } from 'mobx';
import React from 'react';
import { saveFormInterval } from '../../../../constants';
import { CommissionSessionStore } from '../../../../stores';
import { DecisionType, FormDTO } from '../../../../types';

export class SessionProcedureCellFormModal {
    @observable private commissionSessionStore: CommissionSessionStore;
    @observable id: string;
    @observable form: FormDTO;
    @observable formModel: FormModel;
    @observable formApi?: FormApi;
    @observable decisionType: DecisionType;

    constructor(commissionSessionStore: CommissionSessionStore, form: FormDTO, id: string, decisionType: DecisionType) {
        this.id = id;
        this.form = form;
        this.commissionSessionStore = commissionSessionStore;
        this.decisionType = decisionType;
        this.formModel = new FormModel(this.id);
        this.formModel.load(this.form);
    }

    @action.bound
    formHandler(): void {
        if (this.formApi && this.id) {
            this.commissionSessionStore
                .saveDecision(this.id, this.formApi.getSubmission(), this.decisionType)
                .finally(this.commissionSessionStore.setLoadingFinish);
        }
    }

    @action.bound
    onFormReady(form: FormApi): void {
        this.formApi = form;
        this.commissionSessionStore.setLoadingFinish();
    }

    @action.bound
    onFormChange = debounce((isChanged: boolean): void => {
        isChanged ? this.formHandler() : this.commissionSessionStore.setLoadingFinish();
    }, saveFormInterval);
}
