import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTheme } from '@material-ui/core/styles';
import { Form, FormModel } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { clientRoute } from '../../../clientRoute';
import { useAntiDoubleClick, useFormApi, useStore } from '../../../hooks';
import { RouteParamsDefault } from '../../../types';

const StyledForm = withTheme(
    styled(Form)`
        & > div > div {
            padding: 10px 0 ${({ theme }): string => `${theme.spacing(2)}px`} !important;
        }
        .formio-errors {
            padding: 6px 0;
        }
    `,
);

type RouteParams = RouteParamsDefault & {
    procedureId: string;
};

export type ProcedureDialogProps = {
    loadProcedureRows: () => void;
    headerMessage: string;
    submitMessage: string;
};

export const ProcedureDialog = observer((props: ProcedureDialogProps): JSX.Element => {
    const { headerMessage, submitMessage, loadProcedureRows } = props;

    const { commissionSessionStore } = useStore();
    const { id, procedureId } = useParams<RouteParams>();
    const history = useHistory();

    const [procedureForm] = useState<FormModel>(() => new FormModel(id));
    const [formApi, setFormApi] = useFormApi();

    useEffect(() => {
        commissionSessionStore.loadSessionProcedure(procedureId).then((data) => {
            procedureForm.load(data.formInfo);
        });
    }, [commissionSessionStore, procedureId, procedureForm]);

    const closeModal = (): void => {
        history.push(generatePath(clientRoute.commissionSessionSetting, { id }));
    };

    const onSubmit = async (): Promise<void> => {
        if (!formApi) {
            return;
        }
        const isFormModelValid = formApi.validate();

        if (isFormModelValid) {
            await commissionSessionStore.saveProcedureForm(procedureId, formApi.getSubmission());
            loadProcedureRows();
            closeModal();
        }
    };

    const [isSending, endIcon, submitHandler] = useAntiDoubleClick(onSubmit);

    return (
        <Dialog maxWidth="sm" fullWidth open={true}>
            <DialogTitle>{headerMessage}</DialogTitle>

            <DialogContent>
                <StyledForm form={procedureForm} onFormReady={setFormApi} className="t-procedure-form" />
            </DialogContent>

            <DialogActions>
                <Button color="primary" onClick={closeModal}>
                    <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                    className="t-procedure-submit"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSending}
                    endIcon={endIcon}
                    onClick={submitHandler}
                >
                    {submitMessage}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
