import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AutocompleteField } from '../../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../../hooks';
import { EditSubject, EditSubjectField, IdTitle, ModalProps, RouteParamsDefault } from '../../../types';

export type EditSessionDialogProps = ModalProps & {
    onSuccessSubmit: () => void;
};

const sessionsClasses = { popper: 't-editSession-field-options' };

const initialValues: EditSubject = {
    session: null,
};

export const EditSessionDialog = observer((props: EditSessionDialogProps): JSX.Element => {
    const { isOpen, onSuccessSubmit, setIsClosed } = props;
    const { subjectStore, notificationStore } = useStore();
    const { loadCommissionSessionsOptions, updateSession } = subjectStore;
    const { id } = useParams<RouteParamsDefault>();
    const intl = useIntl();

    const [commissionSessionsOptions, setCommissionSessionsOptions] = useState<IdTitle[]>([]);

    useEffect(() => {
        loadCommissionSessionsOptions().then(setCommissionSessionsOptions);
    }, []);

    const { Yup } = useYup();
    const schema = Yup.object({
        session: Yup.object().nullable().required(),
    });

    const handleSubmit = (values: EditSubject): Promise<void> => {
        const sessionId = values.session?.id || '';

        return updateSession(id, sessionId)
            .then(() => {
                setIsClosed();
                onSuccessSubmit();
            })
            .catch((error) => {
                notificationStore.onError(error.response.data);
            });
    };

    const [isSending, endIcon, submitHandler] = useAntiDoubleClick(handleSubmit);

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage id="subject.editSession" />
            </DialogTitle>

            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitHandler}>
                <Form>
                    <DialogContent>
                        <Box pb={3}>
                            <AutocompleteField
                                classes={sessionsClasses}
                                className="t-editSession-field"
                                fieldName={EditSubjectField.session}
                                label={intl.formatMessage({ id: 'subject.fields.commissionSessions' })}
                                options={commissionSessionsOptions}
                                required={true}
                            />
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={setIsClosed}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            className="t-confirm-edit"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
