import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import {
    ActionsMenu,
    AuthorizationCheck,
    DeleteActionMenuItem,
    EditActionMenuItem,
    TotObjectTransitions,
} from '../../../../components';
import { useStore } from '../../../../hooks';
import { SubjectModel } from '../../../../models';
import { RouteParamsDefault } from '../../../../types';

export type SubjectControlPanelProps = {
    subjectModel: SubjectModel;
    onSubmit: () => void;
    onLifeCycleTransition(transitionId: string, requestId: string, validate?: boolean): Promise<void>;
};

export const SubjectControlPanel = observer((props: SubjectControlPanelProps): JSX.Element => {
    const { onSubmit, onLifeCycleTransition, subjectModel } = props;
    const { subjectStore } = useStore();
    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();
    const intl = useIntl();
    const { title } = subjectModel;

    const deleteSubject = (): Promise<void> => {
        return subjectStore.deleteSubject(subjectModel.id).then(() => {
            history.push(generatePath(clientRoute.subjects));
        });
    };

    const goToSubjectReadPage = (): void => {
        history.push(generatePath(clientRoute.subject, { id }));
    };

    const actions = [
        <AuthorizationCheck key="edit" entityCode={entities.Subject} permCode={permissions.Subject.Edit} entityId={id}>
            <EditActionMenuItem key="edit" path={generatePath(clientRoute.subjectEdit, { id })} />
        </AuthorizationCheck>,

        <AuthorizationCheck
            key="delete"
            entityCode={entities.Subject}
            permCode={permissions.Subject.Delete}
            entityId={id}
        >
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                message={intl.formatMessage({ id: 'subject.confirmDeletionInfoText' }, { title })}
                onConfirm={deleteSubject}
            />
        </AuthorizationCheck>,
    ];

    const renderActionItems = (): (() => ReactNode[]) => {
        return (): ReactNode[] => actions;
    };

    return (
        <Grid alignItems="center" justify="space-between" container>
            <Grid item>
                <Grid spacing={3} alignItems="center" justify="center" container>
                    <Switch>
                        <Route key={clientRoute.subject} path={clientRoute.subject} exact>
                            <ActionsMenu renderActionItems={renderActionItems} row={null} />
                        </Route>
                    </Switch>
                </Grid>
            </Grid>
            <Grid item>
                <Switch>
                    <Route path={clientRoute.subjectCreate} exact>
                        <Grid item container spacing={3}>
                            <Grid item>
                                <Button variant="contained" component={NavLink} to={generatePath(clientRoute.subjects)}>
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={onSubmit}>
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Route>
                    <Route path={clientRoute.subjectEdit} exact>
                        <Grid item container spacing={3}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component={NavLink}
                                    to={generatePath(clientRoute.subject, { id })}
                                >
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={onSubmit}>
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Route>
                    <Route path={clientRoute.subject} exact>
                        <Grid item container alignItems="baseline" spacing={3}>
                            <Grid item>
                                <TotObjectTransitions
                                    objectId={id}
                                    updateObjectPage={goToSubjectReadPage}
                                    getTransitions={subjectStore.getLifeCycleTransitions}
                                    lifeCycleTransition={onLifeCycleTransition}
                                />
                            </Grid>
                        </Grid>
                    </Route>
                </Switch>
            </Grid>
        </Grid>
    );
});
