import { Button, Chip, Container, Grid, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import {
    AuthorizationCheck,
    IconButtonLarge,
    TotObjectHeader,
    TotObjectHeaderLabel,
    TotObjectHeaderValue,
} from '../../components';
import { useFlag } from '../../hooks';
import { UserPersonModel } from '../../models';
import { EditIcon } from '../../resources';
import { getOrElse } from '../../utils';
import { UserRolesDialog } from './UserRolesDialog';

export type UserHeaderProps = {
    userPersonModel: UserPersonModel;
    loginAsUser: () => void;
};

export const UserHeader = observer((props: UserHeaderProps): JSX.Element => {
    const { userPersonModel, loginAsUser } = props;
    const [isModalOpen, openModal, closeModal] = useFlag();

    return (
        <TotObjectHeader>
            <Container maxWidth="lg">
                <Grid container spacing={3} direction="column">
                    <Grid item container justify="space-between">
                        <Grid item>
                            <Typography variant="h1">
                                {getOrElse(userPersonModel.login, userPersonModel.login, '-')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <AuthorizationCheck
                                entityCode={entities.User}
                                permCode={permissions.User.LoginAs}
                                entityId={userPersonModel.id}
                            >
                                <Button onClick={loginAsUser} variant="contained" color="primary">
                                    <FormattedMessage id="users.loginAsUser" />
                                </Button>
                            </AuthorizationCheck>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={6} direction="row">
                        <Grid item>
                            <Grid container direction="row" alignItems="baseline" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="users.fields.roles" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectHeaderValue>
                                        {userPersonModel.roles.map((r) => (
                                            <Chip key={r.code} label={r.title} />
                                        ))}
                                    </TotObjectHeaderValue>
                                </Grid>
                                <Route path={clientRoute.user} exact>
                                    <Grid item>
                                        <Tooltip title={<FormattedMessage id="users.editRoles" />}>
                                            <IconButtonLarge onClick={openModal}>
                                                <EditIcon />
                                            </IconButtonLarge>
                                        </Tooltip>
                                        <UserRolesDialog
                                            userPersonModel={userPersonModel}
                                            isOpen={isModalOpen}
                                            setIsClosed={closeModal}
                                        />
                                    </Grid>
                                </Route>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </TotObjectHeader>
    );
});
