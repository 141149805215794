import { FileDTO } from './file';
import { CodeTitle } from './withTitle';

export type PfTemplateDTO = {
    id: string;
    title: string;
    created: string; // date
    fileDTO: FileDTO;
};

export type PfTemplate = {
    title: string;
    file?: File;
};

export enum PrintFormField {
    title = 'title',
    file = 'file',
}

export type PrintFormDTO = {
    templateId: string;
    filename: string;
    pf?: FileDTO;
    delayedTask?: DelayedTaskStatus;
    status: CodeTitle;
};

export enum PrintFormStatusCode {
    generated = 'GENERATED',
    failed = 'FAILED',
    new = 'NEW',
    processing = 'PROCESSING',
}

export type DelayedTaskStatus = {
    taskStatus: PrintFormStatusCode;
    title: string;
};
