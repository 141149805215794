import { action } from 'mobx';
import { ChangeEvent } from 'react';
import { Filter } from '../Filter';

export type LikeFilterValue = string;

export class LikeFilter extends Filter<LikeFilterValue> {
    constructor(defaultValue = '') {
        super(defaultValue);
    }

    @action.bound
    checkActive(value: LikeFilterValue): boolean {
        return !!value;
    }

    @action.bound
    onChange(e: ChangeEvent<HTMLInputElement>): void {
        this.value = e.target.value;
    }

    @action.bound
    clear(): void {
        this.value = '';
    }

    get asJson(): string {
        return this.value;
    }
}
