import { action, computed, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { IntlStore } from '../../../stores';
import { CategoryInfo, FormAttributeDTO, FormComponentCreateDTO } from '../../../types';

export class GeneralizationCategorySelectModel {
    @observable defaultCategoryAttribute: FormAttributeDTO | null = null;
    @observable categoryId: string;
    @observable formVersionsId: string;
    @observable categoryAttributes: FormAttributeDTO[];
    @observable title: string;

    @observable validationStarted = false;
    @observable intl: IntlStore;

    constructor(selectData: CategoryInfo, intl: IntlStore) {
        this.categoryId = selectData.ownerId;
        this.formVersionsId = selectData.formVersionId;
        this.categoryAttributes = selectData.categoryAttributes;
        this.title = selectData.title;
        if (selectData.defaultCategoryAttribute) {
            this.defaultCategoryAttribute = selectData.defaultCategoryAttribute;
        }
        this.intl = intl;
    }

    @action.bound
    onChangeSelect(e: ChangeEvent<{}>, value: FormAttributeDTO | null): void {
        this.validationStarted = true;
        this.defaultCategoryAttribute = value;
    }

    @computed
    get errorSelect(): string {
        if (!this.defaultCategoryAttribute && this.validationStarted) {
            return this.intl.formatMessage('validation.required');
        }

        return '';
    }

    @action.bound
    enableValidation(): void {
        this.validationStarted = true;
    }

    @action.bound
    disableValidation(): void {
        this.validationStarted = false;
    }

    @computed
    get submitAttribute(): FormComponentCreateDTO | null {
        if (this.defaultCategoryAttribute) {
            return {
                formVersionId: this.defaultCategoryAttribute.formVersionId,
                code: this.defaultCategoryAttribute.code,
                ownerId: this.defaultCategoryAttribute.ownerId,
            };
        }

        return null;
    }
}
