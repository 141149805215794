import { action, observable } from 'mobx';
import { ProcedureSubjectDecision, ProcedureSubjectDecisionDTO } from '../../../../types';
import { CommissionSessionStore } from '../../../../stores';
import { PersonModel } from '../../../person';
import { DateUtils } from '../../../../utils';

export class SessionProcedureSubjectDecisionsModel {
    @observable private commissionSessionStore: CommissionSessionStore;
    @observable procedureId: string;
    @observable subjectId: string;

    @observable decisions: ProcedureSubjectDecision[] = [];
    @observable isLoading: boolean = false;

    constructor(commissionSessionStore: CommissionSessionStore, procedureId: string, subjectId: string) {
        this.commissionSessionStore = commissionSessionStore;
        this.procedureId = procedureId;
        this.subjectId = subjectId;
    }

    @action.bound
    loadDecisions(): void {
        this.startLoading();
        this.commissionSessionStore
            .sessionProcedureSubjectPrivateDecisions(this.procedureId, this.subjectId)
            .then((decisions: ProcedureSubjectDecisionDTO[]) => {
                return decisions.map((decision) => {
                    const voted = new Date(decision.voted || '');
                    return {
                        memberFIO: new PersonModel().load(decision.member.person).lastNameWithInitials,
                        mark: decision.mark,
                        voted: DateUtils.isValidDate(voted) ? voted : undefined,
                    };
                });
            })
            .then(this.onLoadDecisions);
    }

    @action.bound
    onLoadDecisions(decisions: ProcedureSubjectDecision[]): void {
        this.setDecisions(decisions);
        this.stopLoading();
    }

    @action.bound
    startLoading(): void {
        this.isLoading = true;
    }

    @action.bound
    stopLoading(): void {
        this.isLoading = false;
    }

    @action.bound
    setDecisions(decisions: ProcedureSubjectDecision[]): void {
        this.decisions = decisions;
    }
}
