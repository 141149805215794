import { AxiosError } from 'axios';
import React from 'react';
import { useStore } from '../../hooks';
import { CheckCastingVoteDialog } from '../../pages';
import { Transition } from '../../types';
import { LifeCycleTransitionConfirmationDialog } from './LifeCycleTransitionConfirmationDialog';

export type TransitionDialogProps = {
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: VoidFunction;
    transition: Transition;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: VoidFunction;
    handleErrorTransition: (error: AxiosError) => void;
};

export const TransitionDialog = (props: TransitionDialogProps): JSX.Element => {
    const { transition } = props;
    const { dialogComponentCode } = transition.params;
    const { commissionSessionStore } = useStore();
    const { numberOfVoter, totalVoter } = commissionSessionStore;

    switch (dialogComponentCode) {
        case 'CommonDecisionMakingToFinished':
            return <CheckCastingVoteDialog {...props} />;
        default:
            return <LifeCycleTransitionConfirmationDialog isNotAllVoters={numberOfVoter < totalVoter} {...props} />;
    }
};
