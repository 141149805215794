import { Box, Typography, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../components';
import { ResultOfCommonDecisionVoting } from '../ResultOfCommonDecisionVoting';
import { StyledLink } from '../SessionProcedureSubjectListStyled';

export type SessionProcedureCommonDecisionResultOfVotingCellProps = {
    commonDecisionId: string;
    resultOfVoting: string;
    openModal: (commonDecisionId: string, resultOfVoting: string) => void;
};

export const SessionProcedureCommonDecisionResultOfVotingCell = observer(
    (props: SessionProcedureCommonDecisionResultOfVotingCellProps): JSX.Element => {
        const { resultOfVoting, commonDecisionId, openModal } = props;

        const theme = useTheme();
        const intl = useIntl();

        const openModalHandler = (): void => {
            openModal(commonDecisionId, resultOfVoting);
        };

        const resultOfVotingMessage =
            resultOfVoting ||
            intl.formatMessage({ id: 'commissionSession.sessionProcedureSubjectList.enterResultOfVoting' });

        return (
            <AuthorizationCheck
                entityCode={entities.SubjectDecision}
                permCode={permissions.SubjectDecision.EditResultOfVoting}
                entityId={commonDecisionId}
            >
                {(allowed: boolean) => {
                    if (allowed) {
                        return (
                            <React.Fragment>
                                {commonDecisionId ? (
                                    <Box
                                        color={theme?.variables.palette.accentContrast}
                                        maxWidth={250}
                                        width="100%"
                                        className="t-common-decision-result-of-voting-edit"
                                    >
                                        <ResultOfCommonDecisionVoting resultOfVoting={resultOfVotingMessage}>
                                            <StyledLink
                                                className="t-common-decision-result-of-voting-edit-link"
                                                onClick={openModalHandler}
                                            >
                                                <Box pt={2} pb={2} pr={2.5} pl={2.5} color="inherit">
                                                    <Typography
                                                        className="t-common-decision-result-of-voting-text"
                                                        color="inherit"
                                                        noWrap
                                                    >
                                                        {resultOfVoting || (
                                                            <FormattedMessage id="commissionSession.sessionProcedureSubjectList.enterResultOfVoting" />
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </StyledLink>
                                        </ResultOfCommonDecisionVoting>
                                    </Box>
                                ) : (
                                    ''
                                )}
                            </React.Fragment>
                        );
                    }

                    return (
                        <React.Fragment>
                            {resultOfVoting && (
                                <Box maxWidth={250} width="100%" className="t-common-decision-result-of-voting-view">
                                    <ResultOfCommonDecisionVoting resultOfVoting={resultOfVoting}>
                                        <Typography color="inherit" noWrap>
                                            {resultOfVoting}
                                        </Typography>
                                    </ResultOfCommonDecisionVoting>
                                </Box>
                            )}
                        </React.Fragment>
                    );
                }}
            </AuthorizationCheck>
        );
    },
);
