import {
    Box,
    Button,
    Grid,
    IconButton,
    SvgIcon,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    withTheme,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck, DeleteActionMenuItem, MenuButton } from '../../../components';
import { useFlag, useStore } from '../../../hooks';
import { SessionProcedureAttributeTableModel } from '../../../models';
import { DotMenuIcon } from '../../../resources';
import { SessionProcedureAttribute } from '../../../types';
import { GeneralizationTable, GeneralizationTableCell } from '../generalization-table';
import { SessionProcedureGeneralizationAttributeDialog } from './SessionProcedureGeneralizationAttributeDialog';

const BlockHeader = styled(Typography)`
    font-size: 15px;
    font-weight: 700;
`;

const HeaderPanel = withTheme(styled(Grid)`
    padding-bottom: ${({ theme }) => `${theme.spacing(3.75)}px`};
`);

export type SessionProcedureGeneralizationAttributesBlockProps = {
    procedureId: string;
    model: SessionProcedureAttributeTableModel;
};

export const SessionProcedureGeneralizationAttributesBlock = observer(
    (props: SessionProcedureGeneralizationAttributesBlockProps): JSX.Element => {
        const { procedureId, model } = props;
        const { commissionSessionStore } = useStore();
        const { categories, attributes, load } = model;
        const [isModalOpen, openModal, closeModal] = useFlag();
        const intl = useIntl();

        useEffect(() => {
            updateTableModel();
        }, [procedureId]);

        const updateTableModel = (): void => {
            commissionSessionStore.sessionProcedureGeneralizationAttributeList(procedureId).then(load);
        };

        const deleteAttribute = (row: SessionProcedureAttribute): (() => Promise<void>) => {
            return (): Promise<void> =>
                commissionSessionStore.sessionProcedureGeneralizationAttributeDelete(row.id).then(updateTableModel);
        };

        const renderActionItems = (row: SessionProcedureAttribute): (() => ReactNode[]) => {
            return (): ReactNode[] => [
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    message={intl.formatMessage(
                        { id: 'commissionSession.sessionProcedureTable.confirmDeletionInfoText' },
                        { title: row.attribute.attributeTitle },
                    )}
                    onConfirm={deleteAttribute(row)}
                />,
            ];
        };

        const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            return (
                <IconButton onClick={onClick}>
                    <SvgIcon>
                        <DotMenuIcon />
                    </SvgIcon>
                </IconButton>
            );
        };

        const renderActions = (row: SessionProcedureAttribute): JSX.Element => {
            return (
                <MenuButton
                    disablePortal={true}
                    renderButton={renderActionsButton}
                    renderMenuItems={renderActionItems(row)}
                />
            );
        };

        const categoriesElements: JSX.Element[] = categories.map((category) => {
            return (
                <GeneralizationTableCell key={category.id}>
                    <Typography>{category.title}</Typography>
                </GeneralizationTableCell>
            );
        });

        const attributesElements: JSX.Element[] = attributes.map((attribute) => {
            return (
                <TableRow hover key={attribute.id}>
                    <GeneralizationTableCell>{attribute.attribute.attributeTitle}</GeneralizationTableCell>
                    {attribute.attribute.categoryAttributes.map((comp, index) => {
                        return (
                            <GeneralizationTableCell key={index}>
                                {typeof comp === 'object' ? comp.title : comp}
                            </GeneralizationTableCell>
                        );
                    })}
                    <TableCell>
                        <AuthorizationCheck
                            entityCode={entities.CommissionSession}
                            permCode={permissions.CommissionSession.EditGenerationAttribute}
                        >
                            {renderActions(attribute)}
                        </AuthorizationCheck>
                    </TableCell>
                </TableRow>
            );
        });

        return (
            <React.Fragment>
                <SessionProcedureGeneralizationAttributeDialog
                    updateTable={updateTableModel}
                    procedureId={procedureId}
                    setIsClosed={closeModal}
                    isOpen={isModalOpen}
                />
                <Box pt={4} width="100%">
                    <HeaderPanel container justify="space-between" alignItems="center">
                        <Grid item>
                            <Box pl={6}>
                                <BlockHeader variant="h4">
                                    <FormattedMessage id="commissionSession.sessionProcedureTable.title" />
                                </BlockHeader>
                            </Box>
                        </Grid>
                        <Box pr={3}>
                            <Tooltip
                                classes={{ popper: 't-generalizationTable-tooltip' }}
                                title={
                                    !model.isGeneralizationTableFull ? (
                                        <FormattedMessage id="commissionSession.generalizationTable.tooltip" />
                                    ) : (
                                        ''
                                    )
                                }
                            >
                                <Grid item>
                                    <Button
                                        className="t-generalizationAttribute-create"
                                        variant="text"
                                        color="primary"
                                        startIcon={<AddCircleOutlineIcon />}
                                        disabled={!model.isGeneralizationTableFull}
                                        onClick={openModal}
                                    >
                                        <FormattedMessage id="commissionSession.generalizationTable.addAttribute" />
                                    </Button>
                                </Grid>
                            </Tooltip>
                        </Box>
                    </HeaderPanel>
                    <Box>
                        <GeneralizationTable
                            categoriesElements={categoriesElements}
                            attributesElements={attributesElements}
                            tableIsEmpty={model.tableIsEmpty}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        );
    },
);
