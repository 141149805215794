import { MenuItem } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFlag } from '../../hooks';
import { ConfirmationDialog } from '../ConfirmationDialog';

export type DeleteActionMenuItemProps = {
    id: string;
    tabIndex?: number;
    message: string;
    onConfirm: () => Promise<void>;
};

export const DeleteActionMenuItem = (props: DeleteActionMenuItemProps): JSX.Element => {
    const { id, tabIndex = 0, message, onConfirm } = props;
    const intl = useIntl();
    const [isModalOpen, openModal, closeModal] = useFlag();

    const onDeleteConfirm = (): Promise<void> => {
        return onConfirm().finally(closeModal);
    };

    return (
        <React.Fragment>
            <MenuItem dense button={true} tabIndex={tabIndex} onClick={openModal}>
                <FormattedMessage id="common.delete" />
            </MenuItem>
            <ConfirmationDialog
                keepMounted
                isOpen={isModalOpen}
                id={id}
                message={message}
                title={intl.formatMessage({ id: 'common.confirmDeletion' })}
                onConfirm={onDeleteConfirm}
                setIsClosed={closeModal}
            />
        </React.Fragment>
    );
};
