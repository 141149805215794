import { IconButton, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { List as ListIcon } from '@material-ui/icons';
import { PrivateDecisionsDialog } from './PrivateDecisionsDialog';
import { useFlag } from '../../../../../../hooks';
import { AuthorizationCheck } from '../../../../../../components';
import { entities, permissions } from '../../../../../../authSchemeConfig';
import { FormattedMessage, useIntl } from 'react-intl';

export type SessionProcedureSubjectPrivateDecisionsProps = {
    procedureId: string;
    subjectId: string;
};

export const SessionProcedureSubjectPrivateDecisions = observer(
    (props: SessionProcedureSubjectPrivateDecisionsProps): JSX.Element => {
        const { procedureId, subjectId } = props;

        const [isOpen, open, close] = useFlag();
        const intl = useIntl();

        return (
            <AuthorizationCheck
                permCode={permissions.SessionProcedure.ViewAllPrivateDecisions}
                entityCode={entities.SessionProcedure}
                entityId={procedureId}
            >
                <Tooltip
                    placement="top"
                    title={intl.formatMessage({
                        id: 'commissionSession.sessionProcedureSubjectList.showAllPrivateDecisions',
                    })}
                >
                    <IconButton onClick={open}>
                        <ListIcon />
                    </IconButton>
                </Tooltip>
                <PrivateDecisionsDialog procedureId={procedureId} subjectId={subjectId} isOpen={isOpen} close={close} />
            </AuthorizationCheck>
        );
    },
);
