import { Portal, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAntiDoubleClick } from '../hooks';
import { ModalProps } from '../types';

export type ConfirmationDialogProps = ModalProps & {
    id: string;
    keepMounted: boolean;
    onConfirm: () => Promise<void>;
    title: ReactNode;
    message: ReactNode;
    cancelText?: string | ReactNode;
    confirmText?: string | ReactNode;
    className?: string;
};

export const ConfirmationDialog = observer((props: ConfirmationDialogProps): JSX.Element => {
    const { cancelText, confirmText, onConfirm, setIsClosed, isOpen, title, message, ...other } = props;
    const [isSending, endIcon, actionHandler] = useAntiDoubleClick(onConfirm);

    return (
        <Portal>
            <Dialog aria-labelledby="confirmation-dialog-title" open={isOpen} {...other}>
                <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
                <DialogContent>{message}</DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={setIsClosed} color="primary">
                        {cancelText || <FormattedMessage id="common.cancel" />}
                    </Button>
                    <Button
                        onClick={actionHandler}
                        color="secondary"
                        variant="contained"
                        disabled={isSending}
                        endIcon={endIcon}
                    >
                        {confirmText || <FormattedMessage id="common.confirm" />}
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
