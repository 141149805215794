import { Box, Link } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const StyledForm = withTheme(
    styled(Box)`
        width: 100%;
        max-width: 250px;
        min-width: 230px;
        .formio-form {
            > div > *:not(.panel):not(fieldset),
            > div > .panel > .panel-body > *:not(.panel):not(fieldset) {
                background: transparent;
                margin-bottom: 0;
                padding: 0 !important;
            }
        }
    `,
);

export const StyledLink = withTheme(styled(Link)`
    width: 100%;
    display: block;
    cursor: text;
    max-width: 300px;

    border-radius: 4px;

    &:hover,
    &:focus {
        background-color: ${({ theme }) => theme.variables.palette.table.row.selected};
    }
`);
