import { Box, Grid, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from '../../../hooks';
import { StartPageHeaderLinks } from '../../../pages';
import { BurgerClosedIcon, BurgerOpenIcon } from '../../../resources';
import { AuthStatus } from '../../../types';
import { BurgerMenu } from '../BurgerMenu';
import { UserButton } from '../UserButton';
import { AppHeaderLinksList } from './AppHeaderLinksList';
import { AllowedLink, AppBarComponent } from './AppHeaderLinksSettings';
import { GridWrapper, StyledGrid } from './AppHeaderLinksWrappers';

export type AppHeaderLinksProps = {
    isOpenMenu: boolean;
    handleBurgerChange: () => void;
    isSmSize: boolean;
    avgLinksWidth: number;
    allLinks: AppBarComponent[];
};

export const AppHeaderLinks = observer((props: AppHeaderLinksProps): JSX.Element => {
    const { allLinks, avgLinksWidth, isSmSize, isOpenMenu, handleBurgerChange } = props;
    const [verifiedLinks, setVerifiedLinks] = useState<AppBarComponent[]>([]);
    const [visibleLinks, setVisibleLinks] = useState<AppBarComponent[]>([]);
    const [hiddenLinks, setHiddenLinks] = useState<AppBarComponent[]>([]);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const { authorizationStore, personStore, api } = useStore();
    const { authStatus, authVerify } = api;

    const updateNavLinks = useCallback(
        (linksCount: number) => {
            if (isSmSize) {
                setVisibleLinks([]);
                setHiddenLinks([...verifiedLinks.slice(0)]);
                return;
            }
            setVisibleLinks([...verifiedLinks.slice(0, linksCount)]);
            setHiddenLinks([...verifiedLinks.slice(linksCount)]);
        },
        [verifiedLinks, isSmSize],
    );

    const handleNavbarChange = useCallback((): void => {
        const navbarWrapper = wrapperRef.current;
        if (!navbarWrapper) {
            return;
        }

        const wrapperWidth = navbarWrapper.clientWidth;

        const verifiedLinksCount = verifiedLinks.length;
        const maxLinksCount = Math.floor(wrapperWidth / avgLinksWidth);
        const linksCount = verifiedLinksCount < maxLinksCount ? verifiedLinksCount : maxLinksCount;

        updateNavLinks(linksCount);
    }, [verifiedLinks, avgLinksWidth, updateNavLinks]);

    const getQueriesArray = useCallback((): Promise<AllowedLink>[] => {
        const { check } = authorizationStore;

        return allLinks.map((link: AppBarComponent) => {
            if (link.query) {
                return check(link.query).then((allowed: boolean) => ({
                    link,
                    allowed,
                }));
            }
            return Promise.resolve({ link, allowed: true });
        });
    }, [authorizationStore, allLinks]);

    const checkLinksPermission = useCallback(async (): Promise<void> => {
        const links = await Promise.all(getQueriesArray());
        const allowedLinks = links.filter(({ allowed }) => allowed);
        setVerifiedLinks(allowedLinks.map(({ link }) => link));
    }, [getQueriesArray]);

    useEffect(() => {
        authVerify();
    }, []);

    useEffect(() => {
        checkLinksPermission();
    }, [checkLinksPermission, personStore.user.id]);

    useEffect(() => {
        handleNavbarChange();
        window.addEventListener('resize', handleNavbarChange);

        return () => {
            window.removeEventListener('resize', handleNavbarChange);
        };
    }, [verifiedLinks, handleNavbarChange]);

    switch (authStatus) {
        case AuthStatus.pending:
            return <React.Fragment />;
        case AuthStatus.ok:
            return (
                <Grid container direction="row" justify="flex-end" spacing={3} wrap="nowrap" className="t-app-bar">
                    <GridWrapper item>
                        <StyledGrid
                            container
                            direction="row"
                            alignItems="center"
                            justify={isSmSize ? 'center' : 'flex-end'}
                            spacing={3}
                            ref={wrapperRef}
                        >
                            <AppHeaderLinksList elementsArray={visibleLinks} />
                        </StyledGrid>
                        <Box pt={3}>
                            <BurgerMenu isSmSize={isSmSize} isOpenMenu={isOpenMenu} toggleArray={hiddenLinks} />
                        </Box>
                    </GridWrapper>
                    <Grid item>
                        {hiddenLinks.length !== 0 ? (
                            <IconButton color="secondary" onClick={handleBurgerChange} className="t-toggleMenu-button">
                                {isOpenMenu ? <BurgerOpenIcon /> : <BurgerClosedIcon />}
                            </IconButton>
                        ) : null}
                    </Grid>
                    <Grid item>
                        <UserButton />
                    </Grid>
                </Grid>
            );
        case AuthStatus.unauthorized:
            return <StartPageHeaderLinks />;
    }
});
