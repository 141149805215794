import { Button } from '@material-ui/core';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl/lib';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../hooks';
import { SessionProcedureParams } from '../../../../types';

export type SessionProcedureSendVoteButtonProps = {
    reloadIncrement: () => void;
    className?: string;
};

export const SessionProcedureSendVoteButton = observer((props: SessionProcedureSendVoteButtonProps): JSX.Element => {
    const { reloadIncrement, className } = props;
    const { commissionSessionStore } = useStore();
    const { procedureId } = useParams<SessionProcedureParams>();
    const intl = useIntl();
    const { locale } = intl;

    const sendVote = useCallback((): void => {
        // Не могу деструктурировать. https://mobx-react.js.org/state-destruct
        if (commissionSessionStore.isUserVoteProcessing) {
            const message = intl.formatMessage({
                id: 'commissionSession.sessionProcedureSubjectList.voteIsYetToBeProcessed',
            });
            return commissionSessionStore.notification.onWarning(message);
        }
        commissionSessionStore.sendVote(procedureId).then(reloadIncrement);
    }, [commissionSessionStore, procedureId, locale]);

    return (
        <Button className={cn('t-send-vote', className)} variant="contained" color="primary" onClick={sendVote}>
            <FormattedMessage id="commissionSession.sessionProcedureSubjectList.toVote" />
        </Button>
    );
});
