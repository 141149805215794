import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { ActionsMenu, DeleteActionMenuItem, EditActionMenuItem } from '../../../components';

export type CategoryOfSubjectsControlPanelProps = {
    categoryOfSubjectsTitle: string;
    categoryOfSubjectsId: string;
    deleteCategoryOfSubjects: () => Promise<void>;
    onSubmit: () => void;
};

export const CategoryOfSubjectsControlPanel = observer((props: CategoryOfSubjectsControlPanelProps): JSX.Element => {
    const { categoryOfSubjectsId, categoryOfSubjectsTitle, deleteCategoryOfSubjects, onSubmit } = props;
    const intl = useIntl();

    const actions = [
        <EditActionMenuItem key="edit" path={generatePath(clientRoute.categoryEdit, { id: categoryOfSubjectsId })} />,
        <DeleteActionMenuItem
            id="delete"
            key="delete"
            message={intl.formatMessage({ id: 'category.confirmDeletionInfoText' }, { title: categoryOfSubjectsTitle })}
            onConfirm={deleteCategoryOfSubjects}
        />,
    ];

    const renderActionItems = (): (() => ReactNode[]) => {
        return (): ReactNode[] => actions;
    };

    return (
        <Grid alignItems="center" justify="space-between" container>
            <Grid item>
                <Grid spacing={3} alignItems="center" justify="center" container>
                    <Switch>
                        <Route exact path={clientRoute.category}>
                            <ActionsMenu renderActionItems={renderActionItems} row={null} />
                        </Route>
                    </Switch>
                </Grid>
            </Grid>
            <Grid item>
                <Grid spacing={3} alignItems="center" justify="center" container>
                    <Switch>
                        <Route exact path={clientRoute.categoryCreate}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component={NavLink}
                                    to={generatePath(clientRoute.categories)}
                                >
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={onSubmit} className="t-save-category">
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                        </Route>
                        <Route exact path={clientRoute.categoryEdit}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component={NavLink}
                                    to={generatePath(clientRoute.category, {
                                        id: categoryOfSubjectsId,
                                    })}
                                >
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={onSubmit} className="t-save-category">
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                        </Route>
                    </Switch>
                </Grid>
            </Grid>
        </Grid>
    );
});
