import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, TotObjectFilterPanelDetails, TotObjectFilterPanelSummary } from '../../components';
import { AutocompleteProps, textFieldProps } from '../../constants';
import { CommissionListModel } from '../../models';

export type CommissionListFilterPanelProps = {
    model: CommissionListModel;
};

export const CommissionListFilterPanel = observer((props: CommissionListFilterPanelProps): JSX.Element => {
    const { model } = props;
    const { filtering } = model;
    const intl = useIntl();

    return (
        <ExpansionPanel>
            <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="common.filters" />
                </Typography>
            </TotObjectFilterPanelSummary>
            <TotObjectFilterPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="commission.fields.title" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.title.value}
                                onChange={filtering.title.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.commissionHeads.values}
                                label={<FormattedMessage id="commission.fields.manager" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.commissionHeadsFilterData}
                                onChange={filtering.commissionHeads.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.commissionMembers.values}
                                label={<FormattedMessage id="commission.fields.members" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.commissionMembersFilterData}
                                onChange={filtering.commissionMembers.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.state.values}
                                label={<FormattedMessage id="commission.fields.state" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.stateFilterData}
                                onChange={filtering.state.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectFilterPanelDetails>
        </ExpansionPanel>
    );
});
