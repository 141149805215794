import { action, observable } from 'mobx';
import { IObservableValue } from 'mobx/lib/internal';
import { CommissionSessionStore } from '../../../stores';
import { SessionProcedure, SessionProcedureDTO } from '../../../types';
import { reorderList } from '../../../utils';

export class SessionProcedureModel {
    @observable commissionSessionStore: CommissionSessionStore;

    @observable sessionId: string;

    @observable rows: SessionProcedure[] = [];
    @observable isListLoading = true;

    @observable activeProcedure: SessionProcedureDTO | null = null;
    @observable activeProcedureId: IObservableValue<string> = observable.box('');

    @observable canChangeProcedurePosition = true;

    constructor(commissionSessionStore: CommissionSessionStore, sessionId: string) {
        this.commissionSessionStore = commissionSessionStore;
        this.sessionId = sessionId;
        this.loadProcedureRows();
    }

    @action.bound
    loadProcedureRows(): void {
        this.isListLoading = true;
        this.commissionSessionStore
            .loadProcedureList(this.sessionId)
            .then((procedureList) => {
                this.rows = procedureList;
                this.updateActiveProcedure();
            })
            .finally(() => {
                this.isListLoading = false;
            });
    }

    @action.bound
    deleteSessionProcedure = (row: SessionProcedure): (() => Promise<void>) => {
        return (): Promise<void> => {
            return this.commissionSessionStore.deleteSessionProcedure(row.id).then(() => {
                this.loadProcedureRows();
            });
        };
    };

    @action.bound
    updateActiveProcedure = () => {
        if (!this.rows.length) {
            this.activeProcedure = null;
            return;
        }

        const firstProcedureId = this.rows[0].id;

        const hasActiveProcedureInRows = !!this.rows.find((procedure) => procedure.id === this.activeProcedure?.id);
        const id = this.activeProcedure && hasActiveProcedureInRows ? this.activeProcedure.id : firstProcedureId;

        this.setActiveProcedure(id);
    };

    @action.bound
    setActiveProcedure = (id: string): void => {
        this.commissionSessionStore
            .loadSessionProcedure(id)
            .then((procedure) => {
                this.activeProcedure = procedure;
                this.activeProcedureId.set(procedure.id);
            })
            .catch(() => {
                this.activeProcedure = null;
            });
    };

    @action.bound
    changeProcedurePosition = (id: string, newPosition: number): void => {
        this.commissionSessionStore
            .changeProcedurePosition(id, newPosition)
            .then(() => {
                this.canChangeProcedurePosition = true;
            })
            .catch(() => {
                this.canChangeProcedurePosition = false;
            });
    };

    @action.bound
    reorderProcedureList = (startIndex: number, endIndex: number): void => {
        this.rows = reorderList<SessionProcedure>(this.rows, startIndex, endIndex);
    };
}
