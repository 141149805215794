import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { RequiredLabel } from '../../../../components';
import { useAntiDoubleClick, useFlag, useStore, useYup } from '../../../../hooks';
import { GeneralizationTableDialogModel } from '../../../../models';
import {
    GeneralizationCategoryField,
    GeneralizationCategoryInfo,
    GeneralizationCategoryInfoDTO,
    ModalProps,
    RouteParamsDefault,
} from '../../../../types';
import { GeneralizationSelects } from './GeneralizationSelects';

export type GeneralizationDialogProps = ModalProps & {
    updateTable: () => void;
    attributeId?: string;
};

export const GeneralizationDialog = observer((props: GeneralizationDialogProps): JSX.Element => {
    const { attributeId, isOpen, updateTable, setIsClosed } = props;
    const { intlStore, commissionSessionStore } = useStore();
    const { getGeneralizationCategoriesInfo, editGeneralizationAttribute, submitGeneralizationAttribute } =
        commissionSessionStore;
    const { id } = useParams<RouteParamsDefault>();
    const intl = useIntl();

    const [isLoading, enableLoading, disableLoading] = useFlag();
    const [isNeedLoading, enableNeedLoading, disableNeedLoading] = useFlag();
    const [attributeTitle, setAttributeTitle] = useState<string>('');
    const model = useMemo(() => new GeneralizationTableDialogModel(id, intlStore), [id]);
    const { isValid, categoryAttributes, loadCategories, enableValidationStarted } = model;

    useEffect(() => {
        enableNeedLoading();
    }, [attributeId, id]);

    useEffect(() => {
        if (isNeedLoading) {
            if (isOpen) {
                enableLoading();
                getGeneralizationCategoriesInfo(id, attributeId)
                    .then((categoryInfo) => {
                        setAttributeTitle(categoryInfo.attributeTitle || '');
                        loadCategories(categoryInfo.categoryAttributes);
                        disableNeedLoading();
                    })
                    .finally(disableLoading);
            } else {
                dropValues();
            }
        }
    }, [isNeedLoading, isOpen]);

    const initialValues: GeneralizationCategoryInfo = {
        title: attributeTitle,
    };

    const { Yup } = useYup();
    const schema = Yup.object({
        title: Yup.string().required(),
    });

    const dropValues = (): void => {
        setAttributeTitle('');
        loadCategories([]);
    };

    const onSuccessSubmit = (): void => {
        updateTable();
        setIsClosed();
        enableNeedLoading();
    };

    const onSubmit = (values: GeneralizationCategoryInfo): Promise<void> => {
        enableValidationStarted();
        if (isValid) {
            const dto: GeneralizationCategoryInfoDTO = {
                commissionSessionId: id,
                attributeTitle: values.title,
                categoryAttributes,
            };

            if (attributeId) {
                return editGeneralizationAttribute(attributeId, id, dto).then(onSuccessSubmit);
            }
            return submitGeneralizationAttribute(id, dto).then(onSuccessSubmit);
        }
        return Promise.resolve();
    };

    const titleLabel = (
        <RequiredLabel text={intl.formatMessage({ id: 'commissionSession.generalizationTable.attributeTitle' })} />
    );

    const [isSending, endIcon, submitHandler] = useAntiDoubleClick(onSubmit);

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage
                    id={`commissionSession.generalizationTable.${attributeId ? 'dialogEditTitle' : 'dialogAddTitle'}`}
                />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={submitHandler}
                enableReinitialize={true}
            >
                <Form>
                    <DialogContent>
                        <Box pb={6}>
                            <Grid container direction="column" spacing={4}>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        label={titleLabel}
                                        name={GeneralizationCategoryField.title}
                                        fullWidth
                                        disabled={false}
                                    />
                                </Grid>
                                <GeneralizationSelects isLoading={isLoading} model={model} />
                            </Grid>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={setIsClosed}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            className="t-procedure-submit"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id={`common.${attributeId ? 'edit' : 'add'}`} />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
