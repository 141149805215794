import { TTableProps } from '@platform/ttable';
import React from 'react';
import { Row } from 'react-table';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../components';
import { SessionProcedureRow } from '../../../../../types';
import { SessionProcedureCommonDecisionCell } from './SessionProcedureCommonDecisionCell';
import { SessionProcedureCommonDecisionResultOfVotingCell } from './SessionProcedureCommonDecisionResultOfVotingCell';
import { SessionProcedurePrivateDecisionCell } from './SessionProcedurePrivateDecisionCell';
import { SessionProcedureSubjectCommentCell } from './comments';

export type SessionProcedureCellRenderProps = {
    row: Row<SessionProcedureRow>;
};

export const SessionProcedureSubjectCellFormatters = (
    procedureId: string,
    openModal: (commonDecisionId: string, resultOfVoting: string) => void,
): TTableProps<SessionProcedureRow>['cellFormatters'] => {
    return {
        'customData.privateDecision': (props: SessionProcedureCellRenderProps): JSX.Element => {
            const { row } = props;
            const { privateDecision } = row.original.customData;

            if (privateDecision) {
                const { form, decisionId } = privateDecision;

                return (
                    <AuthorizationCheck
                        entityCode={entities.SessionProcedure}
                        permCode={permissions.SessionProcedure.ViewPrivateDecisions}
                        entityId={procedureId}
                    >
                        <SessionProcedurePrivateDecisionCell decisionId={decisionId} form={form} />
                    </AuthorizationCheck>
                );
            }

            return <React.Fragment />;
        },
        'customData.commonDecision': (props: SessionProcedureCellRenderProps): JSX.Element => {
            const { row } = props;
            const { original } = row;
            const { id: subjectId, customData } = original;
            const { commonDecision } = customData;

            if (commonDecision) {
                const { form, decisionId } = commonDecision;

                return (
                    <AuthorizationCheck
                        entityCode={entities.SessionProcedure}
                        permCode={permissions.SessionProcedure.ViewCommonDecisions}
                        entityId={procedureId}
                    >
                        <SessionProcedureCommonDecisionCell
                            procedureId={procedureId}
                            subjectId={subjectId}
                            decisionId={decisionId}
                            form={form}
                        />
                    </AuthorizationCheck>
                );
            }

            return <React.Fragment />;
        },
        'customData.resultOfVoting': (props: SessionProcedureCellRenderProps): JSX.Element => {
            const { row } = props;
            const { resultOfVoting } = row.original.customData;

            if (resultOfVoting) {
                const { commonDecisionId, finalDecision } = resultOfVoting;

                return (
                    <AuthorizationCheck
                        entityCode={entities.SessionProcedure}
                        permCode={permissions.SessionProcedure.ViewResultOfVoting}
                        entityId={procedureId}
                    >
                        <SessionProcedureCommonDecisionResultOfVotingCell
                            commonDecisionId={commonDecisionId}
                            resultOfVoting={finalDecision}
                            openModal={openModal}
                        />
                    </AuthorizationCheck>
                );
            }

            return <React.Fragment />;
        },
        'customData.comment': (props: SessionProcedureCellRenderProps): JSX.Element => {
            const { row } = props;
            const { id: subjectId, customData } = row.original;
            const { comment, privateDecision } = customData;
            const commentRaw = comment || '';
            const decisionId = privateDecision?.decisionId;

            return (
                <SessionProcedureSubjectCommentCell
                    procedureId={procedureId}
                    subjectId={subjectId}
                    decisionId={decisionId}
                    commentRaw={commentRaw}
                />
            );
        },
    };
};
