const validation: Record<string, string> = {
    required: 'Required field',
    minLength: 'Minimal length {length, number} {length, plural, one {symbol} other {symbols}}',
    filledFields: 'Not all required fields are filled',
    validEmail: 'Enter a valid email',
    dropzoneError: 'File does not match type "doc" or "docx"',
    maxFileSizeError: 'Maximum file size is exceeded ({size})',
};

export default validation;
