import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';

export type RegistryFetchData = <RowType extends TTableRow>(
    registryCode: string,
    query: TTableQueryData<RowType>,
) => Promise<RowsData<RowType>>;

export type RegistryFetchColumns = <RowType extends TTableRow>(registryCode: string) => Promise<ColumnData<RowType>[]>;

export type RegistryUpload = <RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>) => Promise<void>;

export enum RegistryType {
    sessionProcedure = 'sessionProcedure',
}
