import { action, computed, observable } from 'mobx';
import { CommissionSessionStore } from '../../../../stores';
import { SubjectCommentDTO } from '../../../../types';

export class SessionProcedureSubjectCommentModel {
    @observable comment: string;

    constructor(comment?: string) {
        this.comment = comment || '';
    }

    @computed
    get commentNonEmpty(): boolean {
        return !!this.comment;
    }
}
