import React from 'react';

export const BurgerOpenIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18">
        <path
            fill="currentColor"
            d="M9.984 0H12v12.984H9.984V0zM0 0h2.015v12.984H0V0zm5.015 0h1.969v9.984H5.015V0zm4.594 18L6 14.437 2.39 18 .984 16.594 6 11.578l5.015 5.016L9.609 18z"
        />
    </svg>
);
