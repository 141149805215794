import React from 'react';

export const DeleteChipIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="28 22 14 14">
        <path
            fill="currentColor"
            d="M39.006 31.999c0 .267-.107.522-.296.71-.188.19-.443.296-.71.296-.267 0-.522-.106-.71-.296l-2.29-2.3-2.29 2.3c-.188.19-.443.296-.71.296-.267 0-.522-.106-.71-.296-.189-.188-.296-.443-.296-.71 0-.267.107-.522.296-.71l2.3-2.29-2.3-2.29c-.189-.188-.296-.443-.296-.71 0-.266.107-.522.296-.71.188-.189.443-.296.71-.296.267 0 .522.107.71.296l2.29 2.3 2.29-2.3c.392-.392 1.028-.392 1.42 0 .393.392.393 1.028 0 1.42l-2.3 2.29 2.3 2.29c.189.188.296.443.296.71z"
        />
    </svg>
);
