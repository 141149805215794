import { AxiosError } from 'axios';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { getOrElse } from '../utils';

export function ErrorMessage(error: AxiosError): string | ReactNode {
    return getOrElse(
        error.response && error.response.data,
        error.response?.data,
        <FormattedMessage id="common.errorText" />,
    );
}
