const commissionSession: Record<string, string | Record<string, string | Record<string, string>>> = {
    fields: {
        commission: 'Expert group',
    },
    blocksTitle: {
        commissionInfo: 'Information about the expert group',
    },
    generalizationTable: {
        subjectAttribute: 'Initiative attribute "{category}"',
    },
    sessionProcedureSubjectList: {
        showAllPrivateDecisions: 'Show all decisions',
        privateDecisionsTable: {
            member: 'Full name',
        },
    },
};

export default commissionSession;
