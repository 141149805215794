import { action, observable } from 'mobx';
import { maxPfFileSizeInBytes, msWordFormat, officeDocumentFormat } from '../constants';
import { IntlStore } from '../stores';
import { PfTemplateDTO } from '../types';
import { FileModel } from './FileModel';

export class PfTemplateModel {
    @observable intl: IntlStore;

    @observable id = '';
    @observable title = '';
    @observable file = new FileModel([msWordFormat, officeDocumentFormat], maxPfFileSizeInBytes);

    @observable validationStarted = false;

    constructor(intl: IntlStore) {
        this.intl = intl;
    }

    @action.bound
    load(dto: PfTemplateDTO) {
        const { id, title, fileDTO } = dto;
        this.id = id;
        this.title = title;
        this.file.setFileDTO(fileDTO);
    }
}
