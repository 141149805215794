import { AuthorizationCheckQuery } from './types';

export const entities = {
    System: 'System',
    Subject: 'Subject',
    User: 'User',
    Commission: 'Commission',
    CommissionSession: 'CommissionSession',
    SessionProcedure: 'SessionProcedure',
    SubjectDecision: 'SubjectDecision',
};

export const permissions = {
    System: {
        Administration: 'Administration',

        AddSubject: 'AddSubject',
        ViewSubjectList: 'ViewSubjectList',

        AddCommission: 'AddCommission',
        ViewCommissionList: 'ViewCommissionList',

        AddCommissionSession: 'AddCommissionSession',

        AddSessionProcedure: 'AddSessionProcedure',
        ViewSessionList: 'ViewSessionList',
    },

    Subject: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        DownloadCommonFile: 'DownloadCommonFile',
        DownloadHiddenFile: 'DownloadHiddenFile',
        XlsSubjectList: 'XlsSubjectList',
    },

    User: {
        LoginAs: 'LoginAs',
    },

    Commission: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        XlsCommissionList: 'XlsCommissionList',
    },

    CommissionSession: {
        ViewSetting: 'ViewSetting',
        EditSetting: 'EditSetting',
        ViewProcess: 'ViewProcess',
        Delete: 'Delete',
        EditGenerationAttribute: 'EditGenerationAttribute',
        SaveWithoutValidate: 'SaveWithoutValidate',
        XlsComissionSessionList: 'XlsCommissionSessionList',
        EditPf: 'EditPf',
        GeneratePrintForm: 'GeneratePrintForm',
        DownloadPrintForm: 'DownloadPrintForm',
    },

    SessionProcedure: {
        Edit: 'Edit',
        Delete: 'Delete',
        ChangeStatePrivateDecisions: 'ChangeStatePrivateDecisions',
        ViewPrivateDecisions: 'ViewPrivateDecisions',
        ViewAllPrivateDecisions: 'ViewAllPrivateDecisions',
        ViewCommonDecisions: 'ViewCommonDecisions',
        ViewResultOfVoting: 'ViewResultOfVoting',
        ViewCountVotingProgress: 'ViewCountVotingProgress',
        ViewComment: 'ViewComment',
        ViewAllSubjectComments: 'ViewAllSubjectComments',
    },

    SubjectDecision: {
        EditPrivateDecision: 'EditPrivateDecision',
        EditCommonDecision: 'EditCommonDecision',
        EditResultOfVoting: 'EditResultOfVoting',
        NeedCastingVoteToDone: 'NeedCastingVoteToDone',
        EditComment: 'EditComment',
    },
};

export const permissionsConfig = {
    deleteSubject: (subjectId: string): AuthorizationCheckQuery => ({
        permCode: permissions.Subject.Delete,
        entityCode: entities.Subject,
        entityId: subjectId,
    }),

    editSubject: (subjectId: string): AuthorizationCheckQuery => ({
        permCode: permissions.Subject.Edit,
        entityCode: entities.Subject,
        entityId: subjectId,
    }),

    deleteCommission: (commissionId: string): AuthorizationCheckQuery => ({
        permCode: permissions.Commission.Delete,
        entityCode: entities.Commission,
        entityId: commissionId,
    }),

    editCommission: (commissionId: string): AuthorizationCheckQuery => ({
        permCode: permissions.Commission.Edit,
        entityCode: entities.Commission,
        entityId: commissionId,
    }),

    viewHeaderLinks: (permission: string): AuthorizationCheckQuery => ({
        entityCode: entities.System,
        permCode: permission,
    }),

    sessionProcedureView: (permission: string, procedureId: string): AuthorizationCheckQuery => ({
        entityCode: entities.SessionProcedure,
        permCode: permission,
        entityId: procedureId,
    }),

    sessionProcedureEdit: (permission: string, procedureId: string): AuthorizationCheckQuery => ({
        entityCode: entities.SubjectDecision,
        permCode: permission,
        entityId: procedureId,
    }),
};
