import { Link } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const AlertLink = withTheme(
    styled(Link)`
        color: ${({ theme }) => theme.variables.alertLink.color} !important;
        text-decoration: underline !important;

        &:hover {
            color: ${({ theme }) => theme.variables.alertLink.hoverColor} !important;
        }
    `,
);
