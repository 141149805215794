export default {
    startPageLinks: false,
    startPage: false,
    loginWithoutRegister: false,
    headerLogo: {
        src: require(`../../resources/images/sstp-logo.svg`),
        style: { margin: '10px 5px 10px 0' },
    },
    defaultBackground: false,
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    startPageDefaultText: false,
    esiaLogin: true,
    modularNavigation: true,
};
