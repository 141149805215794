import { Box, Button, Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import styled from 'styled-components';
import { useStore } from '../../hooks';
import { MailIcon, PhoneIcon } from '../../resources';
import { FooterTypography } from './FooterTypography';

const FooterButton = withTheme(
    styled(Button)`
        .MuiButton-label {
            align-items: center;
        }

        .MuiTypography-root {
            font-weight: bold;
        }
    `,
);

export const FooterContactInformation = (): JSX.Element => {
    const [phoneNumber] = useFeature('phoneNumber');
    const { env } = useStore();
    const { totTel, totMail } = env;
    const hrefTel = (totTel || '').replace(/\s/g, '');

    return (
        <Grid item>
            <Grid container direction="row" alignItems="center" justify="flex-end">
                {phoneNumber && (
                    <Grid item>
                        <Box pr={3}>
                            <FooterButton
                                component="a"
                                href={`tel:${hrefTel}`}
                                startIcon={<PhoneIcon />}
                                variant="text"
                                color="secondary"
                            >
                                <FooterTypography component="span">{totTel}</FooterTypography>
                            </FooterButton>
                        </Box>
                    </Grid>
                )}
                <Grid item>
                    <FooterButton
                        component="a"
                        href={`mailto:${totMail}`}
                        startIcon={<MailIcon />}
                        variant="text"
                        color="secondary"
                    >
                        <FooterTypography component="span">{totMail}</FooterTypography>
                    </FooterButton>
                </Grid>
            </Grid>
        </Grid>
    );
};
