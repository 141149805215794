import { Container, Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useStore } from '../../hooks';
import { TotLocale } from '../../types';
import { getCopyrightWithYears } from '../../utils';
import { FooterContactInformation } from './FooterContactInformation';
import { FooterTypography } from './FooterTypography';

const FooterWrapper = withTheme(
    styled.footer`
        position: relative;
        padding: ${({ theme }) => `${theme.spacing(3.75)}px 0`};
        background-color: ${({ theme }) => theme?.variables.palette.appBar.mainContrast};
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: ${({ theme }) => theme?.variables.palette.appBar.mainContrast};
        }
    `,
);

export const Footer = observer((): JSX.Element => {
    const [currentYear, setCurrentYear] = useState<number | undefined>(undefined);
    const { locale } = useIntl();
    const { env, getCurrentYear } = useStore();
    const { totCopyrightEn, totCopyrightRu, totTel, totMail, totCopyrightStartYear, totCopyright } = env;

    useEffect(() => {
        getCurrentYear().then(setCurrentYear);
    }, []);

    const copyright = useMemo((): string | undefined => {
        return (locale === TotLocale.en ? totCopyrightEn : totCopyrightRu) || totCopyright;
    }, [locale, totCopyright, totCopyrightEn, totCopyrightRu]);

    const startYearAsNumber = Number(totCopyrightStartYear);
    const copyrightWithYear = getCopyrightWithYears(startYearAsNumber, currentYear, copyright);

    return (
        <FooterWrapper>
            <Container maxWidth="lg">
                <Grid container direction="row" wrap="nowrap" alignItems="center" justify="space-between" spacing={3}>
                    <Grid item>
                        <FooterTypography component="small" color="primary">
                            {copyrightWithYear}
                        </FooterTypography>
                    </Grid>
                    <FooterContactInformation />
                </Grid>
            </Container>
        </FooterWrapper>
    );
});
