import React from 'react';

export const FolderIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="5 0 20 18" fill="currentColor">
        <defs>
            <mask id="xezob" width="2" height="2" x="-1" y="-1">
                <path fill="#fff" d="M5 0h20v18H5z" />
                <path d="M22 3a3 3 0 0 1 2.999 3v9a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h4.56a3.001 3.001 0 0 1 2.84 2l.32 1zm1 3a1 1 0 0 0-1-1h-7a1 1 0 0 1-.951-.68l-.54-1.64a1 1 0 0 0-.95-.68h-4.56a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6z" />
            </mask>
            <filter id="xezoa" width="112" height="128" x="-41" y="-46" filterUnits="userSpaceOnUse">
                <feOffset dy="18" in="SourceGraphic" result="FeOffset1090Out" />
                <feGaussianBlur in="FeOffset1090Out" result="FeGaussianBlur1091Out" stdDeviation="14.4 14.4" />
            </filter>
        </defs>
        <g>
            <g>
                <g filter="url(#xezoa)">
                    <path
                        fill="none"
                        d="M22 3a3 3 0 0 1 2.999 3v9a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h4.56a3.001 3.001 0 0 1 2.84 2l.32 1zm1 3a1 1 0 0 0-1-1h-7a1 1 0 0 1-.951-.68l-.54-1.64a1 1 0 0 0-.95-.68h-4.56a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6z"
                        mask='url("#xezob")'
                    />
                    <path
                        fill-opacity=".03"
                        d="M22 3a3 3 0 0 1 2.999 3v9a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h4.56a3.001 3.001 0 0 1 2.84 2l.32 1zm1 3a1 1 0 0 0-1-1h-7a1 1 0 0 1-.951-.68l-.54-1.64a1 1 0 0 0-.95-.68h-4.56a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6z"
                    />
                </g>
                <path d="M22 3a3 3 0 0 1 2.999 3v9a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h4.56a3.001 3.001 0 0 1 2.84 2l.32 1zm1 3a1 1 0 0 0-1-1h-7a1 1 0 0 1-.951-.68l-.54-1.64a1 1 0 0 0-.95-.68h-4.56a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6z" />
            </g>
        </g>
    </svg>
);
