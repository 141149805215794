import { createContext } from 'react';
import { CommissionSessionModel, CommissionSessionProcessModel } from '../models';

export type CommissionSessionPageContextType = {
    commissionSessionModel: CommissionSessionModel;
};

export const CommissionSessionPageContext = createContext<CommissionSessionPageContextType>({
    commissionSessionModel: {} as CommissionSessionProcessModel,
});
