import { AppBar, Box, Container, Grid, Link, useMediaQuery } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ModularNavigationWidget } from '@platform/modular-navigation-widget';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';
import { StartPageHeaderLinks } from '../../pages';
import { ReactComponent as Logo } from '../../resources/images/logo.svg';
import { AppTheme } from '../../types';
import { allLinks, AppHeaderLinks } from './app-header-links';
import { LanguageButton } from './LanguageButton';

const TotXLogo = withTheme(
    styled.span`
        color: ${({ theme }): string => theme.palette.primary.contrastText};
        font-size: 30px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.6px;
        white-space: nowrap;
    `,
);

export const TotAppBar = observer((): JSX.Element => {
    const { modulesStore, intlStore, personStore } = useStore();
    const { loadModulesList } = modulesStore;

    const [modularNavigation] = useFeature('modularNavigation');
    const [isWithCustomLogo, logoConfig] = useFeature('headerLogo');

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isSmSize = useMediaQuery((theme: AppTheme) => theme.breakpoints.down('sm'));

    const handleBurgerChange = (): void => {
        setIsMenuOpen((isOpenMenu) => !isOpenMenu);
    };

    return (
        <AppBar color="default" position="static" elevation={0}>
            <Container maxWidth="lg">
                <Box pt={3.75} pb={3.75}>
                    <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        justify="space-between"
                        wrap={isSmSize ? 'wrap' : 'nowrap'}
                    >
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                                <Grid item>
                                    <Link component={RouterLink} to={clientRoute.commissionSessions} underline="none">
                                        <Grid container direction="row" alignItems="center" wrap="nowrap">
                                            {isWithCustomLogo ? (
                                                <Link component={RouterLink} to={clientRoute.root} underline="none">
                                                    <img src={logoConfig.src} alt="logo" style={logoConfig.style} />
                                                </Link>
                                            ) : (
                                                <React.Fragment>
                                                    <Grid item style={{ paddingBottom: '0' }}>
                                                        <Box pr={3}>
                                                            <Logo />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <TotXLogo>
                                                            <FormattedMessage id="productTitle" />
                                                        </TotXLogo>
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </Link>
                                </Grid>
                                {modularNavigation && personStore.user.id && (
                                    <Grid item key={personStore.user.id}>
                                        <ModularNavigationWidget
                                            loadModulesList={loadModulesList}
                                            locale={intlStore.locale}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Box mr={3} ml={3} pt={3.75}>
                                <Switch>
                                    <Route exact path={[clientRoute.root, clientRoute.login]}>
                                        <StartPageHeaderLinks />
                                    </Route>
                                    <Route>
                                        <AppHeaderLinks
                                            isSmSize={isSmSize}
                                            isOpenMenu={isMenuOpen}
                                            handleBurgerChange={handleBurgerChange}
                                            avgLinksWidth={140}
                                            allLinks={allLinks}
                                        />
                                    </Route>
                                </Switch>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box pt={3.75}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    spacing={3}
                                    wrap="nowrap"
                                >
                                    {/*<Grid item>
                                        <IconButton color="secondary">
                                            <Badge badgeContent={2} color="error">
                                                <BellIcon />
                                            </Badge>
                                        </IconButton>
                                    </Grid>*/}
                                    <Grid item>
                                        <LanguageButton />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </AppBar>
    );
});
