import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PeopleIcon from '@material-ui/icons/People';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import SubjectIcon from '@material-ui/icons/Subject';
import React from 'react';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { FolderIcon, ShapeIcon } from '../../../resources';
import { AuthorizationCheckQuery } from '../../../types';

export type AppBarComponent = {
    key: string;
    to: string;
    icon: JSX.Element;
    messageID: string;
    query?: AuthorizationCheckQuery;
};

export type AllowedLink = {
    link: AppBarComponent;
    allowed: boolean;
};

export const allLinks: AppBarComponent[] = [
    {
        key: 'categories',
        to: clientRoute.categories,
        icon: <FolderIcon />,
        messageID: 'homePage.headerLinks.categories',
        query: {
            entityCode: entities.System,
            permCode: permissions.System.Administration,
        },
    },
    {
        key: 'subjects',
        to: clientRoute.subjects,
        icon: <ShapeIcon />,
        messageID: 'homePage.headerLinks.subjects',
        query: {
            entityCode: entities.System,
            permCode: permissions.System.ViewSubjectList,
        },
    },
    {
        key: 'commissions',
        to: clientRoute.commissions,
        icon: <PeopleOutline style={{ fontSize: '28px' }} />,
        messageID: 'homePage.headerLinks.commissions',
        query: {
            entityCode: entities.System,
            permCode: permissions.System.ViewCommissionList,
        },
    },
    {
        key: 'sessions',
        to: clientRoute.commissionSessions,
        icon: <MeetingRoomIcon style={{ fontSize: '24px' }} />,
        messageID: 'homePage.headerLinks.commissionSessions',
        query: {
            entityCode: entities.System,
            permCode: permissions.System.ViewSessionList,
        },
    },
    {
        key: 'users',
        to: clientRoute.users,
        icon: <PeopleIcon style={{ fontSize: '24px' }} />,
        messageID: 'homePage.headerLinks.users',
        query: {
            entityCode: entities.System,
            permCode: permissions.System.Administration,
        },
    },
    {
        key: 'pfTemplates',
        to: clientRoute.pfTemplateList,
        icon: <SubjectIcon style={{ fontSize: '24px' }} />,
        messageID: 'homePage.headerLinks.pfTemplates',
        query: {
            entityCode: entities.System,
            permCode: permissions.System.Administration,
        },
    },
];
