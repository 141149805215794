const commission: Record<string, string | Record<string, string>> = {
    listTitle: 'Комиссии',
    create: 'Создать комиссию',
    fields: {
        title: 'Наименование',
        created: 'Дата создания',
        author: 'Автор',
        lastModified: 'Последнее изменение',
        manager: 'Председатель комиссии',
        members: 'Члены комиссии',
        form: 'Форма',
        process: 'ЖЦ',
        state: 'Состояние',
    },
    actions: {
        delete: 'Удалить комиссию',
        edit: 'Редактировать комиссию',
    },
    blocks: {
        serviceInfo: 'Служебная информация',
    },
    pagingInfo:
        'Всего {count, number} комисс{count, plural, one {ия} few {ии} other {ий}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmDeletionInfoText: 'Вы действительно хотите удалить комиссию "{title}"?',
    members: {
        title: 'Участники',
        member: 'Член комиссии',
        head: 'Председатель комиссии',
        addMember: 'Добавить участника',
        addHeadMember: 'Назначить председателя',
        memberRole: 'Роль пользователя',
        confirmDeletionInfoText: 'Вы действительно хотите удалить участника {title}?',
    },
};

export default commission;
