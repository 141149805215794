const users: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Пользователи',
    fields: {
        login: 'Логин',
        status: 'Статус',
        name: 'ФИО',
        roles: 'Роли',
    },
    actions: {
        block: 'Заблокировать',
        unblock: 'Активировать',
    },
    status: {
        blocked: 'Заблокирован',
        active: 'Активный',
        inactive: 'Неактивный',
    },
    loginAsUser: 'Авторизоваться под пользователем',
    editRoles: 'Редактировать роли',
    registryPagingInfo:
        'Всего {count, number} пользовател{count, plural, one {ь} few {я} other {ей}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmBlock: 'Подтвердите блокирование',
    confirmBlockInfoText: 'Вы действительно хотите заблокировать {login}?',
    deleteUser: 'Удаление пользователя',
    deleteUserConfirmText: 'Пользователь будет удален. Подтвердить действие?',
};

export default users;
