import { PrimitiveType } from 'intl-messageformat';
import { action, computed, observable } from 'mobx';
import { createIntl, createIntlCache, IntlCache, IntlShape } from 'react-intl';
import { apiConfigs } from '../apiConfigs';
import { defaultLocale, supportedLocales } from '../constants';
import { LocalizedMessages, TotLocale } from '../types';
import { Api } from './Api';
import { RootStore } from './RootStore';

const TOT_X_LANG_LOCALIZATION = 'TotXLangLocalization';

type MessageFormatter = (id: string, values?: Record<string, PrimitiveType>) => string;

export class IntlStore {
    private savedLocale: TotLocale | null = localStorage.getItem(TOT_X_LANG_LOCALIZATION) as TotLocale;
    private messagesCache: Record<string, Record<string, string>> = {};

    private readonly intlCache: IntlCache;
    @observable intl: IntlShape;

    @observable private rootStore: RootStore;
    @observable private api: Api;
    @observable locale: TotLocale;

    locales: TotLocale[] = supportedLocales;

    @observable formatMessage: MessageFormatter;

    constructor(rootStore: RootStore, locales: LocalizedMessages) {
        this.messagesCache = { ...locales };
        this.rootStore = rootStore;
        this.locale = this.savedLocale || defaultLocale;
        document.documentElement.lang = this.locale;
        this.intlCache = createIntlCache();
        this.intl = createIntl({ locale: this.locale, messages: this.messages }, this.intlCache);
        this.formatMessage = this.changeMessageFormatter();
        this.api = rootStore.api;
    }

    @action.bound
    changeMessageFormatter(): MessageFormatter {
        return (id: string, values?: Record<string, PrimitiveType>): string => {
            return this.intl.formatMessage({ id }, values);
        };
    }

    @action.bound
    changeLocale(locale: string): void {
        if (this.api.authStatus === 'ok') {
            this.api.client(apiConfigs.setLang(locale)).then(() => {
                this.setLocalLocalization(locale);
            });
        } else {
            this.setLocalLocalization(locale);
        }
    }

    @action.bound
    setLocalLocalization(locale: string): void {
        localStorage.setItem(TOT_X_LANG_LOCALIZATION, locale);
        this.locale = locale as TotLocale;
        this.intl = createIntl({ locale: this.locale, messages: this.messages }, this.intlCache);
        this.formatMessage = this.changeMessageFormatter();
        document.documentElement.lang = locale;
    }

    @computed
    get messages(): Record<string, string> {
        return this.messagesCache[this.locale];
    }
}
