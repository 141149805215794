import { Button } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExportIcon } from '../../resources';
import { TableQueryData } from '../../types';

export type ExportButtonProps = {
    queryData: TableQueryData;
    loadRegisterList: (queryData: TableQueryData) => void;
};

export const ExportButton = (props: ExportButtonProps): JSX.Element => {
    const { queryData, loadRegisterList } = props;

    const exportListXls = (): void => {
        loadRegisterList(queryData);
    };

    return (
        <Button variant="text" color="primary" startIcon={<ExportIcon />} onClick={exportListXls}>
            <FormattedMessage id="common.export" />
        </Button>
    );
};
