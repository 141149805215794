const subject: Record<string, string | Record<string, string>> = {
    subjectListTitle: 'Objects',
    createSubject: 'Create object',
    newSubject: 'New object',
    commonFiles: 'Documents for {title}',
    hiddenFiles: 'Private documents for {title}',
    editSession: 'Edit session',
    fields: {
        title: 'Title',
        identifier: 'Number',
        commissionSessions: 'Session',
        created: 'Created',
        state: 'State',
        author: 'Author',
        session: 'Session',
        lastModified: 'Last modified',
        category: 'Category',
        process: 'Subject`s LC',
        form: 'Subject`s Form',
    },
    actions: {
        deleteSubject: 'Delete object',
        editSubject: 'Edit object',
    },
    registryPagingInfo: 'Total {count, number} objects. Displayed from {from, number} to {to, number}',
    blocks: {
        serviceInfo: 'Service information',
    },
    confirmDeletionInfoText: 'Are you sure you want to delete the expertise object "{title}"?',
};

export default subject;
