import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TotObjectFilterPanelDetails, TotObjectFilterPanelSummary } from '../../../components';
import { textFieldProps } from '../../../constants';
import { UserListModel } from '../../../models';

export type UserListFilterPanelProps = {
    model: UserListModel;
};

export const UserListFilterPanel = observer((props: UserListFilterPanelProps): JSX.Element => {
    const { model } = props;
    const { filtering } = model;
    const intl = useIntl();

    return (
        <ExpansionPanel>
            <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="common.filters" />
                </Typography>
            </TotObjectFilterPanelSummary>
            <TotObjectFilterPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container spacing={6}>
                        <Grid item xs={6}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="users.fields.login" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.login.value}
                                onChange={filtering.login.onChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="users.fields.name" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.name.value}
                                onChange={filtering.name.onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectFilterPanelDetails>
        </ExpansionPanel>
    );
});
