import styled from 'styled-components';
import { Grid, TableCell, Typography, withTheme } from '@material-ui/core';

export const GeneralizationTableCell = styled(TableCell)`
    min-width: 200px;
`;

export const NoDataText = withTheme(styled(Typography)`
    font-size: 16px;
    font-weight: 700;
`);

export const DefaultContentContainer = styled(Grid)`
    min-height: 100px !important;
`;
