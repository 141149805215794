import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStore } from '../../../../hooks';
import { SessionProcedureParams } from '../../../../types';

const StyledNumbers = styled(Typography)`
    font-weight: 700;
    font-size: 24px;
`;

const StyledText = styled(Typography)`
    font-weight: 700;
    font-size: 18px;
`;

export type SessionProcedureNumberOfVotersProps = {
    updateObjectPage: VoidFunction;
    reloadKey: number;
};

export const SessionProcedureNumberOfVoters = (props: SessionProcedureNumberOfVotersProps): JSX.Element => {
    const { updateObjectPage, reloadKey } = props;
    const { commissionSessionStore } = useStore();
    const { procedureId } = useParams<SessionProcedureParams>();

    const { getCompletedVotes, numberOfVoter, totalVoter } = commissionSessionStore;

    useEffect(() => {
        getCompletedVotes(procedureId).then(updateObjectPage);
    }, [commissionSessionStore, procedureId, reloadKey]);

    return (
        <Box className="t-number-of-voters" pt={5.5} pb={5.5} pr={6} pl={6} minHeight={15}>
            <Grid container alignItems="center">
                <Grid item>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item>
                            <StyledNumbers className="t-number-of-voters-cell">{numberOfVoter}</StyledNumbers>
                        </Grid>
                        <Grid item>/</Grid>
                        <Grid item>
                            <StyledNumbers className="t-total-voters-cell">{totalVoter}</StyledNumbers>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Box pl={10}>
                        <StyledText>
                            <FormattedMessage id="commissionSession.sessionProcedureSubjectList.voted" />
                        </StyledText>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
