const commission: Record<string, string | Record<string, string>> = {
    listTitle: 'Commissions',
    create: 'Create commission',
    fields: {
        title: 'Title',
        created: 'Created',
        author: 'Author',
        lastModified: 'Last modified',
        manager: 'Manager',
        members: 'Members',
        form: 'Form',
        process: 'Life cycle',
        state: 'State',
    },
    actions: {
        deleteCategory: 'Delete commission',
        editCategory: 'Edit commission',
    },
    blocks: {
        serviceInfo: 'Service information',
    },
    pagingInfo: 'Total {count, number} commissions. Displayed from {from, number} to {to, number}',
    confirmDeletionInfoText: 'Are you sure you want to delete the commission "{title}"?',
    members: {
        title: 'Members',
        member: 'Commission member',
        head: 'Commission head',
        addMember: 'Add member',
        addHeadMember: 'Assign head',
        memberRole: 'User role',
        confirmDeletionInfoText: 'Are you sure you want to delete the member {title}?',
    },
};

export default commission;
