import React from 'react';

export const BellIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="2 0 20 20" fill="currentColor">
        <defs>
            <mask id="mqneb" width="2" height="2" x="-1" y="-1">
                <path fill="#fff" d="M4 0h16v20H4z" />
                <path d="M18 11.18A3.001 3.001 0 0 1 19.998 14v2a1 1 0 0 1-1 1h-3.14a4 4 0 0 1-7.72 0h-3.14a1 1 0 0 1-1-1v-2a3 3 0 0 1 2-2.82V8a6 6 0 0 1 5-5.91V1a1 1 0 1 1 2 0v1.09a6.001 6.001 0 0 1 5 5.91zM7.998 11h8V8a4 4 0 0 0-8 0zm4 7a2.003 2.003 0 0 0 1.72-1h-3.44A2 2 0 0 0 12 18zm6-4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v1h12v-1z" />
            </mask>
            <filter id="mqnea" width="108" height="130" x="-42" y="-46" filterUnits="userSpaceOnUse">
                <feOffset dy="18" in="SourceGraphic" result="FeOffset1233Out" />
                <feGaussianBlur in="FeOffset1233Out" result="FeGaussianBlur1234Out" stdDeviation="14.4 14.4" />
            </filter>
        </defs>
        <g>
            <g>
                <g filter="url(#mqnea)">
                    <path
                        fill="none"
                        d="M18 11.18A3.001 3.001 0 0 1 19.998 14v2a1 1 0 0 1-1 1h-3.14a4 4 0 0 1-7.72 0h-3.14a1 1 0 0 1-1-1v-2a3 3 0 0 1 2-2.82V8a6 6 0 0 1 5-5.91V1a1 1 0 1 1 2 0v1.09a6.001 6.001 0 0 1 5 5.91zM7.998 11h8V8a4 4 0 0 0-8 0zm4 7a2.003 2.003 0 0 0 1.72-1h-3.44A2 2 0 0 0 12 18zm6-4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v1h12v-1z"
                        mask='url("#mqneb")'
                    />
                    <path
                        fill-opacity=".03"
                        d="M18 11.18A3.001 3.001 0 0 1 19.998 14v2a1 1 0 0 1-1 1h-3.14a4 4 0 0 1-7.72 0h-3.14a1 1 0 0 1-1-1v-2a3 3 0 0 1 2-2.82V8a6 6 0 0 1 5-5.91V1a1 1 0 1 1 2 0v1.09a6.001 6.001 0 0 1 5 5.91zM7.998 11h8V8a4 4 0 0 0-8 0zm4 7a2.003 2.003 0 0 0 1.72-1h-3.44A2 2 0 0 0 12 18zm6-4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v1h12v-1z"
                    />
                </g>
                <path d="M18 11.18A3.001 3.001 0 0 1 19.998 14v2a1 1 0 0 1-1 1h-3.14a4 4 0 0 1-7.72 0h-3.14a1 1 0 0 1-1-1v-2a3 3 0 0 1 2-2.82V8a6 6 0 0 1 5-5.91V1a1 1 0 1 1 2 0v1.09a6.001 6.001 0 0 1 5 5.91zM7.998 11h8V8a4 4 0 0 0-8 0zm4 7a2.003 2.003 0 0 0 1.72-1h-3.44A2 2 0 0 0 12 18zm6-4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v1h12v-1z" />
            </g>
        </g>
    </svg>
);
