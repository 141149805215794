const subject: Record<string, string | Record<string, string>> = {
    subjectListTitle: 'Инициативы',
    createSubject: 'Создать инициативу',
    newSubject: 'Новая инициатива',
    fields: {
        process: 'ЖЦ инициативы',
        form: 'Форма инициативы',
    },
    actions: {
        deleteSubject: 'Удалить инициативу',
        editSubject: 'Редактировать инициативу',
    },
    registryPagingInfo:
        'Всего {count, number} инициатив{count, plural, one {а} few {ы} other {}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmDeletionInfoText: 'Вы действительно хотите удалить инициативу экспертизы "{title}"?',
};

export default subject;
