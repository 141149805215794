import {
    Box,
    Container,
    createStyles,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { ErrorDialog, ErrorMessage, TableHeadTypography, TotTablePagination } from '../../../components';
import { useError, useStore } from '../../../hooks';
import { UserListModel } from '../../../models';
import { UserRowDTO, UserStatusCode } from '../../../types';
import { UserBlockButton, UserDeleteForeverButton } from './user-buttons';
import { UserListFilterPanel } from './UserListFilterPanel';

const useStyles = makeStyles(() =>
    createStyles({
        nowrap: {
            whiteSpace: 'nowrap',
        },
    }),
);

export const UserListPage = observer((): JSX.Element => {
    const [isError, errorText, enableError, resetError] = useError();
    const { userStore } = useStore();
    const classes = useStyles();

    const [model] = useState<UserListModel>(() => new UserListModel(userStore));

    const renderBlockUnblock = (user: UserRowDTO): JSX.Element => {
        const { blockUser, unblockUser } = model;
        const { id, title } = user.login;
        return user.status === 'blocked' ? (
            <Tooltip title={<FormattedMessage id="users.actions.unblock" />}>
                <IconButton onClick={unblockUser(id)}>
                    <PersonAddIcon />
                </IconButton>
            </Tooltip>
        ) : (
            <UserBlockButton login={title} onConfirm={blockUser(id)} />
        );
    };

    const renderBody = (): JSX.Element[] => {
        return model.rows.map((user) => {
            const { id, title } = user.login;
            return (
                <TableRow key={id} hover>
                    <TableCell>
                        <Link component={NavLink} underline="always" to={generatePath(clientRoute.user, { id })}>
                            {title}
                        </Link>
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id={statusTextId(user.status)} />
                    </TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.roles}</TableCell>
                    <TableCell className={classes.nowrap}>
                        {renderBlockUnblock(user)}
                        <UserDeleteForeverButton login={title} onConfirm={deleteUser(id)} />
                    </TableCell>
                </TableRow>
            );
        });
    };

    const statusTextId = (statusCode: UserStatusCode): string => {
        switch (statusCode) {
            case 'active':
                return 'users.status.active';
            case 'blocked':
                return 'users.status.blocked';
            case 'inactive':
                return 'users.status.inactive';
        }
    };

    const deleteUser = (id: string): (() => Promise<void>) => {
        return () => model.deleteUser(id)().catch(handleErrorDeletion);
    };

    const handleErrorDeletion = (error: AxiosError) => {
        const errorText = ErrorMessage(error);
        enableError(errorText);
    };

    useEffect(() => {
        return model.dispose;
    }, [model]);

    return (
        <Container maxWidth="lg">
            <Box pt={5} pb={5}>
                <Grid container spacing={10} direction="column">
                    <Grid item container direction="row" justify="space-between">
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="users.listTitle" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <UserListFilterPanel model={model} />
                    </Grid>
                    <Grid item>
                        <TableContainer component={Paper}>
                            {model.isLoading && <LinearProgress />}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '23%' }}>
                                            <TableHeadTypography>
                                                <FormattedMessage id="users.fields.login" />
                                            </TableHeadTypography>
                                        </TableCell>
                                        <TableCell style={{ width: '23%' }}>
                                            <TableHeadTypography>
                                                <FormattedMessage id="users.fields.status" />
                                            </TableHeadTypography>
                                        </TableCell>
                                        <TableCell style={{ width: '23%' }}>
                                            <TableHeadTypography>
                                                <FormattedMessage id="users.fields.name" />
                                            </TableHeadTypography>
                                        </TableCell>
                                        <TableCell style={{ width: '23%' }}>
                                            <TableHeadTypography>
                                                <FormattedMessage id="users.fields.roles" />
                                            </TableHeadTypography>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderBody()}</TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={100}>
                                            <TotTablePagination
                                                count={model.rowsCount}
                                                page={model.pageNumber}
                                                onChangePage={model.onChangePage}
                                                onChangeRowsPerPage={model.onChangePageSize}
                                                rowsPerPage={model.pageSize}
                                                rowsPerPageOptions={model.pageSizeOptions}
                                                pagingInfoMessageId="users.registryPagingInfo"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            <ErrorDialog message={errorText} isOpen={isError} setIsClosed={resetError} />
        </Container>
    );
});
