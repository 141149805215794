import React from 'react';

export const LightbulbAltIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="currentColor">
        <g>
            <g>
                <path d="M13.089 1.8a8.002 8.002 0 0 1 1.13 11.2A5.168 5.168 0 0 0 13 16.19V19a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3v-3a4.54 4.54 0 0 0-1.14-2.87A8.066 8.066 0 0 1 .14 6.48 8 8 0 0 1 6.409.16a8.001 8.001 0 0 1 6.68 1.66V1.8zM10.999 18H5v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm1.67-6.24A5.893 5.893 0 0 0 13.999 8a6 6 0 0 0-7.21-5.88 6.001 6.001 0 0 0-3.39 9.72 6.501 6.501 0 0 1 1.6 4.16h2v-3a1 1 0 1 1 2 0v3h2a7.127 7.127 0 0 1 1.67-4.24z" />
            </g>
        </g>
    </svg>
);
