const subject: Record<string, string | Record<string, string>> = {
    subjectListTitle: 'Initiatives',
    createSubject: 'Create initiative',
    newSubject: 'New initiative',
    fields: {
        process: 'LC of initiative',
        form: 'Form of initiative',
    },
    actions: {
        deleteSubject: 'Delete initiative',
        editSubject: 'Edit initiative',
    },
    registryPagingInfo: 'Total {count, number} initiatives. Displayed from {from, number} to {to, number}',
    confirmDeletionInfoText: 'Are you sure you want to delete the expertise initiative "{title}"?',
};

export default subject;
