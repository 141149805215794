const category: Record<string, string | Record<string, string>> = {
    categoryListTitle: 'Categories of objects',
    createCategory: 'Create category',
    fields: {
        title: 'Title',
        created: 'Created',
        author: 'Author',
        lastModified: 'Last modified',
        form: 'Subject`s Form',
        process: 'Subject`s LC',
    },
    actions: {
        deleteCategory: 'Delete category',
        editCategory: 'Edit category',
    },
    blocks: {
        serviceInfo: 'Service information',
    },
    pagingInfo: 'Total {count, number} categories. Displayed from {from, number} to {to, number}',
    confirmDeletionInfoText: 'Are you sure you want to delete the category "{title}"?',
};

export default category;
