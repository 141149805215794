import { Box, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteField } from '../../../components/';
import { useAntiDoubleClick, useStore, useYup } from '../../../hooks';
import {
    IdTitle,
    ModalProps,
    SessionAttributesField,
    SessionProcedureAttributes,
    SessionProcedureAttributesDTO,
} from '../../../types';

export type SessionProcedureGeneralizationAttributeDialogProps = ModalProps & {
    updateTable: () => void;
    procedureId: string;
};

const initialValues: SessionProcedureAttributes = {
    attributes: [],
};

export const SessionProcedureGeneralizationAttributeDialog = observer(
    (props: SessionProcedureGeneralizationAttributeDialogProps): JSX.Element => {
        const { procedureId, isOpen, updateTable, setIsClosed } = props;
        const { commissionSessionStore } = useStore();
        const { sessionProcedureGeneralizationAttributeListSelectData, sessionProcedureGeneralizationAttributeCreate } =
            commissionSessionStore;
        const [attributes, setAttributes] = useState<IdTitle[]>([]);
        const intl = useIntl();

        useEffect(() => {
            sessionProcedureGeneralizationAttributeListSelectData(procedureId).then(setAttributes);
        }, [isOpen]);

        const { Yup } = useYup();
        const schema = Yup.object({
            attributes: Yup.array().min(1),
        });

        const onSubmit = (values: SessionProcedureAttributes): Promise<void> => {
            const dto: SessionProcedureAttributesDTO = {
                procedureId,
                attributeIds: values.attributes.map((attribute) => attribute.id),
            };

            return sessionProcedureGeneralizationAttributeCreate(procedureId, dto).then(() => {
                updateTable();
                setIsClosed();
            });
        };

        const [isSending, endIcon, submitHandler] = useAntiDoubleClick(onSubmit);

        return (
            <Dialog maxWidth="sm" fullWidth open={isOpen}>
                <DialogTitle>
                    <FormattedMessage id="commissionSession.generalizationTable.dialogAddTitle" />
                </DialogTitle>

                <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitHandler}>
                    <Form>
                        <DialogContent>
                            <Box pb={3}>
                                <AutocompleteField
                                    fieldName={SessionAttributesField.attributes}
                                    label={intl.formatMessage({
                                        id: 'commissionSession.sessionProcedureTable.attribute',
                                    })}
                                    options={attributes}
                                    required={true}
                                    multiple
                                />
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button color="primary" onClick={setIsClosed}>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                            <Button
                                className="t-confirm-edit"
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={isSending}
                                endIcon={endIcon}
                            >
                                <FormattedMessage id="common.add" />
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </Dialog>
        );
    },
);
