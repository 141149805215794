import React from 'react';

export const ExportIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="31 25 20 20">
        <path
            fill="currentColor"
            d="M47.998 44.999H33.999c-1.658 0-3-1.343-3-3v-4c0-.553.447-1 .999-1 .553 0 1.001.447 1.001 1v4c0 .552.448 1 1 1h13.999c.553 0 1-.448 1-1v-4c0-.553.449-1 1.001-1 .551 0 1 .447 1 1v4c0 1.657-1.343 3-3.001 3zm-7-20c.552 0 1.001.447 1.001 1v9.59l2.289-2.3c.392-.392 1.029-.392 1.42 0 .393.392.393 1.028 0 1.42l-4 4c-.095.091-.207.162-.33.21-.242.107-.517.107-.759 0-.123-.048-.236-.119-.33-.21l-4-4c-.393-.392-.393-1.028 0-1.42.392-.392 1.028-.392 1.419 0l2.29 2.3v-9.59c0-.553.449-1 1-1z"
        />
    </svg>
);
