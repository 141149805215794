import { Dialog, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RequiredLabel } from '../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../hooks';
import { PfTemplateModel } from '../../models';
import { ModalProps, PfTemplate, PfTemplateDTO, PrintFormField } from '../../types';
import { PFTemplateDropzone } from './PFTemplateDropzone';

export type PfTemplateDialogProps = ModalProps & {
    pfTemplateList: PfTemplateDTO[];
    onSuccessSubmit: () => void;
    editPfId?: string;
};

export const PfTemplateDialog = observer((props: PfTemplateDialogProps): JSX.Element => {
    const { pfTemplateList, editPfId, isOpen, setIsClosed, onSuccessSubmit } = props;
    const { intlStore, pfTemplateStore } = useStore();
    const { create, update } = pfTemplateStore;
    const intl = useIntl();

    const pfTemplateModel = useMemo(() => new PfTemplateModel(intlStore), [editPfId]);
    const { file, title } = pfTemplateModel;

    useEffect(() => {
        if (editPfId && isOpen) {
            const templateDto = pfTemplateList.find((dto) => dto.id === editPfId);
            templateDto && pfTemplateModel.load(templateDto);
        }
    }, [editPfId, isOpen]);

    const { Yup } = useYup();
    const mixed = Yup.mixed();
    const schema = Yup.object({
        title: Yup.string().required(),
        file: file.fileName ? mixed : mixed.required(),
    });

    const initialValues: PfTemplate = {
        title,
    };

    const handleSave = (values: PfTemplate): Promise<void> => {
        const { title, file } = values;

        const data: FormData = new FormData();
        data.append('title', title);
        file && data.append('file', file);

        const saveFunc = editPfId ? update : create;
        return saveFunc(data, editPfId).then(() => {
            onSuccessSubmit();
            setIsClosed();
        });
    };

    const titleLabel = <RequiredLabel text={intl.formatMessage({ id: 'pfTemplate.fields.title' })} />;

    const [isSending, endIcon, submitHandler] = useAntiDoubleClick(handleSave);

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage id={editPfId ? 'pfTemplate.edit' : 'pfTemplate.create'} />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={submitHandler}
                enableReinitialize={true}
            >
                <Form>
                    <DialogContent dividers>
                        <Grid container spacing={6} direction="column">
                            <Grid item>
                                <Field
                                    component={TextField}
                                    variant="outlined"
                                    label={titleLabel}
                                    name={PrintFormField.title}
                                    fullWidth
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item>
                                <PFTemplateDropzone model={file} />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={setIsClosed}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
