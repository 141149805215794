import { Button, Container, Grid, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { IconButtonLarge, StickyWrapper, TotObjectActionsBar } from '../../components';
import { UserPersonModel } from '../../models';
import { EditIcon } from '../../resources';

export type UserControlPanelProps = {
    userPersonModel: UserPersonModel;
    saveForm: () => Promise<void>;
};

export const UserControlPanel = observer((props: UserControlPanelProps) => {
    const { userPersonModel, saveForm } = props;
    const { id } = userPersonModel;

    return (
        <StickyWrapper mode="bottom">
            <TotObjectActionsBar>
                <Container maxWidth="lg">
                    <Grid alignItems="center" justify="space-between" container>
                        <Grid item>
                            <Grid spacing={3} alignItems="center" justify="center" container>
                                <Switch>
                                    <Route exact path={clientRoute.user}>
                                        <Grid item>
                                            <Tooltip title={<FormattedMessage id="common.edit" />}>
                                                <IconButtonLarge
                                                    // @ts-ignore
                                                    component={NavLink}
                                                    to={generatePath(clientRoute.userEdit, { id })}
                                                >
                                                    <EditIcon />
                                                </IconButtonLarge>
                                            </Tooltip>
                                        </Grid>
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid spacing={3} alignItems="center" justify="center" container>
                                <Switch>
                                    <Route exact path={clientRoute.userEdit}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                component={NavLink}
                                                to={generatePath(clientRoute.user, { id })}
                                            >
                                                <FormattedMessage id="common.cancel" />
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" onClick={saveForm}>
                                                <FormattedMessage id="common.save" />
                                            </Button>
                                        </Grid>
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectActionsBar>
        </StickyWrapper>
    );
});
