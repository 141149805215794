import React from 'react';

export const FileDocumentBoxSearchIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21">
        <path
            fill="currentColor"
            fill-rule="evenodd"
            d="M14.876 17.322c-.689.43-1.504.678-2.376.678-2.485 0-4.5-2.015-4.5-4.5S10.015 9 12.5 9s4.5 2.015 4.5 4.5c0 .883-.254 1.706-.693 2.401l3.084 3.083-1.407 1.406-3.108-3.068zM12.5 11c-1.381 0-2.5 1.119-2.5 2.5s1.119 2.5 2.5 2.5 2.5-1.119 2.5-2.5-1.119-2.5-2.5-2.5zM0 16V2C0 .89.89 0 2 0h14c1.11 0 2 .89 2 2v8.034c-.511-.808-1.195-1.497-2-2.012V2H2v14h4.498c.312.747.759 1.424 1.311 2H2c-1.11 0-2-.89-2-2zm4-2v-2h2.174c-.114.481-.174.984-.174 1.5l.019.5H4zM4 4h10v2H4V4zm3.022 6H4V8h5.034c-.809.511-1.497 1.195-2.012 2z"
        />
    </svg>
);
