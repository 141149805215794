import { action, computed, observable } from 'mobx';
import { entities, permissions } from '../../authSchemeConfig';
import { AuthorizationStore, PrintFormStore, RootStore } from '../../stores';
import { AuthorizationCheckQuery } from '../../types';
import { PrintFormModel } from './PrintFormModel';

export class PrintFormListModel {
    @observable protected rootStore: RootStore;
    @observable protected printFormStore: PrintFormStore;
    @observable protected authorizationStore: AuthorizationStore;

    @observable sessionId: string;
    @observable printForms: PrintFormModel[] = [];
    @observable isLoading = false;
    @observable updatePfListIntervalId?: number;
    @observable isWithEditPf = true;
    @observable isWithDownloadPf = true;

    constructor(sessionId: string, rootStore: RootStore) {
        this.rootStore = rootStore;
        this.printFormStore = rootStore.printFormStore;
        this.authorizationStore = rootStore.authorizationStore;
        this.sessionId = sessionId;
    }

    @computed
    get isSomePrintFormGenerating(): boolean {
        return this.printForms.some((printFormModel) => printFormModel.isGenerating);
    }

    @action.bound
    clearUpdatePfListInterval(): void {
        if (this.updatePfListIntervalId) {
            clearInterval(this.updatePfListIntervalId);
            this.setUpdatePfListIntervalId(undefined);
        }
    }

    @action.bound
    updateIfSomePrintFormIsGenerating(pfDelay: number): void {
        this.clearUpdatePfListInterval();
        if (this.isSomePrintFormGenerating) {
            const updatePfListInterval = setInterval(this.load, pfDelay);
            this.setUpdatePfListIntervalId(updatePfListInterval as unknown as number);
        }
    }

    @action.bound
    load(): void {
        this.setIsLoading(true);
        this.printFormStore
            .getPrintForms(this.sessionId)
            .then((printFormsDTO) => {
                this.setPrintForms(
                    printFormsDTO.map(
                        (printForm) => new PrintFormModel(this.sessionId, printForm, this.printFormStore),
                    ),
                );
            })
            .finally(() => this.setIsLoading(false));
    }

    @action.bound
    checkIsWithEditPf(): Promise<void> {
        const query: AuthorizationCheckQuery = {
            entityCode: entities.CommissionSession,
            permCode: permissions.CommissionSession.GeneratePrintForm,
            entityId: this.sessionId,
        };

        return this.authorizationStore.check(query).then(this.setIsWithEditPf);
    }

    @action.bound
    checkIsWithDownloadPf(): Promise<void> {
        const query: AuthorizationCheckQuery = {
            entityCode: entities.CommissionSession,
            permCode: permissions.CommissionSession.DownloadPrintForm,
            entityId: this.sessionId,
        };

        return this.authorizationStore.check(query).then(this.setIsWithDownloadPf);
    }

    @action.bound
    setUpdatePfListIntervalId(id?: number): void {
        this.updatePfListIntervalId = id;
    }

    @action.bound
    setPrintForms(printForms: PrintFormModel[]): void {
        this.printForms = printForms;
    }

    @action.bound
    setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }

    @action.bound
    setIsWithEditPf(isWithEditPf: boolean): void {
        this.isWithEditPf = isWithEditPf;
    }

    @action.bound
    setIsWithDownloadPf(isWithDownloadPf: boolean): void {
        this.isWithDownloadPf = isWithDownloadPf;
    }
}
