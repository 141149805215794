import { History } from 'history';
import downloadFile from 'js-file-download';
import { action, computed, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { FullSubmission, IdTitle, NewSubjectDTO, SubjectDTO, TableQueryData, TransitionsDTO } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export class SubjectStore {
    @observable private rootStore: RootStore;
    @observable private api: Api;
    @observable showFormInfoValidation: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    loadSubjectList(queryData: TableQueryData) {
        return this.api
            .client(apiConfigs.subjects(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    404: () => this.history.replace(clientRoute.notFound),
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    exportListXls(queryData: TableQueryData): void {
        const filename = this.rootStore.intlStore.formatMessage('subject.subjectListTitle');
        this.api
            .client(apiConfigs.subjectListXls(queryData))
            .then((r) => r.data)
            .then((f) => downloadFile(f, `${filename}.xlsx`, 'application/vnd.ms-excel'));
    }

    @action.bound
    downloadSubjectCommonFiles(subjectId: string, subjectTitle: string): void {
        const filename = this.rootStore.intlStore.formatMessage('subject.commonFiles', { title: subjectTitle });
        this.api
            .client(apiConfigs.downloadSubjectCommonFiles(subjectId))
            .then((r) => r.data)
            .then((f) => downloadFile(f, `${filename}.zip`, 'application/zip'));
    }

    @action.bound
    downloadSubjectHiddenFiles(subjectId: string, subjectTitle: string): void {
        const filename = this.rootStore.intlStore.formatMessage('subject.hiddenFiles', { title: subjectTitle });
        this.api
            .client(apiConfigs.downloadSubjectHiddenFiles(subjectId))
            .then((r) => r.data)
            .then((f) => downloadFile(f, `${filename}.zip`, 'application/zip'));
    }

    @action.bound
    loadSubject(id: string): Promise<SubjectDTO> {
        return this.api
            .client(apiConfigs.subject(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadSubjectsSelectList(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.subjectsSelectList).then((r) => r.data);
    }

    @action.bound
    saveForm(subjectId: string, formSubmission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveSubjectForm(subjectId, formSubmission)).then((r) => r.data);
    }

    @action.bound
    lifeCycleTransition(transitionId: string, subjectId: string, updateReloadKey: () => void): Promise<void> {
        return this.api.client(apiConfigs.subjectLifeCycleTransition(transitionId, subjectId)).then((r) => {
            updateReloadKey();
            return r.data;
        });
    }

    @action.bound
    getLifeCycleTransitions(subjectId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.getSubjectLifeCycleTransitions(subjectId)).then((r) => r.data);
    }

    @action.bound
    loadCategorySelectOptions(): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.categorySelectOptions)
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadCommissionSessionsOptions(): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.commissionSessionsSelectOptions)
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    createSubject(dto: NewSubjectDTO): Promise<string> {
        return this.api.client(apiConfigs.createSubject(dto)).then((r) => r.data.id);
    }

    @action.bound
    deleteSubject(id: string): Promise<void> {
        return this.api
            .client(apiConfigs.deleteSubject(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    updateSession(id: string, sessionId: string): Promise<void> {
        return this.api.client(apiConfigs.updateSessionInSubject(id, sessionId)).then((r) => r.data);
    }
}
