import React from 'react';

export const QuestionCircleIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="5 0 20 20" fill="currentColor">
        <defs>
            <mask id="1xr4b" width="2" height="2" x="-1" y="-1">
                <path fill="#fff" d="M5 0h20v20H5z" />
                <path d="M14.289 13.29a1 1 0 0 1 1.709.71.99.99 0 0 1-.06.38.901.901 0 0 1-.539.54.945.945 0 0 1-.76 0 .902.902 0 0 1-.54-.54.845.845 0 0 1-.08-.38 1.355 1.355 0 0 1 0-.2.668.668 0 0 1 .059-.18.772.772 0 0 1 .09-.18c.037-.053.078-.103.121-.15zM14.999 0c5.522 0 10 4.476 10 10 0 5.522-4.478 10-10 10-5.523 0-10-4.478-10-10C5 4.476 9.476 0 15 0zm0 18a8 8 0 1 0 0-16.001 8 8 0 0 0 0 16zm0-13a3 3 0 0 1 1 5.82V11a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1 1 1 0 0 0 0-2 1 1 0 0 0-.87.5 1 1 0 1 1-1.73-1 3 3 0 0 1 2.6-1.5z" />
            </mask>
            <filter id="1xr4a" width="112" height="130" x="-41" y="-46" filterUnits="userSpaceOnUse">
                <feOffset dy="18" in="SourceGraphic" result="FeOffset1110Out" />
                <feGaussianBlur in="FeOffset1110Out" result="FeGaussianBlur1111Out" stdDeviation="14.4 14.4" />
            </filter>
        </defs>
        <g>
            <g>
                <g filter="url(#1xr4a)">
                    <path
                        fill="none"
                        d="M14.289 13.29a1 1 0 0 1 1.709.71.99.99 0 0 1-.06.38.901.901 0 0 1-.539.54.945.945 0 0 1-.76 0 .902.902 0 0 1-.54-.54.845.845 0 0 1-.08-.38 1.355 1.355 0 0 1 0-.2.668.668 0 0 1 .059-.18.772.772 0 0 1 .09-.18c.037-.053.078-.103.121-.15zM14.999 0c5.522 0 10 4.476 10 10 0 5.522-4.478 10-10 10-5.523 0-10-4.478-10-10C5 4.476 9.476 0 15 0zm0 18a8 8 0 1 0 0-16.001 8 8 0 0 0 0 16zm0-13a3 3 0 0 1 1 5.82V11a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1 1 1 0 0 0 0-2 1 1 0 0 0-.87.5 1 1 0 1 1-1.73-1 3 3 0 0 1 2.6-1.5z"
                        mask='url("#1xr4b")'
                    />
                    <path
                        fill-opacity=".03"
                        d="M14.289 13.29a1 1 0 0 1 1.709.71.99.99 0 0 1-.06.38.901.901 0 0 1-.539.54.945.945 0 0 1-.76 0 .902.902 0 0 1-.54-.54.845.845 0 0 1-.08-.38 1.355 1.355 0 0 1 0-.2.668.668 0 0 1 .059-.18.772.772 0 0 1 .09-.18c.037-.053.078-.103.121-.15zM14.999 0c5.522 0 10 4.476 10 10 0 5.522-4.478 10-10 10-5.523 0-10-4.478-10-10C5 4.476 9.476 0 15 0zm0 18a8 8 0 1 0 0-16.001 8 8 0 0 0 0 16zm0-13a3 3 0 0 1 1 5.82V11a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1 1 1 0 0 0 0-2 1 1 0 0 0-.87.5 1 1 0 1 1-1.73-1 3 3 0 0 1 2.6-1.5z"
                    />
                </g>
                <path d="M14.289 13.29a1 1 0 0 1 1.709.71.99.99 0 0 1-.06.38.901.901 0 0 1-.539.54.945.945 0 0 1-.76 0 .902.902 0 0 1-.54-.54.845.845 0 0 1-.08-.38 1.355 1.355 0 0 1 0-.2.668.668 0 0 1 .059-.18.772.772 0 0 1 .09-.18c.037-.053.078-.103.121-.15zM14.999 0c5.522 0 10 4.476 10 10 0 5.522-4.478 10-10 10-5.523 0-10-4.478-10-10C5 4.476 9.476 0 15 0zm0 18a8 8 0 1 0 0-16.001 8 8 0 0 0 0 16zm0-13a3 3 0 0 1 1 5.82V11a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1 1 1 0 0 0 0-2 1 1 0 0 0-.87.5 1 1 0 1 1-1.73-1 3 3 0 0 1 2.6-1.5z" />
            </g>
        </g>
    </svg>
);
