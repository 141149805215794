import downloadFile from 'js-file-download';
import { action, IObservableArray, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { FileDTO, PfTemplateDTO } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export class PfTemplateStore {
    @observable rootStore: RootStore;
    @observable api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    create(data: FormData): Promise<void> {
        return this.api.client(apiConfigs.createPfTemplate(data)).then((r) => r.data);
    }

    @action.bound
    templateList(): Promise<PfTemplateDTO[]> {
        return this.api
            .client(apiConfigs.pfTemplates)
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.rootStore.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    downloadTemplateFile(fileDTO: FileDTO): void {
        this.api
            .client(apiConfigs.downloadPfTemplateFile(fileDTO.id))
            .then((r) => r.data)
            .then((f) => downloadFile(f, fileDTO.filename, fileDTO.mimeType));
    }

    @action.bound
    update(data: FormData, templateId?: string): Promise<void> {
        return this.api.client(apiConfigs.updatePfTemplate(data, templateId)).then((r) => r.data);
    }
}
