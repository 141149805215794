import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const AppContentContainer = withTheme(
    styled.main.attrs(() => ({
        className: 'app-content-container',
    }))`
        display: flex;
        flex-grow: 1;
    `,
);
