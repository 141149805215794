import { GeneralizationCategorySelectModel } from '../models';
import { IdTitle } from './withTitle';

export type DialogCategoryInfo = {
    attributeTitle?: string;
    categoryAttributes: CategoryInfo[];
};

export type AttributesDTO = {
    attributeId: string;
    attributeTitle: string;
    categoryAttributes: FormAttributeDTO[];
};

export type AttributesTableType = {
    attributeId: string;
    attributeTitle: string;
    categoryAttributes: Array<FormAttributeDTO | string>;
};

export type GeneralizationTableDTO = {
    categories: IdTitle[];
    generalizationAttributes: AttributesDTO[];
};

export type FormAttributeDTO = {
    ownerId: string;
    formVersionId: string;
    code: string;
    title: string;
};

export type CategoryInfo = {
    ownerId: string;
    title: string;
    defaultCategoryAttribute?: FormAttributeDTO;
    formVersionId: string;
    categoryAttributes: FormAttributeDTO[];
};

export type SelectModels = {
    ownerId: string;
    model: GeneralizationCategorySelectModel;
};

export type FormComponentCreateDTO = {
    formVersionId: string;
    code: string;
    ownerId: string;
};

export type GeneralizationCategoryInfoDTO = {
    commissionSessionId: string;
    attributeTitle: string;
    categoryAttributes: FormComponentCreateDTO[];
};

export type GeneralizationCategoryInfo = {
    title: string;
};

export enum GeneralizationCategoryField {
    title = 'title',
}
