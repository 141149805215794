import { makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AppTheme } from '../types';

const useStyles = makeStyles((theme: AppTheme) => ({
    active: {
        color: `${theme.variables.palette.green} !important`,
    },
    skip: {
        color: `${theme.variables.palette.red} !important`,
    },
}));

export type TotStepperProps = {
    activeStep: string | undefined;
    stepList: string[] | undefined;
};

export const TotStepper = (props: TotStepperProps): JSX.Element => {
    const { activeStep, stepList } = props;
    const classes = useStyles();
    const isSingleItem = Boolean(stepList && stepList.length === 1);
    let activeStepIndex = stepList?.findIndex((step) => step === activeStep);
    if (isSingleItem && activeStepIndex !== undefined) {
        // Состояение completed - индекс последнего элемента + 1
        activeStepIndex++;
    }

    return (
        <Stepper activeStep={activeStepIndex ?? 0} alternativeLabel>
            {stepList?.map((step) => (
                <Step key={step}>
                    <StepLabel
                        StepIconProps={{
                            classes: {
                                active: isSingleItem ? '' : classes.active,
                                completed: isSingleItem ? classes.skip : '',
                            },
                        }}
                    >
                        <FormattedMessage id={`commissionSession.commissionState.${step}`} />
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};
