import { Button, Grid } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { Transition, TransitionsDTO } from '../../types';

const buttonStyle: CSSProperties = {
    display: 'block',
    whiteSpace: 'nowrap',
};

export const renderTransitions = (
    data: TransitionsDTO,
    onClickModalOpen: (transition: Transition) => () => void,
): Array<JSX.Element> => {
    return data.map((transition: Transition): JSX.Element => {
        return (
            <Grid item key={transition.id}>
                <Button
                    onClick={onClickModalOpen(transition)}
                    color={transition.params.btnType}
                    size="medium"
                    variant="contained"
                    type="button"
                    style={buttonStyle}
                >
                    {transition.title}
                </Button>
            </Grid>
        );
    });
};
