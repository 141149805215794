import { AxiosResponse } from 'axios';
import { action, computed, observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { PersonModel } from '../models';
import UserModel from '../models/person/UserModel';
import { PersonInfo } from '../types';
import { RootStore } from './RootStore';

export class PersonStore {
    @observable private rootStore: RootStore;

    @observable user: UserModel = new UserModel();
    @observable person: PersonModel = new PersonModel();
    @observable roles: string[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        this.getInfo().then((r) => r);
    }

    @asyncAction
    @action.bound
    async getInfo(): Promise<void> {
        const { api, intlStore } = this.rootStore;
        const response: AxiosResponse<PersonInfo> = await api.client(apiConfigs.person(intlStore.locale));
        const info: PersonInfo = response.data;
        this.user = info.user;
        this.person.load(info.person);
        this.roles = info.roles;
    }

    @action.bound
    clearPerson() {
        this.user = new UserModel();
        this.person = new PersonModel();
        this.roles = [];
    }

    @computed
    get redirectPath(): string {
        return clientRoute.commissionSessions;
    }
}
