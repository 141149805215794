import { TTableRow } from '@platform/ttable';
import { FormDTO } from './formio';
import { AttributesDTO, AttributesTableType } from './generalization';
import { MetaInfoDTO } from './metaInfo';
import { RouteParamsDefault } from './route';
import { CodeTitle, IdTitle } from './withTitle';
import { UserPersonDTO } from './userPerson';

export interface SessionProcedure {
    id: string;
    title: string;
    format: CodeTitle;
    method: CodeTitle;
    position: number;
    state: string;
}

export type SessionProcedureCreateInfo = {
    id: string;
    form: FormDTO;
};

export type SessionProcedureDTO = {
    id: string;
    title: string;
    description: string;
    format: CodeTitle;
    method: CodeTitle;
    members: CodeTitle;
    formInfo: FormDTO;
    metaInfo: MetaInfoDTO;
};

export type SessionProcedureAttribute = {
    id: string;
    attribute: AttributesTableType;
};

export type SessionProcedureAttributeDTO = {
    id: string;
    attribute: AttributesDTO;
};

export type SessionProcedureTableInfoDTO = {
    categories: IdTitle[];
    attributes: SessionProcedureAttributeDTO[];
};

export enum DecisionType {
    private = 'private',
    common = 'common',
}

export type SessionDecision = {
    decisionId: string;
    form: FormDTO;
};

export type SessionProcedureParams = RouteParamsDefault & {
    procedureId: string;
};

export type SessionProcedureReloadTable = () => void;

export type SessionProcedureRow = TTableRow & {
    customData: {
        commonDecision?: SessionDecision;
        privateDecision?: SessionDecision;
        resultOfVoting?: {
            commonDecisionId: string;
            finalDecision: string;
        };
        comment?: string;
    };
};

export type SessionProcedureAttributesDTO = {
    procedureId: string;
    attributeIds: string[];
};

export type SessionProcedureAttributes = {
    attributes: IdTitle[];
};

export type ResultOfVotingDTO = {
    resultOfVoting: string;
};

export enum ResultOfVotingField {
    resultOfVoting = 'resultOfVoting',
}

export type SubjectCommentDTO = {
    comment: string;
};

export enum SubjectCommentField {
    comment = 'comment',
}

export enum SessionAttributesField {
    attributes = 'attributes',
}

export enum SessionProcedureStep {
    skipped = 'Skipped',
    draft = 'Draft',
    ready = 'Ready',
    voting = 'Voting',
    commonDecisionMaking = 'CommonDecisionMaking',
    finishes = 'Finishes',
}

export type ProcedureSubjectDecisionDTO = {
    member: UserPersonDTO;
    mark?: string;
    voted?: string;
};

export type ProcedureSubjectDecision = {
    memberFIO: string;
    mark?: string;
    voted?: Date;
};

export type ProcedureSubjectCommentDTO = {
    member: UserPersonDTO;
    comment?: string;
};

export type ProcedureSubjectComment = {
    memberFIO: string;
    comment?: string;
};
