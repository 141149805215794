import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { entities, permissions } from '../../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../../components';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { InsertComment, Notes } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useFlag, useStore } from '../../../../../../hooks';
import { SubjectCommentDialog } from './SubjectCommentDialog';
import { SessionProcedureSubjectCommentCellModel } from '../../../../../../models';
import { SubjectCommentListDialog } from './SubjectCommentListDialog';

export type SessionProcedureSubjectCommentCellProps = {
    procedureId: string;
    subjectId: string;
    decisionId?: string;
    commentRaw: string;
};

export const SessionProcedureSubjectCommentCell = observer(
    (props: SessionProcedureSubjectCommentCellProps): JSX.Element => {
        const { procedureId, subjectId, decisionId, commentRaw } = props;
        const intl = useIntl();
        const [isOpenCommentDialog, openCommentDialog, closeCommentDialog] = useFlag(false);
        const [isOpenCommentListDialog, openCommentListDialog, closeCommentListDialog] = useFlag(false);
        const { commissionSessionStore } = useStore();

        const cellModel: SessionProcedureSubjectCommentCellModel = useMemo(
            () =>
                new SessionProcedureSubjectCommentCellModel(
                    commissionSessionStore,
                    procedureId,
                    subjectId,
                    decisionId,
                    commentRaw,
                ),
            [commissionSessionStore, procedureId, subjectId, decisionId, commentRaw],
        );

        const { commentDialogModel, commentListModel } = cellModel;
        const commentModel = commentDialogModel?.commentModel;
        const commentNonEmpty = commentModel?.commentNonEmpty;
        const comment = commentModel?.comment;

        const commentTooltipId = commentNonEmpty
            ? 'commissionSession.sessionProcedureSubjectList.editComment'
            : 'commissionSession.sessionProcedureSubjectList.createComment';

        return (
            <AuthorizationCheck
                entityCode={entities.SessionProcedure}
                permCode={permissions.SessionProcedure.ViewComment}
                entityId={procedureId}
            >
                <Grid container spacing={2} alignItems="center" alignContent="center">
                    {commentNonEmpty && <Grid item>{comment}</Grid>}
                    {commentDialogModel && (
                        <AuthorizationCheck
                            entityCode={entities.SubjectDecision}
                            permCode={permissions.SubjectDecision.EditComment}
                            entityId={decisionId}
                        >
                            <Grid item>
                                <Tooltip placement="top" title={intl.formatMessage({ id: commentTooltipId })}>
                                    <IconButton onClick={openCommentDialog}>
                                        <InsertComment />
                                    </IconButton>
                                </Tooltip>
                                <SubjectCommentDialog
                                    isOpen={isOpenCommentDialog}
                                    close={closeCommentDialog}
                                    model={commentDialogModel}
                                />
                            </Grid>
                        </AuthorizationCheck>
                    )}
                    <AuthorizationCheck
                        entityCode={entities.SessionProcedure}
                        permCode={permissions.SessionProcedure.ViewAllSubjectComments}
                        entityId={procedureId}
                    >
                        <Grid item>
                            <Tooltip
                                placement="top"
                                title={intl.formatMessage({
                                    id: 'commissionSession.sessionProcedureSubjectList.allComments',
                                })}
                            >
                                <IconButton onClick={openCommentListDialog}>
                                    <Notes />
                                </IconButton>
                            </Tooltip>
                            <SubjectCommentListDialog
                                isOpen={isOpenCommentListDialog}
                                close={closeCommentListDialog}
                                model={commentListModel}
                            />
                        </Grid>
                    </AuthorizationCheck>
                </Grid>
            </AuthorizationCheck>
        );
    },
);
