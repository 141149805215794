import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PasswordModel from '../../models/authentication/fields/PasswordModel';

export type PasswordFieldProps = {
    model: PasswordModel;
    label?: React.ReactNode;
};

export const PasswordField = observer((props: PasswordFieldProps): JSX.Element => {
    const { model, label } = props;
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

    const toggleVisibilityPassword = (): void => {
        setIsShowPassword((isShow) => !isShow);
    };

    const fieldType = isShowPassword ? 'text' : 'password';

    return (
        <TextField
            inputProps={{
                className: 't-password-field',
            }}
            FormHelperTextProps={{
                className: 't-password-field-help-text',
            }}
            required
            onChange={model.onPasswordChange}
            fullWidth
            label={label || <FormattedMessage id="authentication.password" />}
            variant="outlined"
            type={fieldType}
            error={!!model.errorPassword}
            helperText={model.errorPassword}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton className="t-visibility-password" onClick={toggleVisibilityPassword}>
                            {isShowPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
});
