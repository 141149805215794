import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SessionProcedureSubjectCommentListModel } from '../../../../../../models';
import { SubjectCommentsTable } from './SubjectCommentsTable';
import { NoDataText } from '../../../../generalization-table';

export type SubjectCommentListDialogProps = {
    isOpen: boolean;
    close: () => void;
    model: SessionProcedureSubjectCommentListModel;
};

export const SubjectCommentListDialog = observer((props: SubjectCommentListDialogProps): JSX.Element => {
    const { isOpen, close, model } = props;
    const { loadComments, comments, isLoading, noComments } = model;

    useEffect(() => {
        if (isOpen) {
            loadComments();
        }
    }, [isOpen]);

    const renderDialogContent = (): JSX.Element => {
        if (isLoading) {
            return (
                <Grid container justify="center" alignItems="center">
                    <CircularProgress />
                </Grid>
            );
        }

        if (noComments) {
            return (
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <NoDataText>
                            <FormattedMessage id="common.noData" />
                        </NoDataText>
                    </Grid>
                </Grid>
            );
        }

        return <SubjectCommentsTable comments={comments} />;
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage id="commissionSession.sessionProcedureSubjectList.commentDialogListTitle" />
            </DialogTitle>
            <DialogContent>{renderDialogContent()}</DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={close}>
                    <FormattedMessage id="common.ok" />
                </Button>
            </DialogActions>
        </Dialog>
    );
});
