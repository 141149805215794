import { Grid, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const StyledGrid = withTheme(
    styled(Grid)`
        height: 50px;
        overflow: hidden;

        ${({ theme }): string => theme.breakpoints.down('sm')} {
            height: auto;
        }
    `,
);

export const GridWrapper = styled(Grid)`
    height: auto;
    width: 100%;
    white-space: nowrap;
    position: relative;
`;
