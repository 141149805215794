import { CodeTitleOrIdTitle } from '../types';

export const getSelectedOption = <T extends CodeTitleOrIdTitle>(option: T, value: T): boolean => {
    if (option.id) {
        return option.id === value.id;
    }

    return option.code === value.code;
};

export const getOptionLabel = <T extends CodeTitleOrIdTitle>(option: T): string => {
    return option.title;
};
