import {
    Box,
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    Grid,
    IconButton,
    LinearProgress,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { CSSProperties, MouseEvent, ReactNode } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck, DeleteActionMenuItem, MenuButton, TotObjectPanelSummary } from '../../../components';
import { sxWidth30 } from '../../../constants';
import { useStore } from '../../../hooks';
import { SessionProcedureModel } from '../../../models';
import { DotMenuIcon, MoveIcon } from '../../../resources';
import { RouteParamsDefault, SessionProcedure } from '../../../types';

export type SessionProceduresListProps = {
    model: SessionProcedureModel;
};

const actionTableCellSx: CSSProperties = { width: '20%', minWidth: '96px' };

export const SessionProceduresList = observer((props: SessionProceduresListProps): JSX.Element => {
    const { model } = props;
    const { id } = useParams<RouteParamsDefault>();
    const { commissionSessionStore } = useStore();
    const intl = useIntl();
    const history = useHistory();
    const {
        activeProcedure,
        rows,
        canChangeProcedurePosition,
        deleteSessionProcedure,
        setActiveProcedure,
        changeProcedurePosition,
        reorderProcedureList,
    } = model;

    const onAddProcedure = (event: MouseEvent): void => {
        event.stopPropagation();

        commissionSessionStore.createSessionProcedure(id).then((data) => {
            history.push(generatePath(clientRoute.sessionProcedureCreate, { id, procedureId: data.id }));
        });
    };

    const onChangeActiveProcedure = (procedureId: string): void => {
        if (activeProcedure && activeProcedure.id === procedureId) {
            return;
        }
        setActiveProcedure(procedureId);
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        changeProcedurePosition(result.draggableId, result.destination.index + 1);
        reorderProcedureList(result.source.index, result.destination.index);
    };

    const nonDraggable = (provided: DraggableProvided): JSX.Element => {
        return <div {...provided.dragHandleProps} style={{ display: 'none' }} />;
    };

    const renderActionItems = (row: SessionProcedure): (() => ReactNode[]) => {
        return (): ReactNode[] => [
            <AuthorizationCheck
                key="delete"
                entityCode={entities.SessionProcedure}
                entityId={row.id}
                permCode={permissions.SessionProcedure.Delete}
            >
                <DeleteActionMenuItem
                    id="delete-procedure"
                    key="delete-procedure"
                    message={intl.formatMessage(
                        { id: 'commissionSession.confirmProcedureDeletionInfoText' },
                        { title: row.title },
                    )}
                    onConfirm={deleteSessionProcedure(row)}
                />
            </AuthorizationCheck>,
        ];
    };

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton onClick={onClick}>
                <SvgIcon>
                    <DotMenuIcon />
                </SvgIcon>
            </IconButton>
        );
    };

    const renderActions = (row: SessionProcedure): JSX.Element => {
        return (
            <MenuButton
                disablePortal={true}
                renderButton={renderActionsButton}
                renderMenuItems={renderActionItems(row)}
            />
        );
    };

    return (
        <ExpansionPanel defaultExpanded elevation={0}>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3">
                            4. <FormattedMessage id="commissionSession.blocksTitle.procedures" />
                        </Typography>
                    </Grid>
                    <AuthorizationCheck
                        key="create-procedure"
                        entityCode={entities.CommissionSession}
                        permCode={permissions.System.AddSessionProcedure}
                        entityId={id}
                    >
                        <Box pr={3}>
                            <Grid item>
                                <Button
                                    className="t-procedure-create"
                                    variant="text"
                                    color="primary"
                                    startIcon={
                                        <SvgIcon>
                                            <AddCircleOutlineIcon />
                                        </SvgIcon>
                                    }
                                    onClick={onAddProcedure}
                                >
                                    <FormattedMessage id="commissionSession.createProcedure" />
                                </Button>
                            </Grid>
                        </Box>
                    </AuthorizationCheck>
                </Grid>
            </TotObjectPanelSummary>

            <ExpansionPanelSummary style={{ padding: 0 }}>
                <TableContainer>
                    {model.isListLoading && <LinearProgress />}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Table className="t-procedures-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={sxWidth30} className="t-procedureTitle-col">
                                        <Typography>
                                            <FormattedMessage id="commissionSession.procedureFields.title" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={sxWidth30} className="t-format-col">
                                        <Typography>
                                            <FormattedMessage id="commissionSession.procedureFields.format" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={sxWidth30} className="t-method-col">
                                        <Typography>
                                            <FormattedMessage id="commissionSession.procedureFields.method" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={actionTableCellSx} align="right" />
                                </TableRow>
                            </TableHead>
                            <Droppable droppableId="table">
                                {(droppableProvided) => (
                                    <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                        {rows.map((procedure, index) => (
                                            <Draggable
                                                draggableId={procedure.id}
                                                index={index}
                                                key={procedure.id}
                                                isDragDisabled={!canChangeProcedurePosition}
                                            >
                                                {(provided) => (
                                                    <TableRow
                                                        hover
                                                        key={procedure.id}
                                                        onClick={() => onChangeActiveProcedure(procedure.id)}
                                                        selected={
                                                            !!activeProcedure && activeProcedure.id === procedure.id
                                                        }
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <TableCell style={sxWidth30} className="t-procedureTitle-val">
                                                            {procedure.title}
                                                        </TableCell>
                                                        <TableCell style={sxWidth30} className="t-format-val">
                                                            {procedure.format.title}
                                                        </TableCell>
                                                        <TableCell style={sxWidth30} className="t-method-col">
                                                            {procedure.method.title}
                                                        </TableCell>
                                                        <TableCell style={actionTableCellSx} align="right">
                                                            <Grid
                                                                container
                                                                justify="flex-end"
                                                                wrap="nowrap"
                                                                spacing={3}
                                                            >
                                                                <Grid item>
                                                                    <Tooltip
                                                                        title={<FormattedMessage id="common.moveRow" />}
                                                                    >
                                                                        <AuthorizationCheck
                                                                            key="edit-procedure"
                                                                            entityCode={entities.SessionProcedure}
                                                                            entityId={procedure.id}
                                                                            permCode={permissions.SessionProcedure.Edit}
                                                                            pendingElement={nonDraggable(provided)}
                                                                            errorElement={nonDraggable(provided)}
                                                                        >
                                                                            <IconButton {...provided.dragHandleProps}>
                                                                                <SvgIcon viewBox="0 0 20 20">
                                                                                    <MoveIcon />
                                                                                </SvgIcon>
                                                                            </IconButton>
                                                                        </AuthorizationCheck>
                                                                    </Tooltip>
                                                                </Grid>

                                                                <Grid item onClick={(e) => e.stopPropagation()}>
                                                                    <Tooltip
                                                                        title={<FormattedMessage id="common.actions" />}
                                                                    >
                                                                        {renderActions(procedure)}
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        ))}
                                        {droppableProvided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </Table>
                    </DragDropContext>
                </TableContainer>
            </ExpansionPanelSummary>
        </ExpansionPanel>
    );
});
