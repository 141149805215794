import { Grid, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck, TotObjectHeaderLabel, TotObjectHeaderValue } from '../../../../components';
import { SubjectModel } from '../../../../models';
import { EditIcon } from '../../../../resources';
import { getOrElse } from '../../../../utils';

export type SubjectDescriptionProps = {
    subjectModel: SubjectModel;
    setModalIsOpen: () => void;
};

export const SubjectDescription = observer((props: SubjectDescriptionProps): JSX.Element => {
    const { subjectModel, setModalIsOpen } = props;

    return (
        <Grid container direction="row" justify="space-between">
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item container spacing={7} direction="row" alignItems="center">
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="subject.fields.identifier" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectHeaderValue>
                                        {subjectModel.identifier || <FormattedMessage id="common.withoutNumber" />}
                                    </TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" wrap="nowrap" alignItems="center" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="subject.fields.session" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item container direction="row" wrap="nowrap" spacing={2} alignItems="center">
                                    <Grid item>
                                        <TotObjectHeaderValue>
                                            {getOrElse(subjectModel.session, subjectModel.session?.title)}
                                        </TotObjectHeaderValue>
                                    </Grid>
                                    <AuthorizationCheck
                                        entityCode={entities.Subject}
                                        permCode={permissions.Subject.Edit}
                                        entityId={subjectModel.id}
                                    >
                                        <Grid item>
                                            <IconButton onClick={setModalIsOpen} className="t-edit-session">
                                                <EditIcon />
                                            </IconButton>
                                        </Grid>
                                    </AuthorizationCheck>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={7} direction="row">
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="subject.fields.created" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectHeaderValue>
                                        {getOrElse(
                                            subjectModel.metaInfo.created,
                                            <FormattedDate value={subjectModel.metaInfo.created} />,
                                        )}
                                    </TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="subject.fields.state" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectHeaderValue>{subjectModel.metaInfo.stateTitle}</TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
