import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, useHistory } from 'react-router-dom';
import { entities, permissions, permissionsConfig } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import {
    ActionsMenu,
    AuthorizationCheck,
    AuthorizationCheckAll,
    DeleteActionMenuItem,
    EditActionMenuItem,
    ExportButton,
    TableHeadTypography,
    TotTablePagination,
} from '../../components';
import { useFlag, useStore } from '../../hooks';
import { CommissionListModel } from '../../models';
import { ClearFilterIcon } from '../../resources';
import { CommissionRowModel } from '../../types';
import { CommissionListFilterPanel } from './CommissionListFilterPanel';

const startIcon = (
    <SvgIcon>
        <ClearFilterIcon />
    </SvgIcon>
);

export const CommissionListPage = observer((): JSX.Element => {
    const [isLoading, enableLoading, disableLoading] = useFlag();
    const rootStore = useStore();
    const intl = useIntl();
    const history = useHistory();

    const { notificationStore, commissionStore } = rootStore;
    const { onError } = notificationStore;
    const { exportListXls } = commissionStore;

    const model = useMemo<CommissionListModel>(() => {
        return new CommissionListModel(rootStore);
    }, []);

    const { sorting, reloadData, clearFilters, dispose, changeSorting, queryData } = model;
    const { title } = sorting;
    const { isActive, direction } = title;

    const tableSortLabelOnClick = changeSorting(title);

    const createCommission = async (): Promise<void> => {
        try {
            enableLoading();
            const id = await commissionStore.createCommission();
            history.push(generatePath(clientRoute.commissionCreate, { id }));
        } catch (error) {
            disableLoading();
        }
    };

    const deleteCommission = (row: CommissionRowModel): (() => Promise<void>) => {
        return async (): Promise<void> => {
            return commissionStore
                .deleteCommission(row.id)
                .then(reloadData)
                .catch((error) => onError(error.response.data));
        };
    };

    const renderActionItems = (row: CommissionRowModel): (() => ReactNode[]) => {
        return (): ReactNode[] => [
            <AuthorizationCheck
                key="delete"
                entityCode={entities.Commission}
                entityId={row.id}
                permCode={permissions.Commission.Delete}
            >
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    message={intl.formatMessage({ id: 'commission.confirmDeletionInfoText' }, { title: row.title })}
                    onConfirm={deleteCommission(row)}
                />
            </AuthorizationCheck>,
            <AuthorizationCheck
                key="edit"
                entityCode={entities.Commission}
                entityId={row.id}
                permCode={permissions.Commission.Edit}
            >
                <EditActionMenuItem key="edit" path={generatePath(clientRoute.commissionEdit, { id: row.id })} />
            </AuthorizationCheck>,
        ];
    };

    useEffect(() => {
        return dispose;
    }, [model]);

    return (
        <Container maxWidth="lg">
            <Box pt={5} pb={5}>
                <Grid container direction="column" spacing={10}>
                    <Grid item container direction="row" justify="space-between">
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="commission.listTitle" />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} container spacing={2} justify="flex-end">
                            <Grid item>
                                <Button variant="text" color="primary" startIcon={startIcon} onClick={clearFilters}>
                                    <FormattedMessage id="common.resetFilters" />
                                </Button>
                            </Grid>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.Commission.XlsCommissionList}
                            >
                                <Grid item>
                                    <ExportButton queryData={queryData} loadRegisterList={exportListXls} />
                                </Grid>
                            </AuthorizationCheck>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.AddCommission}
                            >
                                <Grid item>
                                    <Button
                                        disabled={isLoading}
                                        color="primary"
                                        variant="contained"
                                        className="t-create-commission"
                                        onClick={createCommission}
                                    >
                                        <FormattedMessage id="commission.create" />
                                    </Button>
                                </Grid>
                            </AuthorizationCheck>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CommissionListFilterPanel model={model} />
                    </Grid>
                    <Grid item>
                        <TableContainer component={Paper}>
                            {model.isLoading && <LinearProgress />}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={isActive}
                                                direction={direction}
                                                onClick={tableSortLabelOnClick}
                                                IconComponent={ExpandMoreIcon}
                                            >
                                                <TableHeadTypography>
                                                    <FormattedMessage id="commission.fields.title" />
                                                </TableHeadTypography>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableHeadTypography>
                                                <FormattedMessage id="commission.fields.manager" />
                                            </TableHeadTypography>
                                        </TableCell>
                                        <TableCell>
                                            <TableHeadTypography>
                                                <FormattedMessage id="commission.fields.members" />
                                            </TableHeadTypography>
                                        </TableCell>
                                        <TableCell>
                                            <TableHeadTypography>
                                                <FormattedMessage id="commission.fields.state" />
                                            </TableHeadTypography>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {model.rows.map((row) => {
                                        const { id, title, manager, members, state } = row;
                                        return (
                                            <TableRow key={id} hover>
                                                <TableCell>
                                                    <Link
                                                        component={NavLink}
                                                        underline="always"
                                                        to={generatePath(clientRoute.commission, { id })}
                                                    >
                                                        {title}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {manager && (
                                                        <Link
                                                            component={NavLink}
                                                            underline="always"
                                                            to={generatePath(clientRoute.user, {
                                                                id: manager.userId,
                                                            })}
                                                        >
                                                            {manager.name}
                                                        </Link>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {members.map((user): JSX.Element => {
                                                        const { userId, name } = user;
                                                        return (
                                                            <Link
                                                                key={userId}
                                                                component={NavLink}
                                                                underline="always"
                                                                to={generatePath(clientRoute.user, {
                                                                    id: userId,
                                                                })}
                                                            >
                                                                {name}
                                                            </Link>
                                                        );
                                                    })}
                                                </TableCell>
                                                <TableCell>{state}</TableCell>
                                                <TableCell align="right">
                                                    <AuthorizationCheckAll
                                                        or={true}
                                                        queries={[
                                                            permissionsConfig.deleteCommission(id),
                                                            permissionsConfig.editCommission(id),
                                                        ]}
                                                    >
                                                        <ActionsMenu renderActionItems={renderActionItems} row={row} />
                                                    </AuthorizationCheckAll>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={100}>
                                            <TotTablePagination
                                                count={model.rowsCount}
                                                page={model.pageNumber}
                                                onChangePage={model.onChangePage}
                                                onChangeRowsPerPage={model.onChangePageSize}
                                                rowsPerPage={model.pageSize}
                                                rowsPerPageOptions={model.pageSizeOptions}
                                                pagingInfoMessageId="commission.pagingInfo"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
