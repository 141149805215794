import { Box, useTheme } from '@material-ui/core';
import React, { ReactNode } from 'react';

export type RequiredLabelProps = {
    text: ReactNode;
};

export const RequiredLabel = (props: RequiredLabelProps): JSX.Element => {
    const { text } = props;
    const theme = useTheme();
    return (
        <React.Fragment>
            {text}
            <Box display="inline" component="span" color={theme.variables.palette.red}>
                {' *'}
            </Box>
        </React.Fragment>
    );
};
