import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck, TotObjectForm, TotObjectMain } from '../../../components';
import { useFlag, useFormApi, useReload, useStore } from '../../../hooks';
import { SubjectModel } from '../../../models';
import { RouteParamsDefault } from '../../../types';
import { EditSessionDialog } from './EditSessionDialog';
import { SubjectHeader } from './subject-header';
import { SubjectServiceInfo } from './SubjectServiceInfo';

export const SubjectPage = observer((): JSX.Element => {
    const rootStore = useStore();
    const { subjectStore } = rootStore;
    const { lifeCycleTransition } = subjectStore;
    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();
    const intl = useIntl();

    const [formApi, setFormApi] = useFormApi();
    const [isModalOpen, openModal, closeModal] = useFlag();
    const [reloadKey, reloadIncrement] = useReload();
    const model = useMemo(() => new SubjectModel(rootStore, id), [id]);
    const { formInfo, load } = model;

    useEffect(() => {
        load();
    }, [intl.locale]);

    const onSubmit = (): void => {
        const isFormModelValid = (formApi && formApi.validate()) || false;
        if (formApi && isFormModelValid) {
            subjectStore.saveForm(id, formApi.getSubmission()).then(onSaveSuccess);
        }
    };

    const onLifeCycleTransition = (transitionId: string, subjectId: string, validate?: boolean): Promise<void> => {
        const onSuccess = () => lifeCycleTransition(transitionId, subjectId, reloadIncrement);
        if (validate) {
            return validateForms(onSuccess);
        } else {
            return onSuccess();
        }
    };

    const validateForms = (onSuccess?: () => Promise<void>): Promise<void> => {
        const isFormModelValid = (formApi && formApi.validate()) || false;
        if (isFormModelValid) {
            setFormInfoValidationState(!isFormModelValid);
            return onSuccess ? onSuccess() : Promise.resolve();
        } else if (formApi?.readOnly) {
            setFormInfoValidationState(!isFormModelValid);
        }
        return Promise.reject(['validation error']);
    };

    const onSaveSuccess = (): void => {
        history.push(generatePath(clientRoute.subject, { id }));
        load();
    };

    const setFormInfoValidationState = (state: boolean): void => {
        subjectStore.showFormInfoValidation = state;
    };

    return (
        <React.Fragment>
            <EditSessionDialog setIsClosed={closeModal} onSuccessSubmit={load} isOpen={isModalOpen} />
            <Grid container direction="column">
                <Grid item>
                    <SubjectHeader
                        onLifeCycleTransition={onLifeCycleTransition}
                        onSubmit={onSubmit}
                        subjectModel={model}
                        setModalIsOpen={openModal}
                    />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                <Grid item>
                                    <SubjectServiceInfo subjectModel={model} />
                                </Grid>
                            </AuthorizationCheck>
                            <Grid item>
                                <TotObjectForm
                                    reloadKey={reloadKey}
                                    formName="formInfo"
                                    model={formInfo}
                                    editPath={clientRoute.subjectEdit}
                                    createPath={clientRoute.subjectCreate}
                                    readPath={clientRoute.subject}
                                    onFormReady={setFormApi}
                                    showReadonlyValidation={subjectStore.showFormInfoValidation}
                                    hideReadonlyValidation={() => setFormInfoValidationState(false)}
                                    editAuthProps={{
                                        entityCode: entities.Subject,
                                        permCode: permissions.Subject.Edit,
                                        entityId: id,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid>{/* TODO: Sidebar */}</Grid>
                    </Grid>
                </TotObjectMain>
            </Grid>
        </React.Fragment>
    );
});
