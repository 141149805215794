import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { CategoriesOfSubjectsListPage } from './CategoriesOfSubjectsListPage';
import { CategoryOfSubjectsPage } from './CategoryOfSubjectsPage';

export const CategoriesOfSubjectsPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route path={clientRoute.category}>
                    <CategoryOfSubjectsPage />
                </Route>
                <Route>
                    <CategoriesOfSubjectsListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
