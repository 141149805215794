import { Box, Button, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { EmailField, TotBackground } from '../../components';
import { useStore } from '../../hooks';
import { RecoveryPasswordModel } from '../../models';

export const RecoveryPasswordPage = observer((): JSX.Element => {
    const { authenticationStore } = useStore();
    const recoveryPasswordModel = useMemo<RecoveryPasswordModel>(
        () => authenticationStore.recoveryPasswordModel,
        [authenticationStore],
    );

    return (
        <TotBackground>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Container className="t-recovery-password-page" maxWidth="xs">
                    <Paper elevation={0}>
                        <Box p={12}>
                            <Grid container justify="center">
                                <Grid item>
                                    <Typography variant="h5" className="t-recovery-title">
                                        <Box fontWeight="fontWeightBold">
                                            <FormattedMessage id="authentication.recoveryTitle" />
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box pt={8}>
                                <form noValidate onSubmit={recoveryPasswordModel.sendToken}>
                                    <Grid container spacing={6} direction="column">
                                        <Grid item>
                                            <EmailField model={recoveryPasswordModel} />
                                        </Grid>
                                        <Grid item>
                                            <Typography className="t-recovery-info">
                                                <FormattedMessage id="authentication.recoveryInfo" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className="t-recovery"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                            >
                                                <FormattedMessage id="authentication.sendToken" />
                                            </Button>
                                        </Grid>
                                        <Grid item container justify="center">
                                            <Link
                                                className="t-login"
                                                component={RouterLink}
                                                to={clientRoute.login}
                                                underline="none"
                                            >
                                                <FormattedMessage id="authentication.login" />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Box>
                    </Paper>
                    {recoveryPasswordModel.sentSucceed && <Redirect to={clientRoute.recoveryPasswordInfoSuccess} />}
                </Container>
            </Grid>
        </TotBackground>
    );
});
