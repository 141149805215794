import { IconButton, SvgIcon } from '@material-ui/core';
import React from 'react';
import { DotMenuIcon } from '../../resources';

export const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
    return (
        <IconButton onClick={onClick}>
            <SvgIcon>
                <DotMenuIcon />
            </SvgIcon>
        </IconButton>
    );
};
