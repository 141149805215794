import React from 'react';

export const AccountCardDetailsIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18">
        <path
            fill="#63666A"
            fill-rule="evenodd"
            d="M22 18H2c-1.089-.036-1.964-.911-2-2V1.999C.036.91.911.036 2 0h20c1.089.036 1.964.911 2 2v14c-.036 1.089-.911 1.964-2 2zm0-16H2v14h20V2zM9 10.25c1.66 0 5 .84 5 2.5V14H4v-1.25c0-1.66 3.34-2.5 5-2.5zM14 8h4v1h-4V8zm0-2h6v1h-6V6zm0-2h6v1h-6V4zM9 9C7.619 9 6.5 7.881 6.5 6.5S7.619 4 9 4s2.5 1.119 2.5 2.5S10.381 9 9 9z"
        />
    </svg>
);
