import { CSSProperties } from 'react';

export const sxWidth10: CSSProperties = { width: '10%' };
export const sxWidth15: CSSProperties = { width: '15%' };
export const sxWidth20: CSSProperties = { width: '20%' };
export const sxWidth25: CSSProperties = { width: '25%' };
export const sxWidth30: CSSProperties = { width: '30%' };
export const sxWidth35: CSSProperties = { width: '35%' };
export const sxWidth40: CSSProperties = { width: '40%' };
export const sxWidth45: CSSProperties = { width: '45%' };
