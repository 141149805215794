import { Box, Grid, Paper, Tab, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, Route, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { OuterTabsProps, RoutedTabs, TotStepper } from '../../../components';
import { useReload, useStore } from '../../../hooks';
import { RouteParamsDefault, SessionProcedure, SessionProcedureStep } from '../../../types';
import { SessionProcedureSubjectList } from './session-procedure-subject-list';

export type SessionProceduresProcessProps = {
    updateSessionInfo: () => Promise<void>;
    activeProcedureTitle: string;
};

const routedTabsProps: OuterTabsProps = {
    orientation: 'horizontal',
    indicatorColor: 'primary',
    variant: 'scrollable',
    scrollButtons: 'auto',
};

export const SessionProceduresProcess = observer((props: SessionProceduresProcessProps): JSX.Element => {
    const intl = useIntl();
    const { updateSessionInfo, activeProcedureTitle } = props;
    const { id } = useParams<RouteParamsDefault>();
    const [reloadKey, reloadIncrement] = useReload();

    const history = useHistory();
    const [activeStep, setActiveStep] = useState<string | undefined>();
    const { commissionSessionStore } = useStore();

    const [procedures, setProcedures] = useState<SessionProcedure[]>([]);

    const updateProcedures = (): Promise<void> => {
        return commissionSessionStore.loadProcedureList(id).then((sessionProcedure: SessionProcedure[]) => {
            setProcedures(sessionProcedure);
        });
    };

    const updateSubjectList = (): void => {
        updateSessionInfo();
        updateProcedures();
    };

    useEffect(() => {
        updateProcedures();
    }, [commissionSessionStore.loadProcedureList, id, intl, reloadKey]);

    useEffect(() => {
        if (procedures.length && reloadKey < 2) {
            const activeProcedureTemp = procedures.find((item) => item.title === activeProcedureTitle) ?? procedures[0];
            const activeProcedurePath = generatePath(clientRoute.commissionSessionProcessProcedure, {
                id: id,
                procedureId: activeProcedureTemp.id,
            });
            history.push(activeProcedurePath);
        }
    }, [procedures, activeProcedureTitle]);

    const [stepList, setStepList] = useState<string[]>();

    useEffect(() => {
        if (activeStep === undefined) {
            return;
        }

        activeStep === SessionProcedureStep.skipped
            ? setStepList([SessionProcedureStep.skipped])
            : setStepList([
                  SessionProcedureStep.draft,
                  SessionProcedureStep.ready,
                  SessionProcedureStep.voting,
                  SessionProcedureStep.commonDecisionMaking,
                  SessionProcedureStep.finishes,
              ]);
    }, [activeStep, setStepList]);

    useEffect(() => {
        reloadIncrement();
    }, [activeStep]);

    const routedTabsSettings = procedures.map((procedure, index) => ({
        tab: index,
        path: [
            generatePath(clientRoute.commissionSessionProcessProcedure, {
                id,
                procedureId: procedure.id,
            }),
        ],
        exact: false,
    }));

    const generatedRoutedTabs = procedures.map((procedure, index) => (
        <Tab
            key={procedure.id}
            tabIndex={index}
            value={index}
            component={NavLink}
            to={generatePath(clientRoute.commissionSessionProcessProcedure, {
                id,
                procedureId: procedure.id,
            })}
            label={procedure.title}
        />
    ));

    return (
        <Grid container direction="column" spacing={5} className="t-procedure-tabs">
            <Grid item>
                <Typography variant="h3">
                    <FormattedMessage id="commissionSession.blocksTitle.procedures" />
                </Typography>
            </Grid>
            <Grid item>
                <Box maxWidth={1256}>
                    <Paper elevation={0}>
                        <RoutedTabs tabsProps={routedTabsProps} settings={routedTabsSettings}>
                            {generatedRoutedTabs}
                        </RoutedTabs>
                        <TotStepper activeStep={activeStep} stepList={stepList} />
                        <Box mt={3}>
                            <Route path={clientRoute.commissionSessionProcessProcedure}>
                                <SessionProcedureSubjectList
                                    updateSessionInfo={updateSubjectList}
                                    setActiveStep={setActiveStep}
                                    procedures={procedures}
                                    reloadKey={reloadKey}
                                    reloadIncrement={reloadIncrement}
                                />
                            </Route>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
});
