import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { CodeTitle, FormDTO, FullSubmission, RowsData, TableQueryData, UserRowDTO } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export interface UserDTO {
    userId: string;
    lang: string;
    login: string;
    roles: CodeTitle[];
    formInfo: FormDTO;
}

export class UserStore {
    @observable private rootStore: RootStore;
    @observable private api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    loginAsUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.loginAsUser(id)).then((r) => r.data);
    }

    @action.bound
    loadUser(id: string): Promise<UserDTO> {
        return this.api
            .client(apiConfigs.loadUser(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    404: () => this.history.replace(clientRoute.notFound),
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    saveUser(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveUser(id, submission)).then((r) => r.data);
    }

    @action.bound
    saveUserRoles(id: string, roles: CodeTitle[]): Promise<void> {
        return this.api
            .client(
                apiConfigs.saveUserRoles(
                    id,
                    roles.map((role) => role.code),
                ),
            )
            .then((r) => r.data);
    }

    @action.bound
    userRoleList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.userRoleList).then((r) => r.data);
    }

    @action.bound
    loadList(queryData: TableQueryData): Promise<RowsData<UserRowDTO>> {
        return this.api
            .client(apiConfigs.userList(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    blockUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.blockUser(id)).then((r) => r.data);
    }

    @action.bound
    unblockUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.unblockUser(id)).then((r) => r.data);
    }

    @action.bound
    deleteUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteUser(id)).then((r) => r.data);
    }
}
