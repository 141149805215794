import { Button, Container, ExpansionPanelDetails, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StickyHeader, TotHeaderPanel, TotHeaderPanelSummary, TotObjectHeader } from '../../../components';
import { useCommissionSessionPageContext, useToggle } from '../../../hooks';
import { getOrElse } from '../../../utils';
import { CommissionSessionControlPanel } from './CommissionSessionControlPanel';
import { CommissionSessionDescription } from './CommissionSessionDescription';

export type CommissionSessionHeaderProps = {
    reloadKey: number;
    onSubmit?: (canSaveWithoutValidation: boolean) => void;
    updateObjectPage: () => Promise<void>;
    sessionTransition: (transitionId: string, objectId: string, validate?: boolean) => Promise<void>;
};

const expandMoreIcon = <ExpandMoreIcon />;
const navigateNextIcon = <NavigateNextIcon />;

export const CommissionSessionHeader = observer((props: CommissionSessionHeaderProps): JSX.Element => {
    const { onSubmit, reloadKey, updateObjectPage, sessionTransition } = props;
    const [isOpen, handleToggle] = useToggle();

    const { title } = useCommissionSessionPageContext().commissionSessionModel;

    const startIcon = isOpen ? expandMoreIcon : navigateNextIcon;

    return (
        <StickyHeader>
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={3} direction="column">
                        <Grid item container direction="row" spacing={5} alignItems="center">
                            <Grid item>
                                <Typography className="t-title" variant="h1">
                                    {getOrElse(title)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TotHeaderPanel expanded={isOpen}>
                                <TotHeaderPanelSummary>
                                    <Grid container direction="row" spacing={5} alignItems="center">
                                        <Grid item>
                                            <Button onClick={handleToggle} startIcon={startIcon}>
                                                <FormattedMessage id="common.description" />
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <CommissionSessionControlPanel
                                                key={reloadKey}
                                                onSubmit={onSubmit}
                                                updateObjectPage={updateObjectPage}
                                                sessionTransition={sessionTransition}
                                            />
                                        </Grid>
                                    </Grid>
                                </TotHeaderPanelSummary>
                                <ExpansionPanelDetails>
                                    <CommissionSessionDescription />
                                </ExpansionPanelDetails>
                            </TotHeaderPanel>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectHeader>
        </StickyHeader>
    );
});
