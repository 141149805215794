import { CircularProgress, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { renderAutoCompleteInput, RequiredLabel } from '../../../../components';
import { GeneralizationTableDialogModel } from '../../../../models';
import { getOptionLabel, getSelectedOption } from '../../../../utils';
import { DefaultContentContainer } from '../GeneralizationTableStyled';

export type GeneralizationSelectsProps = {
    model: GeneralizationTableDialogModel;
    isLoading: boolean;
};

export const GeneralizationSelects = observer((props: GeneralizationSelectsProps): JSX.Element => {
    const { model, isLoading } = props;
    const { selectModels, enableValidationStarted, disableValidationStarted } = model;
    const intl = useIntl();
    const formikContext = useFormikContext();
    const { isSubmitting } = formikContext;

    useEffect(() => {
        isSubmitting && enableValidationStarted();
    }, [isSubmitting]);

    useEffect(() => {
        return disableValidationStarted;
    }, []);

    return (
        <React.Fragment>
            {isLoading && !selectModels.length ? (
                <DefaultContentContainer container justify="center" alignItems="center">
                    <Grid item>
                        <CircularProgress size="40px" />
                    </Grid>
                </DefaultContentContainer>
            ) : (
                selectModels.map((select): JSX.Element => {
                    const { ownerId, model: selectModel } = select;
                    const { title, categoryAttributes, defaultCategoryAttribute, onChangeSelect, enableValidation } =
                        selectModel;

                    const label = (
                        <RequiredLabel
                            text={intl.formatMessage(
                                { id: 'commissionSession.generalizationTable.subjectAttribute' },
                                { category: title },
                            )}
                        />
                    );
                    const renderInput = renderAutoCompleteInput(label, false, selectModel.errorSelect);

                    return (
                        <Grid item key={ownerId}>
                            <Autocomplete
                                onChange={onChangeSelect}
                                getOptionSelected={getSelectedOption}
                                getOptionLabel={getOptionLabel}
                                options={categoryAttributes}
                                renderInput={renderInput}
                                value={defaultCategoryAttribute}
                                onBlur={enableValidation}
                            />
                        </Grid>
                    );
                })
            )}
        </React.Fragment>
    );
});
