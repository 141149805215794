import { Box, CircularProgress, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { TotBackground } from '../../components';
import { useStore } from '../../hooks';
import { RegistrationConfirmModel } from '../../models';

type RouteParams = {
    token: string | undefined;
};

export const RegistrationConfirmPage = observer((): JSX.Element => {
    const { token } = useParams<RouteParams>();
    const { authenticationStore } = useStore();
    const registrationConfirmModel = useMemo<RegistrationConfirmModel>(
        () => authenticationStore.registrationConfirmModel,
        [authenticationStore],
    );

    useEffect(() => {
        registrationConfirmModel.confirmEmail(token);
    }, [token]);

    const { status } = registrationConfirmModel;

    const renderByStatus = (): JSX.Element | null => {
        switch (status) {
            case 'pending':
                return <CircularProgress />;
            case 'success':
                return <Redirect to={clientRoute.commissionSessions} />;
            case 'expired':
                return <Redirect to={clientRoute.registrationInfoExpired} />;
            case 'not-found':
                return <Redirect to={clientRoute.registrationInfoNotFound} />;
            default:
                return null;
        }
    };

    return (
        <TotBackground>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Box>{renderByStatus()} </Box>
            </Grid>
        </TotBackground>
    );
});
