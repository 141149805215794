import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { AppBarComponent } from './AppHeaderLinksSettings';

export type AppHeaderLinksListProps = {
    elementsArray: AppBarComponent[];
};

export const AppHeaderLinksList = (props: AppHeaderLinksListProps): JSX.Element => {
    const { elementsArray } = props;

    return (
        <React.Fragment>
            {elementsArray.map(({ key, to, icon, messageID }) => (
                <Grid item key={key}>
                    <Button
                        component={NavLink}
                        to={to}
                        variant="text"
                        color="secondary"
                        startIcon={icon}
                        className={`t-${key}-link`}
                    >
                        <FormattedMessage id={messageID} />
                    </Button>
                </Grid>
            ))}
        </React.Fragment>
    );
};
