import {
    Box,
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    Grid,
    IconButton,
    MenuItem,
    SvgIcon,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck, DeleteActionMenuItem, MenuButton, TotObjectPanelSummary } from '../../../components';
import { useFlag, useStore } from '../../../hooks';
import { GeneralizationTableModel } from '../../../models';
import { DotMenuIcon } from '../../../resources';
import { AttributesTableType, RouteParamsDefault } from '../../../types';
import { GeneralizationDialog } from './generalization-dialog';
import { GeneralizationTable } from './GeneralizationTable';
import { GeneralizationTableCell } from './GeneralizationTableStyled';

export type GeneralizationAttributesBlockProps = {
    model: GeneralizationTableModel;
};

export const GeneralizationAttributesBlock = observer((props: GeneralizationAttributesBlockProps): JSX.Element => {
    const { model } = props;
    const { id } = useParams<RouteParamsDefault>();
    const { commissionSessionStore } = useStore();
    const intl = useIntl();
    const { categories, attributes, tableIsEmpty, activeProcedureId, procedureAttributeTableModel, load } = model;
    const [isModalOpen, openModal, closeModal] = useFlag();
    const [attributeId, setAttributeId] = useState<string | undefined>();

    useEffect(() => {
        updateTableModel();
    }, []);

    const updateTableModel = (): void => {
        commissionSessionStore.getGeneralizationTableInfo(id).then(load);

        if (activeProcedureId.get() === '') {
            return;
        }

        commissionSessionStore
            .sessionProcedureGeneralizationAttributeList(activeProcedureId.get())
            .then(procedureAttributeTableModel.load);
    };

    const deleteAttribute = (row: AttributesTableType): (() => Promise<void>) => {
        return (): Promise<void> =>
            commissionSessionStore.deleteGeneralizationAttribute(row.attributeId, id).then(updateTableModel);
    };

    const createOpenEditModalFunc =
        (pfId: string): (() => void) =>
        () => {
            setAttributeId(pfId);
            openModal();
        };

    const openCreateModal = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setAttributeId(undefined);
        openModal();
    };

    const renderActionItems = (row: AttributesTableType): (() => ReactNode[]) => {
        const openEditModal = createOpenEditModalFunc(row.attributeId);

        return (): ReactNode[] => [
            <MenuItem dense button={true} onClick={openEditModal}>
                <FormattedMessage id="common.edit" />
            </MenuItem>,
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                message={intl.formatMessage(
                    { id: 'commissionSession.generalizationTable.confirmDeletionInfoText' },
                    { title: row.attributeTitle },
                )}
                onConfirm={deleteAttribute(row)}
            />,
        ];
    };

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton onClick={onClick}>
                <SvgIcon>
                    <DotMenuIcon />
                </SvgIcon>
            </IconButton>
        );
    };

    const renderActions = (row: AttributesTableType): JSX.Element => {
        return (
            <MenuButton
                disablePortal={true}
                renderButton={renderActionsButton}
                renderMenuItems={renderActionItems(row)}
            />
        );
    };

    const categoriesElements: JSX.Element[] = categories.map((category): JSX.Element => {
        return (
            <GeneralizationTableCell key={category.id}>
                <Typography>{category.title}</Typography>
            </GeneralizationTableCell>
        );
    });

    const attributesElements: JSX.Element[] = attributes.map((attribute) => {
        return (
            <TableRow hover key={attribute.attributeId}>
                <GeneralizationTableCell>{attribute.attributeTitle}</GeneralizationTableCell>
                {attribute.categoryAttributes.map((comp, index) => {
                    return (
                        <GeneralizationTableCell key={index}>
                            {typeof comp === 'object' ? comp.title : comp}
                        </GeneralizationTableCell>
                    );
                })}
                <TableCell>
                    <AuthorizationCheck
                        entityCode={entities.CommissionSession}
                        permCode={permissions.CommissionSession.EditGenerationAttribute}
                    >
                        {renderActions(attribute)}
                    </AuthorizationCheck>
                </TableCell>
            </TableRow>
        );
    });

    return (
        <React.Fragment>
            <GeneralizationDialog
                isOpen={isModalOpen}
                attributeId={attributeId}
                setIsClosed={closeModal}
                updateTable={updateTableModel}
            />

            <ExpansionPanel defaultExpanded elevation={0}>
                <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h3">
                                3. <FormattedMessage id="commissionSession.blocksTitle.generalizationTable" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box pr={3}>
                                <AuthorizationCheck
                                    entityCode={entities.CommissionSession}
                                    permCode={permissions.CommissionSession.EditGenerationAttribute}
                                    entityId={id}
                                >
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={<AddCircleOutlineIcon />}
                                        onClick={openCreateModal}
                                    >
                                        <FormattedMessage id="commissionSession.generalizationTable.addAttribute" />
                                    </Button>
                                </AuthorizationCheck>
                            </Box>
                        </Grid>
                    </Grid>
                </TotObjectPanelSummary>

                <ExpansionPanelSummary style={{ padding: 0 }}>
                    <GeneralizationTable
                        tableIsEmpty={tableIsEmpty}
                        categoriesElements={categoriesElements}
                        attributesElements={attributesElements}
                    />
                </ExpansionPanelSummary>
            </ExpansionPanel>
        </React.Fragment>
    );
});
