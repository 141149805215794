class ClientRoute {
    root = '/';
    notFound = '/404';
    notAllowed = '/403';

    categories = '/categories';
    category = '/categories/:id';
    categoryEdit = '/categories/:id/edit';
    categoryCreate = '/categories/:id/create';

    subjects = '/subjects';
    subject = '/subjects/:id';
    subjectCreate = '/subjects/:id/create';
    subjectEdit = '/subjects/:id/edit';

    commissions = '/commissions';
    commission = '/commissions/:id';
    commissionCreate = '/commissions/:id/create';
    commissionEdit = '/commissions/:id/edit';
    commissionAddHeadMember = '/commissions/:id/head';

    commissionSessions = '/sessions';
    commissionSessionSetting = '/sessions/:id/setting';
    commissionSessionSettingCreate = '/sessions/:id/setting/create';
    commissionSessionSettingEdit = '/sessions/:id/setting/edit';
    sessionProcedureCreate = '/sessions/:id/setting/procedure/:procedureId/create';
    sessionProcedureEdit = '/sessions/:id/setting/procedure/:procedureId/edit';

    commissionSessionProcess = '/sessions/:id';
    commissionSessionProcessProcedure = '/sessions/:id/procedures/:procedureId';

    users = '/users';
    user = '/users/:id';
    userEdit = '/users/:id/edit';

    pfTemplateList = '/pf-templates';

    login = '/login';
    recoveryPassword = '/recovery';
    newPassword = '/recovery/:token';
    registration = '/registration';
    authenticationInfo = '/info';
    registrationInfoSuccess = '/info/registration/success';
    registrationInfoExpired = '/info/registration/expired';
    registrationInfoNotFound = '/info/registration/404';
    recoveryPasswordInfoSuccess = '/info/recovery-password/success';
    registrationConfirm = '/registration/confirm/:token';

    esia = '/esia';
    esiaGetAuthUrl = '/esia/auth-url';
    esiaLogin = '/esia/login';
}

export const clientRoute = new ClientRoute();
