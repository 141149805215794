import { ThemeOptions } from '@material-ui/core';
import { ThemeOverrides } from '../../../types';

const mainFont = '"Roboto", sans-serif';

export const createLandingThemeOptions = (overrides: ThemeOverrides): ThemeOptions => {
    const { palette, fonts } = overrides;
    return {
        typography: {
            fontFamily: mainFont,
            fontSize: 18,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
        },
        overrides: {
            MuiLink: {
                root: {
                    color: palette.main,

                    '&:hover': {
                        color: palette.accentContrast,
                    },
                },
            },
            MuiTypography: {
                colorPrimary: {
                    color: palette.background.textMain,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                },
                colorSecondary: {
                    color: `${palette.mainContrast} !important`,

                    '&:hover': {
                        color: `${palette.hover} !important`,
                    },
                },
            },
        },
        variables: {
            palette: palette,
            fonts: {
                ...fonts,
                mainFont,
            },
        },
    };
};
