import { action, computed, observable } from 'mobx';
import { FormAttributeDTO, IdTitle, SessionProcedureAttribute, SessionProcedureTableInfoDTO } from '../../../types';

export class SessionProcedureAttributeTableModel {
    @observable categories: IdTitle[] = [];
    @observable attributes: SessionProcedureAttribute[] = [];
    @observable isGeneralizationTableFull = false;

    @computed
    get tableIsEmpty(): boolean {
        return !!this.categories.length && !!this.attributes.length;
    }

    @action.bound
    setIsGeneralizationTableFull(tableIsFull: boolean): void {
        this.isGeneralizationTableFull = tableIsFull;
    }

    @action.bound
    load(dto: SessionProcedureTableInfoDTO): void {
        this.categories = dto.categories;
        this.attributes = dto.attributes.map((attributeDTO) => {
            const components: Array<FormAttributeDTO | string> = [];

            dto.categories.forEach((category) => {
                const component = attributeDTO.attribute.categoryAttributes.find(
                    (comp) => comp.ownerId === category.id,
                );
                components.push(component ? component : '');
            });

            return {
                id: attributeDTO.id,
                attribute: {
                    attributeId: attributeDTO.attribute.attributeId,
                    attributeTitle: attributeDTO.attribute.attributeTitle,
                    categoryAttributes: components,
                },
            };
        });
    }
}
