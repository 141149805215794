import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { GetAppOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { ReactNode, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ActionsMenu, Authentication, TableHeadTypography } from '../../components';
import { useFlag, useStore } from '../../hooks';
import { PfTemplateListModel } from '../../models/PfTemplateListModel';
import { PfTemplateDTO } from '../../types';
import { PfTemplateDialog } from './PfTemplateDialog';

export const PfTemplateListPage = observer((): JSX.Element => {
    const { pfTemplateStore } = useStore();
    const { downloadTemplateFile } = pfTemplateStore;
    const [isModalOpen, openModal, closeModal] = useFlag();
    const [editPfId, setEditPfId] = useState<string | undefined>();
    const model = useMemo(() => new PfTemplateListModel(pfTemplateStore), []);
    const { pfTemplateList, load } = model;

    const handleDownloadTemplateFile = (pfTemplate: PfTemplateDTO): (() => void) => {
        return () => downloadTemplateFile(pfTemplate.fileDTO);
    };

    const createOpenEditModalFunc =
        (pfId: string): (() => void) =>
        () => {
            setEditPfId(pfId);
            openModal();
        };

    const openCreateModal = (): void => {
        setEditPfId(undefined);
        openModal();
    };

    const renderActions = (pfTemplate: PfTemplateDTO): (() => ReactNode[]) => {
        const openEditModal = createOpenEditModalFunc(pfTemplate.id);

        return () => [
            <MenuItem dense key="edit" tabIndex={0} button={true} onClick={openEditModal}>
                <FormattedMessage id="common.edit" />
            </MenuItem>,
        ];
    };

    return (
        <Authentication>
            <PfTemplateDialog
                pfTemplateList={pfTemplateList}
                editPfId={editPfId}
                isOpen={isModalOpen}
                setIsClosed={closeModal}
                onSuccessSubmit={load}
            />
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column">
                        <Box pb={10}>
                            <Grid item container direction="row" justify="space-between">
                                <Grid item>
                                    <Typography variant="h1">
                                        <FormattedMessage id="pfTemplate.listTitle" />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button color="primary" variant="contained" onClick={openCreateModal}>
                                        <FormattedMessage id="pfTemplate.create" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid item>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TableHeadTypography>
                                                    <FormattedMessage id="pfTemplate.fields.title" />
                                                </TableHeadTypography>
                                            </TableCell>
                                            <TableCell>
                                                <TableHeadTypography>
                                                    <FormattedMessage id="pfTemplate.fields.created" />
                                                </TableHeadTypography>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pfTemplateList.map((pfTemplate) => {
                                            return (
                                                <TableRow key={pfTemplate.id} hover>
                                                    <TableCell>{pfTemplate.title}</TableCell>
                                                    <TableCell>
                                                        <FormattedDate value={pfTemplate.created} />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip
                                                            title={
                                                                <FormattedMessage id="pfTemplate.actions.download" />
                                                            }
                                                        >
                                                            <IconButton
                                                                onClick={handleDownloadTemplateFile(pfTemplate)}
                                                            >
                                                                <GetAppOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <ActionsMenu
                                                            renderActionItems={renderActions}
                                                            row={pfTemplate}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Authentication>
    );
});
