import { Grid } from '@material-ui/core';
import { Form, FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import { AuthorizationCheck, ServiceInfoPanel, TotObjectMain } from '../../components';
import { useFormApi, useStore } from '../../hooks';
import { RouteParamsDefault } from '../../types';
import { CategoryOfSubjectsHeader } from './categories-subjects-header';

export const CategoryOfSubjectsPage = observer((): JSX.Element => {
    const { categoriesOfSubjectsStore } = useStore();
    const {
        getCategoryOfSubjectsModel,
        saveChangeCategoryOfSubjects,
        deleteCategoryOfSubjects,
        loadCategoryOfSubjectsDTO,
    } = categoriesOfSubjectsStore;
    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();
    const intl = useIntl();
    const { locale } = intl;

    const [formApi, setFormApi] = useFormApi();
    const model = useMemo(() => getCategoryOfSubjectsModel(id), [id]);
    const { formModel, metaInfo, load } = model;

    const reloadModel = async () => {
        const categoryOfSubjectsDTO = await loadCategoryOfSubjectsDTO(id);
        load(categoryOfSubjectsDTO);
        history.push(generatePath(clientRoute.category, { id }));
    };

    const onSubmit = (): void => {
        if (formApi && formApi.validate()) {
            saveChangeCategoryOfSubjects(id, formApi.getSubmission()).then(reloadModel);
        }
    };

    const handLeDeleteCategoryOfSubjects = (): Promise<void> => {
        return deleteCategoryOfSubjects(id).then(() => {
            history.push(generatePath(clientRoute.categories));
        });
    };

    const onNotAllowed = (): void => {
        history.replace(clientRoute.notAllowed);
    };

    return (
        <AuthorizationCheck
            entityCode={entities.System}
            permCode={permissions.System.Administration}
            onNotAllowed={onNotAllowed}
        >
            <Grid container direction="column">
                <Grid item>
                    <CategoryOfSubjectsHeader
                        categoryOfSubjectsModel={model}
                        deleteCategoryOfSubjects={handLeDeleteCategoryOfSubjects}
                        onSubmit={onSubmit}
                    />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                <Grid item>
                                    <ServiceInfoPanel metaInfoModel={metaInfo} withLifecycle={false} />
                                </Grid>
                            </AuthorizationCheck>
                            <Grid item>
                                <Switch>
                                    <Route exact path={[clientRoute.categoryEdit, clientRoute.categoryCreate]}>
                                        <Form className="t-category-form" form={formModel} onFormReady={setFormApi} />
                                    </Route>
                                    <Route exact path={clientRoute.category}>
                                        <FormView locale={locale} form={formModel} onFormReady={setFormApi} />
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                        <Grid item>{/*  TODO: Sidebar  */}</Grid>
                    </Grid>
                </TotObjectMain>
            </Grid>
        </AuthorizationCheck>
    );
});
