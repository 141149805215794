import { action, computed, observable } from 'mobx';
import { FileDTO } from '../types';

export class FileModel {
    @observable fileForUpload?: File;
    @observable fileDTO?: FileDTO;
    @observable maxFileSize: number;
    @observable acceptedFiles: string[] = [];

    constructor(acceptedFiles: string[], maxFileSize: number) {
        this.maxFileSize = maxFileSize;
        this.acceptedFiles = acceptedFiles;
    }

    @action.bound
    onDeleteFile(): void {
        this.fileDTO = undefined;
        this.fileForUpload = undefined;
    }

    @computed
    get fileName(): string | undefined {
        return this.fileForUpload?.name || this.fileDTO?.filename;
    }

    @action.bound
    setFileDTO(fileDTO: FileDTO): void {
        this.fileDTO = fileDTO;
    }
}
