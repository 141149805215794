const homePage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        commissions: 'Committees',
        commissionSessions: 'Sessions',
        categories: 'Categories',
        subjects: 'Objects',
        users: 'Users',
        pfTemplates: 'Templates of PF',
    },
};

export default homePage;
