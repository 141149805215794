import React from 'react';

export const ShapeIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20">
        <path
            fill="currentColor"
            fill-rule="evenodd"
            d="M14.5 20c-2.485 0-4.5-2.015-4.5-4.5s2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5zm0-7c-1.381 0-2.5 1.119-2.5 2.5s1.119 2.5 2.5 2.5 2.5-1.119 2.5-2.5-1.119-2.5-2.5-2.5zM9 .016l5.484 9H3.516L9 .016zm0 3.843L7.078 7h3.844L9 3.859zM0 11.5h8.016v8.016H0V11.5zm2.016 6H6v-3.985H2.016V17.5z"
        />
    </svg>
);
