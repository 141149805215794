import { AxiosError } from 'axios';

export function handleAxiosErrorByResponseStatus(actions: Record<number, () => void>) {
    return function (error: AxiosError): Promise<AxiosError> {
        const status = error.response?.status;
        if (status) {
            const action = actions[status];
            if (action) {
                action();
            }
        }
        return Promise.reject(error);
    };
}
