import React from 'react';

export const UserPlusIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="currentColor">
        <g>
            <g>
                <path d="M11.3 9.22A8.002 8.002 0 0 1 16 16.5a1 1 0 1 1-2 0 6 6 0 0 0-12.001 0 1 1 0 1 1-2 0 8 8 0 0 1 4.7-7.28 4.92 4.92 0 0 1-1.7-3.72 5.001 5.001 0 0 1 10 0 4.92 4.92 0 0 1-1.7 3.72zM8 8.5a3 3 0 1 0-.002-6.001 3 3 0 0 0 .001 6z" />
            </g>
        </g>
    </svg>
);
