import { Box, ExpansionPanel, Grid, Link, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useStore } from '../hooks';
import { MetaInfoModel } from '../models';
import { getOrElse } from '../utils';
import { TotObjectPanelDetails, TotObjectPanelLabel, TotObjectPanelSummary, TotObjectPanelValue } from './tot-object';

export type ServiceInfoPanelProps = {
    metaInfoModel: MetaInfoModel;
    withForm?: boolean;
    elevation?: number;
    withLifecycle?: boolean;
};

export const ServiceInfoPanel = observer((props: ServiceInfoPanelProps): JSX.Element => {
    const { metaInfoModel, withForm = true, elevation = 0, withLifecycle = true } = props;
    const { services } = useStore();

    return (
        <ExpansionPanel defaultExpanded elevation={elevation}>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="common.serviceInfo" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item container direction="row">
                        <Grid item xs={6}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.author" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>{metaInfoModel.author.name}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.lastModified" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            metaInfoModel.modified,
                                            <FormattedDate value={metaInfoModel.modified} />,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {withLifecycle && (
                        <Grid item>
                            <Box pt={8}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="common.serviceInfoFields.lifeCycle" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                metaInfoModel.processInfo,
                                                <Link
                                                    target="_blank"
                                                    href={services.lifecycleProcessLink(metaInfoModel.processInfo?.id)}
                                                >
                                                    {metaInfoModel.processInfo?.title}
                                                </Link>,
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )}
                    {withForm && (
                        <Grid item>
                            <Box pt={8}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="common.serviceInfoFields.form" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                metaInfoModel.formInfo,
                                                <Link
                                                    target="_blank"
                                                    href={services.formVersionLink(metaInfoModel.formInfo)}
                                                >
                                                    {metaInfoModel.formInfo}
                                                </Link>,
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
});
