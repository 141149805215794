import { ExpansionPanel, Grid, Link, Typography, withTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { clientRoute } from '../../../clientRoute';
import {
    TotObjectPanelDetails,
    TotObjectPanelLabel,
    TotObjectPanelSummary,
    TotObjectPanelValue,
} from '../../../components';
import { useStore } from '../../../hooks';
import { SubjectModel } from '../../../models';
import { getOrElse } from '../../../utils';

const StyledLink = withTheme(styled(Link)`
    font-size: 15px;
`);

export type SubjectServiceInfoProps = {
    subjectModel: SubjectModel;
};

export const SubjectServiceInfo = observer((props: SubjectServiceInfoProps): JSX.Element => {
    const { subjectModel } = props;
    const { services } = useStore();
    const { metaInfo } = subjectModel;

    return (
        <ExpansionPanel defaultExpanded elevation={0}>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="subject.blocks.serviceInfo" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container direction="row">
                        <Grid item xs={8}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.author" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {metaInfo.author.userId ? (
                                            <StyledLink
                                                component={NavLink}
                                                underline="none"
                                                to={generatePath(clientRoute.user, { id: metaInfo.author.userId })}
                                            >
                                                {metaInfo.author.name}
                                            </StyledLink>
                                        ) : (
                                            '-'
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.lastModified" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            subjectModel.metaInfo.modified,
                                            <FormattedDate value={subjectModel.metaInfo.modified} />,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.category" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {subjectModel.categoryId ? (
                                            <StyledLink
                                                component={NavLink}
                                                underline="none"
                                                to={generatePath(clientRoute.category, {
                                                    id: subjectModel.categoryId,
                                                })}
                                            >
                                                {getOrElse(subjectModel.categoryTitle)}
                                            </StyledLink>
                                        ) : (
                                            '-'
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.process" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            subjectModel.metaInfo.processInfo,
                                            <Link
                                                target="_blank"
                                                href={services.lifecycleProcessLink(
                                                    subjectModel.metaInfo.processInfo?.id,
                                                )}
                                            >
                                                {subjectModel.metaInfo.processInfo?.title}
                                            </Link>,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.form" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                subjectModel.metaInfo.formInfo,
                                                <Link
                                                    target="_blank"
                                                    href={services.formVersionLink(subjectModel.metaInfo.formInfo)}
                                                >
                                                    {subjectModel.metaInfo.formInfo}
                                                </Link>,
                                            )}
                                        </TotObjectPanelValue>
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
});
