import { createBrowserHistory } from 'history';
import { RootStoreProps } from './types';

const projectName = process.env.REACT_APP_THEME_NAME;

const env: RootStoreProps['env'] = {
    apiUrl: process.env.REACT_APP_API_URL,
    totCopyright: process.env.REACT_APP_TOT_COPYRIGHT,
    totCopyrightStartYear: process.env.REACT_APP_TOT_COPYRIGHT_START_YEAR,
    totCopyrightRu: process.env.REACT_APP_TOT_COPYRIGHT_RU,
    totCopyrightEn: process.env.REACT_APP_TOT_COPYRIGHT_EN,
    termsOfServiceLinkRu: process.env.REACT_APP_TERMS_OF_SERVICE_RU,
    termsOfServiceLinkEn: process.env.REACT_APP_TERMS_OF_SERVICE_EN,
    totTel: process.env.REACT_APP_TOT_TEL,
    totMail: process.env.REACT_APP_TOT_MAIL,
    appThemeName: process.env.REACT_APP_THEME_NAME,
    formServiceUrl: process.env.REACT_APP_FORM_UI_SERVICE_URL,
    lifeCycleServiceUrl: process.env.REACT_APP_LIFECYCLE_SERVICE_URL,
    logoName: process.env.REACT_APP_LOGO_NAME,
    backgroundName: process.env.REACT_APP_BACKGROUND_NAME,
    startPageText: process.env.REACT_APP_START_PAGE_TEXT,
    pfDelay: Number(process.env.REACT_APP_PF_DELAY) || 30000,
};

let features: RootStoreProps['features'];
let themeOverrides: RootStoreProps['themeOverrides'];
let ru = {};
let en = {};

if (projectName) {
    features = require(`./customization/${projectName}/features`).default;
    themeOverrides = require(`./customization/${projectName}/theme`).default;
    ru = require(`./customization/${projectName}/locales/ru`).default;
    en = require(`./customization/${projectName}/locales/en`).default;
}

const locales: RootStoreProps['locales'] = { ru, en };

const history = createBrowserHistory();

export { env, features, locales, themeOverrides, history };
