import { History } from 'history';
import downloadFile from 'js-file-download';
import { action, computed, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { CategoryOfSubjectsModel } from '../models';
import { FormDTO, FullSubmission, MetaInfoDTO, TableQueryData } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export interface CategoryOfSubjectsDTO {
    id: string;
    title: string;
    metaInfo: MetaInfoDTO;
    formInfo: FormDTO;
}

export class CategoriesOfSubjectsStore {
    @observable api: Api;
    @observable rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    saveChangeCategoryOfSubjects(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveChangeCategoryOfSubjects(id, submission)).then((r) => r.data);
    }

    @action.bound
    getCategoryOfSubjectsModel(id: string): CategoryOfSubjectsModel {
        const model = new CategoryOfSubjectsModel(id);
        this.loadCategoryOfSubjectsDTO(id).then(model.load);
        return observable(model);
    }

    @action.bound
    loadCategoryOfSubjectsDTO(id: string): Promise<CategoryOfSubjectsDTO> {
        return this.api
            .client(apiConfigs.loadCategoryOfSubjectsDTO(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    createCategoryOfSubjects(): Promise<string> {
        return this.api
            .client(apiConfigs.createCategoryOfSubjects)
            .then((r) => r.data.id)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    deleteCategoryOfSubjects(id: string): Promise<void> {
        return this.api
            .client(apiConfigs.deleteCategoryOfSubjects(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    exportListXls(queryData: TableQueryData): void {
        const filename = this.rootStore.intlStore.formatMessage('subject.subjectListTitle');
        this.api
            .client(apiConfigs.categoryListXls(queryData))
            .then((r) => r.data)
            .then((f) => downloadFile(f, `${filename}.xlsx`, 'application/vnd.ms-excel'));
    }
}
