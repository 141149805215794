import { action, observable } from 'mobx';
import { apiConfigs } from '../../apiConfigs';
import { clientRoute } from '../../clientRoute';
import { Api, CatalogStore } from '../../stores';
import { IdTitle, RowsData, TableQueryData } from '../../types';
import { handleAxiosErrorByResponseStatus } from '../../utils';
import { DateRangeFilter, InSetFilter, LikeFilter, Sort, TableModel } from '../list';

export interface CategoriesOfSubjectsRowDTO {
    id: string;
    title: string;
    processName: string;
    formName: string;
    created: string; //date
}

type CategoriesOfSubjectsFilter = {
    identifier: LikeFilter;
    title: LikeFilter;
    processCode: InSetFilter;
    formCode: InSetFilter;
    created: DateRangeFilter;
};

type CategoriesOfSubjectsSort = {
    title: Sort;
    created: Sort;
};

export class CategoriesOfSubjectsListModel extends TableModel<
    CategoriesOfSubjectsRowDTO,
    CategoriesOfSubjectsFilter,
    CategoriesOfSubjectsSort
> {
    @observable api: Api;
    @observable processFilterData: IdTitle[];
    @observable formFilterData: IdTitle[];
    @observable catalogStore: CatalogStore;

    constructor(api: Api, catalogStore: CatalogStore) {
        const filter = {
            identifier: new LikeFilter(),
            title: new LikeFilter(),
            processCode: new InSetFilter(),
            formCode: new InSetFilter(),
            created: new DateRangeFilter(),
        };

        const sort = {
            title: new Sort(),
            created: new Sort('desc'),
        };

        super(filter, sort);
        this.catalogStore = catalogStore;

        this.api = api;
        this.processFilterData = [];
        this.formFilterData = [];

        this.loadFiltersData();
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<CategoriesOfSubjectsRowDTO>> {
        const { rootStore } = this.catalogStore;
        const { history } = rootStore;

        return this.api
            .client(apiConfigs.categoriesOfSubjectsListData(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => history.replace(clientRoute.notAllowed),
                    404: () => history.replace(clientRoute.notFound),
                }),
            )
            .then((r) => r);
    }

    @action.bound
    loadFiltersData(): void {
        this.catalogStore.getFilterData(apiConfigs.loadFormsFilterData('Subject')).then(this.setFormFilterData);
        this.catalogStore.getFilterData(apiConfigs.loadProcessFilterData).then(this.setProcessFilterData);
    }

    @action.bound
    setFormFilterData(filterData: IdTitle[]): void {
        this.formFilterData = filterData;
    }

    @action.bound
    setProcessFilterData(filterData: IdTitle[]): void {
        this.processFilterData = filterData;
    }
}
