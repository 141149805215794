import { FormModel } from '@platform/formiojs-react';
import { action, observable } from 'mobx';
import { CommissionDTO } from '../../types';
import { MetaInfoModel } from '../MetaInfo';

export class CommissionModel {
    @observable id: string;
    @observable title = '';
    @observable formModel: FormModel;
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();

    constructor(id: string) {
        this.id = id;
        this.formModel = new FormModel(id);
    }

    @action.bound
    load(dto: CommissionDTO): void {
        const formInfo = dto.formInfo;
        this.title = dto.title;
        this.formModel.load(formInfo);
        this.metaInfo.load(dto.metaInfo);
    }
}
