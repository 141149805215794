import { Box, Link, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStore } from '../../hooks';

const renderLink =
    (termsOfService?: string): ((chunks: string[]) => JSX.Element) =>
    (...chunks) => {
        return (
            <Link
                href={termsOfService}
                target="_blank"
                underline="none"
                style={{ display: 'inline', fontWeight: 600 }}
                rel="noopener"
            >
                {chunks}
            </Link>
        );
    };

export type DataUsePolicyComponentProps = {
    buttonText: string;
};

export const DataUsePolicyComponent = (props: DataUsePolicyComponentProps): JSX.Element => {
    const { buttonText } = props;
    const { env } = useStore();
    const intl = useIntl();

    const termsOfService = useMemo((): string | undefined => {
        return intl.locale === 'en' ? env.termsOfServiceLinkEn : env.termsOfServiceLinkRu;
    }, [intl.locale, env]);

    const link = renderLink(termsOfService);

    return (
        <React.Fragment>
            {termsOfService && (
                <Box pl={2} pr={2}>
                    <Typography>
                        <FormattedMessage
                            id="authentication.dataUsePolicy"
                            values={{
                                a: link,
                                button: buttonText,
                            }}
                        />
                    </Typography>
                </Box>
            )}
        </React.Fragment>
    );
};
