import { Tabs, TabsActions, TabsProps } from '@material-ui/core';
import { debounce } from 'debounce';
import { observer } from 'mobx-react';
import React, { ReactNode, useMemo } from 'react';
import { matchPath, RouteProps, useLocation } from 'react-router-dom';

export type OuterTabsProps = Omit<TabsProps, 'value' | 'onChange'>;
export type RouteSetting = Pick<RouteProps, 'path' | 'exact' | 'sensitive' | 'strict'> & { tab: number | string };

export type RenderChildren = (updateIndicator: () => void) => ReactNode;

export type RoutedTabsProps = {
    tabsProps: OuterTabsProps;
    settings: RouteSetting[];
    children: RenderChildren | ReactNode;
    customTabsActions?: React.Ref<TabsActions>;
};

export const RoutedTabs = observer((props: RoutedTabsProps): JSX.Element => {
    const { settings, tabsProps, children, customTabsActions } = props;
    const location = useLocation();
    const { pathname } = location;

    const tabsActions = useMemo(() => {
        return React.createRef<TabsActions>();
    }, []);

    // debounce сильно сокращает количество реальных расчетов индикатора
    const updateIndicator = debounce((): void => {
        tabsActions.current && tabsActions.current.updateIndicator();
    }, 50);

    const renderChildren = (): ReactNode => {
        if (typeof children === 'function') {
            const render = children as RenderChildren;
            return render(updateIndicator);
        }
        return children;
    };

    const match = useMemo(() => {
        return settings.find((s) => !!matchPath(pathname, s));
    }, [settings, pathname]);

    const selected = useMemo(() => {
        return match === undefined ? false : match.tab;
    }, [match]);

    return (
        <Tabs value={selected} action={customTabsActions || tabsActions} {...tabsProps}>
            {renderChildren()}
        </Tabs>
    );
});
