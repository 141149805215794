import { Preset, TTableQueryData } from '@platform/ttable';
import { TTableRow } from '@platform/ttable/lib/data/TTableRow';
import { AxiosRequestConfig } from 'axios';
import { ConfirmEmailDTO, EmailDTO, LoginDTO, NewPasswordDTO, RegistrationDTO } from './models';
import {
    AuthorizationCheckQuery,
    FullSubmission,
    GeneralizationCategoryInfoDTO,
    NewCommissionSessionDTO,
    NewSubjectDTO,
    ResultOfVotingDTO,
    SessionProcedureAttributesDTO,
    SubjectCommentDTO,
    TableQueryData,
} from './types';
import { getUTCTimezone } from './utils';

class ApiConfigs {
    login: (data: LoginDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/login',
            method: 'PUT',
            data,
        };
    };

    esiaAuthUrl: AxiosRequestConfig = {
        url: '/authUrl/esia',
        method: 'GET',
    };

    esiaLogin: (search: string) => AxiosRequestConfig = (search) => {
        return {
            url: `/login/esia${search}`,
            method: 'PUT',
        };
    };

    logout: AxiosRequestConfig = {
        url: '/logout',
        method: 'PUT',
    };

    resetPassword: (data: NewPasswordDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/reset-password',
            method: 'PUT',
            data,
        };
    };

    forgotPassword: (data: EmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/forgot-password',
            method: 'PUT',
            data,
        };
    };

    confirmEmail: (data: ConfirmEmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/confirm-email',
            method: 'PUT',
            data,
        };
    };

    register: (data: RegistrationDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/register',
            method: 'PUT',
            data,
        };
    };

    authorizationCheck: (queries: Readonly<AuthorizationCheckQuery[]>) => AxiosRequestConfig = (queries) => {
        return {
            url: '/check',
            method: 'PUT',
            data: queries,
        };
    };

    person: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            url: `/person`,
            method: 'GET',
            params: {
                lang,
            },
        };
    };

    setLang: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            url: `/person?lang=${lang}`,
            method: 'PUT',
        };
    };

    categoriesOfSubjectsListData: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/categories',
            method: 'PUT',
            data: queryData,
        };
    };

    createCategoryOfSubjects: AxiosRequestConfig = {
        url: '/category',
        method: 'POST',
    };

    deleteCategoryOfSubjects: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/category/${id}`,
            method: 'DELETE',
        };
    };

    loadCategoryOfSubjectsDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/category/${id}`,
            method: 'GET',
        };
    };

    saveChangeCategoryOfSubjects: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/category/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    categorySelectOptions: AxiosRequestConfig = {
        url: '/select/categories',
        method: 'GET',
    };

    subjects: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/subjects',
            method: 'PUT',
            data,
        };
    };

    createSubject: (data: NewSubjectDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/subject',
            method: 'POST',
            data,
        };
    };

    deleteSubject: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/subject/${id}`,
            method: 'DELETE',
        };
    };

    subject: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/subject/${id}`,
            method: 'GET',
        };
    };

    updateSessionInSubject: (id: string, sessionId: string) => AxiosRequestConfig = (id, sessionId) => {
        return {
            url: `/subject/${id}/commission-session`,
            method: 'PUT',
            params: {
                sessionId,
            },
        };
    };

    saveSubjectForm: (subjectId: string, formSubmission: FullSubmission) => AxiosRequestConfig = (
        subjectId,
        formSubmission,
    ) => {
        return {
            url: `/subject/${subjectId}`,
            method: 'PUT',
            data: {
                formSubmission: formSubmission,
            },
        };
    };

    subjectLifeCycleTransition: (transitionId: string, subjectId: string) => AxiosRequestConfig = (
        transitionId,
        subjectId,
    ) => {
        return {
            url: `/subject/${subjectId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    downloadSubjectCommonFiles: (subjectId: string) => AxiosRequestConfig = (subjectId) => {
        return {
            url: `/subject/${subjectId}/common/files`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    downloadSubjectHiddenFiles: (subjectId: string) => AxiosRequestConfig = (subjectId) => {
        return {
            url: `/subject/${subjectId}/hidden/files`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    getSubjectLifeCycleTransitions: (subjectId: string) => AxiosRequestConfig = (subjectId) => {
        return {
            url: `/subject/${subjectId}/transitions`,
            method: 'GET',
        };
    };

    subjectsSelectList: AxiosRequestConfig = {
        url: '/select/expertise/subjects',
        method: 'GET',
    };

    subjectListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/subjectsXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    categoryListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/categoryXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    loadStateFilterData: AxiosRequestConfig = {
        url: '/subjects/filters',
        method: 'GET',
    };

    loadProcessFilterData: AxiosRequestConfig = {
        url: '/lifecycles/subject',
        method: 'GET',
    };

    loadFormsFilterData: (code: string) => AxiosRequestConfig = (code) => ({
        url: `/select/forms/search?code=${code}`,
        method: 'GET',
    });

    userList: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/administration/users',
            method: 'PUT',
            data,
        };
    };

    loadUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'GET',
        };
    };

    saveUser: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/administration/user/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    userRoleList: AxiosRequestConfig = {
        url: '/roles',
        method: 'GET',
    };

    saveUserRoles: (id: string, roles: string[]) => AxiosRequestConfig = (id, roles) => {
        return {
            url: `/administration/user/${id}/roles`,
            method: 'PUT',
            data: roles,
        };
    };

    blockUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/block`,
            method: 'PUT',
        };
    };

    unblockUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/unblock`,
            method: 'PUT',
        };
    };

    deleteUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'DELETE',
        };
    };

    loginAsUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/login-as/${id}`,
            method: 'PUT',
        };
    };

    catalogItems: (catalogCode: string, lang: string) => AxiosRequestConfig = (catalogCode, lang) => {
        return {
            url: '/catalogItems',
            method: 'GET',
            params: {
                catalogCode,
                lang,
            },
        };
    };

    pfTemplates: AxiosRequestConfig = {
        url: '/pf-templates',
        method: 'GET',
    };

    createPfTemplate: (formData: FormData) => AxiosRequestConfig = (formData) => {
        return {
            url: '/pf-template',
            method: 'POST',
            data: formData,
        };
    };

    updatePfTemplate: (formData: FormData, templateId?: string) => AxiosRequestConfig = (formData, templateId) => {
        return {
            url: '/pf-template/update',
            method: 'PUT',
            params: {
                id: templateId,
            },
            data: formData,
        };
    };

    downloadPfTemplateFile: (pfTemplateId: string) => AxiosRequestConfig = (pfTemplateId) => {
        return {
            url: `/pf-template/${pfTemplateId}/file`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    usersByRole: (roleCode: string) => AxiosRequestConfig = (roleCode) => {
        return {
            url: '/select/users',
            method: 'GET',
            params: {
                role: roleCode,
            },
        };
    };

    //<editor-fold desk="Commission">

    commissions: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/commissions',
            method: 'PUT',
            data,
        };
    };

    commissionsListFilterData: AxiosRequestConfig = {
        url: '/commissions/filters',
        method: 'GET',
    };

    createCommission: AxiosRequestConfig = {
        url: '/commission',
        method: 'POST',
    };

    deleteCommission: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/commission/${id}`,
            method: 'DELETE',
        };
    };

    loadCommissionDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/commission/${id}`,
            method: 'GET',
        };
    };

    saveCommission: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/commission/${id}`,
            method: 'PUT',
            data: {
                formSubmission: submission,
            },
        };
    };

    commissionLifeCycleTransition: (transitionId: string, commissionId: string) => AxiosRequestConfig = (
        transitionId,
        commissionId,
    ) => {
        return {
            url: `/commission/${commissionId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    getCommissionLifeCycleTransitions: (commissionId: string) => AxiosRequestConfig = (commissionId) => {
        return {
            url: `/commission/${commissionId}/transitions`,
            method: 'GET',
        };
    };

    commissionMembers: (commissionId: string) => AxiosRequestConfig = (commissionId) => {
        return {
            url: `/commission/${commissionId}/members`,
            method: 'GET',
        };
    };

    selectCommissionMembers: (commissionId: string) => AxiosRequestConfig = (commissionId) => {
        return {
            url: `/commission/${commissionId}/select/members`,
            method: 'GET',
        };
    };

    addCommissionMember: (commissionId: string, userId: string, isHead: boolean) => AxiosRequestConfig = (
        commissionId,
        userId,
        isHead,
    ) => ({
        url: `/commission/${commissionId}/member`,
        method: 'POST',
        params: {
            memberId: userId,
            isHead: isHead,
        },
    });

    deleteCommissionMember: (memberId: string) => AxiosRequestConfig = (memberId) => ({
        url: `/commission/member/${memberId}`,
        method: 'DELETE',
    });

    commissionListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/commissionXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    commissionSessionsSelectOptions: AxiosRequestConfig = {
        url: '/select/commission-sessions',
        method: 'GET',
    };

    commissionSessionSetting: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/commission-session/setting/${id}`,
            method: 'GET',
        };
    };

    commissionSessionProcess: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/commission-session/${id}`,
            method: 'GET',
        };
    };

    createCommissionSession: (data: NewCommissionSessionDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/commission-session',
            method: 'POST',
            data,
        };
    };

    commissionSessionListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/sessionXls',
            method: 'PUT',
            responseType: 'blob',
            headers: {
                'X-Timezone': getUTCTimezone(),
            },
            data: queryData,
        };
    };

    lifecyclesSelectOptions: AxiosRequestConfig = {
        url: '/lifecycles/commission-session',
        method: 'GET',
    };

    //</editor-fold>

    sessionList: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/commission-sessions',
            method: 'PUT',
            data,
        };
    };

    deleteCommissionSession: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/commission-session/${id}`,
            method: 'DELETE',
        };
    };

    saveCommissionSessionSettingForm: (
        id: string,
        commonFormSubmission: FullSubmission,
        rulesFormSubmission: FullSubmission,
    ) => AxiosRequestConfig = (id, commonFormSubmission, rulesFormSubmission) => {
        return {
            url: `/commission-session/setting/${id}`,
            method: 'PUT',
            data: {
                commonFormSubmission,
                rulesFormSubmission,
            },
        };
    };

    commissionSessionLifeCycleTransition: (transitionId: string, sessionId: string) => AxiosRequestConfig = (
        transitionId,
        sessionId,
    ) => {
        return {
            url: `/commission-session/${sessionId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    getCommissionSessionLifeCycleTransitions: (sessionId: string) => AxiosRequestConfig = (sessionId) => {
        return {
            url: `/commission-session/${sessionId}/transitions`,
            method: 'GET',
        };
    };

    sessionProcedureLifeCycleTransition: (transitionId: string, procedureId: string) => AxiosRequestConfig = (
        transitionId,
        procedureId,
    ) => {
        return {
            url: `/session-procedure/${procedureId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    getSessionProcedureLifeCycleTransitions: (procedureId: string) => AxiosRequestConfig = (procedureId) => {
        return {
            url: `/session-procedure/${procedureId}/transitions`,
            method: 'GET',
        };
    };

    getGeneralizationCategories: (sessionId: string, attributeId?: string) => AxiosRequestConfig = (
        sessionId,
        attributeId = '',
    ) => {
        return {
            url: `/commission-session/${sessionId}/components`,
            method: 'GET',
            params: attributeId
                ? {
                      attributeId,
                  }
                : undefined,
        };
    };

    getGeneralizationTableInfo: (sessionId: string) => AxiosRequestConfig = (sessionId) => {
        return {
            url: `/commission-session/${sessionId}/generalization`,
            method: 'GET',
        };
    };

    submitGeneralizationAttribute: (sessionId: string, data: GeneralizationCategoryInfoDTO) => AxiosRequestConfig = (
        sessionId,
        data,
    ) => {
        return {
            url: `/commission-session/${sessionId}/generalization`,
            method: 'POST',
            data,
        };
    };

    deleteGeneralizationAttribute: (attributeId: string, sessionId: string) => AxiosRequestConfig = (
        attributeId,
        sessionId,
    ) => {
        return {
            url: `/commission-session/${sessionId}/generalization/${attributeId}`,
            method: 'DELETE',
        };
    };

    editGeneralizationAttribute: (
        attributeId: string,
        sessionId: string,
        data: GeneralizationCategoryInfoDTO,
    ) => AxiosRequestConfig = (attributeId, sessionId, data) => {
        return {
            url: `/commission-session/${sessionId}/generalization/${attributeId}`,
            method: 'PUT',
            data,
        };
    };

    loadSessionProcedure: (procedureId: string) => AxiosRequestConfig = (procedureId) => {
        return {
            url: `/session-procedure/${procedureId}`,
            method: 'GET',
        };
    };

    createSessionProcedure: (sessionId: string) => AxiosRequestConfig = (sessionId) => {
        return {
            url: `/session-procedure/${sessionId}`,
            method: 'POST',
        };
    };

    deleteSessionProcedure: (procedureId: string) => AxiosRequestConfig = (procedureId) => {
        return {
            url: `/session-procedure/${procedureId}`,
            method: 'DELETE',
        };
    };

    sessionProceduresList: (procedureId: string) => AxiosRequestConfig = (procedureId) => {
        return {
            url: `/session-procedures/${procedureId}`,
            method: 'PUT',
        };
    };

    changeProcedurePosition: (procedureId: string, newPosition: number) => AxiosRequestConfig = (
        procedureId,
        newPosition,
    ) => {
        return {
            url: `/session-procedure/${procedureId}/position/${newPosition}`,
            method: 'PUT',
        };
    };

    saveSessionProcedureForm: (procedureId: string, procedureFormSubmission: FullSubmission) => AxiosRequestConfig = (
        procedureId,
        procedureFormSubmission,
    ) => {
        return {
            url: `/session-procedure/${procedureId}`,
            method: 'PUT',
            data: {
                formSubmission: procedureFormSubmission,
            },
        };
    };

    categoryFilterData: AxiosRequestConfig = {
        url: '/select/categories',
        method: 'GET',
    };

    stateFilterData: AxiosRequestConfig = {
        url: '/commission-sessions/filters',
        method: 'GET',
    };

    commissionFilterData: AxiosRequestConfig = {
        url: '/select/commissions',
        method: 'GET',
    };

    sessionProcedureGeneralizationAttributeListSelectData: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/procedure-attribute/${id}/select`,
            method: 'GET',
        };
    };

    sessionProcedureGeneralizationAttributeCreate: (
        id: string,
        data: SessionProcedureAttributesDTO,
    ) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/procedure-attribute/${id}`,
            method: 'POST',
            data,
        };
    };

    sessionProcedureGeneralizationAttributeDelete: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/procedure-attribute/${id}`,
            method: 'DELETE',
        };
    };

    sessionProcedureGeneralizationAttributeList: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/procedure-attributes/${id}`,
            method: 'PUT',
        };
    };

    sessionProcedureSubjectList: (sessionId: string, procedureId: string, data: TableQueryData) => AxiosRequestConfig =
        (sessionId, procedureId, data) => {
            return {
                url: `/commission-session/${sessionId}/procedure/${procedureId}`,
                method: 'PUT',
                data,
            };
        };

    sessionProcedureSubjectListSetting: (sessionId: string, procedureId: string) => AxiosRequestConfig = (
        sessionId,
        procedureId,
    ) => {
        return {
            url: `/commission-session/${sessionId}/procedure/${procedureId}/setting`,
            method: 'GET',
        };
    };

    sessionProcedureSubjectListXls: (
        queryData: TableQueryData,
        procedureId: string,
        sessionId: string,
    ) => AxiosRequestConfig = (queryData, procedureId, sessionId) => {
        return {
            url: `/commission-session/${sessionId}/procedure/${procedureId}/getXls`,
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    saveDecision: (id: string, submission: FullSubmission, type: string) => AxiosRequestConfig = (
        id,
        submission,
        type,
    ) => {
        return {
            url: `/decision/${type}/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    saveResultOfVoting: (id: string, data: ResultOfVotingDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/decision/common/${id}/result-of-voting`,
            method: 'PUT',
            data,
        };
    };

    saveSubjectComment: (id: string, data: SubjectCommentDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/decision/${id}/comment`,
            method: 'PUT',
            data,
        };
    };

    checkCastingVoteStatus: (procedureID: string) => AxiosRequestConfig = (procedureID) => {
        return {
            url: `/session-procedure/${procedureID}/checkCastingVoteStatus`,
            method: 'GET',
        };
    };

    getCompletedVotes: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/session-procedure/${id}/checkCompletedVotes`,
            method: 'GET',
        };
    };

    sendVote: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/session-procedure/${id}/changeSubjectDecisionState`,
            method: 'PUT',
        };
    };

    changeCommonDecisionState: (decisionId: string, transitionId: string) => AxiosRequestConfig = (
        decisionId,
        transitionId,
    ) => {
        return {
            url: `/decision/common/${decisionId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    commonDecisionTransition: (decisionId: string) => AxiosRequestConfig = (decisionId: string) => {
        return {
            url: `/decision/common/${decisionId}/transition`,
            method: 'GET',
        };
    };

    sessionProcedureSubjectPrivateDecisionList: (procedureId: string, subjectId: string) => AxiosRequestConfig = (
        procedureId: string,
        subjectId: string,
    ) => {
        return {
            url: `/session-procedure/${procedureId}/subject/${subjectId}/private-decisions`,
            method: 'GET',
        };
    };

    sessionProcedureSubjectCommentList: (procedureId: string, subjectId: string) => AxiosRequestConfig = (
        procedureId: string,
        subjectId: string,
    ) => {
        return {
            url: `/session-procedure/${procedureId}/subject/${subjectId}/comments`,
            method: 'GET',
        };
    };

    loadModulesList = (): AxiosRequestConfig => ({
        url: '/getRoutes',
        method: 'GET',
    });

    registryFetchData: <RowType extends TTableRow>(
        registryCode: string,
        queryData: TTableQueryData<RowType>,
        params?: Record<string, string>,
    ) => AxiosRequestConfig = (registryCode, queryData, params) => {
        return {
            url: `/registry/${registryCode}/table`,
            method: 'PUT',
            data: queryData,
            params,
        };
    };

    registryFetchColumns: <RowType extends TTableRow>(
        registryCode: string,
        params?: Record<string, string>,
    ) => AxiosRequestConfig = (registryCode, params) => {
        return {
            url: `/registry/${registryCode}/columns`,
            method: 'GET',
            params,
        };
    };

    registryDownload: <RowType extends TTableRow>(
        registryCode: string,
        queryData: TTableQueryData<RowType>,
        params?: Record<string, string>,
    ) => AxiosRequestConfig = (registryCode, queryData, params) => {
        return {
            url: `/registry/${registryCode}/export`,
            method: 'PUT',
            responseType: 'arraybuffer',
            data: queryData,
            params,
        };
    };

    listPreset: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: '/presets',
            method: 'GET',
            params: {
                registryCode,
            },
        };
    };

    savePreset: <RowType extends TTableRow>(preset: Preset<RowType>) => AxiosRequestConfig = (preset) => {
        return {
            url: '/preset',
            method: `PUT`,
            data: preset,
        };
    };

    deletePreset: (presetId: string) => AxiosRequestConfig = (presetId) => {
        return {
            url: `/preset/${presetId}`,
            method: 'DELETE',
        };
    };

    saveListPresets = <RowType extends TTableRow>(presets: Preset<RowType>[]): AxiosRequestConfig => ({
        url: '/presets',
        method: 'PUT',
        data: presets,
    });

    getLastPreset = (registryCode: string): AxiosRequestConfig => ({
        url: '/lastPreset',
        method: 'GET',
        params: {
            registryCode,
        },
    });

    saveLastPreset = (registryCode: string, presetId: string): AxiosRequestConfig => ({
        url: '/lastPreset',
        method: 'PUT',
        params: {
            registryCode,
            presetId,
        },
    });

    getPrintForms: (sessionId: string) => AxiosRequestConfig = (sessionId) => {
        return {
            url: `/commission-session/${sessionId}/templates`,
            method: 'GET',
        };
    };

    generatePrintForm: (sessionId: string, templateId: string) => AxiosRequestConfig = (sessionId, templateId) => {
        return {
            url: `/commission-session/${sessionId}/template/${templateId}`,
            method: 'PUT',
        };
    };

    downloadPrintForm: (sessionId: string, templateId: string) => AxiosRequestConfig = (sessionId, templateId) => {
        return {
            url: `/commission-session/${sessionId}/template/${templateId}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    getCurrentYear: AxiosRequestConfig = {
        url: '/getCurrentYear',
        method: 'GET',
    };
}

export const apiConfigs = new ApiConfigs();
