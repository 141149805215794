import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    ClearFilterButton,
    Select,
    TotObjectFilterPanelDetails,
    TotObjectFilterPanelSummary,
} from '../../../components';
import { AutocompleteProps, pickerProps, textFieldProps } from '../../../constants';
import { CommissionSessionListModel } from '../../../models';

export type CommissionSessionFilterPanelProps = {
    model: CommissionSessionListModel;
};

export const CommissionSessionFilterPanel = observer((props: CommissionSessionFilterPanelProps): JSX.Element => {
    const { model } = props;
    const { filtering } = model;
    const intl = useIntl();

    return (
        <ExpansionPanel>
            <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="common.filters" />
                </Typography>
            </TotObjectFilterPanelSummary>
            <TotObjectFilterPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="commissionSession.fields.title" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.title.value}
                                onChange={filtering.title.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="commissionSession.fields.number" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.identifier.value}
                                onChange={filtering.identifier.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.state.values}
                                label={<FormattedMessage id="commissionSession.fields.state" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.stateFilterData}
                                onChange={filtering.state.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.categoryIds.values}
                                label={<FormattedMessage id="commissionSession.fields.categories" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.categoryFilterData}
                                onChange={filtering.categoryIds.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.commissionIds.values}
                                label={<FormattedMessage id="commissionSession.fields.commission" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.commissionFilterData}
                                onChange={filtering.commissionIds.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                        <Grid item xs={4} container justify="space-between">
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    label={<FormattedMessage id="commissionSession.fields.startDate" />}
                                    placeholder={intl.formatMessage({ id: 'common.from' })}
                                    value={filtering.startDate.value.from}
                                    onChange={filtering.startDate.onFromChange}
                                    maxDate={filtering.startDate.value.to ?? undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={(): void => filtering.startDate.onFromChange(null)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    placeholder={intl.formatMessage({ id: 'common.until' })}
                                    value={filtering.startDate.value.to}
                                    onChange={filtering.startDate.onToChange}
                                    minDate={filtering.startDate.value.from ?? undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={(): void => filtering.startDate.onToChange(null)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={6} justify="flex-end">
                        <Grid item xs={4} container justify="space-between">
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    label={<FormattedMessage id="commissionSession.fields.endDate" />}
                                    placeholder={intl.formatMessage({ id: 'common.from' })}
                                    value={filtering.endDate.value.from}
                                    onChange={filtering.endDate.onFromChange}
                                    maxDate={filtering.endDate.value.to ?? undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={(): void => filtering.endDate.onFromChange(null)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    placeholder={intl.formatMessage({ id: 'common.until' })}
                                    value={filtering.endDate.value.to}
                                    onChange={filtering.endDate.onToChange}
                                    minDate={filtering.endDate.value.from ?? undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton clear={(): void => filtering.endDate.onToChange(null)} />
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectFilterPanelDetails>
        </ExpansionPanel>
    );
});
