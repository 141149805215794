import React from 'react';

export const FileDocumentBoxIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="currentColor">
        <g>
            <g>
                <path d="M3.997 13.999v-2h5v2zm0-4v-2h8v2zm0-4V4h8v2zm12-4v14c0 1.105-.895 2-2 2h-12a2 2 0 0 1-2-2V2c0-1.11.89-2 2-2h12c1.1 0 2 .89 2 2zm-14 14h12V2h-12z" />
            </g>
        </g>
    </svg>
);
