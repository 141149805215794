const homePage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        commissions: 'Комиссии',
        commissionSessions: 'Заседания',
        categories: 'Категории',
        subjects: 'Объекты',
        users: 'Пользователи',
        pfTemplates: 'Шаблоны ПФ',
    },
};

export default homePage;
