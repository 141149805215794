const commissionSession: Record<string, string | Record<string, string | Record<string, string>>> = {
    fields: {
        commission: 'Экспертная группа',
    },
    blocksTitle: {
        commissionInfo: 'Информация об экспертной группе',
    },
    generalizationTable: {
        subjectAttribute: 'Атрибут инициативы "{category}"',
    },
    sessionProcedureSubjectList: {
        showAllPrivateDecisions: 'Посмотреть решения всех экспертов',
        privateDecisionsTable: {
            member: 'ФИО эксперта',
        },
    },
};

export default commissionSession;
