import { Link, MenuItem } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

export type EditActionMenuItemProps = {
    path: string;
};

export const EditActionMenuItem = (props: EditActionMenuItemProps): JSX.Element => {
    const { path } = props;

    return (
        <MenuItem dense button={true}>
            <Link component={NavLink} underline="none" to={path} style={{ color: 'inherit' }}>
                <FormattedMessage id="common.edit" />
            </Link>
        </MenuItem>
    );
};
