import {
    Box,
    Button,
    Container,
    createStyles,
    Grid,
    LinearProgress,
    Link,
    makeStyles,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    ActionsMenu,
    AuthorizationCheck,
    AuthorizationCheckAll,
    DeleteActionMenuItem,
    EditActionMenuItem,
    ExportButton,
    TableHeadTypography,
    TotTablePagination,
} from '../../../components';
import { useFlag, useStore } from '../../../hooks';
import { CommissionSessionListModel } from '../../../models';
import { ClearFilterIcon } from '../../../resources';
import { CommissionSessionRow } from '../../../types';
import { CreateSessionDialog } from '../CreateSessionDialog';
import { CommissionSessionFilterPanel } from './CommissionSessionFilterPanel';

const useStyles = makeStyles(() =>
    createStyles({
        linkInline: {
            display: 'inline',
        },
    }),
);

export const CommissionSessionListPage = observer((): JSX.Element => {
    const [isModalOpen, openModal, closeModal] = useFlag();
    const { commissionSessionStore, api, notificationStore } = useStore();
    const { handleError } = notificationStore;
    const classes = useStyles();
    const intl = useIntl();

    const [model] = useState<CommissionSessionListModel>(
        () => new CommissionSessionListModel(api, commissionSessionStore),
    );
    const { sorting } = model;

    const deleteCommissionSession = (row: CommissionSessionRow): (() => Promise<void>) => {
        return (): Promise<void> => {
            return commissionSessionStore.deleteCommissionSession(row.id).then(model.reloadData).catch(handleError);
        };
    };

    const renderActionItems = (row: CommissionSessionRow): (() => ReactNode[]) => {
        return (): ReactNode[] => [
            <AuthorizationCheck
                key="edit"
                entityCode={entities.CommissionSession}
                entityId={row.id}
                permCode={permissions.CommissionSession.EditSetting}
            >
                <EditActionMenuItem
                    key="edit"
                    path={generatePath(clientRoute.commissionSessionProcess, { id: row.id })}
                />
            </AuthorizationCheck>,
            <AuthorizationCheck
                key="delete"
                entityCode={entities.CommissionSession}
                entityId={row.id}
                permCode={permissions.CommissionSession.Delete}
            >
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    message={intl.formatMessage(
                        { id: 'commissionSession.confirmDeletionInfoText' },
                        { title: row.title },
                    )}
                    onConfirm={deleteCommissionSession(row)}
                />
            </AuthorizationCheck>,
        ];
    };

    useEffect(() => {
        return model.dispose;
    }, [model]);

    return (
        <React.Fragment>
            <CreateSessionDialog setIsClosed={closeModal} isOpen={isModalOpen} />
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Box pb={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography className="t-registry-title" variant="h1">
                                    <FormattedMessage id="commissionSession.listTitle" />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <SvgIcon>
                                                <ClearFilterIcon />
                                            </SvgIcon>
                                        }
                                        onClick={model.clearFilters}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.CommissionSession.XlsComissionSessionList}
                                >
                                    <Grid item>
                                        <ExportButton
                                            queryData={model.queryData}
                                            loadRegisterList={commissionSessionStore.exportListXls}
                                        />
                                    </Grid>
                                </AuthorizationCheck>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.AddCommissionSession}
                                >
                                    <Grid item>
                                        <Button
                                            className="t-create-session"
                                            color="primary"
                                            variant="contained"
                                            onClick={openModal}
                                        >
                                            <FormattedMessage id="commissionSession.create" />
                                        </Button>
                                    </Grid>
                                </AuthorizationCheck>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box pb={10}>
                        <CommissionSessionFilterPanel model={model} />
                    </Box>

                    <TableContainer component={Paper}>
                        {model.isLoading && <LinearProgress />}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="t-number-col">
                                        <TableSortLabel
                                            active={sorting.identifier.isActive}
                                            direction={sorting.identifier.direction}
                                            onClick={model.changeSorting(sorting.identifier)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <TableHeadTypography>
                                                <FormattedMessage id="commissionSession.fields.number" />
                                            </TableHeadTypography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className="t-title-col">
                                        <TableSortLabel
                                            active={sorting.title.isActive}
                                            direction={sorting.title.direction}
                                            onClick={model.changeSorting(sorting.title)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <TableHeadTypography>
                                                <FormattedMessage id="commissionSession.fields.title" />
                                            </TableHeadTypography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className="t-category-col">
                                        <TableHeadTypography>
                                            <FormattedMessage id="commissionSession.fields.categories" />
                                        </TableHeadTypography>
                                    </TableCell>
                                    <TableCell className="t-commission-col">
                                        <TableHeadTypography>
                                            <FormattedMessage id="commissionSession.fields.commission" />
                                        </TableHeadTypography>
                                    </TableCell>
                                    <TableCell className="t-startDate-col">
                                        <TableSortLabel
                                            active={sorting.startDate.isActive}
                                            direction={sorting.startDate.direction}
                                            onClick={model.changeSorting(sorting.startDate)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <TableHeadTypography>
                                                <FormattedMessage id="commissionSession.fields.startDate" />
                                            </TableHeadTypography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className="t-endDate-col">
                                        <TableSortLabel
                                            active={sorting.endDate.isActive}
                                            direction={sorting.endDate.direction}
                                            onClick={model.changeSorting(sorting.endDate)}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <TableHeadTypography>
                                                <FormattedMessage id="commissionSession.fields.endDate" />
                                            </TableHeadTypography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className="t-state-col">
                                        <TableHeadTypography>
                                            <FormattedMessage id="commissionSession.fields.state" />
                                        </TableHeadTypography>
                                    </TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {model.rows.map((m) => {
                                    return (
                                        <TableRow key={m.id} hover>
                                            <TableCell className="t-number-val">
                                                {m.identifier && (
                                                    <Link
                                                        component={NavLink}
                                                        underline="always"
                                                        to={generatePath(clientRoute.commissionSessionProcess, {
                                                            id: m.id,
                                                        })}
                                                    >
                                                        {m.identifier}
                                                    </Link>
                                                )}
                                            </TableCell>
                                            <TableCell className="t-title-val">{m.title}</TableCell>
                                            <TableCell className="t-categories-val">
                                                {m.categories.map((c, index) => (
                                                    <React.Fragment>
                                                        {index !== 0 ? ', ' : undefined}
                                                        <Link
                                                            className={classes.linkInline}
                                                            key={c.id}
                                                            component={NavLink}
                                                            underline="always"
                                                            to={generatePath(clientRoute.category, {
                                                                id: c.id,
                                                            })}
                                                        >
                                                            {c.title}
                                                        </Link>
                                                    </React.Fragment>
                                                ))}
                                            </TableCell>
                                            <TableCell className="t-commission-val">
                                                {m.commission && (
                                                    <Link
                                                        component={NavLink}
                                                        underline="always"
                                                        to={generatePath(clientRoute.commission, {
                                                            id: m.commission.id,
                                                        })}
                                                    >
                                                        {m.commission.title}
                                                    </Link>
                                                )}
                                            </TableCell>
                                            <TableCell className="t-startDate-val">
                                                {m.startDate && (
                                                    <React.Fragment>
                                                        <FormattedDate value={m.startDate} />{' '}
                                                        <FormattedTime value={m.startDate} />
                                                    </React.Fragment>
                                                )}
                                            </TableCell>
                                            <TableCell className="t-endDate-val">
                                                {m.endDate && (
                                                    <React.Fragment>
                                                        <FormattedDate value={m.endDate} />{' '}
                                                        <FormattedTime value={m.endDate} />
                                                    </React.Fragment>
                                                )}
                                            </TableCell>
                                            <TableCell className="t-state-val">{m.state}</TableCell>

                                            <TableCell align="right">
                                                <AuthorizationCheckAll
                                                    or={true}
                                                    queries={[
                                                        {
                                                            entityCode: entities.CommissionSession,
                                                            entityId: m.id,
                                                            permCode: permissions.CommissionSession.EditSetting,
                                                        },
                                                        {
                                                            entityCode: entities.CommissionSession,
                                                            entityId: m.id,
                                                            permCode: permissions.CommissionSession.Delete,
                                                        },
                                                    ]}
                                                >
                                                    <ActionsMenu renderActionItems={renderActionItems} row={m} />
                                                </AuthorizationCheckAll>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={100}>
                                        <TotTablePagination
                                            count={model.rowsCount}
                                            page={model.pageNumber}
                                            onChangePage={model.onChangePage}
                                            onChangeRowsPerPage={model.onChangePageSize}
                                            rowsPerPage={model.pageSize}
                                            rowsPerPageOptions={model.pageSizeOptions}
                                            pagingInfoMessageId="commissionSession.pagingInfo"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </Container>
        </React.Fragment>
    );
});
