import { Box, Button, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteField } from '../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../hooks';
import { AddCommissionField, AddCommissionMember, CodeTitle, ModalProps } from '../../types';

export type AddCommissionMemberDialogProps = ModalProps & {
    isHeadModal: boolean;
    commissionId: string;
    onSuccessSubmit: () => void;
};

const userClasses = { popper: 't-users-field-options' };

const initialValues: AddCommissionMember = {
    user: null,
};

export const AddCommissionMemberDialog = observer((props: AddCommissionMemberDialogProps): JSX.Element => {
    const { commissionId, isOpen, isHeadModal, setIsClosed, onSuccessSubmit } = props;
    const { commissionStore } = useStore();
    const { loadSelectCommissionMembers, addMember } = commissionStore;
    const [members, setMembers] = useState<CodeTitle[]>([]);
    const intl = useIntl();

    useEffect(() => {
        loadSelectCommissionMembers(commissionId).then(setMembers);
    }, [commissionId]);

    const { Yup } = useYup();
    const schema = Yup.object({
        user: Yup.object().nullable().required(),
    });

    const handleSave = (values: AddCommissionMember): Promise<void> => {
        const userId = values.user?.code || '';
        return addMember(commissionId, userId, isHeadModal).then(() => {
            setIsClosed();
            onSuccessSubmit();
        });
    };

    const [isSending, endIcon, saveHandler] = useAntiDoubleClick(handleSave);

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage
                    id={isHeadModal ? 'commission.members.addHeadMember' : 'commission.members.addMember'}
                />
            </DialogTitle>

            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={saveHandler}>
                <Form>
                    <DialogContent>
                        <Box pb={3}>
                            <AutocompleteField
                                classes={userClasses}
                                className="t-users-field"
                                fieldName={AddCommissionField.user}
                                label={intl.formatMessage({ id: 'common.user' })}
                                options={members}
                                required={true}
                            />
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={setIsClosed}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            className="t-submitMember-btn"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
