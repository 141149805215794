import { Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import { Pagination, PaginationItem, PaginationItemProps, PaginationProps } from '@material-ui/lab';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ReversedKeyboardTabIcon = styled(KeyboardTabIcon)`
    transform: rotate(180deg);
`;

const StyledIconButton = withTheme(
    styled(IconButton)`
        &:hover {
            color: ${({ theme }): string => theme.variables.palette.main};
            background-color: ${({ theme }): string => theme.variables.palette.hoverInLists};
        }
    `,
);

const StyledPaginationItem = withTheme(styled(PaginationItem)`
    &:hover {
        background-color: ${({ theme }): string => theme.variables.palette.hoverInLists};
    }
`);

export type TotTablePaginationProps = PaginationProps & {
    onChangePage: (event: unknown, page: number) => void;
    onChangeRowsPerPage: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    rowsPerPageOptions: number[];
    rowsPerPage: number; // размер страницы
    page: number; // номер страницы
    count: number; // Число строк
    pagingInfoMessageId: string;
};

export const TotTablePagination = (props: TotTablePaginationProps): JSX.Element => {
    const { rowsPerPage, count, page, rowsPerPageOptions, onChangePage, onChangeRowsPerPage, pagingInfoMessageId } =
        props;

    const pagesCount = Math.ceil(count / rowsPerPage);
    const from = count ? page * rowsPerPage + 1 : 0;
    let to = (page + 1) * rowsPerPage;

    if ((page + 1) * rowsPerPage > count) {
        to = count;
    }

    const renderPaginationItem = (item: PaginationItemProps): JSX.Element => {
        const { disabled, onClick, type } = item;

        switch (type) {
            case 'first':
                return (
                    <StyledIconButton disabled={disabled} onClick={onClick}>
                        <ReversedKeyboardTabIcon />
                    </StyledIconButton>
                );
            case 'last':
                return (
                    <StyledIconButton disabled={disabled} onClick={onClick}>
                        <KeyboardTabIcon />
                    </StyledIconButton>
                );
            default:
                return <StyledPaginationItem {...item} />;
        }
    };

    return (
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography>
                            <FormattedMessage id="common.rowsPerPage" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="filled-select-currency"
                            select
                            size="small"
                            value={rowsPerPage}
                            onChange={onChangeRowsPerPage}
                            variant="outlined"
                        >
                            {rowsPerPageOptions &&
                                rowsPerPageOptions.map((option: number) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Pagination
                    count={pagesCount}
                    page={page + 1}
                    onChange={onChangePage}
                    color="primary"
                    showFirstButton
                    showLastButton
                    renderItem={renderPaginationItem}
                />
            </Grid>
            <Grid item>
                <Typography>
                    <FormattedMessage id={pagingInfoMessageId} values={{ count, from, to }} />
                </Typography>
            </Grid>
        </Grid>
    );
};
