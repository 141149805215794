import { FormModel } from '@platform/formiojs-react';
import { action, observable } from 'mobx';
import { CommissionSessionStore } from '../../stores';
import { CommissionSessionSettingDTO } from '../../types';
import { DateUtils } from '../../utils';
import { MetaInfoModel } from '../MetaInfo';
import { CommissionSessionModel } from './CommissionSessionModel';
import { GeneralizationTableModel } from './generalization-table';
import { SessionProcedureAttributeTableModel, SessionProcedureModel } from './session-procedure';

export class CommissionSessionSettingModel implements CommissionSessionModel {
    @observable id: string;
    @observable identifier = '';
    @observable title = '';
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();
    @observable startDate?: Date;
    @observable endDate?: Date;
    @observable commonFormInfo: FormModel;
    @observable rulesFormInfo: FormModel;
    @observable commonFormId = '';
    @observable rulesFormId = '';

    @observable commissionSessionStore: CommissionSessionStore;

    @observable sessionProcedureModel: SessionProcedureModel;
    @observable generalizationTableModel: GeneralizationTableModel;
    @observable procedureAttributeTableModel: SessionProcedureAttributeTableModel;

    constructor(id: string, commissionSessionStore: CommissionSessionStore) {
        this.id = id;
        this.commonFormInfo = new FormModel(id);
        this.rulesFormInfo = new FormModel(id);

        this.commissionSessionStore = commissionSessionStore;

        this.sessionProcedureModel = new SessionProcedureModel(commissionSessionStore, id);
        this.procedureAttributeTableModel = new SessionProcedureAttributeTableModel();

        this.generalizationTableModel = new GeneralizationTableModel(
            this.procedureAttributeTableModel,
            this.sessionProcedureModel.activeProcedureId,
        );
    }

    @action.bound
    load(dto: CommissionSessionSettingDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.commonFormInfo.load(dto.commonFormInfo);
        this.rulesFormInfo.load(dto.rulesFormInfo);
        this.metaInfo.load(dto.metaInfo);
        this.commonFormId = dto.commonFormId;
        this.rulesFormId = dto.rulesFormId;

        const startDate = new Date(dto.startDate || '');
        if (DateUtils.isValidDate(startDate)) {
            this.startDate = startDate;
        }

        const endDate = new Date(dto.endDate || '');
        if (DateUtils.isValidDate(endDate)) {
            this.endDate = endDate;
        }
    }
}
