const subject: Record<string, string | Record<string, string>> = {
    subjectListTitle: 'Объекты',
    createSubject: 'Создать объект',
    newSubject: 'Новый объект',
    commonFiles: 'Документы по {title}',
    hiddenFiles: 'Закрытые документы по {title}',
    editSession: 'Изменить заседание',
    fields: {
        title: 'Наименование',
        identifier: 'Номер',
        commissionSessions: 'Заседание',
        created: 'Дата создания',
        state: 'Состояние',
        author: 'Автор',
        session: 'Заседание',
        lastModified: 'Последнее изменение',
        category: 'Категория',
        process: 'ЖЦ обьекта',
        form: 'Форма объекта',
    },
    actions: {
        deleteSubject: 'Удалить объект',
        editSubject: 'Редактировать объект',
    },
    registryPagingInfo:
        'Всего {count, number} объект{count, plural, one {} few {а} other {ов}}. ' +
        'Отображены c {from, number} по {to, number}',
    blocks: {
        serviceInfo: 'Служебная информация',
    },
    confirmDeletionInfoText: 'Вы действительно хотите удалить объект экспертизы "{title}"?',
};

export default subject;
