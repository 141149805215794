import { Button, Container, ExpansionPanelDetails, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { permissionsConfig } from '../../../../authSchemeConfig';
import {
    AuthorizationCheckAll,
    StickyHeader,
    TotHeaderPanel,
    TotHeaderPanelSummary,
    TotObjectHeader,
} from '../../../../components';
import { useToggle } from '../../../../hooks';
import { SubjectModel } from '../../../../models';
import { RouteParamsDefault } from '../../../../types';
import { SubjectControlPanel } from './SubjectControlPanel';
import { SubjectDescription } from './SubjectDescription';

export type SubjectHeaderProps = {
    subjectModel: SubjectModel;
    setModalIsOpen: () => void;
    onSubmit: () => void;
    onLifeCycleTransition(transitionId: string, requestId: string, validate?: boolean): Promise<void>;
};

export const SubjectHeader = observer((props: SubjectHeaderProps): JSX.Element => {
    const { subjectModel, setModalIsOpen, onSubmit, onLifeCycleTransition } = props;
    const [isOpen, handleToggle] = useToggle();
    const { id } = useParams<RouteParamsDefault>();

    return (
        <StickyHeader>
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <Typography variant="h1">{subjectModel.title}</Typography>
                        </Grid>
                        <Grid item>
                            <TotHeaderPanel expanded={isOpen}>
                                <TotHeaderPanelSummary>
                                    <Grid container direction="row" spacing={5} alignItems="center">
                                        <Grid item>
                                            <Button
                                                onClick={handleToggle}
                                                startIcon={isOpen ? <ExpandMoreIcon /> : <NavigateNextIcon />}
                                            >
                                                <FormattedMessage id="common.description" />
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <AuthorizationCheckAll
                                                or={true}
                                                queries={[
                                                    permissionsConfig.deleteSubject(id),
                                                    permissionsConfig.editSubject(id),
                                                ]}
                                            >
                                                <SubjectControlPanel
                                                    subjectModel={subjectModel}
                                                    onSubmit={onSubmit}
                                                    onLifeCycleTransition={onLifeCycleTransition}
                                                />
                                            </AuthorizationCheckAll>
                                        </Grid>
                                    </Grid>
                                </TotHeaderPanelSummary>
                                <ExpansionPanelDetails>
                                    <SubjectDescription setModalIsOpen={setModalIsOpen} subjectModel={subjectModel} />
                                </ExpansionPanelDetails>
                            </TotHeaderPanel>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectHeader>
        </StickyHeader>
    );
});
