import { observable } from 'mobx';
import { CommissionSessionStore } from '../../../../stores';
import { SessionProcedureSubjectCommentListModel } from './SessionProcedureSubjectCommentListModel';
import { SessionProcedureSubjectCommentDialogModel } from './SessionProcedureSubjectCommentDialogModel';

export class SessionProcedureSubjectCommentCellModel {
    @observable commentDialogModel?: SessionProcedureSubjectCommentDialogModel;
    @observable commentListModel: SessionProcedureSubjectCommentListModel;

    constructor(
        commissionSessionStore: CommissionSessionStore,
        procedureId: string,
        subjectId: string,
        decisionId?: string,
        comment?: string,
    ) {
        if (decisionId) {
            this.commentDialogModel = new SessionProcedureSubjectCommentDialogModel(
                commissionSessionStore,
                decisionId,
                comment,
            );
        }

        this.commentListModel = new SessionProcedureSubjectCommentListModel(
            commissionSessionStore,
            procedureId,
            subjectId,
        );
    }
}
