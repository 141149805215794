import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, useHistory } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import {
    ActionsMenu,
    AuthorizationCheck,
    DeleteActionMenuItem,
    EditActionMenuItem,
    ExportButton,
    TableHeadTypography,
    TotTablePagination,
} from '../../components';
import { useFlag, useStore } from '../../hooks';
import { CategoriesOfSubjectsListModel, CategoriesOfSubjectsRowDTO } from '../../models';
import { ClearFilterIcon } from '../../resources';
import { CategoriesOfSubjectsListFilterPanel } from './CategoriesOfSubjectsListFilterPanel';

export const CategoriesOfSubjectsListPage = observer((): JSX.Element => {
    const [isLoading, enableLoading, disableLoading] = useFlag();
    const { categoriesOfSubjectsStore, api, history: rootHistory, catalogStore } = useStore();
    const history = useHistory();
    const intl = useIntl();

    const [model] = useState<CategoriesOfSubjectsListModel>(() => new CategoriesOfSubjectsListModel(api, catalogStore));
    const { sorting } = model;

    const createCategoryOfSubjects = async (): Promise<void> => {
        try {
            enableLoading();
            const id = await categoriesOfSubjectsStore.createCategoryOfSubjects();
            history.push(generatePath(clientRoute.categoryCreate, { id }));
        } catch (error) {
            disableLoading();
        }
    };

    const deleteCategoryOfSubjects = (categoryRow: CategoriesOfSubjectsRowDTO): (() => Promise<void>) => {
        return (): Promise<void> => {
            return categoriesOfSubjectsStore.deleteCategoryOfSubjects(categoryRow.id).then(model.reloadData);
        };
    };

    const onNotAllowed = (): void => {
        rootHistory.replace(clientRoute.notAllowed);
    };

    const renderActionItems = (categoryRow: CategoriesOfSubjectsRowDTO): (() => ReactNode[]) => {
        return (): ReactNode[] => [
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                message={intl.formatMessage({ id: 'category.confirmDeletionInfoText' }, { title: categoryRow.title })}
                onConfirm={deleteCategoryOfSubjects(categoryRow)}
            />,
            <EditActionMenuItem key="edit" path={generatePath(clientRoute.categoryEdit, { id: categoryRow.id })} />,
        ];
    };

    useEffect(() => {
        return model.dispose;
    }, [model]);

    return (
        <AuthorizationCheck
            entityCode={entities.System}
            permCode={permissions.System.Administration}
            onNotAllowed={onNotAllowed}
        >
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="category.categoryListTitle" />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <SvgIcon>
                                                <ClearFilterIcon />
                                            </SvgIcon>
                                        }
                                        onClick={model.clearFilters}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <ExportButton
                                        queryData={model.queryData}
                                        loadRegisterList={categoriesOfSubjectsStore.exportListXls}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={isLoading}
                                        color="primary"
                                        variant="contained"
                                        className="t-createCategory-btn"
                                        onClick={createCategoryOfSubjects}
                                    >
                                        <FormattedMessage id="category.createCategory" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CategoriesOfSubjectsListFilterPanel model={model} />
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {model.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '25%' }}>
                                                <TableSortLabel
                                                    active={sorting.title.isActive}
                                                    direction={sorting.title.direction}
                                                    onClick={model.changeSorting(sorting.title)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <TableHeadTypography>
                                                        <FormattedMessage id="category.fields.title" />
                                                    </TableHeadTypography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell style={{ width: '25%' }}>
                                                <TableHeadTypography>
                                                    <FormattedMessage id="category.fields.process" />
                                                </TableHeadTypography>
                                            </TableCell>
                                            <TableCell style={{ width: '25%' }}>
                                                <TableHeadTypography>
                                                    <FormattedMessage id="category.fields.form" />
                                                </TableHeadTypography>
                                            </TableCell>
                                            <TableCell style={{ width: '25%' }}>
                                                <TableSortLabel
                                                    active={sorting.created.isActive}
                                                    direction={sorting.created.direction}
                                                    onClick={model.changeSorting(sorting.created)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <TableHeadTypography>
                                                        <FormattedMessage id="category.fields.created" />
                                                    </TableHeadTypography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {model.rows.map((c) => {
                                            return (
                                                <TableRow key={c.id} hover>
                                                    <TableCell>
                                                        <Link
                                                            component={NavLink}
                                                            underline="always"
                                                            to={generatePath(clientRoute.category, { id: c.id })}
                                                        >
                                                            {c.title}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>{c.processName}</TableCell>
                                                    <TableCell>{c.formName}</TableCell>
                                                    <TableCell>
                                                        <FormattedDate value={c.created} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <ActionsMenu renderActionItems={renderActionItems} row={c} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={100}>
                                                <TotTablePagination
                                                    count={model.rowsCount}
                                                    page={model.pageNumber}
                                                    onChangePage={model.onChangePage}
                                                    onChangeRowsPerPage={model.onChangePageSize}
                                                    rowsPerPage={model.pageSize}
                                                    rowsPerPageOptions={model.pageSizeOptions}
                                                    pagingInfoMessageId="category.pagingInfo"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </AuthorizationCheck>
    );
});
