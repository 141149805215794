import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { WebForm } from '@platform/formio/WebForm';
import {
    FormApi,
    FormioSidebarStore,
    FormModel,
    FormView,
    MultiLangFormEdit,
    OnFormReady,
    validateEditPage,
} from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useFormApi, useStore } from '../../hooks';
import { AuthorizationCheckQuery, RouteParamsDefault } from '../../types';
import { AuthorizationCheck } from '../AuthorizationCheck';
import { AlertLink } from '../links';

export type TotObjectFormProps = {
    formName: string;
    model: FormModel;
    formioSidebarStore?: FormioSidebarStore;

    editPath: string;
    readPath: string;
    createPath: string;
    editAuthProps: AuthorizationCheckQuery;

    showReadonlyValidation: boolean;

    onFormReady: OnFormReady;
    hideReadonlyValidation: () => void;

    reloadKey: number;
};

export const TotObjectForm = observer((props: TotObjectFormProps): JSX.Element => {
    const {
        formioSidebarStore,
        formName,
        showReadonlyValidation,
        editPath,
        createPath,
        readPath,
        reloadKey,
        editAuthProps,
        model,
        hideReadonlyValidation,
        onFormReady,
    } = props;
    const [formApi, setFormApi] = useFormApi();
    const { intlStore } = useStore();
    const history = useHistory();
    const { id } = useParams<RouteParamsDefault>();
    const intl = useIntl();
    const { locale } = intl;

    const validateAfterReadonly = () => {
        // Этот метод вызывается, когда после валидации в текстовом виде переходишь в режим редактирования.
        // Это улучшение по сравнению с демо стендом, т.к. в редактирование в данном случае переходят как раз для того,
        // чтобы исправить ошибки
        if (showReadonlyValidation) {
            validateEditPage(formApi, formioSidebarStore, formName).finally(() => {
                hideReadonlyValidation();
            });
        }
    };

    const handleFormReady = (form: FormApi): void => {
        setFormApi(form);
        formioSidebarStore && formioSidebarStore.initSidebarItems(formName, form.form);
        validateAfterReadonly();
        onFormReady(form);
    };

    const onFormChange = (form: WebForm): void => {
        formioSidebarStore && formioSidebarStore.updateItemsVisibility(formName, form);
    };

    const goToEditPageAndValidate = (): void => {
        history.push(generatePath(editPath, { id }));
    };

    const renderLink = (...chunks: string[]): JSX.Element => {
        return (
            <AlertLink onClick={goToEditPageAndValidate} href="#" style={{ display: 'inline' }}>
                {chunks}
            </AlertLink>
        );
    };

    const renderAlert = (): JSX.Element => {
        return (
            <Box mb={5}>
                <Alert elevation={0} variant="filled" severity="error">
                    <FormattedMessage
                        id="common.readonlyValidationMsg"
                        values={{
                            a: renderLink,
                        }}
                    />
                </Alert>
            </Box>
        );
    };

    return (
        <Switch>
            <Route path={[editPath, createPath]} exact>
                <AuthorizationCheck {...editAuthProps} isWithRedirect={true}>
                    <MultiLangFormEdit
                        intlStore={intlStore}
                        form={model}
                        onFormReady={handleFormReady}
                        onFormChange={onFormChange}
                    />
                </AuthorizationCheck>
            </Route>
            <Route path={readPath} key={`${readPath}_${reloadKey}`}>
                {showReadonlyValidation && renderAlert()}
                <FormView form={model} onFormReady={handleFormReady} multiLang={true} locale={locale} />
            </Route>
        </Switch>
    );
});
