import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TotObjectHeaderLabel, TotObjectHeaderValue } from '../../../components';
import { CategoryOfSubjectsModel } from '../../../models';
import { getOrElse } from '../../../utils';

export type CategoriesOfSubjectsDescriptionProps = {
    categoryOfSubjectsModel: CategoryOfSubjectsModel;
};

export const CategoriesOfSubjectsDescription = observer((props: CategoriesOfSubjectsDescriptionProps): JSX.Element => {
    const { categoryOfSubjectsModel } = props;

    return (
        <Grid container spacing={6} direction="row">
            <Grid item>
                <Grid container direction="row" alignItems="baseline" spacing={2}>
                    <Grid item>
                        <TotObjectHeaderLabel>
                            <FormattedMessage id="category.fields.created" />
                        </TotObjectHeaderLabel>
                    </Grid>
                    <Grid item>
                        <TotObjectHeaderValue>
                            {getOrElse(
                                categoryOfSubjectsModel.metaInfo.created,
                                <FormattedDate value={categoryOfSubjectsModel.metaInfo.created} />,
                                '-',
                            )}
                        </TotObjectHeaderValue>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" alignItems="baseline" spacing={2}>
                    <Grid item>
                        <TotObjectHeaderLabel>
                            <FormattedMessage id="category.fields.lastModified" />
                        </TotObjectHeaderLabel>
                    </Grid>
                    <Grid item>
                        <TotObjectHeaderValue>
                            {getOrElse(
                                categoryOfSubjectsModel.metaInfo.modified,
                                <FormattedDate value={categoryOfSubjectsModel.metaInfo.modified} />,
                                '-',
                            )}
                        </TotObjectHeaderValue>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
