import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../types';
import fonts from './fonts';

type ColorName =
    | 'black'
    | 'white'
    | 'yellow'
    | 'yellowDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'red'
    | 'blue'
    | 'brightBlue'
    | 'darkBlue'
    | 'lightBlue'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'disabled'
    | 'green';

const expertrepColors: Record<ColorName, string> = {
    black: '#000000',
    white: '#ffffff',
    yellow: '#ffff33',
    yellowDark: '#f5c722',
    greyMain: '#314349',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    red: '#f04438',
    blue: '#0096df',
    brightBlue: 'rgba(0, 160, 224, .8)',
    darkBlue: '#0578b0',
    lightBlue: '#E5F5FC',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',
    disabled: 'rgba(0, 0, 0, 0.12)',
    green: '#03cea4',
};

const expertrepFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
        fontWeight: 400,
    },
};

const expertrepIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

const accentBackgroundGradient = `linear-gradient(-30deg, ${expertrepColors.darkBlue} 0%, ${expertrepColors.darkBlue} 100%)`;

export const expertrepPalette: AppPalette = {
    main: expertrepColors.brightBlue,
    textMain: expertrepColors.greyMain,
    mainMiddleLight: expertrepColors.greyMiddleLight,
    mainLight: expertrepColors.brightBlue,
    mainDark: expertrepColors.greyDark,
    mainContrast: expertrepColors.white,
    mainContrastDarker: expertrepColors.greyLighter,
    accent: expertrepColors.brightBlue,
    accentDark: expertrepColors.brightBlue,
    accentContrast: expertrepColors.black,
    accentBackgroundGradient: accentBackgroundGradient,
    accentContrastBackgroundGradient: expertrepColors.black,
    hover: expertrepColors.darkBlue,
    hoverInLists: expertrepColors.greyWhite,
    green: expertrepColors.green,
    red: expertrepColors.red,
    table: {
        row: {
            even: expertrepColors.white,
            odd: expertrepColors.greyLighter,
            checked: expertrepColors.darkBlue,
            selected: expertrepColors.lighterBlue,
        },
    },
    card: {
        header: {
            main: expertrepColors.lighterGrey,
        },
    },
    button: {
        accentContrast: expertrepColors.white,
        disabled: expertrepColors.disabled,
    },
    iconButton: {
        color: expertrepColors.greyMain,
        hoverColor: expertrepColors.white,
        hoverBackgroundColor: expertrepColors.greyMain,
    },
    appBar: {
        mainContrast: expertrepColors.lighterBlue,
    },
    background: {
        textMain: expertrepColors.black,
    },
    panel: {
        markerBackgroundColor: expertrepColors.lightBlue,
        markerColor: expertrepColors.black,
    },
};

export default {
    fonts: expertrepFonts,
    palette: expertrepPalette,
    icons: expertrepIcons,
} as ThemeOverrides;
