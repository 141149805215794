import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { CommissionSessionListPage } from './commission-session-list';
import { CommissionSessionProcessPage } from './CommissionSessionProcessPage';
import { CommissionSessionSettingPage } from './CommissionSessionSettingPage';

export const CommissionSessionPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route path={clientRoute.commissionSessionSetting}>
                    <CommissionSessionSettingPage />
                </Route>
                <Route path={clientRoute.commissionSessionProcess}>
                    <CommissionSessionProcessPage />
                </Route>
                <Route>
                    <CommissionSessionListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
