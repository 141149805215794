import { observer } from 'mobx-react';
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ProcedureSubjectDecision } from '../../../../../../types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { getOrElse } from '../../../../../../utils';

export type PrivateDecisionsTableProps = {
    decisions: ProcedureSubjectDecision[];
};

export const PrivateDecisionsTable = observer((props: PrivateDecisionsTableProps): JSX.Element => {
    const { decisions } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <FormattedMessage id="commissionSession.sessionProcedureSubjectList.privateDecisionsTable.number" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="commissionSession.sessionProcedureSubjectList.privateDecisionsTable.member" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="commissionSession.sessionProcedureSubjectList.privateDecisionsTable.mark" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="commissionSession.sessionProcedureSubjectList.privateDecisionsTable.date" />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {decisions.map((decision, index) => {
                    const { mark, voted } = decision;
                    return (
                        <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{decision.memberFIO}</TableCell>
                            <TableCell>
                                {getOrElse(
                                    mark,
                                    mark,
                                    <FormattedMessage id="commissionSession.sessionProcedureSubjectList.privateDecisionsTable.noMark" />,
                                )}
                            </TableCell>
                            <TableCell>{getOrElse(voted, <FormattedDate value={voted} />, '')}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
});
