import { DateRangeFilter, InSetFilter, LikeFilter, Sort } from '../models';
import { FormDTO } from './formio';
import { MetaInfoDTO } from './metaInfo';
import { UserPersonDTO } from './userPerson';
import { CodeTitleNull, IdTitle, IdTitleNull } from './withTitle';

export type CommissionSessionFilters = {
    title: LikeFilter;
    identifier: LikeFilter;
    startDate: DateRangeFilter;
    endDate: DateRangeFilter;
    categoryIds: InSetFilter;
    commissionIds: InSetFilter;
    state: InSetFilter;
};

export type CommissionSessionSort = {
    title: Sort;
    identifier: Sort;
    startDate: Sort;
    endDate: Sort;
};

export type CommissionSessionProcessDTO = {
    id: string;
    title: string;
    identifier: string;
    startDate?: string; // date
    endDate?: string; //date
    metaInfo: MetaInfoDTO;
    commissionMembers: UserPersonDTO[];
    activeProcedureTitle: string;
};

export type CommissionSessionSettingDTO = {
    id: string;
    title: string;
    identifier: string;
    startDate?: string; // date
    endDate?: string; //date
    commonFormInfo: FormDTO;
    metaInfo: MetaInfoDTO;
    rulesFormInfo: FormDTO;
    commonFormId: string;
    rulesFormId: string;
};

export interface CommissionSessionRowDTO {
    id: string;
    identifier: string;
    title: string;
    commission?: IdTitle;
    categories: IdTitle[];
    startDate?: string; // date
    endDate?: string; //date
    state: string;
}

export interface CommissionSessionRow {
    id: string;
    title: string;
    identifier: string;
    commission?: IdTitle;
    categories: IdTitle[];
    startDate?: Date; // date
    endDate?: Date; //date
    state: string;
}

export type NewCommissionSessionDTO = {
    commonFormCode: string;
    processCode: string;
};

export type NewCommissionSession = {
    commonForm: IdTitleNull;
    process: IdTitleNull;
};

export type CompleteVotesDTO = {
    completeVote: number;
    allVote: number;
};

export type AddCommissionMember = {
    user: CodeTitleNull;
};

export enum CreateCommissionField {
    commonForm = 'commonForm',
    process = 'process',
}

export enum AddCommissionField {
    user = 'user',
}
