/**
 * Функция, возвращающая текст с годами работы компании и копирайтом (используется в компоненте футера)
 */
export const getCopyrightWithYears = (startYear?: number, currentYear?: number, copyright?: string): string => {
    const result: string[] = [];
    const isAtLeastOneYearIsFalsy = !(startYear && currentYear);
    const areBothYearsSame = startYear === currentYear;
    let yearsString = '';
    if (!isAtLeastOneYearIsFalsy && areBothYearsSame) {
        yearsString = String(currentYear);
    }
    if (!isAtLeastOneYearIsFalsy && !areBothYearsSame) {
        yearsString = `${startYear}-${currentYear}`;
    }
    yearsString && result.push(yearsString);
    copyright && result.push(copyright);
    return result.join(' ');
};
