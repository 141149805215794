import { DateRangeFilter, InSetFilter, LikeFilter, Sort } from '../models';
import { FormDTO } from './formio';
import { MetaInfoDTO } from './metaInfo';
import { IdTitle, IdTitleNull } from './withTitle';

export type SubjectDTO = {
    id: string;
    title: string;
    identifier: string;
    category: IdTitle;
    session: IdTitle;
    metaInfo: MetaInfoDTO;
    formInfo: FormDTO;
};

export type SubjectRowDTO = {
    id: string;
    identifier: string;
    title: string;
    processTitle: string;
    formTitle: string;
    created: string; //date
    state: string;
    session: IdTitle;
    category: IdTitle;
};

export type SubjectRow = {
    id: string;
    identifier: string;
    title: string;
    processTitle: string;
    formTitle: string;
    created?: Date; //date
    state: string;
    session: IdTitle;
    category: IdTitle;
};

export type SubjectsFilter = {
    identifier: LikeFilter;
    title: LikeFilter;
    processCode: InSetFilter;
    formCode: InSetFilter;
    created: DateRangeFilter;
    categoryIds: InSetFilter;
    commissionSessions: InSetFilter;
    state: InSetFilter;
};

export type SubjectsSort = {
    identifier: Sort;
    title: Sort;
    created: Sort;
    category: Sort;
    expertise: Sort;
};

export type NewSubjectDTO = {
    categoryId: string;
    sessionId?: string;
};

export type NewSubject = {
    category: IdTitleNull;
    session: IdTitleNull;
};

export type EditSubject = {
    session: IdTitleNull;
};

export enum CreateSubjectField {
    category = 'category',
    session = 'session',
}

export enum EditSubjectField {
    session = 'session',
}
