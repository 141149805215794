import { action, observable } from 'mobx';
import { apiConfigs } from '../../apiConfigs';
import { Api, CommissionSessionStore } from '../../stores';
import {
    CommissionSessionFilters,
    CommissionSessionRow,
    CommissionSessionRowDTO,
    CommissionSessionSort,
    GroupedIdTitle,
    IdTitle,
    RowsData,
    TableQueryData,
} from '../../types';
import { DateUtils } from '../../utils';
import { DateRangeFilter, InSetFilter, LikeFilter, Sort, TableModel } from '../list';

export class CommissionSessionListModel extends TableModel<
    CommissionSessionRow,
    CommissionSessionFilters,
    CommissionSessionSort
> {
    @observable api: Api;
    @observable commissionSessionStore: CommissionSessionStore;

    @observable stateFilterData: GroupedIdTitle;
    @observable categoryFilterData: IdTitle[];
    @observable commissionFilterData: IdTitle[];

    constructor(api: Api, commissionSessionStore: CommissionSessionStore) {
        const filters = {
            title: new LikeFilter(),
            identifier: new LikeFilter(),
            startDate: new DateRangeFilter(),
            endDate: new DateRangeFilter(),
            categoryIds: new InSetFilter(),
            commissionIds: new InSetFilter(),
            state: new InSetFilter(),
        };

        const sorts = {
            title: new Sort(),
            identifier: new Sort(),
            startDate: new Sort(),
            endDate: new Sort(),
        };

        super(filters, sorts);

        this.api = api;
        this.commissionSessionStore = commissionSessionStore;
        this.stateFilterData = {};
        this.categoryFilterData = [];
        this.commissionFilterData = [];

        this.getCategoryFilterData();
        this.getStateFilterData();
        this.getCommissionFilterData();
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<CommissionSessionRow>> {
        return this.commissionSessionStore
            .loadList(queryData)
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    getCategoryFilterData(): void {
        this.api.client(apiConfigs.categoryFilterData).then((r) => {
            this.categoryFilterData = r.data;
        });
    }

    @action.bound
    getStateFilterData(): void {
        this.api.client(apiConfigs.stateFilterData).then((r) => {
            this.stateFilterData = r.data.statesByProcess;
        });
    }

    @action.bound
    getCommissionFilterData(): void {
        this.api.client(apiConfigs.commissionFilterData).then((r) => {
            this.commissionFilterData = r.data;
        });
    }

    @action.bound
    mapDtoToRow(dto: CommissionSessionRowDTO): CommissionSessionRow {
        const startDate = new Date(dto.startDate || '');
        const endDate = new Date(dto.endDate || '');
        return {
            id: dto.id,
            title: dto.title,
            identifier: dto.identifier,
            commission: dto.commission,
            categories: dto.categories,
            startDate: DateUtils.isValidDate(startDate) ? startDate : undefined,
            endDate: DateUtils.isValidDate(endDate) ? endDate : undefined,
            state: dto.state,
        };
    }
}
