import React from 'react';

export const BurgerClosedIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
        <path
            fill="currentColor"
            d="M16.594 11.015L11.578 6 16.594.984 18 2.39 14.437 6 18 9.609l-1.406 1.406zM0 0h12.984v2.016H0V0zm12.984 12H0V9.984h12.984V12zM0 5.016h9.984v1.968H0V5.016z"
        />
    </svg>
);
