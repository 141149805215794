import React from 'react';

export const MailIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="7 0 20 16" fill="currentColor">
        <defs>
            <mask id="12knb" width="2" height="2" x="-1" y="-1">
                <path fill="#fff" d="M7 0h20v16H7z" />
                <path d="M23.998 0a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-14a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm-13.59 2l5.88 5.88a1.004 1.004 0 0 0 1.42 0L23.588 2zm14.59 1.41l-5.88 5.88a3 3 0 0 1-4.24 0l-5.88-5.88V13a1 1 0 0 0 1 .999h14a.999.999 0 0 0 1-1z" />
            </mask>
            <filter id="12kna" width="112" height="126" x="-39" y="-46" filterUnits="userSpaceOnUse">
                <feOffset dy="18" in="SourceGraphic" result="FeOffset1204Out" />
                <feGaussianBlur in="FeOffset1204Out" result="FeGaussianBlur1205Out" stdDeviation="14.4 14.4" />
            </filter>
        </defs>
        <g>
            <g>
                <g filter="url(#12kna)">
                    <path
                        fill="none"
                        d="M23.998 0a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-14a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm-13.59 2l5.88 5.88a1.004 1.004 0 0 0 1.42 0L23.588 2zm14.59 1.41l-5.88 5.88a3 3 0 0 1-4.24 0l-5.88-5.88V13a1 1 0 0 0 1 .999h14a.999.999 0 0 0 1-1z"
                        mask='url("#12knb")'
                    />
                    <path
                        fill-opacity=".03"
                        d="M23.998 0a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-14a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm-13.59 2l5.88 5.88a1.004 1.004 0 0 0 1.42 0L23.588 2zm14.59 1.41l-5.88 5.88a3 3 0 0 1-4.24 0l-5.88-5.88V13a1 1 0 0 0 1 .999h14a.999.999 0 0 0 1-1z"
                    />
                </g>
                <path d="M23.998 0a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-14a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm-13.59 2l5.88 5.88a1.004 1.004 0 0 0 1.42 0L23.588 2zm14.59 1.41l-5.88 5.88a3 3 0 0 1-4.24 0l-5.88-5.88V13a1 1 0 0 0 1 .999h14a.999.999 0 0 0 1-1z" />
            </g>
        </g>
    </svg>
);
