import { Box, Button, ExpansionPanel, ExpansionPanelDetails, Grid, Typography, withTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    AuthorizationCheck,
    TotObjectPanelDetails,
    TotObjectPanelLabel,
    TotObjectPanelSummary,
    TotObjectPanelValue,
} from '../../../components';
import { SessionProcedureAttributeTableModel, SessionProcedureModel } from '../../../models';
import { EditIcon } from '../../../resources';
import { RouteParamsDefault } from '../../../types';
import { getOrElse } from '../../../utils';
import { SessionProcedureGeneralizationAttributesBlock } from './SessionProcedureGeneralizationAttributesBlock';

const StyledTypography = withTheme(styled(Typography)`
    padding: ${({ theme }): string => `${theme.spacing(2)}px 0`};
`);

const GeneralizationTablePanel = withTheme(styled(ExpansionPanelDetails)`
    border-top: ${({ theme }) => `1px solid ${theme.variables.palette.hoverInLists}`};
    padding: 0;
`);

export type SessionProcedureInfoProps = {
    model: SessionProcedureModel;
    procedureAttributeTableModel: SessionProcedureAttributeTableModel;
};

export const SessionProcedureInfo = observer((props: SessionProcedureInfoProps): JSX.Element => {
    const { model, procedureAttributeTableModel } = props;
    const { activeProcedure } = model;
    const history = useHistory();
    const { id } = useParams<RouteParamsDefault>();

    const editSessionProcedure = (event: MouseEvent): void => {
        event.stopPropagation();

        if (!activeProcedure) {
            return;
        }

        const procedureId = activeProcedure.id;
        history.push(generatePath(clientRoute.sessionProcedureEdit, { id, procedureId }));
    };

    return (
        <ExpansionPanel defaultExpanded elevation={0} className="t-activeProcedure-block">
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={10}>
                        <StyledTypography variant="h3">{activeProcedure?.title}</StyledTypography>
                    </Grid>
                    <AuthorizationCheck
                        key="edit-procedure"
                        entityCode={entities.SessionProcedure}
                        entityId={activeProcedure?.id}
                        permCode={permissions.SessionProcedure.Edit}
                    >
                        <Box pr={3}>
                            <Grid item>
                                <Button
                                    className="t-editProcedure-button"
                                    variant="text"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    onClick={editSessionProcedure}
                                >
                                    <FormattedMessage id="common.edit" />
                                </Button>
                            </Grid>
                        </Box>
                    </AuthorizationCheck>
                </Grid>
            </TotObjectPanelSummary>

            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item container direction="row" justify="space-between" spacing={3}>
                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                <Grid item className="t-procedure-format">
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="commissionSession.procedureFields.format" />
                                    </TotObjectPanelLabel>

                                    <TotObjectPanelValue>{activeProcedure?.format.title}</TotObjectPanelValue>
                                </Grid>
                                <Grid item className="t-procedure-method">
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="commissionSession.procedureFields.method" />
                                    </TotObjectPanelLabel>

                                    <TotObjectPanelValue>{activeProcedure?.method.title}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                <Grid item className="t-procedure-descr">
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="commissionSession.procedureFields.descr" />
                                    </TotObjectPanelLabel>

                                    <TotObjectPanelValue>
                                        {getOrElse(activeProcedure?.description, activeProcedure?.description)}
                                    </TotObjectPanelValue>
                                </Grid>

                                <Grid item className="t-procedure-process">
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="commissionSession.procedureFields.process" />
                                    </TotObjectPanelLabel>

                                    <TotObjectPanelValue>
                                        {activeProcedure?.metaInfo.processInfo?.title}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                <Grid item className="t-procedure-members">
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="commissionSession.procedureFields.members" />
                                    </TotObjectPanelLabel>
                                    <TotObjectPanelValue>{activeProcedure?.members.title}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
            {activeProcedure && (
                <GeneralizationTablePanel>
                    <SessionProcedureGeneralizationAttributesBlock
                        procedureId={activeProcedure.id}
                        model={procedureAttributeTableModel}
                    />
                </GeneralizationTablePanel>
            )}
        </ExpansionPanel>
    );
});
