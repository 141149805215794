import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { SessionProcedureSubjectCommentDialogModel } from '../../../../../../models';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { SubjectCommentDTO, SubjectCommentField } from '../../../../../../types';
import { useAntiDoubleClick } from '../../../../../../hooks';

export type SubjectCommentDialogProps = {
    isOpen: boolean;
    close: () => void;
    model: SessionProcedureSubjectCommentDialogModel;
};

export const SubjectCommentDialog = observer((props: SubjectCommentDialogProps): JSX.Element => {
    const { isOpen, close, model } = props;
    const { commentViewModel, commentModel, setViewModelComment, save } = model;
    const { reset } = commentViewModel;
    const { comment } = commentModel;
    const initialValues: SubjectCommentDTO = { comment };

    const intl = useIntl();

    const handleSubmit = (dto: SubjectCommentDTO): Promise<void> => {
        const { comment } = dto;
        setViewModelComment(comment);
        return save().finally(close);
    };

    const [isSending, endIcon, submitHandler] = useAntiDoubleClick<SubjectCommentDTO>(handleSubmit);

    const handleClose = (): void => {
        reset();
        close();
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage id="commissionSession.sessionProcedureSubjectList.commentDialogTitle" />
            </DialogTitle>
            <DialogContent>
                <Formik initialValues={initialValues} onSubmit={submitHandler}>
                    <Form id="comment-form">
                        <Field
                            component={TextField}
                            variant="outlined"
                            label={intl.formatMessage({
                                id: 'commissionSession.sessionProcedureSubjectList.commentField',
                            })}
                            name={SubjectCommentField.comment}
                            fullWidth
                            multiline
                            rows={4}
                            disabled={false}
                        />
                    </Form>
                </Formik>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    form="comment-form"
                    disabled={isSending}
                    endIcon={endIcon}
                >
                    <FormattedMessage id="common.save" />
                </Button>
            </DialogActions>
        </Dialog>
    );
});
