import { Button, Container, ExpansionPanelDetails, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StickyHeader, TotHeaderPanel, TotHeaderPanelSummary, TotObjectHeader } from '../../../components';
import { useToggle } from '../../../hooks';
import { CommissionModel } from '../../../models';
import { getOrElse } from '../../../utils';
import { CommissionControlPanel } from './CommissionControlPanel';
import { CommissionDescription } from './CommissionDescription';

export type CommissionHeaderProps = {
    commissionModel: CommissionModel;
    saveForm: () => void;
    deleteCommission: () => Promise<void>;
};

const expandMoreIcon = <ExpandMoreIcon />;
const navigateNextIcon = <NavigateNextIcon />;

export const CommissionHeader = observer((props: CommissionHeaderProps): JSX.Element => {
    const { commissionModel, saveForm, deleteCommission } = props;
    const [isOpen, handleToggle] = useToggle();

    return (
        <StickyHeader>
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <Grid item>
                                <Typography className="t-commission-title" variant="h1">
                                    {getOrElse(commissionModel.title)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TotHeaderPanel expanded={isOpen}>
                                <TotHeaderPanelSummary>
                                    <Grid container direction="row" spacing={5} alignItems="center">
                                        <Grid item>
                                            <Button
                                                onClick={handleToggle}
                                                startIcon={isOpen ? expandMoreIcon : navigateNextIcon}
                                            >
                                                <FormattedMessage id="common.description" />
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <CommissionControlPanel
                                                commissionModel={commissionModel}
                                                saveForm={saveForm}
                                                deleteCommission={deleteCommission}
                                            />
                                        </Grid>
                                    </Grid>
                                </TotHeaderPanelSummary>
                                <ExpansionPanelDetails>
                                    <CommissionDescription commissionModel={commissionModel} />
                                </ExpansionPanelDetails>
                            </TotHeaderPanel>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectHeader>
        </StickyHeader>
    );
});
