import { ExpansionPanelDetails, ExpansionPanelSummary, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const TotObjectFilterPanelSummary = withTheme(
    styled(ExpansionPanelSummary)`
        && {
            padding-left: ${({ theme }): string => `${theme.spacing(6)}px`};
            padding-right: 0;
        }
    `,
);

export const TotObjectFilterPanelDetails = withTheme(
    styled(ExpansionPanelDetails)`
        && {
            padding-left: ${({ theme }): string => `${theme.spacing(6)}px`};
            padding-right: ${({ theme }): string => `${theme.spacing(6)}px`};
            padding-bottom: ${({ theme }): string => `${theme.spacing(6)}px`};
        }
    `,
);
