import { action, observable } from 'mobx';
import { CommissionMemberDTO } from '../../types';
import { UserNameModel } from '../person';

export class CommissionMemberModel {
    @observable id: string;
    @observable person: UserNameModel;
    @observable isHead = false;

    constructor(id: string) {
        this.id = id;
        this.person = new UserNameModel();
    }

    @action.bound
    load(dto: CommissionMemberDTO): CommissionMemberModel {
        this.person.load(dto.person);
        this.isHead = dto.isHead;
        return this;
    }
}
