import {
    Checkbox,
    CheckboxProps,
    createStyles,
    ListSubheader,
    makeStyles,
    TextField,
    Theme,
    Tooltip,
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete, AutocompleteRenderGroupParams } from '@material-ui/lab';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete/Autocomplete';
import React, { ChangeEvent, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CheckboxCheckedIcon, CheckboxDefaultIcon } from '../../resources';
import { GroupedIdTitle, IdTitle, IdTitleParent } from '../../types';

const checkboxProps: CheckboxProps = {
    icon: <CheckboxDefaultIcon />,
    checkedIcon: <CheckboxCheckedIcon />,
    color: 'primary',
};

export const SelectCheckbox = withTheme(
    styled(Checkbox)`
        &.MuiCheckbox-colorPrimary {
            padding: 0;
            color: ${({ theme }): string => theme.variables.palette.mainMiddleLight};
            margin-right: ${({ theme }): string => `${theme.spacing(2)}px`};
        }

        &.MuiCheckbox-colorPrimary.Mui-checked {
            color: ${({ theme }): string => theme.variables.palette.main};
        }
    `,
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupLabel: {
            lineHeight: '180%',
            padding: '5px 15px',
            backgroundColor: theme.palette.background.paper,
            top: -8,
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
        listBox: {
            boxSizing: 'border-box',
            '& ul': {
                padding: 0,
                margin: 0,
            },
        },
        groupUl: {
            padding: 0,
        },
    }),
);

type AutoCompleteProps = {
    disableClearable: boolean;
    freeSolo: boolean;
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    limitTags?: number;
    disabled?: boolean;
};

type SelectData = Array<IdTitle | IdTitleParent> | GroupedIdTitle;
type OnChangeMultiple = (e: ChangeEvent<{}>, value: string[]) => void;
type OnChangeNotMultiple = (e: ChangeEvent<{}>, value: string | null) => void;
type OnChange = (e: ChangeEvent<{}>, value: string | string[] | null) => void;

export type SelectProps = {
    values: string[] | string;
    label: ReactNode | JSX.Element;
    placeholder?: string;
    multiple?: boolean;
    selectData: SelectData;
    onChange: OnChangeMultiple | OnChangeNotMultiple;
    textFieldProps: TextFieldProps;
    autoCompleteProps: AutoCompleteProps;
    classes?: Partial<Record<AutocompleteClassKey, string>>;
};

const popupIcon = <ExpandMoreIcon />;

export const Select = (props: SelectProps): JSX.Element => {
    const {
        selectData,
        multiple = true,
        values,
        placeholder,
        label,
        textFieldProps,
        autoCompleteProps,
        onChange,
    } = props;
    const classes = useStyles();
    const intl = useIntl();

    const groupByFunc = (option: string): string => {
        const selectDataGrouped = selectData as GroupedIdTitle;
        return getTitle(option, selectDataGrouped, true);
    };

    const renderOptionArrayData = (option: string, { selected }: { selected: boolean }): JSX.Element => {
        const selectDataArray = selectData as Array<IdTitle | IdTitleParent>;

        return (
            <React.Fragment>
                {multiple && <SelectCheckbox {...checkboxProps} checked={selected} />}
                {getArrayDataTitle(option, selectDataArray)}
            </React.Fragment>
        );
    };

    const renderOptionGroupedData = (option: string, { selected }: { selected: boolean }): JSX.Element => {
        const selectDataGrouped = selectData as GroupedIdTitle;

        return (
            <React.Fragment>
                <SelectCheckbox {...checkboxProps} checked={selected} />
                {getTitle(option, selectDataGrouped, false)}
            </React.Fragment>
        );
    };

    const getOptionLabelArrayData = (option: string): string => {
        const selectDataArray = selectData as Array<IdTitle | IdTitleParent>;
        return getArrayDataTitle(option, selectDataArray);
    };

    const getOptionLabelGroupedData = (option: string): string => {
        const selectDataGrouped = selectData as GroupedIdTitle;
        return getTitle(option, selectDataGrouped, false);
    };

    const renderInput = (params: object): JSX.Element => {
        return (
            <TextField
                {...params}
                {...textFieldProps}
                label={label}
                placeholder={values.length && placeholder ? '' : placeholder}
            />
        );
    };

    const renderGroup = (params: AutocompleteRenderGroupParams): JSX.Element[] => {
        const { key, group, children } = params;

        return [
            <li key={key}>
                <Tooltip title={group.length > 90 ? group : ''}>
                    <ListSubheader className={classes?.groupLabel} component="div">
                        {group}
                    </ListSubheader>
                </Tooltip>
                <ul className={classes?.groupUl}>{children}</ul>
            </li>,
        ];
    };

    const isArray = isArrayFunc(selectData);
    const selectDataArray = selectData as Array<IdTitle | IdTitleParent>;
    const selectDataGrouped = selectData as GroupedIdTitle;

    const options = isArray ? idArray(selectDataArray) : idArrayGroupedData(selectDataGrouped);
    const groupBy = isArray ? undefined : groupByFunc;
    const renderOption = isArray ? renderOptionArrayData : renderOptionGroupedData;
    const getOptionLabel = isArray ? getOptionLabelArrayData : getOptionLabelGroupedData;

    if (multiple) {
        const filteringValues = (values as string[]).filter((value: string) => options.includes(value));
        const isValuesLengthToEqualFilteringLength = options.length && values.length !== filteringValues.length;

        if (isValuesLengthToEqualFilteringLength) {
            (onChange as OnChangeMultiple)({} as ChangeEvent<{}>, filteringValues);
        }
    }

    const autoCompleteValue = multiple ? (values as string[]) : (values as string);

    return (
        <Autocomplete
            multiple={multiple}
            {...autoCompleteProps}
            popupIcon={popupIcon}
            value={autoCompleteValue}
            noOptionsText={intl.formatMessage({ id: 'common.noOptions' })}
            groupBy={groupBy}
            disableCloseOnSelect={multiple}
            disableClearable={false}
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={onChange as OnChange}
            renderOption={renderOption}
            renderInput={renderInput}
            {...(multiple && { renderGroup })}
        />
    );
};

function isArrayFunc(data: SelectData): boolean {
    const array = '[object Array]';
    return Object.prototype.toString.call(data) === array;
}

function idArray(selectData: Array<IdTitle | IdTitleParent>): Array<string> {
    return selectData.map((o) => o.id);
}

function idArrayGroupedData(selectData: GroupedIdTitle): Array<string> {
    const idArray: Array<string> = [];
    Object.keys(selectData).forEach((groupName) => {
        selectData[groupName].forEach((i) => {
            idArray.push(i.id);
        });
    });
    return idArray;
}

function getArrayDataTitle(option: string, data: Array<IdTitle | IdTitleParent>): string {
    const selectDataItem = data.find((o) => o.id === option);
    return selectDataItem ? selectDataItem.title : '';
}

function getTitle(option: string, data: GroupedIdTitle, isGroupBy: boolean): string {
    let title = '';
    Object.keys(data).find((groupName) => {
        const groupedItem = data[groupName].find((itemGroup) => itemGroup.id === option);

        if (!groupedItem) {
            return false;
        }

        title = isGroupBy ? groupName : groupedItem.title;
        return true;
    });

    return title;
}
