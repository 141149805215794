import { observer } from 'mobx-react';
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ProcedureSubjectComment } from '../../../../../../types';

export type SubjectCommentsTableProps = {
    comments: ProcedureSubjectComment[];
};

export const SubjectCommentsTable = observer((props: SubjectCommentsTableProps): JSX.Element => {
    const { comments } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <FormattedMessage id="commissionSession.sessionProcedureSubjectList.commentsTable.member" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="commissionSession.sessionProcedureSubjectList.commentsTable.comment" />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {comments.map((subjectComment, index) => {
                    const { memberFIO, comment } = subjectComment;
                    return (
                        <TableRow key={index}>
                            <TableCell>{memberFIO}</TableCell>
                            <TableCell>{comment}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
});
