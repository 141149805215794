import { FormModel } from '@platform/formiojs-react';
import { action, observable } from 'mobx';
import { RootStore, SubjectStore } from '../../stores';
import { IdTitle, SubjectDTO } from '../../types';
import { MetaInfoModel } from '../MetaInfo';

export class SubjectModel {
    @observable private rootStore: RootStore;
    @observable private subjectStore: SubjectStore;

    @observable id = '';
    @observable title = '';
    @observable identifier = '';
    @observable categoryTitle = '';
    @observable categoryId = '';
    @observable session?: IdTitle;
    @observable formInfo: FormModel;
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();

    constructor(rootStore: RootStore, id: string) {
        this.rootStore = rootStore;
        this.subjectStore = rootStore.subjectStore;
        this.id = id;
        this.formInfo = new FormModel(id);
        this.load();
    }

    @action.bound
    load(): void {
        this.subjectStore.loadSubject(this.id).then(this.setMainFields);
    }

    @action.bound
    setMainFields(dto: SubjectDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.categoryTitle = dto.category?.title;
        this.categoryId = dto.category?.id;
        this.formInfo.load(dto.formInfo);
        this.metaInfo.load(dto.metaInfo);
        this.session = dto.session;
    }
}
