import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientRoute } from './clientRoute';
import { AppContentContainer, Authentication, Footer, NotificationComponent, TotAppBar } from './components';
import {
    AuthenticationInfoPage,
    CategoriesOfSubjectsPages,
    CommissionPages,
    CommissionSessionPages,
    EsiaPages,
    LoginDialog,
    NewPasswordPage,
    NotAllowed,
    NotFound,
    PfTemplateListPage,
    RecoveryPasswordPage,
    RegistrationConfirmPage,
    RegistrationPage,
    StartPage,
    SubjectPages,
    UserPages,
} from './pages';
import { useFeature } from 'feature-toggle-jsx';
import { useStore } from './hooks';

export const App = observer((): JSX.Element => {
    const [startPage] = useFeature('startPage');
    const { personStore } = useStore();
    return (
        <React.Fragment>
            <TotAppBar />
            <AppContentContainer>
                <Switch>
                    <Route path={clientRoute.subjects}>
                        <SubjectPages />
                    </Route>
                    <Route path={clientRoute.categories}>
                        <CategoriesOfSubjectsPages />
                    </Route>
                    <Route path={clientRoute.commissions}>
                        <CommissionPages />
                    </Route>
                    <Route path={clientRoute.commissionSessions}>
                        <CommissionSessionPages />
                    </Route>
                    <Route path={clientRoute.users}>
                        <UserPages />
                    </Route>
                    <Route path={clientRoute.pfTemplateList}>
                        <PfTemplateListPage />
                    </Route>
                    <Route path={clientRoute.esia}>
                        <EsiaPages />
                    </Route>
                    <Route path={clientRoute.newPassword}>
                        <NewPasswordPage />
                    </Route>
                    <Route path={clientRoute.recoveryPassword}>
                        <RecoveryPasswordPage />
                    </Route>
                    <Route path={clientRoute.authenticationInfo}>
                        <AuthenticationInfoPage />
                    </Route>
                    <Route path={clientRoute.registrationConfirm}>
                        <RegistrationConfirmPage />
                    </Route>
                    <Route path={clientRoute.registration}>
                        <RegistrationPage />
                    </Route>
                    <Route path={clientRoute.login}>
                        {startPage && <StartPage />}
                        <Route path={clientRoute.login}>
                            <LoginDialog />
                        </Route>
                    </Route>
                    <Route path={clientRoute.root} exact>
                        {startPage && <StartPage />}
                        <Authentication {...(startPage && { redirectUrl: clientRoute.root })}>
                            <Redirect to={personStore.redirectPath} />
                        </Authentication>
                    </Route>
                    <Route path={clientRoute.notFound}>
                        <NotFound />
                    </Route>
                    <Route path={clientRoute.notAllowed}>
                        <NotAllowed />
                    </Route>
                    <Route>
                        <Redirect to={clientRoute.notFound} />
                    </Route>
                </Switch>
                <NotificationComponent />
            </AppContentContainer>
            <Footer />
        </React.Fragment>
    );
});
