import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClearFilterButton, Select, TotObjectFilterPanelDetails, TotObjectFilterPanelSummary } from '../../components';
import { AutocompleteProps, pickerProps, textFieldProps } from '../../constants';
import { CategoriesOfSubjectsListModel } from '../../models';

export type CategoriesOfSubjectsListFilterPanelProps = {
    model: CategoriesOfSubjectsListModel;
};

export const CategoriesOfSubjectsListFilterPanel = observer(
    (props: CategoriesOfSubjectsListFilterPanelProps): JSX.Element => {
        const { model } = props;
        const { filtering } = model;
        const intl = useIntl();

        return (
            <ExpansionPanel>
                <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="common.filters" />
                    </Typography>
                </TotObjectFilterPanelSummary>
                <TotObjectFilterPanelDetails>
                    <Grid container direction="column" spacing={6}>
                        <Grid item container spacing={6}>
                            <Grid item xs={4}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="category.fields.title" />}
                                    placeholder={intl.formatMessage({ id: 'common.all' })}
                                    value={filtering.title.value}
                                    onChange={filtering.title.onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    values={filtering.formCode.values}
                                    label={<FormattedMessage id="category.fields.form" />}
                                    placeholder={intl.formatMessage({ id: 'common.all' })}
                                    selectData={model.formFilterData}
                                    onChange={filtering.formCode.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    values={filtering.processCode.values}
                                    label={<FormattedMessage id="category.fields.process" />}
                                    placeholder={intl.formatMessage({ id: 'common.all' })}
                                    selectData={model.processFilterData}
                                    onChange={filtering.processCode.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={6}>
                            <Grid item xs={4} container justify="space-between">
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        label={<FormattedMessage id="category.fields.created" />}
                                        placeholder={intl.formatMessage({ id: 'common.from' })}
                                        value={filtering.created.value.from}
                                        onChange={filtering.created.onFromChange}
                                        maxDate={filtering.created.value.to ?? undefined}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={(): void => filtering.created.onFromChange(null)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        placeholder={intl.formatMessage({ id: 'common.until' })}
                                        value={filtering.created.value.to}
                                        onChange={filtering.created.onToChange}
                                        minDate={filtering.created.value.from ?? undefined}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={(): void => filtering.created.onToChange(null)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectFilterPanelDetails>
            </ExpansionPanel>
        );
    },
);
