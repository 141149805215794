import { Button, Container, ExpansionPanelDetails, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StickyHeader, TotHeaderPanel, TotHeaderPanelSummary, TotObjectHeader } from '../../../components';
import { useToggle } from '../../../hooks';
import { CategoryOfSubjectsModel } from '../../../models';
import { getOrElse } from '../../../utils';
import { CategoriesOfSubjectsDescription } from './CategoriesOfSubjectsDescription';
import { CategoryOfSubjectsControlPanel } from './CategoryOfSubjectsControlPanel';

export type CategoryOfSubjectsHeaderProps = {
    categoryOfSubjectsModel: CategoryOfSubjectsModel;
    deleteCategoryOfSubjects: () => Promise<void>;
    onSubmit: () => void;
};

export const CategoryOfSubjectsHeader = observer((props: CategoryOfSubjectsHeaderProps): JSX.Element => {
    const { categoryOfSubjectsModel, onSubmit, deleteCategoryOfSubjects } = props;
    const [isOpen, handleToggle] = useToggle();

    return (
        <StickyHeader>
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <Typography variant="h1" className="t-category-title">
                                {getOrElse(categoryOfSubjectsModel.title, categoryOfSubjectsModel.title, '-')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TotHeaderPanel expanded={isOpen}>
                                <TotHeaderPanelSummary>
                                    <Grid container direction="row" spacing={5} alignItems="center">
                                        <Grid item>
                                            <Button
                                                onClick={handleToggle}
                                                startIcon={isOpen ? <ExpandMoreIcon /> : <NavigateNextIcon />}
                                            >
                                                <FormattedMessage id="common.description" />
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <CategoryOfSubjectsControlPanel
                                                categoryOfSubjectsTitle={categoryOfSubjectsModel.title}
                                                categoryOfSubjectsId={categoryOfSubjectsModel.id}
                                                deleteCategoryOfSubjects={deleteCategoryOfSubjects}
                                                onSubmit={onSubmit}
                                            />
                                        </Grid>
                                    </Grid>
                                </TotHeaderPanelSummary>
                                <ExpansionPanelDetails>
                                    <CategoriesOfSubjectsDescription
                                        categoryOfSubjectsModel={categoryOfSubjectsModel}
                                    />
                                </ExpansionPanelDetails>
                            </TotHeaderPanel>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectHeader>
        </StickyHeader>
    );
});
