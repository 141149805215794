import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck, TotObjectTransitions } from '../../../../components';
import { useFlag, useStore } from '../../../../hooks';
import {
    AuthorizationCheckQuery,
    SessionProcedure,
    SessionProcedureParams,
    SessionProcedureReloadTable,
} from '../../../../types';
import { SessionProcedureSubjectTable } from './session-procedure-subject-table';
import { SessionProcedureNumberOfVoters } from './SessionProcedureNumberOfVoters';
import { SessionProcedureSendVoteButton } from './SessionProcedureSendVoteButton';

type StateList = {
    [key: string]: string;
};

export const stateList: StateList = {
    Формирование: 'Draft',
    Draft: 'Draft',
    Ready: 'Ready',
    'Ожидающая старта': 'Ready',
    Voting: 'Voting',
    Голосование: 'Voting',
    CommonDecisionMaking: 'CommonDecisionMaking',
    'Формирование общего решения': 'CommonDecisionMaking',
    Finishes: 'Finishes',
    'Процедура завершена': 'Finishes',
    Skipped: 'Skipped',
    'Процедура пропущена': 'Skipped',
};

const useStyles = makeStyles(() => {
    return {
        gridObjectTransition: {
            marginRight: '15px',
            display: 'flex',
        },
        voteButton: {
            marginLeft: '10px',
            marginRight: '-14px',
            minWidth: '118px',
        },
    };
});

export type SessionProcedureSubjectListProps = {
    updateSessionInfo: () => void;
    setActiveStep: React.Dispatch<React.SetStateAction<string | undefined>>;
    procedures: SessionProcedure[];
    reloadKey: number;
    reloadIncrement: () => void;
};

export const SessionProcedureSubjectList = observer((props: SessionProcedureSubjectListProps): JSX.Element => {
    const { updateSessionInfo, setActiveStep, procedures, reloadKey, reloadIncrement } = props;

    const { procedureId } = useParams<SessionProcedureParams>();
    const { commissionSessionStore } = useStore();
    const [reloadData, setReloadData] = useState<SessionProcedureReloadTable>();

    const { getProcedureLifeCycleTransitions, procedureLifeCycleTransition } = commissionSessionStore;
    const { authorizationStore } = useStore();
    const [isWithNumberOfVoters, showNumberOfVoters, hideNumberOfVoters] = useFlag();

    const classes = useStyles();
    const theme = useTheme();

    const updateObjectPage = (): void => {
        updateSessionInfo();
        reloadData && reloadData();
    };

    useEffect(() => {
        const currentState: string | undefined = procedures.find((item) => item.id === procedureId)?.state;
        const step = stateList[currentState as string];
        setActiveStep(step);
    }, [procedures, procedureId, reloadKey]);

    useEffect(() => {
        const query: AuthorizationCheckQuery = {
            entityCode: entities.SessionProcedure,
            entityId: procedureId,
            permCode: permissions.SessionProcedure.ViewCountVotingProgress,
        };

        authorizationStore.check(query).then((allowed) => {
            allowed ? showNumberOfVoters() : hideNumberOfVoters();
        });
    }, [procedureId, updateObjectPage]);

    return (
        <Box
            pt={4}
            className="t-procedure"
            {...(isWithNumberOfVoters && { bgcolor: theme?.variables.palette.table.row.selected })}
        >
            <Box pb={3} pr={4}>
                <Grid container justify="space-between" direction="row-reverse" alignItems="center" spacing={3}>
                    <Grid item className={classes.gridObjectTransition}>
                        <TotObjectTransitions
                            objectId={procedureId}
                            updateObjectPage={updateObjectPage}
                            getTransitions={getProcedureLifeCycleTransitions}
                            lifeCycleTransition={procedureLifeCycleTransition}
                        />
                        <AuthorizationCheck
                            key={reloadKey}
                            entityCode={entities.SessionProcedure}
                            permCode={permissions.SessionProcedure.ChangeStatePrivateDecisions}
                            entityId={procedureId}
                        >
                            <SessionProcedureSendVoteButton
                                className={classes.voteButton}
                                reloadIncrement={reloadIncrement}
                            />
                        </AuthorizationCheck>
                    </Grid>
                    {isWithNumberOfVoters && (
                        <Grid item>
                            <SessionProcedureNumberOfVoters reloadKey={reloadKey} updateObjectPage={updateObjectPage} />
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Box>
                <SessionProcedureSubjectTable reloadData={reloadData} setReloadData={setReloadData} />
            </Box>
        </Box>
    );
});
