import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { apiConfigs } from '../../apiConfigs';
import { clientRoute } from '../../clientRoute';
import { AutocompleteField } from '../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../hooks';
import { CreateCommissionField, IdTitle, ModalProps, NewCommissionSession, NewCommissionSessionDTO } from '../../types';

const processClasses = { popper: 't-sessionProcess-field-options' };
const formClasses = { popper: 't-sessionForm-field-options' };

const initialValues: NewCommissionSession = {
    commonForm: null,
    process: null,
};

export const CreateSessionDialog = observer((props: ModalProps): JSX.Element => {
    const { isOpen, setIsClosed } = props;
    const { catalogStore, commissionSessionStore } = useStore();
    const { getSelectData } = catalogStore;
    const intl = useIntl();
    const history = useHistory();
    const [formOptions, setFormOptions] = useState<IdTitle[]>([]);
    const [processOptions, setProcessOptions] = useState<IdTitle[]>([]);

    useEffect(() => {
        getSelectData(apiConfigs.loadFormsFilterData('CommissionSession_common')).then(setFormOptions);
        getSelectData(apiConfigs.lifecyclesSelectOptions).then(setProcessOptions);
    }, []);

    const { Yup } = useYup();
    const schema = Yup.object({
        process: Yup.object().nullable().required(),
        commonForm: Yup.object().nullable().required(),
    });

    const handleSubmit = (values: NewCommissionSession): Promise<void> => {
        const { commonForm, process } = values;

        const dto: NewCommissionSessionDTO = {
            processCode: process?.id || '',
            commonFormCode: commonForm?.id || '',
        };

        return commissionSessionStore.createSession(dto).then((id) => {
            setIsClosed();
            history.push(generatePath(clientRoute.commissionSessionSettingCreate, { id }));
        });
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(handleSubmit);

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage id="commissionSession.newSession" />
            </DialogTitle>

            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={createHandler}>
                <Form>
                    <DialogContent>
                        <Box pb={6}>
                            <Grid container direction="column" spacing={4}>
                                <Grid item>
                                    <AutocompleteField
                                        classes={processClasses}
                                        className="t-sessionProcess-field"
                                        fieldName={CreateCommissionField.process}
                                        label={intl.formatMessage({ id: 'commissionSession.fields.process' })}
                                        options={processOptions}
                                        required={true}
                                    />
                                </Grid>

                                <Grid item>
                                    <AutocompleteField
                                        classes={formClasses}
                                        className="t-sessionForm-field"
                                        fieldName={CreateCommissionField.commonForm}
                                        label={intl.formatMessage({ id: 'commissionSession.fields.form' })}
                                        options={formOptions}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={setIsClosed}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            className="t-createSession-submit"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="commissionSession.create" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
