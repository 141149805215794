import { ExpansionPanel, ExpansionPanelSummary, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const TotHeaderPanelSummary = withTheme(styled(ExpansionPanelSummary)`
    && {
        &.MuiExpansionPanelSummary-root {
            cursor: default;
            padding-left: 0;
        }
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 0;
    }
`);

export const TotHeaderPanel = withTheme(
    styled(ExpansionPanel)`
        && {
            &.MuiExpansionPanel-root {
                margin: 0;
                border: none;
                box-shadow: none;
            }
        }
    `,
);
