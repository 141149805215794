import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { env, features, history, locales, themeOverrides } from './initRootProps';
import { Root } from './Root';

ReactDOM.render(
    <Root env={env} features={features} locales={locales} themeOverrides={themeOverrides} history={history} />,
    document.getElementById('root'),
);
