import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Portal } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalProps } from '../types';

type ErrorDialogProps = ModalProps & {
    message?: string | ReactNode;
};

export const ErrorDialog = (props: ErrorDialogProps): JSX.Element => {
    const { message, isOpen, setIsClosed } = props;
    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" aria-labelledby="information-dialog" open={isOpen}>
                <DialogTitle>
                    <FormattedMessage id="common.error" />
                </DialogTitle>
                <DialogContent>{message || ''}</DialogContent>
                <DialogActions>
                    <Button onClick={setIsClosed} color="secondary" variant="contained">
                        <FormattedMessage id={'common.close'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
};
