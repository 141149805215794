import React from 'react';

export const AngleDownIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 6" fill="currentColor">
        <g>
            <g>
                <path d="M11 .17a1 1 0 0 1 0 1.42L6.708 5.83a.999.999 0 0 1-1.42 0L1.05 1.59a.999.999 0 0 1 0-1.42 1 1 0 0 1 1.41 0L6 3.71 9.59.17a1 1 0 0 1 1.41 0z" />
            </g>
        </g>
    </svg>
);
