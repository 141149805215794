const validation: Record<string, string> = {
    required: 'Обязательное поле',
    minLength: 'Минимальная длина {length, number} {length, plural, one {символ} few {символа} other {символов}}',
    filledFields: 'Заполнены не все обязательные поля',
    validEmail: 'Введите валидный email',
    dropzoneError: 'Файл не соответствует типу "doc" или "docx"',
    maxFileSizeError: 'Превышен максимальный размер файла ({size})',
};

export default validation;
