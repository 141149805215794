import { Button, ButtonProps, CircularProgress, Link, TableCell, TableRow, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { PrintFormModel } from '../../../models';

export type CommissionSessionPrintFormTableRowProps = {
    model: PrintFormModel;
    isWithEditPf: boolean;
    isWithDownloadPf: boolean;
};

export const CommissionSessionPrintFormTableRow = observer(
    (props: CommissionSessionPrintFormTableRowProps): JSX.Element => {
        const { model, isWithEditPf, isWithDownloadPf } = props;
        const { pf, filename, generatePrintForm, downloadPrintForm, isGenerating, status } = model;
        const { formatMessage } = useIntl();

        const fileCellContent =
            pf && isWithDownloadPf ? (
                <Link onClick={downloadPrintForm}>{pf.filename}</Link>
            ) : (
                <Typography>{filename}</Typography>
            );

        const pfButtonTextMessage = pf
            ? formatMessage({ id: 'commissionSession.printForms.updateAction' })
            : formatMessage({ id: 'commissionSession.printForms.generateAction' });

        const pfButtonEndIcon: ButtonProps['endIcon'] = isGenerating ? <CircularProgress size={20} /> : undefined;

        let pfButton = (
            <Button
                color="secondary"
                variant="contained"
                onClick={generatePrintForm}
                disabled={isGenerating}
                endIcon={pfButtonEndIcon}
            >
                {pfButtonTextMessage}
            </Button>
        );

        return (
            <TableRow>
                <TableCell>{fileCellContent}</TableCell>
                <TableCell>{status}</TableCell>
                <TableCell>{isWithEditPf && pfButton}</TableCell>
            </TableRow>
        );
    },
);
