import { IconButton, Tooltip } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfirmationDialog } from '../../../../components';
import { useFlag } from '../../../../hooks';

export type UserDeleteForeverButtonProps = {
    login: string;
    onConfirm: () => Promise<void>;
};

export const UserDeleteForeverButton = (props: UserDeleteForeverButtonProps): JSX.Element => {
    const { login, onConfirm } = props;
    const intl = useIntl();
    const [isModalOpen, openModal, closeModal] = useFlag();

    const handleConfirm = (): Promise<void> => {
        return onConfirm().then(closeModal);
    };

    return (
        <React.Fragment>
            <Tooltip title={<FormattedMessage id="common.delete" />}>
                <IconButton onClick={openModal}>
                    <DeleteForeverIcon />
                </IconButton>
            </Tooltip>

            <ConfirmationDialog
                id="delete-user"
                isOpen={isModalOpen}
                title={intl.formatMessage({ id: 'users.deleteUser' })}
                message={intl.formatMessage({ id: 'users.deleteUserConfirmText' }, { login })}
                onConfirm={handleConfirm}
                setIsClosed={closeModal}
                keepMounted
            />
        </React.Fragment>
    );
};
