import { Box, Container, Grid, ThemeProvider, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { TotBackground } from '../../components';
import { createLandingThemeCreator, LandingThemeCreator } from '../../createThemes';
import { useStore } from '../../hooks';

const StyledContainer = withTheme(
    styled(Container)`
        text-align: center;
    `,
);

// todo: вынести в landingTheme в Typography, если не будет других кандидатов
const TotXTypography = withTheme(
    styled.h1`
        font-family: 'Roboto', sans-serif;
        margin: 0;
        font-size: 60px;
        line-height: 1.08;
        font-weight: 900;
        letter-spacing: normal;
    `,
);

const LandingTypography = withTheme(
    styled(Box)`
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 1.67;
        font-weight: 300;
        letter-spacing: 0.27px;
    `,
);

export const StartPage = observer((): JSX.Element => {
    const rootStore = useStore();
    const { startPageText } = rootStore.env;
    const [startPageDefaultText] = useFeature('startPageDefaultText');
    const landingThemeCreator: LandingThemeCreator = createLandingThemeCreator(rootStore);

    return (
        <TotBackground>
            <ThemeProvider theme={landingThemeCreator}>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <StyledContainer maxWidth="xs">
                        <Box mr={6}>
                            {startPageDefaultText ? (
                                <React.Fragment>
                                    <TotXTypography>
                                        TOT&nbsp;<span>Committee</span>
                                    </TotXTypography>
                                    <LandingTypography mt={3} component="p">
                                        <FormattedMessage id="startPage.productDescription" />
                                    </LandingTypography>
                                </React.Fragment>
                            ) : (
                                <Typography variant="h3" component="h1" color="primary">
                                    {startPageText}
                                </Typography>
                            )}
                        </Box>
                    </StyledContainer>
                </Grid>
            </ThemeProvider>
        </TotBackground>
    );
});
