import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TotObjectHeaderLabel, TotObjectHeaderValue } from '../../../components';
import { CommissionModel } from '../../../models';
import { getOrElse } from '../../../utils';

export type CommissionDescriptionProps = {
    commissionModel: CommissionModel;
};

export const CommissionDescription = observer((props: CommissionDescriptionProps): JSX.Element => {
    const { commissionModel } = props;

    return (
        <Grid container direction="row" justify="space-between">
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item container spacing={7} direction="row">
                        <Grid item>
                            <Grid container direction="row" alignItems="baseline" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="commission.fields.state" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item className="t-commission-state">
                                    <TotObjectHeaderValue>{commissionModel.metaInfo.stateTitle}</TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={7} direction="row">
                        <Grid item>
                            <Grid container direction="row" alignItems="baseline" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="commission.fields.created" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectHeaderValue>
                                        {getOrElse(
                                            commissionModel.metaInfo.created,
                                            <FormattedDate value={commissionModel.metaInfo.created} />,
                                        )}
                                    </TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
