export default {
    startPageLinks: true,
    startPage: true,
    loginWithoutRegister: true,
    headerLogo: false,
    defaultBackground: true,
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    startPageDefaultText: true,
    esiaLogin: true,
    modularNavigation: false,
};
