import { action, observable } from 'mobx';
import { IntlStore } from './IntlStore';
import { NotificationStore, SeverityType, severityVariants } from './NotificationStore';
import { RootStore } from './RootStore';

type ResponseErrorType = {
    typeMessage: SeverityType;
    isVisible: boolean;
    textMessage: string;
};

export class ErrorStore {
    @observable intl: IntlStore;
    @observable notificationStore: NotificationStore;

    constructor(rootStore: RootStore) {
        this.intl = rootStore.intlStore;
        this.notificationStore = rootStore.notificationStore;
    }

    @action.bound
    handleError(responseErrorObject: ResponseErrorType): void {
        if (responseErrorObject.isVisible) {
            const textMessage = responseErrorObject.textMessage;

            switch (responseErrorObject.typeMessage) {
                case severityVariants.warning:
                    this.notificationStore.onWarning(textMessage);
                    break;
                default:
                    this.notificationStore.onError(textMessage);
                    break;
            }
        } else {
            this.notificationStore.onError(this.intl.formatMessage('common.errorText'));
        }
    }
}
