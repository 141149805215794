import { action, computed, observable } from 'mobx';
import { IntlStore } from '../../../stores';
import { CategoryInfo, FormComponentCreateDTO, SelectModels } from '../../../types';
import { GeneralizationCategorySelectModel } from './GeneralizationCategorySelectModel';

export class GeneralizationTableDialogModel {
    @observable commissionSessionId = '';
    @observable intl: IntlStore;

    @observable categoriesSelectInfo: CategoryInfo[] = observable.array([]);

    constructor(id: string, intl: IntlStore) {
        this.commissionSessionId = id;
        this.intl = intl;
    }

    @action.bound
    enableValidationStarted(): void {
        this.selectModels.forEach((m) => {
            m.model.enableValidation();
        });
    }

    @action.bound
    disableValidationStarted(): void {
        this.selectModels.forEach((m) => {
            m.model.disableValidation();
        });
    }

    @action.bound
    loadCategories(dto: CategoryInfo[]) {
        this.categoriesSelectInfo = observable.array(dto);
    }

    @computed
    get selectModels(): SelectModels[] {
        return observable.array(
            this.categoriesSelectInfo.map((a) => {
                const model = new GeneralizationCategorySelectModel(a, this.intl);
                return {
                    ownerId: a.ownerId,
                    model: observable(model),
                };
            }),
        );
    }

    @computed
    get selectResults(): Array<FormComponentCreateDTO | null> {
        return this.selectModels.map((m) => m.model.submitAttribute);
    }

    @computed
    get isValid(): boolean {
        return !this.selectModels.find((m) => m.model.errorSelect !== '');
    }

    @computed
    get categoryAttributes(): FormComponentCreateDTO[] {
        return this.selectResults.filter((select) => select) as FormComponentCreateDTO[];
    }
}
