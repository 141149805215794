import { Box, Divider, IconButton, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../hooks';
import { UserPlusIcon } from '../../resources';
import { MenuButton } from '../buttons';

export const UserButton = observer((): JSX.Element => {
    const { authenticationStore, personStore, api } = useStore();

    const handleLogout = (hideMenu: () => void) => async (): Promise<void> => {
        await authenticationStore.logout().then(personStore.clearPerson).catch(hideMenu);
        await api.authVerify();
        await personStore.getInfo();
    };

    const renderMenuItems = (hideMenu: () => void): ReactNode[] => {
        return [
            <Box mb={2} key="person">
                <MenuItem dense button={false} tabIndex={-1}>
                    <Box pb={2} fontWeight="fontWeightBold">
                        {personStore.person.lastNameWithInitials}
                    </Box>
                </MenuItem>
            </Box>,
            <Box key="divider" mb={2}>
                <Divider />
            </Box>,
            <MenuItem dense key="logout" button={true} onClick={handleLogout(hideMenu)} tabIndex={0}>
                <FormattedMessage id="authentication.logout" />
            </MenuItem>,
        ];
    };

    const renderButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton color="secondary" onClick={onClick}>
                <UserPlusIcon />
            </IconButton>
        );
    };

    return <MenuButton renderButton={renderButton} renderMenuItems={renderMenuItems} />;
});
