import { Grid } from '@material-ui/core';
import { FormView, MultiLangFormEdit } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { TotObjectMain } from '../../components';
import { useFormApi, useStore } from '../../hooks';
import { UserPersonModel } from '../../models';
import { RouteParamsDefault } from '../../types';
import { UserControlPanel } from './UserControlPanel';
import { UserHeader } from './UserHeader';

export const UserPage = observer((): JSX.Element => {
    const { userStore, personStore, intlStore } = useStore();
    const { loginAsUser } = userStore;
    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();
    const intl = useIntl();
    const { locale } = intl;

    const [formApi, setFormApi] = useFormApi();
    const userModel = useMemo(() => new UserPersonModel(userStore, id), [id]);
    const { personForm } = userModel;

    const savePersonForm = async (): Promise<void> => {
        if (formApi && formApi.validate()) {
            await userStore.saveUser(id, formApi.getSubmission());
            history.push(generatePath(clientRoute.user, { id }));
        }
    };

    const handleLoginAsUser = (): void => {
        loginAsUser(id).then(() => {
            personStore.getInfo().then(() => {
                history.push(generatePath(personStore.redirectPath));
            });
        });
    };

    return (
        <Grid container direction="column">
            <Grid item>
                <UserHeader userPersonModel={userModel} loginAsUser={handleLoginAsUser} />
            </Grid>
            <TotObjectMain>
                <Grid container spacing={10}>
                    <Grid item container direction="column" spacing={10}>
                        <Grid item>
                            <Switch>
                                <Route exact path={clientRoute.userEdit}>
                                    <MultiLangFormEdit
                                        intlStore={intlStore}
                                        form={personForm}
                                        onFormReady={setFormApi}
                                    />
                                </Route>
                                <Route exact path={clientRoute.user}>
                                    <FormView
                                        locale={locale}
                                        form={personForm}
                                        onFormReady={setFormApi}
                                        multiLang={true}
                                    />
                                </Route>
                            </Switch>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectMain>
            <Grid item>
                <UserControlPanel userPersonModel={userModel} saveForm={savePersonForm} />
            </Grid>
        </Grid>
    );
});
