export const sassVars = {
    arrowLeftIconHeight: '10px',
    arrowLeftIconWidth: '7px',
    backgroundContrastColor: 'rgb(244, 67, 54)',
    backgroundDarkColor: 'rgb(51, 51, 51)',
    backgroundLightColor: 'rgb(245, 245, 245)',
    backgroundMainColor: 'rgb(255, 255, 255)',
    borderColor: 'rgb(204, 211, 219)',
    btnBackgroundColor: 'rgb(244, 67, 54)',
    btnBorder: '2px solid rgb(244, 67, 54)',
    btnBorderColor: 'rgb(244, 67, 54)',
    btnBorderRadius: '4px',
    btnBorderRadiusLg: '30px',
    btnBorderRadiusXs: '18px',
    btnBorderStyle: 'solid',
    btnBorderWidth: '2px',
    btnBoxShadow: 'none',
    btnColor: 'rgb(255, 255, 255)',
    btnDefaultBackgroundColor: 'rgb(255, 255, 255)',
    btnDefaultBorderColor: 'rgb(244, 67, 54)',
    btnDefaultColor: 'rgb(244, 67, 54)',
    btnDefaultHoverBackgroundColor: 'rgb(51, 51, 51)',
    btnDefaultHoverBorderColor: 'rgb(51, 51, 51)',
    btnDefaultHoverColor: 'rgb(255, 255, 255)',
    btnDisabledBackgroundColor: 'rgb(226, 226, 226)',
    btnDisabledBorderColor: 'rgb(227, 227, 227)',
    btnDisabledColor: 'rgb(153, 153, 153)',
    btnDisabledIconColor: 'rgb(153, 153, 153)',
    btnFocusBoxShadow: 'none',
    btnFontFamily: "'Open Sans', sans-serif",
    btnFontSize: '16px',
    btnFontSizeLg: '16px',
    btnFontSizeXs: '13px',
    btnFontWeight: 700,
    btnHeight: '36px',
    btnHoverBackgroundColor: 'rgb(51, 51, 51)',
    btnHoverBorderColor: 'rgb(51, 51, 51)',
    btnHoverColor: 'rgb(255, 255, 255)',
    btnLetterSpacing: '1.3px',
    btnLetterSpacingLg: '1.92px',
    btnLineHeight: '24px',
    btnLinkBackgroundColor: 'rgba(0, 0, 0, 0)',
    btnLinkBorder: '1px solid rgb(255, 255, 255)',
    btnLinkBorderRadius: '4px',
    btnLinkBoxShadowBlurredBlurRadius: '7px',
    btnLinkBoxShadowBlurredColor: 'rgb(51, 51, 51)',
    btnLinkBoxShadowBlurredOffset: 0,
    btnLinkBoxShadowBlurredSpreadRadius: '4px',
    btnLinkBoxShadowOutlineBlurRadius: 0,
    btnLinkBoxShadowOutlineColor: 'rgb(51, 51, 51)',
    btnLinkBoxShadowOutlineOffset: 0,
    btnLinkBoxShadowOutlineSpreadRadius: '4px',
    btnLinkBoxShadowOverlayBlurRadius: 0,
    btnLinkBoxShadowOverlayColor: 'rgb(255, 255, 255)',
    btnLinkBoxShadowOverlayOffset: 0,
    btnLinkBoxShadowOverlaySpreadRadius: '3px',
    btnLinkColor: 'rgb(244, 67, 54)',
    btnLinkDisabledBackgroundColor: 'rgba(0, 0, 0, 0)',
    btnLinkDisabledColor: 'rgb(153, 153, 153)',
    btnLinkFontFamily: "'Open Sans', sans-serif",
    btnLinkFontSize: '18px',
    btnLinkFontWeight: 400,
    btnLinkHoverBackgroundColor: 'rgba(0, 0, 0, 0)',
    btnLinkHoverBorder: 'none',
    btnLinkHoverColor: 'rgb(0, 0, 0)',
    btnLinkHoverOutline: 'none',
    btnLinkHoverTextDecoration: 'none',
    btnLinkLetterSpacing: '0.36px',
    btnLinkLineHeight: '28px',
    btnLinkMargin: '12px 0',
    btnLinkPadding: 0,
    btnLinkTextTransform: 'none',
    btnPaddingHorizontal: '25px',
    btnPaddingHorizontalLg: '25px',
    btnPaddingHorizontalXs: '25px',
    btnPaddingVertical: '10px',
    btnPaddingVerticalLg: '16px',
    btnPaddingVerticalXs: '4px',
    btnSlideBorder: '2px solid rgb(244, 67, 54)',
    btnSlideBorderRadius: '30px',
    btnSlideColor: 'rgb(244, 67, 54)',
    btnSlideDisabledHoverBackgroundColor: 'rgb(255, 255, 255)',
    btnSlideDisabledHoverTextDisplay: 'none',
    btnSlideDisabledHoverTransition: 'none',
    btnSlideDisabledHoverWidth: '60px',
    btnSlideFontSize: '16px',
    btnSlideFontWeight: 700,
    btnSlideHeight: '60px',
    btnSlideHoverBackgroundColor: 'rgb(51, 51, 51)',
    btnSlideHoverBorderColor: 'rgb(51, 51, 51)',
    btnSlideHoverColor: 'rgb(255, 255, 255)',
    btnSlideHoverIconMarginRight: '19px',
    btnSlideHoverIconTransition: 'color 0.1s 0.1s linear',
    btnSlideHoverTextOpacity: 1,
    btnSlideHoverTextPaddingRight: '4px',
    btnSlideHoverTextTransition: 'color 0.1s 0.1s linear opacity 0.1s 0.1s linear max-width 0.1s 0.1s linear',
    btnSlideHoverTransition: 'max-width 1s 0.1s linear background-color 0.1s linear border-color 0.1s linear',
    btnSlideHoverWidth: '100%',
    btnSlideIconColor: 'rgb(244, 67, 54)',
    btnSlideIconSize: '18px',
    btnSlideLetterSpacing: '1.92px',
    btnSlidePadding: '0 19px',
    btnSlideTextOpacity: 0,
    btnSlideWidth: '60px',
    btnTextTransform: 'uppercase',
    checkboxBoxBackgroundColor: 'rgb(255, 255, 255)',
    checkboxBoxBorder: '2px solid rgb(204, 211, 219)',
    checkboxBoxBorderColor: 'rgb(204, 211, 219)',
    checkboxBoxBorderRadius: '4px',
    checkboxBoxOffsetRight: '15px',
    checkboxBoxSize: '30px',
    checkboxCheckedBoxBackgroundColor: 'rgb(204, 211, 219)',
    checkboxCheckedBoxBorderColor: 'rgb(204, 211, 219)',
    checkboxCheckedIconColor: 'rgb(244, 67, 54)',
    checkboxCheckedLabelColor: 'rgb(0, 0, 0)',
    checkboxHoverBoxBorderColor: 'rgb(0, 0, 0)',
    checkboxHoverIconColor: 'rgb(0, 0, 0)',
    checkboxHoverLabelColor: 'rgb(0, 0, 0)',
    checkboxLabelColor: 'rgb(102, 102, 102)',
    checkboxLabelFontSize: '18px',
    checkboxLabelFontWeight: 400,
    checkboxLabelLineHeight: '30px',
    checkboxLabelPaddingLeft: '45px',
    checkboxRadioBoxDisabledBackgroundColor: 'rgb(226, 226, 226)',
    checkboxRadioBoxDisabledBorderColor: 'rgb(204, 204, 204)',
    checkboxRadioBoxDisabledColor: 'rgb(153, 153, 153)',
    checkboxRadioBoxDisabledIconColor: 'rgb(153, 153, 153)',
    colorBlack: 'rgb(0, 0, 0)',
    colorBlackShaft: 'rgb(51, 51, 51)',
    colorGold: 'rgb(157, 132, 32)',
    colorGoldLight: 'rgb(200, 150, 62)',
    colorGreen: 'rgb(0, 100, 4)',
    colorGreyDove: 'rgb(102, 102, 102)',
    colorGreyDusty: 'rgb(153, 153, 153)',
    colorGreyLight: 'rgb(226, 226, 226)',
    colorGreySeashell: 'rgb(241, 241, 241)',
    colorGreySilver: 'rgb(204, 204, 204)',
    colorGreyWild: 'rgb(227, 227, 227)',
    colorGreyWildSand: 'rgb(245, 245, 245)',
    colorRed: 'rgb(244, 67, 54)',
    colorWhite: 'rgb(255, 255, 255)',
    commentsListBtnToolbarMarginBottom: '15px',
    commentsListMarginBottom: '82px',
    commentsListMinHeight: '120px !important',
    datepickerAddonWidth: '60px',
    datepickerArrowAfterSize: '5px',
    datepickerArrowBeforeSize: '8px',
    datepickerBaseMargin: '6px',
    datepickerBaseSize: '30px',
    datepickerBorderStyle: 'solid',
    datepickerBoxShadow: 'none',
    datepickerContainerBorder: '2px solid rgb(0, 0, 0)',
    datepickerContainerBorderColor: 'rgb(0, 0, 0)',
    datepickerContainerBorderWidth: '2px',
    datepickerContainerPadding: '16px',
    datepickerDayBorderRadius: '3px',
    datepickerDayHoverBackgroundColor: 'rgb(51, 51, 51)',
    datepickerDayHoverBorderColor: 'rgb(51, 51, 51)',
    datepickerDayHoverColor: 'rgb(255, 255, 255)',
    datepickerDaySelectedBackgroundColor: 'rgb(244, 67, 54)',
    datepickerDaySelectedBorderColor: 'rgb(244, 67, 54)',
    datepickerDaySelectedColor: 'rgb(255, 255, 255)',
    datepickerDayTodayBackgroundColor: 'rgb(204, 211, 219)',
    datepickerDayTodayBorderColor: 'rgb(204, 211, 219)',
    datepickerDayTodayColor: 'rgb(244, 67, 54)',
    datepickerDisabledBackgroundColor: 'rgb(226, 226, 226)',
    datepickerDisabledBorderColor: 'rgb(204, 204, 204)',
    datepickerDisabledColor: 'rgb(153, 153, 153)',
    datepickerDisabledIconColor: 'rgb(153, 153, 153)',
    datepickerFontSize: '15px',
    datepickerInputArrowsWidth: '14px',
    datepickerInputBorderWidth: '0 0 1px',
    datepickerInputHourPadding: '16px',
    datepickerInputMinuteMargin: '8px',
    datepickerInputPaddingHoriz: '8px',
    datepickerInputPaddingHorizMin: '2px',
    datepickerInputWidth: '62px',
    datepickerLineHeight: '22px',
    datepickerMonthDropdownOffsetLeft: '4px',
    datepickerMonthDropdownPaddingLeft: '4px',
    datepickerMonthSwitchPaddingHorizLess: '11px',
    datepickerMonthSwitchPaddingHorizMore: '12px',
    datepickerMonthSwitchPaddingVertical: '10px',
    datepickerSmallMargin: '4px',
    datepickerTimeBorderTop: '1px solid rgb(204, 211, 219)',
    datepickerTimeBorderTopColor: 'rgb(204, 211, 219)',
    datepickerTimeBorderTopWidth: '1px',
    datepickerTimeHeight: '38px',
    datepickerTimePaddingTop: '15px',
    datepickerWeekdayColor: 'rgb(153, 153, 153)',
    datepickerWeekdayFontFamily: "'Open Sans', sans-serif",
    datepickerWeekdayFontSize: '10px',
    datepickerWeekdayFontWeight: 400,
    datepickerWeekdayLetterSpacing: '0.4px',
    datepickerWeekdayLineHeight: '18px',
    datepickerWeekdayTextTransform: 'uppercase',
    disabledBackgroundColor: 'rgb(226, 226, 226)',
    disabledBorderColor: 'rgb(204, 204, 204)',
    disabledColor: 'rgb(153, 153, 153)',
    errorColor: 'rgb(244, 67, 54)',
    fileDragBorderColor: 'rgb(0, 0, 0)',
    fileDragBorderStyle: 'dashed',
    fileHasErrorDescriptionMarginTop: '19px',
    fileInputDisabledBackgroundColor: 'rgb(226, 226, 226)',
    fileInputDisabledBorderColor: 'rgb(204, 204, 204)',
    fileInputDisabledColor: 'rgb(153, 153, 153)',
    fileInputDisabledIconColor: 'rgb(153, 153, 153)',
    fileInputHeight: '60px',
    fileInputSelectorBorder: "'none', '!important'",
    fileInputSelectorBorderRadius: '4px',
    fileInputSelectorIconColor: 'rgb(244, 67, 54)',
    fileInputSelectorIconContent: '\ue912',
    fileInputSelectorIconMarginRight: '15px !important',
    fileInputSelectorIconSize: '20px !important',
    fileInputSelectorLetterSpacing: '0.3px',
    fileInputSelectorLineHeight: '22px',
    fileInputSelectorLinkMarginLeft: '5px',
    fileInputSelectorPadding: '15px',
    fileInputSelectorTextColor: 'rgb(153, 153, 153)',
    fileInputSelectorTextSize: '15px',
    fileInputSelectorTransition: 'border-color ease-in-out 0.15s',
    fileListBlockFileDisabledLinkBasis: '100%',
    fileListBlockFileDisabledLinkSizeAlign: 'right',
    fileListBlockFileDisabledRemoveBasis: '0%',
    fileListBlockFileLinkBasis: '96%',
    fileListBlockFileLinkExtBasis: '7%',
    fileListBlockFileLinkNameBasis: '74%',
    fileListBlockFileLinkSizeBasis: '17%',
    fileListBlockFileRemoveBasis: '4%',
    fileListBlockFileRemoveIconSize: '18px',
    fileListIconColor: 'rgb(204, 204, 204)',
    fileListItemBorderBottomColor: 'rgb(204, 211, 219)',
    fileListItemBorderBottomStyle: 'solid',
    fileListItemBorderBottomWidth: '1px',
    fileListItemFontSize: '15px',
    fileListLinkColor: 'rgb(153, 153, 153)',
    fileListNameColor: 'rgb(102, 102, 102)',
    fileListTransition: 'color ease-in-out 0.15s',
    fileProgressBackgroundColor: 'rgb(241, 241, 241)',
    fileProgressBarBackgroundColor: 'rgb(244, 67, 54)',
    fileProgressBarBoxShadow: 'none',
    fileProgressBlockBackgroundColor: 'rgb(255, 255, 255)',
    fileProgressColor: 'rgb(255, 255, 255)',
    fileProgressErrorBackgroundColor: 'rgb(244, 67, 54)',
    fileProgressFontSize: '13px',
    fileProgressHeight: '15px',
    fileProgressInfoBackgroundColor: 'rgb(204, 204, 204)',
    fileProgressLineHeight: '15px',
    fileProgressLinesBackground: "'url(\"../../../../resources/images/scss-icons/progress-bg.svg\")', 'repeat-x'",
    fileProgressLinesHeight: '100%',
    fileProgressLinesWidth: '200%',
    fileProgressMarginBottom: 0,
    fileProgressPaddingLeft: '5px',
    fileProgressRadius: '3px',
    fileProgressWidth: '100%',
    focusColor: 'rgb(0, 0, 0)',
    fontFamilyAccent: "'Open Sans', sans-serif",
    fontFamilyGlyphIcons: 'glyphicons-theme',
    fontFamilyHeadings: "'Open Sans', sans-serif",
    fontFamilyMain: "'Open Sans', sans-serif",
    fontFamilyOpenSans: "'Open Sans', sans-serif",
    fontFamilySecondary: "'Open Sans', sans-serif",
    fontFamilySmallMain: "'Open Sans', sans-serif",
    fontSizeMain: '18px',
    fontSizeMainXs: '14px',
    fontSizeSecondary: '10px',
    fontSizeSmallMain: '15px',
    fontSizeSmallMainXs: '13px',
    fontWeightAccent: 700,
    fontWeightBold: 700,
    fontWeightHeadings: 700,
    fontWeightMain: 400,
    fontWeightRegular: 400,
    fontWeightSecondary: 400,
    fontWeightSmallMain: 400,
    footerHeight: '65px',
    formElementBorderRadius: '4px',
    formElementBorderWidth: '2px',
    formElementLabelColor: 'rgb(102, 102, 102)',
    formElementLabelFontFamily: "'Open Sans', sans-serif",
    formElementLabelFontSize: '15px',
    formElementLabelFontWeight: 400,
    formElementLabelLetterSpacing: '0.3px',
    formElementLabelLineHeight: '22px',
    formElementLabelMarginBottom: '8px',
    formElementLabelRequiredColor: 'rgb(244, 67, 54)',
    formGroupMarginBottom: '24px',
    formGroupMarginTop: '2px',
    formGroupOffsetMagicConst: '7px',
    formGroupOffsetVertical: '24px',
    formGroupOffsetVerticalFact: '31px',
    formioBtnWidth: '140px',
    formioDatagridBorder: '1px dotted rgb(204, 204, 204)',
    formioDatagridBtnAddRowMarginRight: '59px',
    formioDatagridCellPadding: '15px',
    formioDatagridMargin: 0,
    formioDatagridPaddingHorizontal: '24px',
    formioDatagridRemoveColPaddingLeft: '7px',
    formioDatagridRemoveColPaddingRight: '31px',
    formioDatagridRemoveColPaddingTop: '27px',
    formioDatagridRemoveColPaddingVert: '12px',
    formioDatagridTfootPaddingBottom: '23px',
    formioDatagridThPaddingTop: '18px',
    formioEditgridActionsBtnMarginBottom: '21px',
    formioEditgridBorder: '1px dotted rgb(204, 204, 204)',
    formioEditgridBtnAddRowMarginRight: '24px',
    formioEditgridBtnAddRowMarginVertical: '23px',
    formioEditgridBtnCancelBackgroundColor: 'rgb(255, 255, 255)',
    formioEditgridBtnCancelBorderColor: 'rgb(244, 67, 54)',
    formioEditgridBtnCancelColor: 'rgb(244, 67, 54)',
    formioEditgridBtnCancelHoverBackgroundColor: 'rgb(51, 51, 51)',
    formioEditgridBtnCancelHoverBorderColor: 'rgb(51, 51, 51)',
    formioEditgridBtnCancelHoverColor: 'rgb(255, 255, 255)',
    formioEditgridBtnRowActionMarginRight: '12px',
    formioEditgridFormGroupMarginBottom: '25px',
    formioEditgridFormGroupMarginTop: '16px',
    formioEditgridFormGroupOffsetVertical: '36px',
    formioEditgridHeaderColor: 'rgb(102, 102, 102)',
    formioEditgridHeaderFontFamily: "'Open Sans', sans-serif",
    formioEditgridHeaderFontSize: '10px',
    formioEditgridHeaderFontWeight: 400,
    formioEditgridHeaderLetterSpacing: '0.4px',
    formioEditgridHeaderLineHeight: '18px',
    formioEditgridHeaderPaddingTop: 0,
    formioEditgridHeaderTextTransform: 'uppercase',
    formioEditgridItemBorderBottomWidth: '1px',
    formioEditgridItemBorderWidth: '0 0 1px 0',
    formioEditgridItemColor: 'rgb(102, 102, 102)',
    formioEditgridItemFontSize: '15px',
    formioEditgridItemLastChildBorderBottomWidth: 0,
    formioEditgridItemLastChildBtnMarginBottom: 0,
    formioEditgridItemLastChildPaddingBottom: 0,
    formioEditgridItemLetterSpacing: '0.3px',
    formioEditgridItemLineHeight: '22px',
    formioEditgridItemMargin: 0,
    formioEditgridItemPadding: '15px 0 14px',
    formioEditgridItemPaddingBottom: '14px',
    formioEditgridItemPaddingVertical: '15px',
    formioEditgridMargin: 0,
    formioEditgridPadding: '19px 24px 23px',
    formioEditgridPaddingHorizontal: '24px',
    formioEditgridPaddingTop: '19px',
    formioFieldsetBodyPadding: '15px 24px 18px',
    formioFieldsetBodyPaddingBottom: '18px',
    formioFieldsetBodyPaddingHorizontal: '24px',
    formioFieldsetBodyPaddingTop: '15px',
    formioFieldsetBorder: '1px dotted rgb(204, 204, 204)',
    formioFieldsetFormGroupMarginBottom: '6px',
    formioFormMinHeight: '200px !important',
    formioGridMagicConst: '5px',
    formioLoaderBorderColor: 'rgb(244, 67, 54) !important',
    formioLoaderWrapper: 'rgba(0, 0, 0, 0) !important',
    formioSubgroupBorder: '1px dotted rgb(204, 204, 204)',
    formioSubgroupBorderColor: 'rgb(204, 204, 204)',
    formioSubgroupBorderStyle: 'dotted',
    formioSubgroupBorderWidth: '1px',
    formioSubgroupPaddingHorizontal: '24px',
    formioSubgroupPaddingVertical: '23px',
    formioSubgroupRowActionBtnContainerPaddingHorizontal: '7px',
    formioSubgroupRowActionBtnContainerPaddingVertical: '12px',
    formioSubgroupRowActionBtnContainerWidth: '50px',
    formioSubgroupRowActionBtnHeight: '36px',
    formioSubgroupRowActionBtnIconFontFamily: 'glyphicons-theme',
    formioSubgroupRowActionBtnIconFontSize: '18px',
    formioSubgroupRowActionBtnIconLineHeight: 1,
    formioSubgroupRowActionBtnLetterSpacing: 0,
    formioSubgroupRowActionBtnLineHeight: 0,
    formioSubgroupRowActionBtnMarginRight: '12px',
    formioSubgroupRowActionBtnPadding: '9px',
    formioSubgroupRowActionBtnWidth: '36px',
    formioSubgroupRowEditBtnBackgroundColor: 'rgba(0, 0, 0, 0)',
    formioSubgroupRowEditBtnBorder: 'none',
    formioSubgroupRowEditBtnColor: 'rgb(204, 204, 204)',
    formioSubgroupRowEditBtnHoverBackgroundColor: 'rgba(0, 0, 0, 0)',
    formioSubgroupRowEditBtnHoverBorder: 'none',
    formioSubgroupRowEditBtnHoverColor: 'rgb(0, 0, 0)',
    formioSubgroupRowEditBtnIconContent: '\ue907',
    formioSubgroupRowPadding: '15px',
    formioSubgroupRowRemoveBtnBackgroundColor: 'rgba(0, 0, 0, 0)',
    formioSubgroupRowRemoveBtnBorder: 'none',
    formioSubgroupRowRemoveBtnColor: 'rgb(204, 204, 204)',
    formioSubgroupRowRemoveBtnHoverBackgroundColor: 'rgba(0, 0, 0, 0)',
    formioSubgroupRowRemoveBtnHoverBorder: 'none',
    formioSubgroupRowRemoveBtnHoverColor: 'rgb(0, 0, 0)',
    formioSubgroupRowRemoveBtnIconContent: '\ue911',
    formioTextfieldMultipleButtonAddRowMarginTop: '6px',
    formioTextfieldMultipleButtonRemoveRowSize: '36px',
    formioTextfieldMultipleColumnHeight: '60px',
    formioTextfieldMultipleFormControlMarginBottom: '12px',
    formioTextfieldMultipleRemoveColumnPaddingHorizontal: '7px',
    formioTextfieldMultipleRemoveColumnPaddingVertical: '12px',
    formioTextfieldMultipleRemoveColumnWidth: '50px',
    gridGutterWidth: '48px',
    gridGutterWidthHalf: '24px',
    hoverColor: 'rgb(0, 0, 0)',
    hoverColorLight: 'rgb(51, 51, 51)',
    inputAddonIconColor: 'rgb(102, 102, 102)',
    inputAddonTextColor: 'rgb(0, 0, 0)',
    inputAddonTextFontSize: '18px',
    inputAddonTextLineHeight: '28px',
    inputBackgroundColor: 'rgb(241, 241, 241)',
    inputBorder: 'solid rgb(204, 204, 204)',
    inputBorderColor: 'rgb(204, 204, 204)',
    inputBorderRadius: '4px 4px 0 0',
    inputBorderRadiusSize: '4px',
    inputBorderStyle: 'solid',
    inputBorderWidth: '0 0 2px 0',
    inputDisabledBackgroundColor: 'rgb(226, 226, 226)',
    inputDisabledBorderColor: 'rgb(204, 204, 204)',
    inputDisabledColor: 'rgb(153, 153, 153)',
    inputDisabledIconColor: 'rgb(153, 153, 153)',
    inputErrorBorderColor: 'rgb(244, 67, 54)',
    inputErrorHelpBlockColor: 'rgb(244, 67, 54)',
    inputErrorHelpBlockOffsetTop: '1px',
    inputFocusBorderColor: 'rgb(102, 102, 102)',
    inputFocusBoxShadow: 'none',
    inputFocusIconColor: 'rgb(102, 102, 102)',
    inputFontFamily: "'Open Sans', sans-serif",
    inputFontSize: '18px',
    inputFontSizeXs: '14px',
    inputFontWeight: 400,
    inputHeight: '60px',
    inputHelpBlockColor: 'rgb(102, 102, 102)',
    inputHelpBlockFontFamily: "'Open Sans', sans-serif",
    inputHelpBlockFontSize: '10px',
    inputHelpBlockFontWeight: 400,
    inputHelpBlockLineHeight: '18px',
    inputHelpBlockTextTransform: 'uppercase',
    inputHoverBorderColor: 'rgb(0, 0, 0)',
    inputHoverIconColor: 'rgb(0, 0, 0)',
    inputIconTransition: 'border-color ease-in-out 0.15s color ease-in-out 0.15s',
    inputLineHeight: '28px',
    inputLineHeightXs: '20px',
    inputMdHeight: '48px',
    inputMdPadding: '9px 15px',
    inputMdPaddingHorizontal: '15px',
    inputMdPaddingVertical: '9px',
    inputPaddingHorizontal: '25px',
    inputPaddingVertical: '15px',
    inputPlaceholderColor: 'rgb(102, 102, 102)',
    inputTextColor: 'rgb(0, 0, 0)',
    inputTransition: 'border-color ease-in-out 0.15s',
    letterSpacingMain: '0.36px',
    letterSpacingSecondary: '0.4px',
    letterSpacingSmallMain: '0.3px',
    lineHeightMain: '28px',
    lineHeightMainXs: '20px',
    lineHeightSecondary: '18px',
    lineHeightSmallMain: '22px',
    lineHeightSmallMainXs: '20px',
    linkColor: 'rgb(244, 67, 54)',
    linkDisabledColor: 'rgb(153, 153, 153)',
    linkFontFamily: "'Open Sans', sans-serif",
    linkFontWeight: 400,
    linkHoverColor: 'rgb(0, 0, 0)',
    linkHoverOutline: 'none',
    linkHoverTextDecoration: 'none',
    navColor: 'rgb(102, 102, 102)',
    navHoverColor: 'rgb(0, 0, 0)',
    navSelectedColor: 'rgb(244, 67, 54)',
    navTabsBorderBottom: '1px solid rgb(204, 211, 219)',
    paginationPageLinkBackgroundColor: 'rgba(0, 0, 0, 0)',
    paginationPageLinkBorder: 'none',
    paginationPageLinkColor: 'rgb(244, 67, 54)',
    paginationPageLinkFontSize: '15px',
    paginationPageLinkFontWeight: 400,
    paginationPageLinkHoverBackgroundColor: 'rgba(0, 0, 0, 0)',
    paginationPageLinkHoverColor: 'rgb(0, 0, 0)',
    paginationPageLinkIconFontSize: '20px',
    paginationPageLinkIconPadding: '9px 0',
    paginationPageLinkLineHeight: '22px',
    paginationPageLinkMargin: '6px 3px',
    paginationPageLinkMarginVertical: '6px',
    paginationPageLinkPadding: '7px',
    paginationPageLinkSelectedBackgroundColor: 'rgb(241, 241, 241)',
    paginationPageLinkSelectedFontWeight: 700,
    paginationPageLinkSize: '36px',
    panelBodyOffsetVertical: '22px',
    radioBoxBorderRadius: '15px',
    radioBoxSelectedBorderRadius: '7px',
    radioBoxSelectedOffset: '8px',
    radioBoxSelectedSize: '14px',
    radioBoxSize: '30px',
    requestActionsPanelBackgroundColor: 'rgb(255, 255, 255)',
    requestActionsPanelBorder: '1px solid rgb(204, 204, 204)',
    requestActionsPanelBorderHeight: '1px',
    requestActionsPanelHeight: '85px',
    requestActionsPanelPadding: '12px 0',
    requestActionsPanelPaddingVertical: '12px',
    requestActionsPanelTransitionActionsHeight: '60px',
    requiredColor: 'rgb(244, 67, 54)',
    selectBorderWidth: '2px',
    selectChoicesBackgroundColor: 'rgba(0, 0, 0, 0) !important',
    selectChoicesButtonBorderRadius: 0,
    selectChoicesButtonHeight: '10px',
    selectChoicesButtonIconSize: '10px',
    selectChoicesButtonMargin: '0 0 0 11px',
    selectChoicesButtonOpacity: 1,
    selectChoicesButtonPadding: 0,
    selectChoicesButtonRight: '74px',
    selectChoicesButtonWidth: '10px',
    selectChoicesPlaceholderColor: 'rgb(102, 102, 102)',
    selectChoicesPlaceholderOpacity: 1,
    selectDisabledInputBackgroundColor: 'rgb(245, 245, 245)',
    selectDropdownBackgroundColor: 'rgb(255, 255, 255)',
    selectDropdownBorder: '2px solid rgb(0, 0, 0)',
    selectDropdownBorderColor: 'rgb(0, 0, 0)',
    selectDropdownBorderRadius: '0 0 4px 4px',
    selectDropdownMarginTop: '-2px',
    selectDropdownSearchColor: 'rgb(153, 153, 153)',
    selectDropdownSearchFontSize: '15px',
    selectDropdownSearchPadding: '4px 5px 4px 23px',
    selectIconArrowColor: 'rgb(244, 67, 54)',
    selectIconArrowHeight: '7px',
    selectIconArrowHoverColor: 'rgb(0, 0, 0)',
    selectIconArrowLineHeight: '7px',
    selectIconArrowRight: '25px',
    selectIconArrowSize: '10px',
    selectIconArrowTransitionColor: 'color ease-in-out 0.15s transform ease-in-out 0.15s',
    selectIconArrowWidth: '10px',
    selectInputColor: 'rgb(0, 0, 0)',
    selectInputFontSize: '18px',
    selectInputLineHeight: '28px',
    selectListColor: 'rgb(153, 153, 153)',
    selectListFontSize: '15px',
    selectListItemMarginBottom: '2px',
    selectListItemPadding: '0 5px 3px 23px !important',
    selectListPadding: '10px 0 !important',
    selectListSelectableBackgroundColor: 'rgb(241, 241, 241)',
    selectListSelectableColor: 'rgb(102, 102, 102)',
    selectMultipleChoicesBorder: '2px solid rgb(153, 153, 153)',
    selectMultipleChoicesBorderWidth: '2px',
    selectMultipleChoicesColor: 'rgb(0, 0, 0)',
    selectMultipleChoicesFontSize: '15px',
    selectMultipleChoicesFontWeight: 400,
    selectMultipleChoicesHeight: '30px',
    selectMultipleChoicesHoverBorderColor: 'rgb(0, 0, 0)',
    selectMultipleChoicesHoverColor: 'rgb(0, 0, 0)',
    selectMultipleChoicesHoverIconColor: 'rgb(0, 0, 0)',
    selectMultipleChoicesLineHeight: '20px',
    selectMultipleChoicesMargin: '0 6px 6px 0',
    selectMultipleChoicesMarginBottom: '6px',
    selectMultipleChoicesMarginRight: '6px',
    selectMultipleChoicesPadding: '0 10px 0 11px',
    selectMultipleChoicesTransition: 'color ease-in-out 0.15s border-color ease-in-out 0.15s',
    selectOpenIconColor: 'rgb(0, 0, 0)',
    selectSingleChoicesPaddingRight: '42px',
    selectSinglePaddingRight: '17px',
    textAccentColor: 'rgb(0, 0, 0)',
    textContrastColor: 'rgb(255, 255, 255)',
    textHeadingsColor: 'rgb(0, 0, 0)',
    textLinkColor: 'rgb(244, 67, 54)',
    textMainColor: 'rgb(102, 102, 102)',
    textSecondaryColor: 'rgb(153, 153, 153)',
    textSelectedItemColor: 'rgb(244, 67, 54)',
    textTransformSecondary: 'uppercase',
    viewOnlyComponentFieldsetCardBodyDlLastChildMarginBottom: '0 !important',
    viewOnlyComponentFieldsetCardBodyPadding: '38px 24px 40px',
    viewOnlyComponentFieldsetMarginBottom: '36px',
    viewOnlyComponentFieldsetWidth: '100%',
    viewOnlyDatagridCellPaddingLeft: '36px',
    viewOnlyDatagridLastTrPaddingBottom: '36px',
    viewOnlyDatagridMarginBottom: '36px',
    viewOnlyDatagridThPaddingTop: '38px',
    viewOnlyDatagridWidth: '100%',
    viewOnlyDlFloat: 'left',
    viewOnlyDlWidth: '50%',
    viewOnlyFieldLabelColor: 'rgb(153, 153, 153)',
    viewOnlyFieldLabelFontSize: '15px',
    viewOnlyFieldLabelFontWeight: 400,
    viewOnlyFieldLabelLineHeight: '22px',
    viewOnlyFieldLabelMarginBottom: '10px',
    viewOnlyFieldValueColor: 'rgb(0, 0, 0)',
    viewOnlyFieldValueFontSize: '18px',
    viewOnlyFieldValueFontWeight: 400,
    viewOnlyFieldValueLineHeight: '28px',
    viewOnlyFieldsetColumnsBorder: 'none',
    viewOnlyFieldsetColumnsFormioComponentColumnsDlFloat: 'none',
    viewOnlyFieldsetColumnsFormioComponentColumnsDlWidth: '100%',
    viewOnlyFieldsetColumnsFormioComponentColumnsFormioComponentFileMarginBottom: '6px',
    viewOnlyFieldsetColumnsFormioComponentColumnsPaddingTop: 0,
    viewOnlyFieldsetColumnsMarginHorizontal: '18px',
    viewOnlyFieldsetColumnsPMargin: 0,
    viewOnlyFieldsetColumnsPadding: 0,
    viewOnlyFormioComponentColumnsWidth: '100%',
    viewOnlyPanelDefaultLastChildPanelBodyPaddingBottom: '18px',
    viewOnlyPanelDefaultPanelBodyPaddingBottom: '41px',
    viewOnlyPanelDefaultPanelBodyPaddingTop: '43px',
    viewOnlyTableSecondaryHeaderMarginBottom: '-4px',
    viewOnlyTableSecondaryHeaderMarginTop: '14px',
};
