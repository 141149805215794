import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { CommissionListPage } from './CommissionListPage';
import { CommissionPage } from './CommissionPage';

export const CommissionPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route path={clientRoute.commission}>
                    <CommissionPage />
                </Route>
                <Route>
                    <CommissionListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
