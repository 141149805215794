import downloadFile from 'js-file-download';
import { action, observable } from 'mobx';
import { PrintFormStore } from '../../stores';
import { DelayedTaskStatus, FileDTO, PrintFormDTO, PrintFormStatusCode } from '../../types';

export class PrintFormModel {
    @observable protected printFormStore: PrintFormStore;

    @observable sessionId = '';
    @observable templateId = '';
    @observable filename = '';
    @observable status = '';
    @observable pf?: FileDTO;
    @observable delayedTask?: DelayedTaskStatus;
    @observable isGenerating = false;

    constructor(sessionId: string, dto: PrintFormDTO, printFormStore: PrintFormStore) {
        this.printFormStore = printFormStore;
        this.sessionId = sessionId;
        this.load(dto);
    }

    @action.bound
    load(dto: PrintFormDTO) {
        const { templateId, filename, pf, delayedTask, status } = dto;
        this.templateId = templateId;
        this.filename = filename;
        this.pf = pf;
        this.delayedTask = delayedTask;
        this.status = status.title;

        const isGenerating =
            delayedTask &&
            (delayedTask.taskStatus === PrintFormStatusCode.new ||
                delayedTask.taskStatus === PrintFormStatusCode.processing);
        this.setIsGenerating(!!isGenerating);
    }

    @action.bound
    generatePrintForm(): Promise<void> {
        this.setIsGenerating(true);
        return this.printFormStore.generatePrintForm(this.sessionId, this.templateId).catch(() => {
            this.setIsGenerating(false);
        });
    }

    @action.bound
    downloadPrintForm(): Promise<void> {
        if (this.pf) {
            const { filename, mimeType } = this.pf;

            return this.printFormStore
                .downloadPrintForm(this.sessionId, this.templateId)
                .then((file) => downloadFile(file, filename, mimeType));
        }
        return Promise.reject();
    }

    @action.bound
    setIsGenerating(isGenerating: boolean): void {
        this.isGenerating = isGenerating;
    }
}
