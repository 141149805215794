import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TotBackground } from '../components';

export const NotAllowed = (): JSX.Element => {
    return (
        <TotBackground>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Container maxWidth="xs">
                    <Paper elevation={0}>
                        <Box p={12} textAlign="center">
                            <Typography variant="h1">403</Typography>
                            <Typography variant="subtitle1" component="p">
                                <FormattedMessage id="common.actionNotAllowed" />
                            </Typography>
                        </Box>
                    </Paper>
                </Container>
            </Grid>
        </TotBackground>
    );
};
