import { Box, Chip, FormControl, FormHelperText, InputLabel, SnackbarProps } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { HTMLProps, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RequiredLabel, TotDropzoneArea } from '../../components';
import { FileModel } from '../../models';
import { PfTemplate, PrintFormField } from '../../types';

export type GeneralObjectPFDropzoneProps = {
    model: FileModel;
};

const alertSnackbarProps: SnackbarProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
};

export const PFTemplateDropzone = observer((props: GeneralObjectPFDropzoneProps): JSX.Element => {
    const { model } = props;
    const intl = useIntl();
    const { fileName: initialFileName, acceptedFiles, onDeleteFile, maxFileSize } = model;
    const formikContext = useFormikContext<PfTemplate>();
    const { values, errors, isSubmitting, setFieldValue, validateField } = formikContext;
    const fieldName = PrintFormField.file;
    const currentValues = values[fieldName];
    const currentError = errors[fieldName];
    const fileName = initialFileName || currentValues?.name;

    const [isTouched, setIsTouched] = useState<boolean>(false);
    const [rejectedError, setRejectedError] = useState<string>('');

    useEffect(() => {
        if (isSubmitting && !isTouched) {
            setIsTouched(true);
        }
    }, [isSubmitting]);

    const error = rejectedError || (isTouched && currentError);

    const onFileChange = ([file]: File[]): void => {
        if (!file) {
            return;
        }
        onDeleteFile();
        setRejectedError('');
        setFieldValue(fieldName, file);
    };

    const dropInitialFileName = (): void => {
        onDeleteFile();
        setTimeout(() => {
            validateField(fieldName);
        }, 0);
    };

    const onFileDelete = (): void => {
        initialFileName ? dropInitialFileName() : setFieldValue(fieldName, undefined);

        if (!isTouched) {
            setIsTouched(true);
        }
    };

    const inputProps: HTMLProps<HTMLInputElement> = {
        name: fieldName,
    };

    const onDropRejected = (files: File[]): void => {
        const file = files[0];

        if (!acceptedFiles.includes(file.type)) {
            setRejectedError(intl.formatMessage({ id: 'validation.dropzoneError' }));
            return;
        }

        if (file.size > maxFileSize) {
            setRejectedError(
                intl.formatMessage(
                    { id: 'validation.maxFileSizeError' },
                    {
                        size: intl.formatMessage({ id: 'pfTemplate.maxFileSize' }),
                    },
                ),
            );
            return;
        }
    };

    return (
        <FormControl fullWidth required error={!!error} variant="outlined">
            <InputLabel shrink={true} required={false}>
                <RequiredLabel text={intl.formatMessage({ id: 'pfTemplate.fields.file' })} />
            </InputLabel>
            <TotDropzoneArea
                inputProps={inputProps}
                showAlerts={false}
                useChipsForPreview={true}
                showPreviewsInDropzone={false}
                filesLimit={1}
                dropzoneText={intl.formatMessage({ id: 'common.dropzoneText' })}
                acceptedFiles={acceptedFiles}
                onDrop={onFileChange}
                onChange={onFileChange}
                onDropRejected={onDropRejected}
                alertSnackbarProps={alertSnackbarProps}
            />
            {fileName && (
                <Box pt={1}>
                    <Chip label={fileName} onDelete={onFileDelete} />
                </Box>
            )}
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
});
