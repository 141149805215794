import { ttable_en } from '@platform/ttable';
import { recordToLang } from '../utils';
import authentication from './authentication';
import category from './category';
import commission from './commission';
import commissionSession from './commissionSession';
import common from './common';
import homePage from './homePage';
import pfTemplate from './pfTemplate';
import sidebar from './sidebar';
import startPage from './startPage';
import subject from './subject';
import users from './users';
import validation from './validation';

const lang: Record<string, string> = recordToLang({
    productTitle: 'TOT COMMITTEE',
    authentication,
    validation,
    startPage,
    homePage,
    common,
    sidebar,
    category,
    subject,
    users,
    pfTemplate,
    commission,
    commissionSession,
    ttable: ttable_en,
});

export default lang;
