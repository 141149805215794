import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteField } from '../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../hooks';
import { UserPersonModel } from '../../models';
import { CodeTitle, ModalProps, RolesFields, RolesSettings } from '../../types';

export type UserRolesDialogProps = ModalProps & {
    userPersonModel: UserPersonModel;
};

export const UserRolesDialog = observer((props: UserRolesDialogProps): JSX.Element => {
    const { userPersonModel, isOpen, setIsClosed } = props;
    const { userStore } = useStore();
    const intl = useIntl();

    const [allRoles, setAllRoles] = useState<CodeTitle[]>([]);
    const { roles, setRoles } = userPersonModel;

    useEffect(() => {
        userStore.userRoleList().then(setAllRoles);
    }, [intl.locale]);

    const initialValues: RolesSettings = {
        roles: [...roles],
    };

    const { Yup } = useYup();
    const schema = Yup.object({
        roles: Yup.array().min(1),
    });

    const onSubmit = (values: RolesSettings): Promise<void> => {
        return setRoles(values).then(setIsClosed);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(onSubmit);

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
            <DialogTitle>
                <FormattedMessage id="users.editRoles" />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={createHandler}
                enableReinitialize={true}
            >
                <Form>
                    <DialogContent dividers>
                        <AutocompleteField
                            fieldName={RolesFields.roles}
                            label={intl.formatMessage({ id: 'users.fields.roles' })}
                            options={allRoles}
                            multiple={true}
                            required={true}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={setIsClosed}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
