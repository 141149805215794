import { Collapse, Grid } from '@material-ui/core';
import React from 'react';
import { AppBarComponent, AppHeaderLinksList } from './app-header-links';

export type BurgerMenuProps = {
    isSmSize: boolean;
    isOpenMenu: boolean;
    toggleArray: AppBarComponent[];
};

export const BurgerMenu = (props: BurgerMenuProps): JSX.Element => {
    const { isSmSize, isOpenMenu, toggleArray } = props;

    return (
        <Collapse in={isOpenMenu} timeout="auto">
            <Grid container direction="row" alignItems="center" justify={isSmSize ? 'center' : 'flex-end'} spacing={3}>
                {toggleArray && <AppHeaderLinksList elementsArray={toggleArray} />}
            </Grid>
        </Collapse>
    );
};
