import React, { PropsWithChildren } from 'react';
import Sticky from 'react-sticky-el';

export type StickyWrapperProps = PropsWithChildren<{
    mode: string;
    triggerScrollTimeout?: number;
}>;

/*
 * Компонент обертка для Sticky
 * Добавляет фейковый вызов события 'scroll' с таймаутом; см componentDidMount
 * Нужен в случае mode='bottom', когда панель отрисовалась а остальной контент еще нет и по мере отрисовки увеличивает высоту страницы.
 */

const stickyStyle = { zIndex: 1 };

export const StickyWrapper = (props: StickyWrapperProps): JSX.Element => {
    const { children, mode } = props;

    return (
        <Sticky stickyStyle={stickyStyle} scrollElement="#root" mode={mode}>
            {children}
        </Sticky>
    );
};
