import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { PrintFormDTO } from '../types';
import { Api } from './Api';
import { RootStore } from './RootStore';

export class PrintFormStore {
    @observable protected rootStore: RootStore;
    @observable protected api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    generatePrintForm(sessionId: string, templateId: string): Promise<void> {
        return this.api.client(apiConfigs.generatePrintForm(sessionId, templateId)).then((r) => r.data);
    }

    @action.bound
    downloadPrintForm(sessionId: string, templateId: string): Promise<Blob> {
        return this.api.client(apiConfigs.downloadPrintForm(sessionId, templateId)).then((r) => r.data);
    }

    @action.bound
    getPrintForms(sessionId: string): Promise<PrintFormDTO[]> {
        return this.api.client(apiConfigs.getPrintForms(sessionId)).then((r) => r.data);
    }
}
