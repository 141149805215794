import { action, observable } from 'mobx';
import { IdTitle, MetaInfoDTO } from '../types';
import { DateUtils } from '../utils';
import { UserNameModel } from './person';

export class MetaInfoModel {
    @observable author: UserNameModel;
    @observable created?: Date;
    @observable modified?: Date;
    @observable stateTitle?: string;
    @observable processInfo?: IdTitle;
    @observable formInfo?: string;

    constructor() {
        this.author = new UserNameModel();
    }

    @action.bound
    load(dto: MetaInfoDTO): MetaInfoModel {
        this.author.load(dto.author);

        const created = new Date(dto.created || '');
        if (DateUtils.isValidDate(created)) {
            this.created = created;
        }

        const modified = new Date(dto.modified || '');
        if (DateUtils.isValidDate(modified)) {
            this.modified = modified;
        }
        this.stateTitle = dto.stateTitle;
        this.processInfo = dto.processInfo;
        this.formInfo = dto.formInfo;
        return this;
    }
}
