export default {
    startPageLinks: false,
    startPage: true,
    loginWithoutRegister: false,
    defaultLogo: {
        src: require(`../../resources/images/kpmg-logo.png`),
    },
    defaultBackground: false,
    helpIcon: false,
    notificationIcon: false,
    phoneNumber: false,
    startPageDefaultText: false,
    esiaLogin: false,
    modularNavigation: false,
};
