import { recordToLang } from '../../../default';
import category from './category';
import commission from './commission';
import commissionSession from './commissionSession';
import homePage from './homePage';
import subject from './subject';

const lang: Record<string, string> = recordToLang({
    category,
    homePage,
    subject,
    commissionSession,
    commission,
});

export default lang;
