const commission: Record<string, string | Record<string, string>> = {
    listTitle: 'Expert groups',
    create: 'Create an expert group',
    fields: {
        manager: 'Manager',
        members: 'Experts',
    },
    actions: {
        deleteCategory: 'Delete expert group',
        editCategory: 'Edit expert group',
    },
    pagingInfo: 'Total {count, number} expert groups. Displayed from {from, number} to {to, number}',
    confirmDeletionInfoText: 'Are you sure you want to delete the expert group "{title}"?',
    members: {
        member: 'Expert',
        head: 'Head',
    },
};

export default commission;
