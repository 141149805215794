import { makeStyles, TextField as MuiTextField } from '@material-ui/core';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete/Autocomplete';
import { Field, FieldProps, useFormikContext } from 'formik';
import { TextFieldProps } from 'formik-material-ui';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { CodeTitleOrIdTitle, FormValues } from '../../types';
import { getOptionLabel as defaultGetOptionLabel, getSelectedOption } from '../../utils';
import { RequiredLabel } from '../RequiredLabel';

export type AutocompleteFieldProps<T extends CodeTitleOrIdTitle> = {
    options: T[];
    label?: ReactNode;
    fieldName: string;
    autocompleteParams?: Partial<FieldProps>;
    textFieldParams?: Partial<TextFieldProps>;
    filterSelectedOptions?: boolean;
    disableClearable?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    groupBy?: (option: T) => string;
    getLabel?: (option: T) => string;
    onChange?: (value: T) => void;
    onOpen?: () => void;
    className?: string;
    classes?: Partial<Record<AutocompleteClassKey, string>>;
    id?: string;
};

export const useChipMaxWidth = makeStyles(() => {
    return {
        root: {
            maxWidth: 300,
        },
    };
});

export const AutocompleteField = observer(
    <T extends CodeTitleOrIdTitle>(props: AutocompleteFieldProps<T>): JSX.Element => {
        const {
            options,
            label,
            fieldName,
            textFieldParams,
            disableClearable = false,
            disabled = false,
            filterSelectedOptions = false,
            multiple = false,
            required = false,
            className,
            classes,
            groupBy,
            getLabel,
            onChange,
            onOpen,
            id,
        } = props;
        const formikContext = useFormikContext<FormValues>();
        const { setFieldValue } = formikContext;
        const [searchValue, setSearchValue] = React.useState<string>('');

        const touched = formikContext.touched[fieldName];
        const errors = formikContext.errors[fieldName];

        const error = touched && !!errors;
        let helperText = touched && errors;

        if (typeof helperText === 'object') {
            const firstFieldKey = Object.keys(helperText)[0];
            helperText = helperText[firstFieldKey];
        }

        const getOptionLabel = getLabel || defaultGetOptionLabel;

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: T): void => {
            setFieldValue(fieldName, value);
            onChange && onChange(value);
        };

        // Исправляет срабатывание поиска
        const triggerSearch = (value: string): void => {
            setSearchValue(value + ' ');
            setSearchValue(value);
        };

        const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement> | null, value: string): void => {
            setSearchValue(value);
            if (event) {
                setTimeout(() => triggerSearch(value), 0);
            }
        };

        const chipClasses = useChipMaxWidth();
        const requiredLabel = required ? <RequiredLabel text={label} /> : label;
        const inputLabel = label ? requiredLabel : '';
        const inputLabelProps = !label && { shrink: false };

        const chipProps = {
            className: chipClasses.root,
        };

        const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => (
            <MuiTextField
                {...params}
                {...textFieldParams}
                {...(inputLabelProps && { InputLabelProps: inputLabelProps })}
                name={fieldName}
                error={error}
                helperText={helperText}
                label={inputLabel}
                variant="outlined"
            />
        );

        return (
            <Field
                fullWidth
                onChange={handleChange}
                disabled={disabled}
                disableClearable={disableClearable}
                filterSelectedOptions={filterSelectedOptions}
                name={fieldName}
                id={id}
                component={Autocomplete}
                options={options}
                multiple={multiple}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getSelectedOption}
                isOptionEqualToValue={getSelectedOption}
                {...(groupBy && { groupBy })}
                onOpen={onOpen}
                ChipProps={chipProps}
                inputValue={searchValue}
                onInputChange={onSearchInputChange}
                renderInput={renderInput}
                {...(className && { className })}
                {...(classes && { classes })}
            />
        );
    },
);
