import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { entities, permissions, permissionsConfig } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    ActionsMenu,
    AuthorizationCheck,
    AuthorizationCheckAll,
    DeleteActionMenuItem,
    EditActionMenuItem,
    TotObjectTransitions,
} from '../../../components';
import { useReload, useStore } from '../../../hooks';
import { CommissionModel } from '../../../models';
import { RouteParamsDefault } from '../../../types';

export type CommissionControlPanelProps = {
    commissionModel: CommissionModel;
    saveForm: () => void;
    deleteCommission: () => Promise<void>;
};

export const CommissionControlPanel = observer((props: CommissionControlPanelProps): JSX.Element => {
    const { commissionModel, saveForm, deleteCommission } = props;
    const { commissionStore } = useStore();
    const { id } = useParams<RouteParamsDefault>();
    const [reloadKey, reloadIncrement] = useReload();
    const intl = useIntl();
    const history = useHistory();
    const { title } = commissionModel;

    const updateObjectPage = (): void => {
        reloadIncrement();
        history.push(generatePath(clientRoute.commission, { id }));
    };

    const actions = [
        <AuthorizationCheck
            key="edit"
            entityCode={entities.Commission}
            entityId={id}
            permCode={permissions.Commission.Edit}
        >
            <EditActionMenuItem key="edit" path={generatePath(clientRoute.commissionEdit, { id })} />
        </AuthorizationCheck>,

        <AuthorizationCheck
            key="delete"
            entityCode={entities.Commission}
            entityId={id}
            permCode={permissions.Commission.Delete}
        >
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                message={intl.formatMessage({ id: 'commission.confirmDeletionInfoText' }, { title })}
                onConfirm={deleteCommission}
            />
        </AuthorizationCheck>,
    ];

    const renderActionItems = (): (() => ReactNode[]) => {
        return (): ReactNode[] => actions;
    };

    return (
        <Grid alignItems="center" justify="space-between" container>
            <Grid item>
                <AuthorizationCheckAll
                    or={true}
                    queries={[
                        permissionsConfig.deleteCommission(commissionModel.id),
                        permissionsConfig.editCommission(commissionModel.id),
                    ]}
                >
                    <Grid spacing={3} alignItems="center" justify="center" container>
                        <Switch>
                            <Route exact path={clientRoute.commission}>
                                <ActionsMenu renderActionItems={renderActionItems} row={null} />
                            </Route>
                        </Switch>
                    </Grid>
                </AuthorizationCheckAll>
            </Grid>
            <Grid item>
                <Grid spacing={3} alignItems="center" justify="center" container>
                    <Switch>
                        <Route exact path={clientRoute.commission}>
                            <Grid item container alignItems="baseline" spacing={3}>
                                <Grid item>
                                    <TotObjectTransitions
                                        key={reloadKey}
                                        objectId={id}
                                        updateObjectPage={updateObjectPage}
                                        getTransitions={commissionStore.getLifeCycleTransitions}
                                        lifeCycleTransition={commissionStore.lifeCycleTransition}
                                    />
                                </Grid>
                            </Grid>
                        </Route>
                        <Route exact path={clientRoute.commissionEdit}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component={NavLink}
                                    to={generatePath(clientRoute.commission, { id })}
                                >
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={saveForm}>
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                        </Route>
                        <Route exact path={clientRoute.commissionCreate}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component={NavLink}
                                    to={generatePath(clientRoute.commissions)}
                                >
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={saveForm} className="t-save-commission">
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                        </Route>
                    </Switch>
                </Grid>
            </Grid>
        </Grid>
    );
});
