import { Backdrop, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { PropsWithChildren, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { useStore } from '../hooks';
import { AuthStatus } from '../types';

export type AuthenticationProps = PropsWithChildren<{
    redirectUrl?: string;
}>;

export const Authentication = observer((props: AuthenticationProps): JSX.Element => {
    const { children, redirectUrl = clientRoute.login } = props;
    const { api } = useStore();
    const { authStatus } = api;
    const location = useLocation();
    const { search, pathname } = location;
    const backUrl = search.length ? search : `?backUrl=${pathname}`;

    useEffect(() => {
        api.authVerify();
    }, [api]);

    const renderComponent = (status: AuthStatus): JSX.Element => {
        switch (status) {
            case AuthStatus.ok:
                return children as JSX.Element;
            case AuthStatus.unauthorized:
                return <Redirect to={{ pathname: redirectUrl, search: backUrl }} />;
            default:
                return (
                    <Backdrop open>
                        <CircularProgress />
                    </Backdrop>
                );
        }
    };

    return renderComponent(authStatus);
});
