import { ExpansionPanelDetails, ExpansionPanelSummary, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const TotObjectPanelSummary = withTheme(
    styled(ExpansionPanelSummary)`
        && {
            &.Mui-expanded {
                border-bottom: ${({ theme }): string => `1px solid ${theme.variables.palette.hoverInLists}`};
            }
        }
    `,
);

export const TotObjectPanelDetails = withTheme(
    styled(ExpansionPanelDetails)`
        && {
            padding: ${({ theme }): string => `${theme.spacing(12)}px ${theme.spacing(31)}px`};
        }
    `,
);
