import { History } from 'history';
import downloadFile from 'js-file-download';
import { action, computed, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { CommissionMemberModel, CommissionModel, UserNameModel } from '../models';
import {
    CodeTitle,
    CommissionDTO,
    CommissionMemberDTO,
    CommissionRowDTO,
    CommissionRowModel,
    FullSubmission,
    RowsData,
    TableQueryData,
    TransitionsDTO,
    UserPersonDTO,
} from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export class CommissionStore {
    @observable private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action.bound
    createCommission(): Promise<string> {
        return this.api.client(apiConfigs.createCommission).then(({ data }) => data.id);
    }

    @action.bound
    deleteCommission(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteCommission(id)).then((r) => r.data);
    }

    @action.bound
    saveCommission(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveCommission(id, submission)).then((r) => r.data);
    }

    @action.bound
    getCommissionModel(id: string): CommissionModel {
        const model = new CommissionModel(id);
        this.loadCommissionDTO(id).then(model.load);
        return observable(model);
    }

    @action.bound
    loadCommissionDTO(id: string): Promise<CommissionDTO> {
        return this.api.client(apiConfigs.loadCommissionDTO(id)).then((r) => r.data);
    }

    @action.bound
    lifeCycleTransition(transitionId: string, commissionId: string): Promise<void> {
        return this.api
            .client(apiConfigs.commissionLifeCycleTransition(transitionId, commissionId))
            .then((r) => r.data);
    }

    @action.bound
    getLifeCycleTransitions(commissionId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.getCommissionLifeCycleTransitions(commissionId)).then((r) => r.data);
    }

    @action.bound
    loadCommissionMembers(commissionId: string): Promise<CommissionMemberModel[]> {
        return this.api
            .client(apiConfigs.commissionMembers(commissionId))
            .then((r) => r.data)
            .then((dtos: CommissionMemberDTO[]) => dtos.map((dto) => new CommissionMemberModel(dto.id).load(dto)));
    }

    @action.bound
    commissionMembers(commissionId: string): Promise<CommissionMemberModel[]> {
        return this.loadCommissionMembers(commissionId);
    }

    @action.bound
    loadSelectCommissionMembers(commissionId: string): Promise<CodeTitle[]> {
        return this.api
            .client(apiConfigs.selectCommissionMembers(commissionId))
            .then((r) => r.data)
            .then((dtos: UserPersonDTO[]) => dtos.map((dto) => new UserNameModel().load(dto).asCodeTitle));
    }

    @action.bound
    addMember(commissionId: string, userId: string, isHead: boolean): Promise<void> {
        return this.api.client(apiConfigs.addCommissionMember(commissionId, userId, isHead)).then((r) => r.data);
    }

    @action.bound
    deleteMember(memberId: string): Promise<void> {
        return this.api.client(apiConfigs.deleteCommissionMember(memberId)).then((r) => r.data);
    }

    @action.bound
    exportListXls(queryData: TableQueryData): void {
        const filename = this.rootStore.intlStore.formatMessage('commission.listTitle');
        this.api
            .client(apiConfigs.commissionListXls(queryData))
            .then((r) => r.data)
            .then((f) => downloadFile(f, `${filename}.xlsx`, 'application/vnd.ms-excel'));
    }

    @action.bound
    loadList(queryData: TableQueryData): Promise<RowsData<CommissionRowModel>> {
        return this.api
            .client(apiConfigs.commissions(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            )
            .then(({ rows, count }) => ({ rows: rows.map(this.rowDtoToModel), count }));
    }

    @action.bound
    rowDtoToModel(dto: CommissionRowDTO): CommissionRowModel {
        return {
            id: dto.id,
            title: dto.title,
            manager: dto.manager && new UserNameModel().load(dto.manager),
            members: dto.members.map((m) => new UserNameModel().load(m)),
            state: dto.state,
        };
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @computed
    get api(): Api {
        return this.rootStore.api;
    }
}
