import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, useLocation } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks';

export const EsiaLoginPage = observer((): JSX.Element => {
    const { authenticationStore, personStore } = useStore();
    const { esiaLogin } = authenticationStore;
    const { getInfo } = personStore;
    const location = useLocation();
    const [login] = useState<Promise<void>>(esiaLogin(location.search).then(getInfo));

    return fromPromise(login).case({
        pending: () => <CircularProgress />,
        fulfilled: () => <Redirect to={clientRoute.categories} />,
        rejected: () => (
            <Paper elevation={0}>
                <Box p={12}>
                    <Typography variant="h5">
                        <Box fontWeight="fontWeightBold">
                            <FormattedMessage id="authentication.error" />
                        </Box>
                    </Typography>
                </Box>
            </Paper>
        ),
    });
});
