import React from 'react';

export const ThumbsUpIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
            <g>
                <path
                    fill="#03cea4"
                    d="M19.299 8.08c.572.683.81 1.584.65 2.46l-1.27 7A3 3 0 0 1 15.73 20H3a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3h2.35L8.2.59a1 1 0 0 1 .91-.59A4.131 4.131 0 0 1 13 5.57L12.44 7H17a2.999 2.999 0 0 1 2.3 1.08zM4.999 9H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h2zm13 1.18a.999.999 0 0 0-.23-.82A1 1 0 0 0 17 9h-4.56a2.002 2.002 0 0 1-1.87-2.7l.53-1.43a2.11 2.11 0 0 0-1.38-2.78L7 8.21V18h8.73a1 1 0 0 0 1-.82z"
                />
            </g>
        </g>
    </svg>
);
