import { action, computed, observable } from 'mobx';
import { CommissionSessionStore } from '../../../../stores';
import { createViewModel, IViewModel } from 'mobx-utils';
import { SessionProcedureSubjectCommentModel } from './SessionProcedureSubjectCommentModel';
import { SubjectCommentDTO } from '../../../../types';

export class SessionProcedureSubjectCommentDialogModel {
    @observable private commissionSessionStore: CommissionSessionStore;
    @observable decisionId: string;
    @observable commentModel: SessionProcedureSubjectCommentModel;
    @observable commentViewModel: SessionProcedureSubjectCommentModel & IViewModel<SessionProcedureSubjectCommentModel>;

    constructor(commissionSessionStore: CommissionSessionStore, decisionId: string, comment?: string) {
        this.commissionSessionStore = commissionSessionStore;
        this.decisionId = decisionId;
        this.commentModel = new SessionProcedureSubjectCommentModel(comment);
        this.commentViewModel = createViewModel(this.commentModel);
    }

    @computed
    get asCommentDTO(): SubjectCommentDTO {
        return {
            comment: this.commentViewModel.comment,
        };
    }

    @action.bound
    save(): Promise<void> {
        const { submit, reset } = this.commentViewModel;
        return this.commissionSessionStore
            .saveSubjectComment(this.decisionId, this.asCommentDTO)
            .then(submit)
            .catch(reset);
    }

    @action.bound
    setViewModelComment(comment: string): void {
        this.commentViewModel.comment = comment;
    }
}
