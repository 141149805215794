import { FormikHelpers } from 'formik/dist/types';
import React, { useState } from 'react';
import { StyledCircularProgress } from '../components';

export type UseAntiDoubleClickType<T> = [
    boolean,
    boolean | JSX.Element,
    (values: T, formikHelpers?: FormikHelpers<T>) => void,
];

export function useAntiDoubleClick<T>(
    actionFunc: (formValues: T, formikHelpers?: FormikHelpers<T>) => Promise<void>,
): UseAntiDoubleClickType<T> {
    const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);
    const endIcon = isActionInProgress && <StyledCircularProgress />;

    const actionHandler = (values: T, formikHelpers?: FormikHelpers<T>): void => {
        setIsActionInProgress(true);

        actionFunc(values, formikHelpers).finally(() => {
            setIsActionInProgress(false);
        });
    };

    return [isActionInProgress, endIcon, actionHandler];
}
