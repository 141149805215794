import { action, computed, observable } from 'mobx';
import { ProcedureSubjectComment, ProcedureSubjectCommentDTO, ProcedureSubjectDecision } from '../../../../types';
import { CommissionSessionStore } from '../../../../stores';
import { PersonModel } from '../../../person';

export class SessionProcedureSubjectCommentListModel {
    @observable private commissionSessionStore: CommissionSessionStore;
    @observable procedureId: string;
    @observable subjectId: string;

    @observable comments: ProcedureSubjectComment[] = [];
    @observable isLoading: boolean = false;

    constructor(commissionSessionStore: CommissionSessionStore, procedureId: string, subjectId: string) {
        this.commissionSessionStore = commissionSessionStore;
        this.procedureId = procedureId;
        this.subjectId = subjectId;
    }

    @computed
    get noComments(): boolean {
        return !this.isLoading && this.comments.length < 1;
    }

    @action.bound
    loadComments(): void {
        this.startLoading();
        this.commissionSessionStore
            .sessionProcedureSubjectComments(this.procedureId, this.subjectId)
            .then((comments: ProcedureSubjectCommentDTO[]) => {
                return comments.map(({ member, comment }): ProcedureSubjectComment => {
                    return {
                        memberFIO: new PersonModel().load(member.person).lastNameWithInitials,
                        comment,
                    };
                });
            })
            .then(this.onLoadComments);
    }

    @action.bound
    onLoadComments(comments: ProcedureSubjectDecision[]): void {
        this.setComments(comments);
        this.stopLoading();
    }

    @action.bound
    startLoading(): void {
        this.isLoading = true;
    }

    @action.bound
    stopLoading(): void {
        this.isLoading = false;
    }

    @action.bound
    setComments(comments: ProcedureSubjectComment[]): void {
        this.comments = comments;
    }
}
