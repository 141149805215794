import { useCallback, useState } from 'react';

export type UseToggleType = [boolean, () => void];

export function useToggle(): UseToggleType {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = useCallback((): void => {
        setIsOpen((isOpen) => !isOpen);
    }, [setIsOpen]);

    return [isOpen, handleToggle];
}
