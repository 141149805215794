import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DatePickerProps } from '@material-ui/pickers';
import { TextFieldProps } from '@material-ui/core';

export const textFieldProps: TextFieldProps = {
    variant: 'outlined',
    fullWidth: true,
    InputLabelProps: {
        shrink: true,
    },
    size: 'small',
};

export const AutocompleteProps = {
    disableClearable: true,
    freeSolo: false,
    fullWidth: true,
};

export const pickerProps: Partial<DatePickerProps> = {
    autoOk: true,
    variant: 'inline',
    inputVariant: 'outlined',
    format: 'dd.MM.yyyy',
    disableFuture: true,
    size: 'small',
    InputLabelProps: {
        shrink: true,
    },
    invalidDateMessage: <FormattedMessage id="common.invalidDateMessage" />,
    maxDateMessage: <FormattedMessage id="common.maxDateMessage" />,
};
