import { ExpansionPanel, Grid, Link, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import {
    TotObjectPanelDetails,
    TotObjectPanelLabel,
    TotObjectPanelSummary,
    TotObjectPanelValue,
} from '../../components';
import { useStore } from '../../hooks';
import { CommissionSessionSettingModel } from '../../models';
import { getOrElse } from '../../utils';

export type CommissionSessionServiceInfoProps = {
    commissionSessionModel: CommissionSessionSettingModel;
    elevation?: number;
};

export const CommissionSessionServiceInfo = observer((props: CommissionSessionServiceInfoProps): JSX.Element => {
    const { commissionSessionModel, elevation } = props;
    const { services } = useStore();
    const { metaInfo, commonFormId, rulesFormId } = commissionSessionModel;

    return (
        <ExpansionPanel defaultExpanded elevation={elevation}>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="common.serviceInfo" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item container direction="row" justify="space-between">
                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.author" />
                                    </TotObjectPanelLabel>

                                    <TotObjectPanelValue>
                                        {metaInfo.author.userId ? (
                                            <Link
                                                component={NavLink}
                                                underline="none"
                                                to={generatePath(clientRoute.user, { id: metaInfo.author.userId })}
                                            >
                                                {metaInfo.author.name}
                                            </Link>
                                        ) : (
                                            '-'
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.lifeCycle" />
                                    </TotObjectPanelLabel>

                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            metaInfo.processInfo,
                                            <Link
                                                target="_blank"
                                                href={services.lifecycleProcessLink(metaInfo.processInfo?.id)}
                                            >
                                                {metaInfo.processInfo?.title}
                                            </Link>,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.created" />
                                    </TotObjectPanelLabel>

                                    <TotObjectPanelValue>
                                        {getOrElse(metaInfo.created, <FormattedDate value={metaInfo.created} />)}
                                    </TotObjectPanelValue>
                                </Grid>

                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.lastModified" />
                                    </TotObjectPanelLabel>

                                    <TotObjectPanelValue>
                                        {getOrElse(metaInfo.modified, <FormattedDate value={metaInfo.modified} />)}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.commonForm" />
                                    </TotObjectPanelLabel>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            commonFormId,
                                            <Link target="_blank" href={services.formVersionLink(commonFormId)}>
                                                {commonFormId}
                                            </Link>,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>

                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.rulesForm" />
                                    </TotObjectPanelLabel>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            rulesFormId,
                                            <Link target="_blank" href={services.formVersionLink(rulesFormId)}>
                                                {rulesFormId}
                                            </Link>,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
});
