import { action, observable } from 'mobx';
import { apiConfigs } from '../../apiConfigs';
import { Api, CatalogStore, SubjectStore } from '../../stores';
import {
    GroupedIdTitle,
    IdTitle,
    RowsData,
    SubjectRow,
    SubjectRowDTO,
    SubjectsFilter,
    SubjectsSort,
    TableQueryData,
} from '../../types';
import { DateUtils } from '../../utils';
import { DateRangeFilter, InSetFilter, LikeFilter, Sort, TableModel } from '../list';

export class SubjectListModel extends TableModel<SubjectRow, SubjectsFilter, SubjectsSort> {
    @observable protected api: Api;
    @observable protected subjectStore: SubjectStore;
    @observable protected catalogStore: CatalogStore;

    @observable stateFilterData: GroupedIdTitle;
    @observable processFilterData: IdTitle[];
    @observable formFilterData: IdTitle[];
    @observable categoryFilterData: IdTitle[];
    @observable commissionSessionsOptions: IdTitle[];

    constructor(api: Api, catalogStore: CatalogStore, subjectStore: SubjectStore) {
        const filter = {
            identifier: new LikeFilter(),
            title: new LikeFilter(),
            processCode: new InSetFilter(),
            formCode: new InSetFilter(),
            created: new DateRangeFilter(),
            categoryIds: new InSetFilter(),
            commissionSessions: new InSetFilter(),
            state: new InSetFilter(),
        };

        const sort = {
            identifier: new Sort(),
            title: new Sort(),
            created: new Sort('desc'),
            category: new Sort(),
            expertise: new Sort(),
        };

        super(filter, sort);
        this.api = api;
        this.subjectStore = subjectStore;
        this.catalogStore = catalogStore;

        this.stateFilterData = {};
        this.commissionSessionsOptions = [];
        this.processFilterData = [];
        this.formFilterData = [];
        this.categoryFilterData = [];

        this.loadFiltersData();
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<SubjectRow>> {
        return this.subjectStore
            .loadSubjectList(queryData)
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    loadFiltersData(): void {
        this.catalogStore.getSelectOptions(apiConfigs.categorySelectOptions).then(this.setCategoryFilterData);
        this.catalogStore.getFilterData(apiConfigs.loadFormsFilterData('Subject')).then(this.setFormFilterData);
        this.catalogStore.getFilterData(apiConfigs.loadProcessFilterData).then(this.setProcessFilterData);
        this.loadStateFilterData();
    }

    @action.bound
    loadStateFilterData(): void {
        this.catalogStore.loadStateFilterData().then(
            action((data) => {
                const { statesByProcess, commissionSessions } = data;
                this.setStateFilterData(statesByProcess);
                this.setCommissionSessionsOptions(commissionSessions);
            }),
        );
    }

    @action.bound
    mapDtoToRow(dto: SubjectRowDTO): SubjectRow {
        const created = new Date(dto.created);
        const parsedFields = {
            ...(DateUtils.isValidDate(created) && { created }),
        };

        return {
            id: dto.id,
            identifier: dto.identifier,
            title: dto.title,
            processTitle: dto.processTitle,
            formTitle: dto.formTitle,
            state: dto.state,
            session: dto.session,
            category: dto.category,
            ...parsedFields,
        };
    }

    @action.bound
    setCategoryFilterData(filterData: IdTitle[]): void {
        this.categoryFilterData = filterData;
    }

    @action.bound
    setFormFilterData(filterData: IdTitle[]): void {
        this.formFilterData = filterData;
    }

    @action.bound
    setProcessFilterData(filterData: IdTitle[]): void {
        this.processFilterData = filterData;
    }

    @action.bound
    setStateFilterData(filterData: Record<string, IdTitle[]>): void {
        this.stateFilterData = filterData;
    }

    @action.bound
    setCommissionSessionsOptions(options: IdTitle[]): void {
        this.commissionSessionsOptions = options;
    }
}
