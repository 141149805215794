import { createMuiTheme } from '@material-ui/core';
import { ruRU } from '@material-ui/core/locale';
import { sassVars } from './constants';
import { RootStore } from './stores';
import { createAppThemeOptions, createLandingThemeOptions } from './themes';
import { AppTheme, AppThemeOptions, AppThemeVariables, LandingTheme, ThemeOverrides } from './types';

export const createAppTheme = (
    store: RootStore,
    createOptions: (overrides: ThemeOverrides) => AppThemeOptions = createAppThemeOptions,
): AppTheme => {
    const themeOptions = createOptions(store.themeOverrides);
    const themeVariables = themeOptions.variables as AppThemeVariables;
    return createMuiTheme(
        {
            ...themeOptions,
            variables: {
                ...themeVariables,
                sass: {
                    ...sassVars,
                    ...themeVariables.sass,
                },
            },
        },
        ruRU,
    );
};

export type LandingThemeCreator = (appTheme: AppTheme) => LandingTheme;

export const createLandingThemeCreator = (store: RootStore): LandingThemeCreator => {
    const themeName = store.env.appThemeName;
    const themeOverrides = require(`./customization/${themeName}/theme`).default;
    const landingOptions = createLandingThemeOptions(themeOverrides);
    return (appTheme: AppTheme): LandingTheme => {
        return createMuiTheme(
            {
                ...appTheme,
                ...landingOptions,
            },
            ruRU,
        );
    };
};
