import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../hooks';
import { SnackbarComponent } from './SnackbarComponent';

export const NotificationComponent = observer((): JSX.Element => {
    const { notificationStore } = useStore();
    const { model } = notificationStore;
    const { deleteNotification } = model;

    return (
        <React.Fragment>
            {model.notifications.map((model, index) => {
                const { id } = model;
                const closeMessage = (): void => {
                    deleteNotification(id);
                };

                return (
                    <SnackbarComponent
                        key={id}
                        isOpen={model.isOpen}
                        closeMessage={closeMessage}
                        index={index + 1}
                        text={model.title}
                        severity={model.severity}
                    />
                );
            })}
        </React.Fragment>
    );
});
