import { withFunctionCallComponents } from '@platform/formio/components';
import '@platform/formio/formio.form';
import { action, observable } from 'mobx';
import { Formio } from '../types';
import { RootStore } from './RootStore';

export class FormioStore {
    @observable private rootStore: RootStore;
    @observable private apiUrl?: string;
    @observable private windowWithFormio = window as unknown as { Formio: Formio };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.apiUrl = rootStore.env.apiUrl;
        this.addComponents();
    }

    addComponents(): void {
        this.windowWithFormio.Formio.Components.addComponent(
            'select',
            withFunctionCallComponents.select({ baseUrl: this.apiUrl, catalogsUrl: this.apiUrl }),
        );
    }

    @action.bound
    addComponentsWithMaterialTheme() {
        const fileProps = {
            baseUrl: this.apiUrl,
            catalogsUrl: this.apiUrl,
            materialUiTheme: this.rootStore.appTheme,
            intlStore: this.rootStore.intlStore,
        };
        this.windowWithFormio.Formio.Components.addComponent('file', withFunctionCallComponents.styledFile(fileProps));
        this.windowWithFormio.Formio.Components.addComponent(
            'categoryFile',
            withFunctionCallComponents.categoryFile(fileProps),
        );
    }
}
