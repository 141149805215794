import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../types';
import fonts from './fonts';

type ColorName =
    | 'black'
    | 'white'
    | 'whiteTranslucent'
    | 'blue'
    | 'blueDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'green'
    | 'red'
    | 'greyTable'
    | 'yellowLight'
    | 'greyNeutral'
    | 'purpleLight'
    | 'purpleLighter'
    | 'violent'
    | 'disabled';

const kpmgColors: Record<ColorName, string> = {
    black: '#000000',
    white: '#ffffff',
    whiteTranslucent: 'rgba(255, 255, 255, .5)',
    blue: '#b5cdd9',
    blueDark: '#1e6194',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    greyNeutral: '#D9D9D9',
    green: '#03cea4',
    red: '#fb4d3d',
    greyTable: '#ececec',
    yellowLight: '#fffeec',
    purpleLight: '#6D2077',
    purpleLighter: 'rgba(109, 32, 119, 0.1)',
    violent: '#483698',

    disabled: 'rgba(0, 0, 0, 0.12)',
};

const kpmgFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
        fontWeight: 400,
    },
};

const kpmgIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

export const kpmgPalette: AppPalette = {
    main: kpmgColors.violent,
    textMain: kpmgColors.greyMain,
    mainMiddleLight: kpmgColors.greyMiddleLight,
    mainLight: kpmgColors.greyNeutral,
    mainDark: kpmgColors.greyDark,
    mainContrast: kpmgColors.white,
    mainContrastDarker: kpmgColors.greyLighter,
    accent: kpmgColors.violent,
    accentDark: kpmgColors.violent,
    accentContrast: kpmgColors.black,
    accentBackgroundGradient: `linear-gradient(-30deg, ${kpmgColors.violent} 0%, ${kpmgColors.purpleLight} 100%)`,
    accentContrastBackgroundGradient: kpmgColors.white,
    hover: kpmgColors.white,
    hoverInLists: kpmgColors.greyWhite,
    green: kpmgColors.green,
    red: kpmgColors.red,
    table: {
        row: {
            even: kpmgColors.white,
            odd: kpmgColors.greyLighter,
            checked: kpmgColors.greyTable,
            selected: kpmgColors.purpleLighter,
        },
    },
    card: {
        header: {
            main: '#252629',
        },
    },
    button: {
        accentContrast: kpmgColors.white,
        disabled: kpmgColors.disabled,
    },
    iconButton: {
        color: kpmgColors.greyMain,
        hoverColor: kpmgColors.white,
        hoverBackgroundColor: kpmgColors.greyMain,
    },
    appBar: {
        mainContrast: kpmgColors.purpleLight,
    },
    background: {
        overlay: `linear-gradient(-55deg, ${kpmgColors.white} 0%, ${kpmgColors.whiteTranslucent} 100%)`,
        textMain: kpmgColors.purpleLight,
    },
    panel: {
        markerBackgroundColor: kpmgColors.white,
        markerColor: kpmgColors.black,
    },
};

export default {
    fonts: kpmgFonts,
    palette: kpmgPalette,
    icons: kpmgIcons,
} as ThemeOverrides;
