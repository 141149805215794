import {
    ExpansionPanel,
    ExpansionPanelDetails,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { TableHeadTypography, TotObjectPanelSummary } from '../../../components';
import { useCommissionSessionPageContext, useStore } from '../../../hooks';
import { PrintFormListModel } from '../../../models';
import { AppTheme, RouteParamsDefault } from '../../../types';
import { CommissionSessionPrintFormTableRow } from './CommissionSessionPrintFormTableRow';

export type CommissionSessionPrintFormsProps = {};

const useStyles = makeStyles((theme: AppTheme) => ({
    fileCell: {
        width: '60%',
    },
    statusCell: {
        width: '20%',
    },
    actionsCell: {
        width: '20%',
    },
}));

const expandIcon = <ExpandMoreIcon />;

export const CommissionSessionPrintForms = observer((props: CommissionSessionPrintFormsProps): JSX.Element => {
    const { commissionSessionStore, env } = useStore();
    const { id } = useParams<RouteParamsDefault>();
    const { getPrintFormListModel } = commissionSessionStore;

    const printFormListModel = useMemo<PrintFormListModel>(() => getPrintFormListModel(id), [id]);
    const {
        isWithEditPf,
        updateIfSomePrintFormIsGenerating,
        clearUpdatePfListInterval,
        checkIsWithDownloadPf,
        checkIsWithEditPf,
        isWithDownloadPf,
        isSomePrintFormGenerating,
    } = printFormListModel;
    const classes = useStyles();
    const { stateTitle } = useCommissionSessionPageContext().commissionSessionModel.metaInfo;

    useEffect(() => {
        checkIsWithEditPf();
        checkIsWithDownloadPf();
    }, [stateTitle]);

    const { pfDelay } = env;
    useEffect(() => {
        updateIfSomePrintFormIsGenerating(pfDelay as number);
        return clearUpdatePfListInterval;
    }, [isSomePrintFormGenerating, pfDelay]);

    return (
        <ExpansionPanel defaultExpanded={false} elevation={0} className="t-print-forms">
            <TotObjectPanelSummary expandIcon={expandIcon}>
                <Typography variant="h3">
                    <FormattedMessage id="commissionSession.blocksTitle.printForms" />
                </Typography>
            </TotObjectPanelSummary>
            <ExpansionPanelDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.fileCell}>
                                <TableHeadTypography>
                                    <FormattedMessage id="commissionSession.printForms.file" />
                                </TableHeadTypography>
                            </TableCell>
                            <TableCell className={classes.statusCell}>
                                <TableHeadTypography>
                                    <FormattedMessage id="common.serviceInfoFields.state" />
                                </TableHeadTypography>
                            </TableCell>
                            <TableCell className={classes.actionsCell}>
                                <TableHeadTypography>
                                    <FormattedMessage id="commissionSession.printForms.actions" />
                                </TableHeadTypography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {printFormListModel.printForms.map((pf) => (
                            <CommissionSessionPrintFormTableRow
                                key={pf.templateId}
                                model={pf}
                                isWithEditPf={isWithEditPf}
                                isWithDownloadPf={isWithDownloadPf}
                            />
                        ))}
                    </TableBody>
                </Table>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
});
