import React, { PropsWithChildren } from 'react';
import Sticky from 'react-sticky-el';
import styled from 'styled-components';

const StyledSticky = styled(Sticky)`
    .sticky .MuiTypography-h1 {
        font-size: 16px;
    }
`;

export type StickyHeaderProps = PropsWithChildren<{}>;

const stickyStyle = { zIndex: 2 };

export const StickyHeader = (props: StickyHeaderProps): JSX.Element => {
    const { children } = props;

    return (
        <StyledSticky stickyStyle={stickyStyle} scrollElement="#root">
            {children}
        </StyledSticky>
    );
};
