import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ConfirmationDialog, TransitionDialogProps } from '../../../../components';
import { useFlag, useStore } from '../../../../hooks';
import { RouteParamsDefault } from '../../../../types';

type RouteParams = RouteParamsDefault & {
    procedureId: string;
};

export const CheckCastingVoteDialog = observer((props: TransitionDialogProps): JSX.Element => {
    const {
        transition,
        isModalOpen,
        lifeCycleTransition,
        handleErrorTransition,
        handleSuccessTransition,
        setModalIsClosed,
    } = props;

    const { procedureId } = useParams<RouteParams>();
    const [isTransitionAllowed, setIsTransitionAllowed] = useState(false);
    const { commissionSessionStore } = useStore();
    const [isLoading, enableLoading, disableLoading] = useFlag();

    const { formatMessage } = useIntl();

    const handleConfirm = (): Promise<void> => {
        return lifeCycleTransition()
            .then(handleSuccessTransition)
            .catch(handleErrorTransition)
            .finally(setModalIsClosed);
    };

    useEffect(() => {
        enableLoading();
        commissionSessionStore.checkCastingVoteStatus(procedureId).then(setIsTransitionAllowed).finally(disableLoading);
    }, [procedureId]);

    if (isLoading) {
        return <React.Fragment />;
    }

    const messageId = isTransitionAllowed
        ? 'common.lifeCycleConfirmText'
        : 'commissionSession.sessionProcedureTransition.transitionError';

    const cancelText = isTransitionAllowed
        ? undefined
        : formatMessage({ id: 'commissionSession.sessionProcedureTransition.cancelTransition' });

    const confirmText = isTransitionAllowed
        ? transition.params.title
        : formatMessage({ id: 'commissionSession.sessionProcedureTransition.confirmTransition' });

    return (
        <ConfirmationDialog
            id="confirm-transition"
            setIsClosed={setModalIsClosed}
            onConfirm={handleConfirm}
            message={formatMessage({ id: messageId }, { toState: transition.toStateTitle })}
            isOpen={isModalOpen}
            title={formatMessage({ id: 'common.lifeCycleConfirmTitle' })}
            keepMounted={true}
            cancelText={cancelText}
            confirmText={confirmText}
            className="t-transition-dialog"
        />
    );
});
