import { Box, Link, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const TotObjectHeader = withTheme(
    styled(Box)`
        color: ${({ theme }) => theme.palette.secondary.contrastText};
        border-bottom: ${({ theme }) => `1px solid ${theme.variables.palette.hoverInLists}`};
        background-color: ${({ theme }) => theme.palette.primary.contrastText};
        padding: ${({ theme }): string => `${theme.spacing(2.5)}px 0 0 0`};
    `,
);

export type TotObjectHeaderLabelProps = PropsWithChildren<ThemedComponentProps>;

export const TotObjectHeaderLabel = withTheme((props: TotObjectHeaderLabelProps) => {
    const { theme, children } = props;

    return (
        <Box color={theme?.palette.primary.light}>
            <Typography variant="body1" component="span">
                {children}
            </Typography>
        </Box>
    );
});

export type TotObjectHeaderValueProps = PropsWithChildren<{}>;

export const TotObjectHeaderValue = (props: TotObjectHeaderValueProps): JSX.Element => {
    const { children } = props;
    return (
        <Typography variant="body2" component="span" style={{ fontWeight: 'bold' }}>
            {children}
        </Typography>
    );
};

export const TotObjectHeaderLink = withTheme(styled(Link)`
    color: ${({ theme }) => theme.palette.primary.main} !important;
    font-size: 15px;

    &:hover {
        color: ${({ theme }) => theme.palette.secondary.contrastText} !important;
    }
`);
