import { Form, FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../components';
import { useStore } from '../../../../../hooks';
import { SessionProcedureCellFormModal } from '../../../../../models';
import { DecisionType, SessionDecision } from '../../../../../types';
import { StyledForm } from '../SessionProcedureSubjectListStyled';

export type SessionProcedurePrivateDecisionCellProps = SessionDecision;

const editClass = 't-private-decision-table-cell-form-edit';
const viewClass = 't-private-decision-table-cell-form-view';

export const SessionProcedurePrivateDecisionCell = observer(
    (props: SessionProcedurePrivateDecisionCellProps): JSX.Element => {
        const { decisionId, form } = props;
        const { commissionSessionStore } = useStore();
        const { setLoadingStart } = commissionSessionStore;
        const intl = useIntl();
        const { locale } = intl;

        const model = useMemo(() => {
            return new SessionProcedureCellFormModal(commissionSessionStore, form, decisionId, DecisionType.private);
        }, [decisionId, form]);

        const { formModel, onFormReady, onFormChange } = model;

        const beginFormProcessing = (form: any /* Вынужденно, ибо отсутствует тип. */): void => {
            if (form.changed?.flags?.modified) {
                setLoadingStart();
            }
        };

        return (
            <AuthorizationCheck
                entityCode={entities.SubjectDecision}
                permCode={permissions.SubjectDecision.EditPrivateDecision}
                entityId={decisionId}
            >
                {(allowed: boolean) => {
                    return (
                        <StyledForm className={allowed ? editClass : viewClass}>
                            {allowed ? (
                                <Form
                                    setFormIsChanged={onFormChange}
                                    onFormReady={onFormReady}
                                    form={formModel}
                                    onFormChange={beginFormProcessing}
                                />
                            ) : (
                                <FormView form={formModel} locale={locale} />
                            )}
                        </StyledForm>
                    );
                }}
            </AuthorizationCheck>
        );
    },
);
