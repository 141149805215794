import { IconButton, Tooltip } from '@material-ui/core';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfirmationDialog } from '../../../../components';
import { useFlag } from '../../../../hooks';

export type UserBlockButtonProps = {
    login: string;
    onConfirm: () => Promise<void>;
};

export const UserBlockButton = (props: UserBlockButtonProps): JSX.Element => {
    const { login, onConfirm } = props;
    const intl = useIntl();
    const [isModalOpen, openModal, closeModal] = useFlag();

    const handleConfirm = (): Promise<void> => {
        return onConfirm().then(closeModal);
    };

    return (
        <React.Fragment>
            <Tooltip title={<FormattedMessage id="users.actions.block" />}>
                <IconButton onClick={openModal}>
                    <PersonAddDisabledIcon />
                </IconButton>
            </Tooltip>

            <ConfirmationDialog
                id="block-user"
                isOpen={isModalOpen}
                title={intl.formatMessage({ id: 'users.confirmBlock' })}
                message={intl.formatMessage({ id: 'users.confirmBlockInfoText' }, { login })}
                onConfirm={handleConfirm}
                setIsClosed={closeModal}
                keepMounted
            />
        </React.Fragment>
    );
};
