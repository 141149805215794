const users: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Users',
    fields: {
        login: 'Login',
        status: 'Status',
        name: 'Full name',
        roles: 'Roles',
    },
    status: {
        blocked: 'Blocked',
        active: 'Active',
        inactive: 'Inactive',
    },
    actions: {
        block: 'Block',
        unblock: 'Unblock',
    },
    loginAsUser: 'Login as user',
    editRoles: 'Edit roles',
    registryPagingInfo: 'Total {count, number} users. Displayed from {from, number} to {to, number}',
    confirmBlock: 'Confirm block',
    confirmBlockInfoText: 'Are you sure you want to block {login}?',
    deleteUser: 'Delete user',
    deleteUserConfirmText: 'The user will be deleted. Confirm action?',
};

export default users;
