import { Box, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import React, { PropsWithChildren } from 'react';

export type TotObjectPanelLabelProps = PropsWithChildren<ThemedComponentProps>;

export const TotObjectPanelLabel = withTheme((props: TotObjectPanelLabelProps) => {
    const { theme, children } = props;

    return (
        <Box color={theme?.palette.primary.light}>
            <Typography variant="body1" component="span">
                {children}
            </Typography>
        </Box>
    );
});

export type TotObjectPanelValueProps = PropsWithChildren<{}>;

export const TotObjectPanelValue = (props: PropsWithChildren<{}>) => {
    const { children } = props;

    return (
        <Typography variant="body2" component="span">
            {children}
        </Typography>
    );
};
