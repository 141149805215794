import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { AutocompleteField } from '../../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../../hooks';
import { CreateSubjectField, IdTitle, ModalProps, NewSubject, NewSubjectDTO } from '../../../types';

export type CreateSubjectDialogProps = ModalProps & {
    categoryOptions: IdTitle[];
};

const categoryClasses = { popper: 't-category-field-options' };
const sessionsClasses = { popper: 't-session-field-options' };

const initialValues: NewSubject = {
    category: null,
    session: null,
};

export const CreateSubjectDialog = observer((props: CreateSubjectDialogProps): JSX.Element => {
    const { setIsClosed, isOpen, categoryOptions } = props;

    const { subjectStore, notificationStore } = useStore();
    const { loadCommissionSessionsOptions, createSubject } = subjectStore;
    const [commissionSessionsOptions, setCommissionSessionsOptions] = useState<IdTitle[]>([]);
    const intl = useIntl();
    const history = useHistory();

    useEffect(() => {
        loadCommissionSessionsOptions().then(setCommissionSessionsOptions);
    }, []);

    const { Yup } = useYup();
    const schema = Yup.object({
        category: Yup.object().nullable().required(),
        session: Yup.object().nullable(),
    });

    const handleSubmit = (values: NewSubject): Promise<void> => {
        const { category, session } = values;
        const dto: NewSubjectDTO = {
            categoryId: category?.id || '',
        };

        if (session) {
            dto.sessionId = session.id;
        }

        return createSubject(dto)
            .then((id) => {
                setIsClosed();
                history.push(generatePath(clientRoute.subjectCreate, { id }));
            })
            .catch((err) => {
                notificationStore.onError(err.response.data);
            });
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(handleSubmit);

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage id="subject.newSubject" />
            </DialogTitle>

            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={createHandler}>
                <Form>
                    <DialogContent>
                        <Box pb={6}>
                            <Grid container direction="column" spacing={4}>
                                <Grid item>
                                    <AutocompleteField
                                        classes={categoryClasses}
                                        className="t-category-field"
                                        fieldName={CreateSubjectField.category}
                                        label={intl.formatMessage({ id: 'subject.fields.category' })}
                                        options={categoryOptions}
                                        required={true}
                                    />
                                </Grid>

                                <Grid item>
                                    <AutocompleteField
                                        classes={sessionsClasses}
                                        className="t-session-field"
                                        fieldName={CreateSubjectField.session}
                                        label={intl.formatMessage({ id: 'subject.fields.commissionSessions' })}
                                        options={commissionSessionsOptions}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={setIsClosed}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            className="t-create-subject-confirm"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="subject.createSubject" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
