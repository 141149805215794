import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useStore } from '../../../../../../hooks';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PrivateDecisionsTable } from './PrivateDecisionsTable';
import { SessionProcedureSubjectDecisionsModel } from '../../../../../../models';

export type PrivateDecisionsDialogProps = {
    isOpen: boolean;
    close: () => void;
    procedureId: string;
    subjectId: string;
};

export const PrivateDecisionsDialog = observer((props: PrivateDecisionsDialogProps): JSX.Element => {
    const { procedureId, subjectId, isOpen, close } = props;
    const { commissionSessionStore } = useStore();
    const model: SessionProcedureSubjectDecisionsModel = useMemo(
        () => new SessionProcedureSubjectDecisionsModel(commissionSessionStore, procedureId, subjectId),
        [commissionSessionStore, procedureId, subjectId],
    );
    const { isLoading, decisions, loadDecisions } = model;
    useEffect(() => {
        if (isOpen) {
            loadDecisions();
        }
    }, [isOpen]);

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen}>
            <DialogTitle>
                <FormattedMessage id="commissionSession.sessionProcedureSubjectList.privateDecisionsDialogTitle" />
            </DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Grid container justify="center" alignItems="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <PrivateDecisionsTable decisions={decisions} />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={close}>
                    <FormattedMessage id="common.ok" />
                </Button>
            </DialogActions>
        </Dialog>
    );
});
